<template>
  <div class="my-files-container">
    <v-row class="my-files-row">
      <v-col
        class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
        v-for="(file, i) in myFiles"
        :key="i"
      >
        <MyWsCard :cardData="file" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyWsCard from './MyWsCard.vue';

export default {
  name: 'MyWorkspaceFiles',
  data() {
    return { myFiles: [] };
  },
  components: {
    MyWsCard,
  },
  props: {
    files: {
      required: true,
    },
  },
  beforeMount() {
    this.myFiles = this.files;
  },
};
</script>

<style lang="scss" scoped>
.my-files-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 0;
  flex: 1;
  padding: 20px;
  // .row {
  //   height: 200px;
  //   overflow-y: auto;
  // }

  .my-files-row {
    width: 100%;
  }
}
</style>
