<template>
  <div class="upload-container">
    <v-btn :disabled="uploadInprogress" class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <h1 v-if="filess.length" class="manage-heading">
      {{ $t('overnightPresentations.uploadLocalFiles') }}
    </h1>
    <h1 v-else-if="link.web_url" class="manage-heading">
      {{ 'Processing current link' }}
    </h1>
    <div class="rating-cont" v-if="filess.length">
      <v-data-table
        :headers="listHeaders"
        :items="files"
        class="user-panel"
        hide-default-footer
        :no-results-text="$t('build.step1.storylineFieldNoResult')"
      >
        <template v-slot:[`item.fileName`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.fileName }}
              </div>
            </template>
            <span>{{ item.fileName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="status">
            <div>
              <div class="error" v-if="item.error">
                <v-icon :color="'rgb(211 48 47)'">{{
                  'mdi-close-circle'
                }}</v-icon>
              </div>
              <div class="success" v-else-if="item.processingCompleted">
                <v-icon :color="'#21a7e0'">{{ 'mdi-check-circle' }}</v-icon>
              </div>
              <v-progress-circular
                :value="item.progress"
                :color="'#21a7e0'"
                background-color="#f2f2f2"
                :width="3"
                :size="20"
                v-else
              />
            </div>
            <div>
              {{ item.status }}
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="rating-cont" v-else-if="link.web_url">
      <v-data-table
        :headers="listHeaders"
        :items="uploadedFileLink"
        class="user-panel"
        hide-default-footer
        :no-results-text="$t('build.step1.storylineFieldNoResult')"
      >
        <template v-slot:[`item.fileName`]="{ item }">
          <template v-if="item.fileName">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  {{ item.fileName }}
                </div>
              </template>
              <span>{{ item.fileName }}</span>
            </v-tooltip>
          </template>
          <template v-else> {{ 'Fetching file name...' }}</template>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="status">
            <div>
              <div class="error" v-if="item.error">
                <v-icon :color="'rgb(211 48 47)'">{{
                  'mdi-close-circle'
                }}</v-icon>
              </div>
              <div class="success" v-else-if="item.processingCompleted">
                <v-icon :color="'#21a7e0'">{{ 'mdi-check-circle' }}</v-icon>
              </div>
              <v-progress-circular
                indeterminate
                :color="'#21a7e0'"
                background-color="#f2f2f2"
                :width="3"
                :size="20"
                v-else
              />
            </div>
            <div>
              {{ item.status }}
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- <div
        class="errorTextContainer"
        v-if="
          files &&
          files.length > 0 &&
          files.filter(
            (fl) =>
              fl.error === true || fl.combinedSizeError === true || fl.error,
          ).length > 0 &&
          !uploadInprogress
        "
      >
        {{ getErrorMessage(files) }}
      </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import {
  uploadChunks,
  uploadOPFile,
  uploadLinks,
} from '../../../utils/api-helper.js';

export default {
  name: 'UploadMultiFiles',
  data() {
    return {
      listHeaders: [
        {
          text: 'File name',
          value: 'fileName',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
      ],
      files: [],
      uploadedFileLink: [],
      uploadInprogress: false,
    };
  },
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    filess: {
      type: Array,
      default: () => [],
    },
    updateLocalFiles: {
      type: Function,
      default: () => {},
    },
    updateUploadedLinks: {
      type: Function,
      default: () => {},
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),

    totalFilesSize() {
      let totalSize = 0;
      let allFiles = [];
      // TODO: See if we need a store to pass the files
      if (this.filess.length > 0) {
        allFiles = [...this.filess];
      }
      allFiles.forEach((file) => {
        totalSize += Number(file.size);
      });
      return totalSize;
    },
  },
  watch: {
    files: {
      handler(val) {
        if (val && val.length > 0) {
          let filesUploaded = 0;
          val.every((file) => {
            if (file.processingCompleted) {
              filesUploaded++;
            }
            return true;
          });
          if (filesUploaded === this.filess.length) {
            this.updateLocalFiles([...this.files].filter((fl) => !fl.error));
            setTimeout(() => {
              this.close();
            }, 1500);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    uploadedFileLink: {
      handler(val) {
        if (val && val.length > 0) {
          let filesLinkUploaded = 0;
          val.every((file) => {
            if (file.processingCompleted) {
              filesLinkUploaded++;
            }
            return true;
          });
          if (filesLinkUploaded === this.uploadedFileLink.length) {
            this.updateUploadedLinks([...this.uploadedFileLink]);
            setTimeout(() => {
              this.close();
            }, 1500);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$modal.hide('UploadMultiFiles');
    },
  },
  async mounted() {
    if (this.filess.length) {
      let totalSize = this.size;
      this.filess.forEach(async (i, index) => {
        if ((totalSize + i.size) / 1024 ** 2 > 200) {
          this.files.push({
            fileName: i.name,
            type: i.type,
            fileID: uuidv4(),
            errorType: 'size',
            status: 'File exceeds size limitations',
            processingCompleted: true,
            size: i.size,
            numOfPages: i.numOfPages,
            error: true,
          });
        } else if (
          !['ppt', 'pptx', 'doc', 'docx', 'txt', 'pdf', 'xlsx'].includes(
            i.name.split('.').pop(),
          )
        ) {
          this.files.push({
            fileName: i.name,
            type: i.type,
            fileID: uuidv4(),
            errorType: 'type',
            status: 'Unsupported file type',
            processingCompleted: true,
            size: i.size,
            error: true,
            numOfPages: i.numOfPages,
          });
        } else {
          totalSize += i.size;
          const file = {
            progress: 0,
            file: i,
            name: i.name,
            fileName: i.name,
            type: i.type,
            fileID: uuidv4(),
            requestIdentifier: uuidv4(),
            status: 'File is being processed',
            processingCompleted: false,
            size: i.size,
            error: false,
            numOfPages: i.numOfPages,
          };
          this.files.push(file);
          try {
            await uploadChunks(file, 'workspace');
            await uploadOPFile(file.requestIdentifier, file.name, 'workspace')
              .then((res) => {
                Vue.set(this.files, index, {
                  fileName: file.name,
                  type: file.type,
                  fileID: uuidv4(),
                  progress: res.data.progress,
                  s3Bucket: res.data.s3Bucket,
                  s3Prefix: res.data.s3Prefix,
                  sizeError: false,
                  status: 'File uploaded successfully',
                  processingCompleted: true,
                  numOfPages: res.data.numOfPages,
                  size: file.size,
                  error: false,
                });
              })
              .catch((err) => {
                Vue.set(this.files, index, {
                  fileName: file.name,
                  type: file.type,
                  fileID: uuidv4(),
                  errorType: 'upload',
                  status: 'File upload failed',
                  processingCompleted: true,
                  size: file.size,
                  error: true,
                  numOfPages: 0,
                });

                console.log(err);
              });
          } catch (err) {
            Vue.set(this.files, index, {
              fileName: file.name,
              type: file.type,
              fileID: uuidv4(),
              errorType: 'upload',
              status: 'File upload failed',
              processingCompleted: true,
              size: file.size,
              error: true,
              numOfPages: 0,
            });
            console.log(err);
          }
        }
      });
    }
    if (this.link && this.link?.web_url) {
      Vue.set(this.uploadedFileLink, 0, {});
      try {
        const { log } = await uploadLinks(this.link, 'workspace'); // TODO WS: Call Upload lik with retry operation
        if (log && log.fileName) {
          Vue.set(this.uploadedFileLink, 0, {
            fileName: log.fileName,
            progress: 20,
          });
          await uploadOPFile(
            this.link.requestIdentifier,
            log.fileName,
            'workspace',
          )
            .then((res) => {
              Vue.set(this.uploadedFileLink, 0, {
                fileName: log.fileName,
                type: `.${log.fileName?.split('.')[1]}`,
                fileID: uuidv4(),
                progress: res.data.progress,
                s3Bucket: res.data.s3Bucket,
                s3Prefix: res.data.s3Prefix,
                sizeError: false,
                status: 'Link processed successfully',
                processingCompleted: true,
                web_url: this.link?.web_url,
                numOfPages: res.data.numOfPages,
                size: res.data.sizeKb,
                error: false,
              });
            })
            .catch((err) => {
              Vue.set(this.uploadedFileLink, 0, {
                fileName: log.fileName,
                fileID: uuidv4(),
                errorType: 'upload',
                status: 'Link processing failed',
                processingCompleted: true,
                error: true,
                web_url: this.link?.web_url,
                numOfPages: 0,
              });
              console.log(err);
            });
        }
      } catch (err) {
        Vue.set(this.uploadedFileLink, 0, {
          fileID: uuidv4(),
          errorType: 'upload',
          status: 'Link processing failed',
          processingCompleted: true,
          error: true,
          web_url: this.link?.web_url,
          numOfPages: 0,
        });
        console.log(err);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-container {
  padding: 30px 20px;
  // background-color: white;
  .manage-heading {
    letter-spacing: normal;
    font-family: 'Lato';
    font-size: 24px;
  }
  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }
  .rating-cont {
    margin-top: 30px;
    width: 100%;
    ::v-deep .v-data-table {
      width: 100%;
      .v-data-table__wrapper {
        width: 100%;
        table {
          width: 100%;
          thead {
            width: 100%;
            display: block;
            tr {
              display: table;
              width: 100%;
              th:first-child {
                text-align: left !important;
                min-width: 280px;
                max-width: 280px;
                padding: 0px 16px 0px 0px;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
              th {
                width: 40%;
                text-align: left;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
            }
          }
          tbody {
            width: 100%;
            display: block;
            max-height: 200px;
            overflow: auto;
            tr {
              width: 100%;
              display: table;
              .text-start:first-child {
                min-width: 280px;
                max-width: 280px;
                border: none !important;
                padding: 0px 16px 0px 0px;
                div {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .text-start {
                width: 40%;
                border: none !important;
              }
              .text-start:last-child {
                text-align: left;
                border: none !important;
              }
            }
          }
        }
      }
    }
    .success {
      color: green;
    }
    .error {
      color: red;
    }
  }
  .status {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>
