<template>
  <div class="generate-slides" v-if="!isSlidesLimitCrosses">
    <div v-if="rightPanelComponent === 'promptExamples'">
      <div>
        <v-btn
          text
          color="primary"
          @click="closePromptExamples"
          class="back-btn"
        >
          <v-icon size="22">{{ 'mdi-chevron-left' }}</v-icon> Back
        </v-btn>
      </div>
      <SlideGeneratorProTips origin="prezentations" />
    </div>
    <AddNewSlide
      v-if="rightPanelComponent === 'AddSlides'"
      :slideCount="getTotalSlides"
      :promptDetails="changeReq"
      :isFromSmartTools="false"
      @change="handleGenerateSlides"
      origin="prezentations"
    />

    <MagicFill
      v-if="rightPanelComponent === 'MagicFill'"
      @panelChange="openContext"
      @change="handleGenerateSlides"
      :selectedSlide="getSlideDetailFinal"
      :promptDetails="changeReq"
      origin="prezentations"
    />

    <SmartCharts
      v-if="rightPanelComponent === 'smartCharts'"
      :slideType="selectedSlide.slide_type"
      @change="handleGenerateSlides"
      origin="prezentations"
    />

    <replace-visual
      v-if="rightPanelComponent === 'replaceVisual'"
      :slide="selectedSlide"
      :extractedImages="selectedSlide.extractedImages"
      ref="replaceVisual"
      @error="replaceImageError"
      @change="handleGenerateSlides"
      @toggleReplaceImage="onImageReplaced"
      @replaceStart="startReplaceVisuals"
      origin="prezentations"
    />
    <ChangeLayout
      v-if="rightPanelComponent === 'changeLayout'"
      :relatedSlides="selectedSlide ? selectedSlide.relatedSlides : []"
      @change="handleGenerateSlides"
      origin="prezentations"
    />
    <TableNodeCount
      v-if="
        rightPanelComponent === 'nodeCount' &&
        selectedSlide &&
        selectedSlide.slide_type === 'table'
      "
      :node="getNodeCount"
      @change="handleGenerateSlides"
    />
    <NodeCount
      v-if="
        rightPanelComponent === 'nodeCount' &&
        selectedSlide &&
        selectedSlide.slide_type !== 'table'
      "
      :nodeCount="selectedSlide.slide_node_count"
      :siblings="selectedSlide.siblings"
      :slideType="selectedSlide.slide_type"
      @change="handleGenerateSlides"
      origin="prezentations"
    />

    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="
        replaceVisualCancel ? replaceVisualCancel : $t('common.cancel')
      "
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
  <div v-else class="generate-slides">
    <div class="heading-block">
      <div class="heading-block__main">Generate slide</div>
      <div class="heading-block__sub">Powered by ASTRID™ AI</div>
    </div>
    <div v-if="!isSlidesLimitCrosses" class="loader-container">
      <clip-loader color="#21a7e0" :width="20" :height="20" />
    </div>
    <div
      class="suggest-block__summary-text"
      :style="{
        marginTop: '80px',
      }"
      v-else
    >
      <img src="/assets/img/compliance-check-failed.svg" alt />
      <br /><br />
      <div class="error-container">
        <div class="error-text">
          Generate slides is optimized for decks with up to 25 slides. Please
          consider removing some slides from your presentation and try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import EventBus from '../../../../common/event-bus';
import AddNewSlide from '../../../GenerateSlides/AddNewSlide.vue';
import {
  cancelGSRequest,
  checkBulkStatus,
  checkGSStatus,
  errorRequestLog,
  generateSlideCompleteLog,
  generateSlideRequest,
  generateSlideRequestLog,
  generateSlideUpdateLog,
  getFeatureUsage,
  getSlideDataForDetailView,
  getSlideDetail,
  postFeatureUsage,
  retryOperation,
  uploadChunks,
  uploadOPFile,
} from '../../../../../utils/api-helper';
import { getFileURL } from '../../../../../utils/calyrex';
import { GraphQLService } from '../../../../../services/GraphQLService';
import { getRelatedSlides } from '../../../../../graphql/queries';
import MagicFill from '../../../GenerateSlides/MagicFill.vue';
import SmartCharts from '../../../GenerateSlides/SmartCharts.vue';
import SlideGeneratorProTips from './SlideGeneratorProTips.vue';
import { calyrexConfig } from '../../../../../runtimeConfig';
import audienceApi from '../../../../../API/audience-api';
import { slideLimit } from '../../config/actionConfig';
import ChangeLayout from '../../../GenerateSlides/ChangeLayout.vue';
import NodeCount from '../../../GenerateSlides/NodeCount.vue';
import ReplaceVisual from '../../../GenerateSlides/ReplaceVisual/ReplaceVisual.vue';
import { triggerReplaceImage } from '../../../../../utils/replace-image-api-helper';
import ConfirmationDialog from '../../../../common/ConfirmationDialog.vue';
import TableNodeCount from '../../../GenerateSlides/TableNodeCount.vue';

export default {
  components: {
    AddNewSlide,
    SlideGeneratorProTips,
    MagicFill,
    SmartCharts,
    ChangeLayout,
    NodeCount,
    ReplaceVisual,
    ConfirmationDialog,
    TableNodeCount,
  },
  props: {
    origin: {
      type: String,
      default: 'Prezentation',
      required: false,
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      rightPanelComponent: 'AddSlides',
      slides: [],
      loading: false,
      pollingInterval: [],
      generateContext: {
        texts: { text: '' },
        links: [],
        files: [],
        extract_graph_data: false,
      },
      slideCallbackIds: [],
      sectionIndex: 0,
      slideIndex: 0,
      extractedImages: [],
      replaceVisualCancel: '',
      changeReq: null,
      showConfirmation: false,
      dialogPrimary: '',
      dialogText: '',
      dialogTitle: '',
      executionTime: {},
    };
  },
  beforeMount() {
    if (this.selectedSlideAction?.id === 'addSlideContext') {
      this.rightPanelComponent = 'MagicFill';
    } else if (this.selectedSlideAction?.id === 'selectChart') {
      this.rightPanelComponent = 'smartCharts';
    } else if (this.selectedSlideAction?.id === 'changeLayout') {
      this.rightPanelComponent = 'changeLayout';
    } else if (this.selectedSlideAction?.id === 'changeNodeCount') {
      this.rightPanelComponent = 'nodeCount';
    } else if (this.selectedSlideAction?.id === 'changeVisual') {
      this.rightPanelComponent = 'replaceVisual';
    } else {
      this.rightPanelComponent = 'AddSlides';
    }
    this.slides[0] = {
      ...this.selectedSlide,
      ...this.selectedSlide.slideDetails,
    };

    audienceApi.methods
      .audienceSearch({
        query: this.prezentationData.audienceID,
        companyId: this.currentUser.user.companyID,
        userId: this.currentUser.user.id,
        filterByCollection: {
          private_audiences: 'hasFingerPrint:=1',
        },
      })
      .then((audiences) => {
        audiences.forEach((aud) => {
          if (aud.email === this.prezentationData.audienceID) {
            this.audience = {
              id: aud.id,
              type: aud.audienceType,
              num_id: aud.num_id,
              ownerID: aud.ownerID,
            };
          }
        });
      });
  },
  watch: {
    selectedSlideAction(val) {
      if (val?.id === 'addSlideContext') {
        this.rightPanelComponent = 'MagicFill';
      } else if (val?.id === 'selectChart') {
        this.rightPanelComponent = 'smartCharts';
      } else if (val?.id === 'changeLayout') {
        this.rightPanelComponent = 'changeLayout';
      } else if (val?.id === 'changeNodeCount') {
        this.rightPanelComponent = 'nodeCount';
      } else if (val?.id === 'changeVisual') {
        this.rightPanelComponent = 'replaceVisual';
      } else {
        this.rightPanelComponent = 'AddSlides';
      }
    },
  },
  computed: {
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedSlideIndex',
      'selectedSectionIndex',
      'selectedMainAction',
      'selectedSlideAction',
      'actionCompletedInfo',
    ]),
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'themes',
      'limitsInfoObj',
    ]),
    ...mapGetters('users', [
      'isGenerateFullAccess',
      'getAGLiveSuggestionEnabled',
      'getAGLivePromptAccess',
    ]),
    isSlidesLimitCrosses() {
      return (
        this.prezentationData.totalSlides > slideLimit.autoGenerate &&
        this.selectedMainAction?.id === 'generateSlide'
      );
    },
    getNodeCount() {
      if (this.changeReq?.nodeCount) {
        return this.changeReq?.nodeCount;
      }
      return this.selectedSlide.slide_node_count;
    },
    getSlideDetailFinal() {
      return {
        ...this.selectedSlide,
        ...this.selectedSlide?.slideDetails,
      };
    },
    getTotalSlides() {
      let count = 0;

      this.prezentationData?.sections?.list?.forEach((section) => {
        count += section.slides.length;
      });
      return count;
    },
  },
  mounted() {
    EventBus.$on('PREZ_PROMPT_EXAMPLES', this.openPromptExamples);

    const prezentationDataFinal = JSON.parse(
      JSON.stringify(this.prezentationData),
    );

    this.sectionIndex = this.selectedSectionIndex;
    this.slideIndex = this.selectedSlideIndex;

    prezentationDataFinal.sections.list[this.sectionIndex].slides =
      prezentationDataFinal.sections.list[this.sectionIndex].slides.filter(
        (s) => !s.error && !s.isError,
      );

    this.updatePrezentationData(prezentationDataFinal);
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    ...mapActions('prezentationDetails', [
      'setLoadingAnimations',
      'setComplyDetails',
      'setPrezentationData',
      'updateActionCompletedInfo',
      'setSelectedSlideData',
      'updatePrezentationData',
      'setSelectedMainAction',
    ]),

    openPromptExamples() {
      this.rightPanelComponent = 'promptExamples';
    },
    closePromptExamples() {
      this.rightPanelComponent = 'AddSlides';
    },

    onDialogConfirm() {
      console.log(this.changeReq);
      this.showConfirmation = false;
      if (this.changeReq.type === 'clear_context') {
        this.clearContext();
      } else {
        this.generateSlide(this.changeReq);
      }
    },
    onDialogCancel() {
      this.changeReq = null;
      this.showConfirmation = false;
    },

    onImageReplaced(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = 'Exit Replace Visuals';
      this.dialogText = `Any changes to the slide will not be saved. Would you like to leave anyway?`;
      this.dialogPrimary = 'Yes';
      this.replaceVisualCancel = 'No';
    },
    async startReplaceVisuals(val) {
      this.changeReq = {
        type: 'replaceVisuals',
      };
      this.error = null;
      this.replacingImage = true;
      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          showBlast: true,
          type: 'generate',
        },
      });
      this.generatingNewSlide = true;
      const selectedSlideID = this.selectedSlide.id;
      const ind = this.slides.findIndex((i) => i.id === selectedSlideID);
      this.slides[ind].completed = false;
      const resp = await triggerReplaceImage(
        val.imageToReplace,
        // passing origin feature in replace image payload
        { ...val.currentSelectedSlideImageData, originFeature: 'AutoGenerate' },
        this.slides[ind],
        this.currentUser,
      );
      const idx = this.slides.findIndex((i) => i.id === selectedSlideID);
      if (!this.error && !resp.error) {
        if (!this.slides[idx].completed) {
          Vue.set(this.slides, idx, {
            ...this.slides[idx],
            lastSuccessfulSlide: {
              ...this.slides[idx],
              slide_path: resp.path,
              slide_thumbnail: resp.thumbnail,
              landscape: resp.landscape,
              completed: true,
              error: false,
            },
            slide_path: resp.path,
            slide_thumbnail: resp.thumbnail,
            landscape: resp.landscape,
            completed: true,
            error: false,
          });
          this.generatingNewSlide = false;
          this.replacingImage = false;
          this.$refs.replaceVisual?.selectImage(
            val.imageToReplace,
            val.currentSelectedSlideImageData,
            selectedSlideID,
          );

          this.postSlideLog('replaceVisuals', this.slides[idx]);
          this.updatePrezentationGenerated();
        }
      } else if (resp.error && !this.slides[idx].completed) {
        this.slides[idx].completed = true;
        this.slides[idx].error = true;
        this.replacingImage = false;
        this.postSlideLog('replaceVisuals', this.slides[idx]);
      }
      this.setLoadingAnimations({
        isLoading: false,
        animationsData: {},
      });
    },
    async createTextFile(text) {
      const id = uuidv4();
      let txt = {
        file: new Blob([text], { type: 'text/plain' }),
        name: `text_${id}.txt`,
        path: `text_${id}`,
        requestIdentifier: id,
      };
      await uploadChunks(txt, 'generate');
      await uploadOPFile(txt.requestIdentifier, txt.name, 'generate').then(
        (res) => {
          txt = {
            ...txt,
            ...res.data,
            processingCompleted: true,
          };
        },
      );
      return {
        text_id: txt.requestIdentifier,
        file_name: txt.name,
        file_type: 'text/plain',
        s3_path: txt.s3Prefix,
        s3_bucket: txt.s3Bucket,
        source: 'upload',
      };
    },

    clearContext() {
      this.generateContext = {
        texts: { text: '' },
        links: [],
        files: [],
        extract_graph_data: false,
      };
      if (this.slides.length) {
        this.slides.forEach((slide) => {
          slide.context = {
            texts: { text: '' },
            files: [],
            links: [],
            instructions: '',
            extractedImages: [],
            linkAccess: false,
            extract_graph_data: false,
          };
        });
      }
    },
    replaceImageError({ id }) {
      const ind = this.slides.findIndex((i) => i.id === id);
      this.slides[ind].completed = true;
      this.slides[ind].error = true;
      this.replacingImage = false;
    },
    async handleGenerateSlides(changeReq) {
      this.changeReq = { ...this.changeReq, ...changeReq };
      if (changeReq.type === 'add_new_slide') {
        this.updateActionCompletedInfo({
          ...this.actionCompletedInfo,
          promptText: this.changeReq.prompt,
          changeReq,
        });
      } else {
        this.updateActionCompletedInfo({
          ...this.actionCompletedInfo,
          changeReq,
        });
      }

      switch (changeReq.type) {
        case 'clear_context':
          this.dialogTitle =
            'Are you sure you would like to clear the context provided?';
          this.dialogText = `The text, cloud or web links, and local files you provided as context for this deck or for individual slides will be cleared, and all the content will be regenerated. `;
          this.dialogPrimary = 'Clear context';
          this.showConfirmation = true;
          break;
        case 'add_new_slide':
          this.generateDeck(changeReq);
          break;
        case 'context_change':
          this.generateSlide(changeReq);
          break;

        case 'node_change':
          this.dialogTitle =
            'Are you sure you would like to change the node count? ';
          this.dialogText =
            'Changing the node count may result in the loss of any text on the current slide.';
          this.dialogPrimary = 'Change node count';
          this.showConfirmation = true;
          break;
        case 'smart_chart_change':
        case 'slide_change':
          this.dialogTitle =
            'Are you sure you would like to change the layout? ';
          this.dialogText =
            'Changing the layout may result in the loss of any text on the current slide.';
          this.dialogPrimary = 'Change layout';
          this.showConfirmation = true;

          break;

        default:
          break;
      }
    },

    openContext(panel) {
      console.log(panel);
      // this.contextPanel = panel;
      // if (this.rightPanelComponent !== 'MagicFill') {
      //   this.openRightPanel('MagicFill');
      // }
    },
    async generateSlide(changeReq) {
      this.generatingNewSlide = true;

      const selectedSlideID =
        this.selectedSlide.id || this.selectedSlide?.slideDetails?.id;
      const targetSlide = this.selectedSlide?.slideDetails;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.generate?.isLimitCrossed ||
        this.limitsInfoObj?.generate?.isFullAccess
      ) {
        this.setLoadingAnimations({
          isLoading: true,
          animationsData: {
            showBlast: true,
            type: 'generate',
          },
        });
        postFeatureUsage({ feature: 'generate' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));
        targetSlide.completed = false;
        targetSlide.slideDetails = changeReq.slide || targetSlide.slideDetails;
        targetSlide.error = null;
        if (
          changeReq.type === 'context_change' &&
          changeReq.context.texts.text.length &&
          changeReq.context.texts.text !== targetSlide.context.texts?.text
        ) {
          const file = await this.createTextFile(changeReq.context.texts.text);
          changeReq.context.texts = {
            ...file,
            text: changeReq.context.texts.text,
          };
        }

        const prezentationDataFinal = JSON.parse(
          JSON.stringify(this.prezentationData),
        );

        prezentationDataFinal.sections.list[this.sectionIndex].slides[
          this.slideIndex
        ] = {
          ...prezentationDataFinal.sections.list[this.sectionIndex]?.slides[
            this.slideIndex
          ],
          loading: true,
          thumbnail: '',
        };

        this.updatePrezentationData(prezentationDataFinal);

        const body = await this.getGenerateDeckRequest(changeReq);
        generateSlideRequest(body).then(() => {
          targetSlide.completed = false;
          targetSlide.slideDetails =
            changeReq.slide || targetSlide.slideDetails;
          targetSlide.error = null;
          targetSlide.job_callbak_id = null;

          checkGSStatus(body.callback_id, (interval) => {
            this.pollingInterval.push(interval);
          })
            .then(async (resp) => {
              const jobID =
                resp.outputs.SlideTrigger.output.slides[0].job_callbak_id;
              if (this.slides.length === 1) {
                this.executionTime = {
                  execution_start: resp.execution_start,
                  execution_end: resp.execution_end,
                };
                generateSlideRequestLog({
                  prompt: this.actionCompletedInfo?.promptText,
                  uuid: jobID,
                  source: 'Prezentation',
                  extractGraphs: false,
                })
                  .then(() => {})
                  .catch((error) => {
                    console.log(error);
                  });
              }

              const extractedImages =
                resp.outputs.SlideAggregator.output.extracted_images;
              extractedImages.forEach(async (image, index) => {
                if (!this.extractedImages.find((i) => i.s3_path === image)) {
                  const extImg = await getFileURL(
                    this.currentUser.user.cognitoID,
                    `/${image}`,
                    this.currentUser.userIp,
                  );
                  this.extractedImages.unshift({
                    id: index,
                    url: extImg,
                    s3_path: image,
                  });
                }
              });
              targetSlide.job_callbak_id = jobID;
              const links =
                resp.outputs.SlideAggregator.output.processed_files?.links ||
                changeReq.context.links;

              try {
                const slide = await this.getSlideInfo(targetSlide);
                const ind = this.slides.findIndex(
                  (j) => j.id === selectedSlideID,
                );
                this.slides[ind] = { ...slide };

                // const slides = this.slides.map((i) => ({
                //   uniqueID: i.slideDetails.unique_id,
                //   category: i.slideDetails.categories[0],
                //   construct: i.slideDetails.constructs[0],
                //   assetID: i.slide_thumbnail,
                //   fileName: i.slide_path,
                //   thumbnail: i.slide_thumbnail,
                //   node: i.slide_node_count,
                //   mlTags: {
                //     uniqueID: i.slideDetails?.unique_id,
                //     node: i.slide_node_count,
                //     construct: i.slideDetails?.constructs.join(', ') || '',
                //     ...i.slideDetails?.prefs,
                //     source: 'generated',
                //   },
                // }));

                Vue.set(this.slides, ind, {
                  ...slide,
                  extractedImages,
                  completed: true,
                  error: false,
                  ...(changeReq.type === 'context_change' && {
                    context: { ...changeReq.context, links },
                  }),
                  lastSuccessfulSlide: {
                    ...slide,
                    completed: true,
                    error: false,
                    ...(changeReq.type === 'context_change' && {
                      context: { ...changeReq.context, links },
                    }),
                  },
                });

                this.updatePrezentationGenerated();

                this.generatingNewSlide = false;
              } catch (err) {
                console.log(err);

                const ind = this.slides.findIndex(
                  (j) => j.id === selectedSlideID,
                );
                Vue.set(this.slides, ind, {
                  ...this.slides[ind],
                  completed: true,
                  error: true,
                  job_callbak_id: jobID,
                  assetId: '/assets/img/generate/prompt_error.svg',
                  thumbnail: '/assets/img/generate/prompt_error.svg',
                });

                this.updateFailedSlideInPrezentation();
                this.selectedSlideID = this.slides[ind].id;
                this.generatingNewSlide = false;
              }
            })
            .catch((err) => {
              console.log(err);

              this.setLoadingAnimations({
                isLoading: false,
                animationsData: {},
              });
              const ind = this.slides.findIndex(
                (j) => j.id === selectedSlideID,
              );
              Vue.set(this.slides, ind, {
                ...this.slides[ind],
                completed: true,
                error: err.log === 'sb-toxicity' ? 'TOXICERR' : true,
                assetId:
                  err.log === 'sb-toxicity'
                    ? '/assets/img/generate/toxic_error.svg'
                    : '/assets/img/generate/prompt_error.svg',
                thumbnail:
                  err.log === 'sb-toxicity'
                    ? '/assets/img/generate/toxic_error.svg'
                    : '/assets/img/generate/prompt_error.svg',
              });

              this.updateFailedSlideInPrezentation();
            });
        });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      }
    },

    async getSlideInfo(slide) {
      const response = await checkGSStatus(slide.job_callbak_id, (interval) => {
        this.pollingInterval.push(interval);
      });
      return this.getSlideDetails(slide, {
        ...response.outputs,
        execution_end: response.execution_end,
        execution_start: response.execution_start,
      });
    },
    async getSlideDetails(slide, response) {
      const slideInfo = {
        ...slide,
        ...response.SlideAggregator.output,
        execution_end: response.execution_end,
        execution_start: response.execution_start,
      };
      const landscape = await retryOperation(
        () =>
          getFileURL(
            this.currentUser.user.cognitoID,
            slideInfo.slide_thumbnail,
            this.currentUser.userIp,
            'IOCUpload',
          ),
        2000,
        3,
      );
      slideInfo.landscape = landscape;
      if (slideInfo.slide_type === 'table') {
        slideInfo.slideDetails = {
          prefs: {},
          constructs: ['table'],
          categories: ['table'],
        };
        slideInfo.siblings = [];
      } else {
        const slideId = slideInfo.slide_source;
        const slideResp = await retryOperation(
          () => getSlideDataForDetailView(slideId),
          2000,
          3,
        );
        const genSlide = slideResp.data;

        slideInfo.slideDetails = genSlide;

        const uniqueId =
          genSlide.parent && genSlide.parent.unique_id
            ? genSlide.parent.unique_id
            : slideId;
        const sid =
          genSlide.parent && genSlide.parent.sid
            ? genSlide.parent.sid
            : this.changeReq.slide.parent.sid;
        const siblings = await retryOperation(
          () => this.getSiblings(uniqueId, sid),
          2000,
          3,
        );
        slideInfo.siblings = siblings.filter((i) =>
          ['process', 'timeline'].includes(slideInfo.slide_type)
            ? i.asset.prefs.node > 2 && i.asset.prefs.node <= 12
            : true,
        );
      }
      const reccos = await retryOperation(
        () => this.getRecommendedSlides(slideInfo.slide_recommendations),
        2000,
        3,
      );
      slideInfo.relatedSlides = reccos;

      return slideInfo;
    },

    getSiblings(payload, sid) {
      const getSlides = (resolve, reject) => {
        const related = [];
        related.push({
          unique_id: payload,
          keytype: 'K_SLIDEDATA',
          sid,
          theme: this.template || this.prezentationData.theme,
        });
        const params = {
          username: this.currentUser.user.cognitoID,
          related,
        };
        const serialized = JSON.stringify(params);

        GraphQLService.requestWithToken(
          graphqlOperation(getRelatedSlides, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.getRelatedSlides);
            if (result.statusCode === 200) {
              const assets = [];
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                reject(assets);
              } else {
                for (const slide of slides) {
                  const obj = {};
                  obj.asset = slide;
                  obj.asset.name = obj.asset.title;
                  obj.asset.file = obj.asset.filename;
                  obj.asset.pptFile = obj.asset.file;
                  obj.landscape = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.landscape,
                    this.currentUser.userIp,
                  );
                  obj.poster = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.poster,
                    this.currentUser.userIp,
                  );
                  assets.push(obj);
                }

                resolve(
                  assets.sort(
                    (a, b) =>
                      parseInt(a.asset.prefs.node, 10) -
                      parseInt(b.asset.prefs.node, 10),
                  ),
                );
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },
    getRecommendedSlides(slideIds) {
      const recommendSlides = (resolve, reject) => {
        getSlideDetail({
          assetIds: slideIds.map((i) => i.id),
          getMeta: true,
          themeID: this.template || this.prezentationData.theme,
        })
          .then((resp) => {
            const { data } = resp;
            const recSlides = [];
            Object.keys(data).forEach(async (key) => {
              const { meta } = data[key];
              if (meta) {
                meta.landscapePath = await getFileURL(
                  this.currentUser.user.cognitoID,
                  meta.landscape,
                  this.currentUser.userIp,
                );
                meta.thumbnailPath = await getFileURL(
                  this.currentUser.user.cognitoID,
                  meta.thumbnail,
                  this.currentUser.userIp,
                );
                recSlides.push(meta);
              }
            });
            resolve(recSlides);
          })
          .catch((err) => {
            reject(err);
          });
      };
      return new Promise(recommendSlides);
    },

    async generateDeck(change) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.generate?.isLimitCrossed ||
        this.limitsInfoObj?.generate?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'generate' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));
        this.setLoadingAnimations({
          isLoading: true,
          animationsData: {
            showBlast: true,
            type: 'generate',
          },
        });

        let slideCount = 0;
        const prezentationDataFinal = JSON.parse(
          JSON.stringify(this.prezentationData),
        );

        prezentationDataFinal.sections.list?.forEach((section, secIndex) => {
          section.slides.forEach((slide, sliIndex) => {
            if (this.changeReq.slidePos - 1 === slideCount) {
              this.sectionIndex = secIndex;
              this.slideIndex = sliIndex;
            }
            slideCount++;
          });
        });

        if (this.changeReq.slidePos - 1 >= slideCount) {
          this.sectionIndex = prezentationDataFinal.sections.list.length - 1;
          this.slideIndex =
            prezentationDataFinal.sections.list[
              this.sectionIndex
            ].slides.length;
        }
        const newSlide = {
          assetId: '/assets/img/slides/placeholder-slide.svg',
        };

        this.setSelectedSlideData({
          data: { ...newSlide },
          sectionIndex: this.sectionIndex,
          slideIndex: this.slideIndex,
        });

        prezentationDataFinal.sections.list[this.sectionIndex].slides.splice(
          this.slideIndex,
          0,
          newSlide,
        );

        prezentationDataFinal.sections.list.forEach((sec, i) => {
          prezentationDataFinal.sections.list[i].slides =
            prezentationDataFinal.sections.list[i].slides.map((sld) => {
              const { slideDetails, ...otherData } = sld;
              return {
                ...otherData,
              };
            });
        });

        this.updatePrezentationData(prezentationDataFinal);

        this.imageRendered = false;
        // this.disableActions = true;
        this.selectedSlideID = null;
        const body = await this.getGenerateDeckRequest(change, false);
        this.slides = [];
        this.loading = true;
        this.uuid = body.callback_id;
        this.updateActionCompletedInfo({
          ...this.actionCompletedInfo,
          uuid: body.callback_id,
        });
        this.error = null;
        generateSlideRequest(body)
          .then(() => {
            generateSlideRequestLog({
              prompt: this.changeReq.prompt,
              uuid: body.callback_id,
              files: this.generateContext.files,
              texts: (({ text, ...o }) => o)(this.generateContext.texts),
              links: this.generateContext.links,
              suggestionsIgnored: undefined,
              referenceId: this.uuid ? this.uuid : undefined,
              extractGraphs: this.generateContext.extract_graph_data,
              source: 'Prezentation',
            })
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                console.log(error);
              });

            this.handleDeck(body.callback_id);
          })
          .catch((err) => {
            console.log(err);
            if (this.slides.length) {
              this.error = true;
              this.loading = false;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
            }
            Vue.set(this.slides, 0, {
              ...this.slides[0],
              error: this.error,
              completed: true,
              processing: false,
              assetId: '/assets/img/generate/prompt_error.svg',
              thumbnail: '/assets/img/generate/prompt_error.svg',
            });

            this.updateFailedSlideInPrezentation();
          });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      }
    },

    handleDeck(id) {
      checkGSStatus(
        id,
        (interval) => {
          this.uuid = id;

          this.updateActionCompletedInfo({
            ...this.actionCompletedInfo,
            uuid: id,
          });
          this.pollingInterval.push(interval);
        },
        async (res) => {
          try {
            const slideOutput =
              res.outputs.SlideTrigger && res.outputs.SlideTrigger.output
                ? res.outputs.SlideTrigger.output
                : null;
            if (
              slideOutput &&
              slideOutput.slides &&
              slideOutput.slides.length
            ) {
              if (this.slides.length === 0) {
                const slideCount = 1; // slideOutput.slides[0].b_total_slides;
                this.slides = Array.from(Array(slideCount)).map((i, ind) => ({
                  ...i,
                  slide_index: ind,
                  id: uuidv4(),
                  completed: false,
                  siblings: [],
                  relatedSlides: [],
                  context: {
                    texts: { text: '' },
                    files: [],
                    links: [],
                    instructions: '',
                    extractedImages: [],
                    extract_graph_data: false,
                  },
                }));
                this.selectedSlideID = this.slides[0].id;
                this.loading = false;
              }
              // const slides = slideOutput.map((i) => i.slides).flat();
              const sld = slideOutput.slides[0];
              this.slideCallbackIds = [sld];
              Vue.set(this.slides, 0, {
                ...this.slides[0],
                job_callbak_id: sld.job_callbak_id,
                processing: true,
              });
              // slideOutput.slides.forEach((sld) => {
              //   const slideIndex = sld.slide_index;
              //   if (
              //     !this.slides[slideIndex].job_callbak_id &&
              //     !this.slides[slideIndex].processing
              //   ) {
              //     const ind = this.slides.findIndex(
              //       (j) => j.slide_index === slideIndex,
              //     );
              //     this.slideCallbackIds.push(sld);
              //     Vue.set(this.slides, ind, {
              //       ...this.slides[ind],
              //       job_callbak_id: sld.job_callbak_id,
              //       processing: true,
              //     });
              //   }
              // });

              await this.startBulkStatus();
            }
          } catch (err) {
            console.log(err);
            if (this.slides.length) {
              this.loading = false;
              this.error = true;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
              // this.reportErrorRequest(this.uuid, 'Prompt_Error');
            }
            Vue.set(this.slides, 0, {
              ...this.slides[0],
              error: this.error,
              completed: true,
              processing: false,
              assetId: '/assets/img/generate/prompt_error.svg',
              thumbnail: '/assets/img/generate/prompt_error.svg',
            });

            this.updateFailedSlideInPrezentation();
          }
        },
      )
        .then(async (resp) => {
          try {
            const { meta } = resp.outputs.SlideAggregator.output;
            this.filename = meta.filename;
            this.description = meta.description;
            this.animateProTip =
              (meta.prompt_category || 'none_of_the_above') !== this.proTip;
            this.proTip = meta.prompt_category || 'none_of_the_above';
            this.executionTime = {
              execution_start: resp.execution_start,
              execution_end: resp.execution_end,
            };
            this.generateType = meta.generation_type;
            const extractedImages =
              resp.outputs.SlideAggregator.output.extracted_images;
            extractedImages.forEach(async (image, index) => {
              if (!this.extractedImages.find((i) => i.s3_path === image)) {
                const extImg = await getFileURL(
                  this.currentUser.user.cognitoID,
                  `/${image}`,
                  this.currentUser.userIp,
                );
                this.extractedImages.push({
                  id: index,
                  url: extImg,
                  s3_path: image,
                });
              }
            });
            this.generateContext = {
              ...this.generateContext,
              links:
                resp.outputs.SlideAggregator.output.processed_files?.links ||
                this.generateContext.links,
            };
            const { audience } = resp.outputs.SlideAggregator.output;
            this.audience = {
              id: audience.audience_id,
              type: audience.audience_type,
              num_id: audience.audience_num_id,
              ownerID: audience.audience_owner_id,
            };
            this.template =
              resp.outputs.SlideAggregator.output.template.template_code;
          } catch (err) {
            console.log(err);
            if (this.slides.length) {
              this.loading = false;
              this.error = true;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
              // this.reportErrorRequest(this.uuid, 'Prompt_Error');
            }
            Vue.set(this.slides, 0, {
              ...this.slides[0],
              error: this.error,
              completed: true,
              processing: false,
              assetId: '/assets/img/generate/prompt_error.svg',
              thumbnail: '/assets/img/generate/prompt_error.svg',
            });

            this.updateFailedSlideInPrezentation();
          }
        })
        .catch((err) => {
          console.log(err);
          if (this.slides.length) {
            this.loading = false;
            this.error = true;
            this.cancelRequest();
          } else if (err.log === 'sb-suggestions') {
            this.loading = false;
            this.error = true;
            this.generateLoader.nextState(0);
            // this.liveSuggestions = [...err.liveSuggestions];
            // this.proTip = err.promptCategory || 'none_of_the_above';
            // const payload = {
            //   promptSuggestions: this.liveSuggestions,
            //   uuid: this.uuid,
            //   status: 'PromptSuggested',
            //   promptCategory: err.promptCategory,
            // };
            // generateSlideCompleteLog(payload).then((res) => {
            //   console.log(res, 'complete log');
            // });
            // this.showLiveProTip = true;
          } else {
            this.error = err.log === 'sb-toxicity' ? 'TOXICERR' : 'PROMPTERR';
            this.loading = false;
            // this.reportErrorRequest(this.uuid, 'Prompt_Error');
          }
          Vue.set(this.slides, 0, {
            ...this.slides[0],
            error: this.error,
            completed: true,
            processing: false,
            assetId:
              err.log === 'sb-toxicity'
                ? '/assets/img/generate/toxic_error.svg'
                : '/assets/img/generate/prompt_error.svg',
            thumbnail:
              err.log === 'sb-toxicity'
                ? '/assets/img/generate/toxic_error.svg'
                : '/assets/img/generate/prompt_error.svg',
          });

          this.updateFailedSlideInPrezentation();
        });
    },

    async startBulkStatus() {
      try {
        const callbackIds = this.slideCallbackIds.map(
          (slide) => slide.job_callbak_id,
        );

        const response = await checkBulkStatus(callbackIds);
        for (const slide of this.slideCallbackIds) {
          const result = response[slide.job_callbak_id];
          const status = result?.workflow_status;
          if (status === 'success') {
            // eslint-disable-next-line camelcase
            const { outputs, execution_end, execution_start } = result;
            this.getSlideDetails(slide, {
              ...outputs,
              execution_end,
              execution_start,
            })
              .then((newSlide) => {
                const slideIndex = this.slides.findIndex(
                  (s) => s.job_callbak_id === slide.job_callbak_id,
                );
                Vue.set(this.slides, slideIndex, {
                  ...this.slides[slideIndex],
                  ...newSlide,
                  lastSuccessfulSlide: {
                    ...this.slides[slideIndex],
                    ...newSlide,
                    error: false,
                    completed: true,
                    processing: false,
                  },
                  context: { ...this.changeReq.context, instructions: '' },
                  error: false,
                  completed: true,
                  processing: false,
                });
                this.updatePrezentationGenerated();
              })
              .catch((err) => {
                console.log(err);
                const slideIndex = this.slides.findIndex(
                  (s) => s.job_callbak_id === slide.job_callbak_id,
                );
                Vue.set(this.slides, slideIndex, {
                  ...this.slides[slideIndex],
                  error: true,
                  completed: true,
                  processing: false,
                  assetId: '/assets/img/generate/prompt_error.svg',
                  thumbnail: '/assets/img/generate/prompt_error.svg',
                });

                this.updateFailedSlideInPrezentation();
              });
          }
          if (status === 'failed') {
            const slideIndex = this.slides.findIndex(
              (s) => s.job_callbak_id === slide.job_callbak_id,
            );
            console.log('Error in slide', slideIndex + 1);
            Vue.set(this.slides, slideIndex, {
              ...this.slides[slideIndex],
              error: true,
              completed: true,
              processing: false,
              assetId: '/assets/img/generate/prompt_error.svg',
              thumbnail: '/assets/img/generate/prompt_error.svg',
            });

            this.updateFailedSlideInPrezentation();
          }
          // Remove from callback list
          if (['success', 'failed'].includes(status)) {
            this.slideCallbackIds = this.slideCallbackIds.filter(
              (sld) => sld.job_callbak_id !== slide.job_callbak_id,
            );
          }
        }
        if (this.slideCallbackIds.length) {
          setTimeout(async () => {
            await this.startBulkStatus();
          }, 2000);
        } else {
          this.statusApiInProgress = false;
        }
      } catch (err) {
        console.log(err);
        this.cancelRequest();
      }
    },
    updatePrezentationGenerated() {
      const i = this.slides[0];
      const slideData = {
        ...i,
        extractedImages: this.extractedImages,
        uniqueID: i.slideDetails.unique_id,
        category: i.slideDetails.categories[0],
        construct: i.slideDetails.constructs[0],
        assetId: i.slide_thumbnail,
        fileName: i.slide_path,
        filename: i.slide_path,
        file: i.slide_path,
        thumbnail: i.landscape,
        node: i.slide_node_count,
        mlTags: {
          uniqueID: i.slideDetails?.unique_id,
          node: i.slide_node_count,
          construct: i.slideDetails?.constructs.join(', ') || '',
          ...i.slideDetails?.prefs,
          source: 'generated',
        },
        isGenerated: true,
        isGeneratedOnPrez: true,
        source: 'generated',
        job_callbak_id: i.job_callbak_id,
        slideDetails: i,
        loading: false,
      };

      const prezentationDataFinal = JSON.parse(
        JSON.stringify(this.prezentationData),
      );

      prezentationDataFinal.sections.list[this.sectionIndex].slides[
        this.slideIndex
      ] = slideData;
      prezentationDataFinal.convertedTo = 'Prez Generated';

      this.updatePrezentation(prezentationDataFinal);
      this.postSlideLog(this.changeReq.type, this.slides[0]);

      this.updateActionCompletedInfo({
        ...this.actionCompletedInfo,
        generateCompleted: true,
      });

      if (this.changeReq.type === 'add_new_slide') {
        this.setSelectedMainAction({
          id: 'editSlide',
          labelText: 'Edit slide',
          tooltipText: 'Edit slide',
          icon: 'mdi-file-edit-outline',
          isCustomIcon: false,
          dataPendoId: 'story-builder-edit-slide',
        });
      }

      this.setLoadingAnimations({
        isLoading: false,
        animationsData: {},
      });
      // this.changeReq = null;
    },
    updateFailedSlideInPrezentation() {
      const i = this.slides[0];
      const slideData = {
        ...i,
      };
      const prezentationDataFinal = JSON.parse(
        JSON.stringify(this.prezentationData),
      );

      prezentationDataFinal.sections.list[this.sectionIndex].slides[
        this.slideIndex
      ] = slideData;
      this.reportErrorRequest(this.uuid, 'Prompt_Error');
      this.updatePrezentationData(prezentationDataFinal);

      this.setLoadingAnimations({
        isLoading: false,
        animationsData: {},
      });
    },
    reportErrorRequest(uuid, failedReason) {
      errorRequestLog({
        uuid,
        failedReason,
      }).then((resp) => {
        console.log(resp);
      });
    },
    async getGenerateDeckRequest(changeReq, promptSuggestion = false) {
      const uuid = uuidv4();
      // eslint-disable-next-line no-nested-ternary
      const context =
        changeReq.type === 'add_new_slide' ? changeReq.context : {};
      const req = {
        callback_id: uuid,
        report_id: uuid,
        workflow_name: 'db',
        workflow_context: {
          mandate: {
            audience: this.audience && {
              ...this.audience,
            },
            template: {
              template: this.template || this.prezentationData.theme,
            },
          },
          operation:
            changeReq.type === 'add_new_slide'
              ? 'new_generation'
              : changeReq.type,
          prompt: this.actionCompletedInfo.promptText,
          userID: this.currentUser.user.id,
          requesterCompanyCode: this.currentUser.company.name,
          template_code: this.template || this.prezentationData.theme,
          userNumID: this.currentUser.user.num_id,
          teamNumID: this.currentUser.team.num_id,
          companyNumID: this.currentUser.company.num_id,
          prompt_suggestions: promptSuggestion,
          override: {
            workflow:
              this.selectedSlide && this.selectedSlide.slide_callback_id
                ? this.selectedSlide.slide_callback_id
                : this.actionCompletedInfo.uuid,
            audience: changeReq.type === 'add_new_slide' ? null : this.audience,
            template: {
              template:
                changeReq.type === 'add_new_slide'
                  ? null
                  : this.template || this.prezentationData.theme,
            },

            ...(changeReq &&
              [
                'node_change',
                'slide_change',
                'context_change',
                'smart_chart_change',
              ].includes(changeReq.type) && {
                slide_override: {
                  workflow: this.selectedSlide?.job_callbak_id,
                  ...(changeReq.type === 'smart_chart_change' && {
                    slide_type: changeReq.slide_type || 'list',
                  }),
                  ...(changeReq.type === 'node_change' && {
                    node_count: changeReq.slide
                      ? parseInt(changeReq.slide.prefs.node, 10)
                      : changeReq.nodeCount,
                  }),
                  ...((changeReq.type === 'slide_change' ||
                    (changeReq.type === 'node_change' && changeReq.slide)) && {
                    slide: changeReq.slide.unique_id,
                  }),
                  ...(changeReq.type === 'context_change' && {
                    context: {
                      context: changeReq.context.instructions,
                      extract_graph_data: changeReq.context.extract_graph_data,
                      ...(changeReq.context.files?.length && {
                        files: changeReq.context.files.map((file) => ({
                          file_id: file.fileID,
                          file_name: file.fileName,
                          file_type: file.type,
                          s3_path: file.s3Prefix,
                          s3_bucket: file.s3Bucket,
                          source: 'upload',
                        })),
                      }),
                      ...(changeReq.context.links?.length && {
                        links: changeReq.context.links,
                      }),
                      ...(changeReq.context.texts?.text_id && {
                        texts: [
                          {
                            text_id: changeReq.context.texts.text_id,
                            file_name: changeReq.context.texts.file_name,
                            file_type: 'text/plain',
                            s3_path: changeReq.context.texts.s3_path,
                            s3_bucket: changeReq.context.texts.s3_bucket,
                            source: 'upload',
                          },
                        ],
                      }),
                    },
                  }),
                },
                slde_index: this.selectedSlide.slide_index || 0,
              }),
            ...(changeReq &&
              ['audience_change', 'template_change'].includes(
                changeReq.type,
              ) && {
                keep_slides: this.slides
                  .filter((i) => !i.error)
                  .map((i) => ({
                    workflow: i.job_callbak_id,
                  })),
              }),
          },
          ...(context.extract_graph_data !== undefined && {
            extract_graph_data: context.extract_graph_data,
          }),
          ...(context.files?.length && {
            files: context.files.map((file) => ({
              file_id: file.fileID,
              file_name: file.fileName,
              file_type: file.type,
              s3_path: file.s3Prefix,
              s3_bucket: file.s3Bucket,
              source: 'upload',
            })),
          }),
          ...(context.links?.length && {
            links: context.links,
          }),
          ...(context.texts?.text_id && {
            texts: [
              {
                text_id: context.texts.text_id,
                file_name: context.texts.file_name,
                file_type: 'text/plain',
                s3_path: context.texts.s3_path,
                s3_bucket: context.texts.s3_bucket,
                source: 'upload',
              },
            ],
          }),
        },
      };

      return req;
    },
    postSlideLog(operation, slide) {
      if (this.slides.length === 1) {
        this.postLog({ type: operation });
      } else {
        let req;
        if (slide.error) {
          req = {
            uuid: this.uuid,
            slide: {
              uuid: slide.job_callbak_id,
              operation,
              status: 'failed',
            },
          };
        } else {
          req = {
            uuid: this.uuid,
            slide: {
              uuid: slide.job_callbak_id,
              uniqueID: slide.slideDetails.unique_id,
              category: slide.slideDetails.categories[0],
              construct: slide.slideDetails.constructs[0],
              assetID: slide.slideDetails.unique_id,
              s3: slide.slide_path.startsWith('/newImageReplaceOutput/output/')
                ? calyrexConfig.calyrex_bucket_name
                : process.env.VUE_APP_MVP_ASSETS_BUCKET,
              fileName: slide.slide_path,
              thumbnail: slide.slide_thumbnail,
              node: slide.slide_node_count,
              image: slide.image_keyword,
              extractedImages: slide.extractedImages,
              secondaryPrompt:
                operation === 'context_change'
                  ? slide.context.instructions
                  : '',
              execution_end: moment.utc(
                slide.execution_end,
                'DD/MM/YY HH:mm:ss:SSSSSS',
              ),
              execution_start: moment.utc(
                slide.execution_start,
                'DD/MM/YY HH:mm:ss:SSSSSS',
              ),
              slideTitle: slide.slide_title,
              slideType: slide.slide_type,
              imageSource: slide.image_source,
              status: 'success',
              operation,
              ...((operation === 'context_change' ||
                operation === 'add_new_slide') && {
                files: slide.context.files,
                texts: [(({ text, ...o }) => o)(slide.context.texts)],
                links: slide.context.links,
              }),
            },
          };
        }
        generateSlideUpdateLog(req).then((res) => {
          console.log(res, 'slide complete log');
        });
      }
    },
    postLog(changeReq) {
      const req = this.getGenerateLog(changeReq);
      generateSlideCompleteLog(req).then((res) => {
        console.log(res, 'complete log');
      });
      // if (
      //   this.actionCompletedInfo.generateCompleted &&
      //   changeReq &&
      //   [
      //     'slide_change',
      //     'node_change',
      //     'context_change',
      //     'replaceVisuals',
      //     'smart_chart_change',
      //   ].includes(changeReq.type)
      // ) {
      //   const req = this.getGenerateLog(changeReq);
      //   generateSlideCompleteLog(req).then((res) => {
      //     console.log(res, 'complete log');
      //   });
      // }
    },
    getGenerateLog(changeReq) {
      const nonErrSlides = this.slides.filter((i) => !i.error);
      let maxExecTime = moment.utc(
        this.executionTime.execution_end,
        'DD/MM/YY HH:mm:ss:SSSSSS',
      );
      this.slides.forEach((slide) => {
        if (
          slide.execution_end &&
          (!maxExecTime ||
            moment.utc(slide.execution_end, 'DD/MM/YY HH:mm:ss:SSSSSS') >
              maxExecTime)
        ) {
          maxExecTime = moment.utc(
            slide.execution_end,
            'DD/MM/YY HH:mm:ss:SSSSSS',
          );
        }
      });
      return {
        uuid:
          this.slides.length === 1 &&
          changeReq &&
          changeReq.type &&
          [
            'smart_chart_change',
            'slide_change',
            'node_change',
            'context_change',
            'replaceVisuals',
          ].includes(changeReq.type)
            ? this.slides[0].job_callbak_id
            : this.uuid,
        audienceID: this.audience?.id,
        templateCode: this.template,
        name: this.filename,
        description: this.description,
        execution_start: moment.utc(
          this.executionTime.execution_start,
          'DD/MM/YY HH:mm:ss:SSSSSS',
        ),
        execution_end: maxExecTime,
        generateType: this.generateType,
        extractedImages: this.extractedImages.map((i) => i.s3_path),
        status:
          // eslint-disable-next-line no-nested-ternary
          nonErrSlides.length === 0
            ? 'Failed'
            : this.slides.length === nonErrSlides.length
            ? 'Success'
            : 'Partial', // Failed, Partail
        operation: changeReq ? changeReq.type : '', // TEMPLATE_CHANGED, AUDIENCE_CHANGED
        promptCategory: !changeReq ? this.proTip : null,
        slideMeta: {
          slides: this.slides.map((i) =>
            i.error
              ? {
                  uuid: i.job_callbak_id,
                  status: 'failed',
                }
              : {
                  execution_end: moment.utc(
                    i.execution_end,
                    'DD/MM/YY HH:mm:ss:SSSSSS',
                  ),
                  execution_start: moment.utc(
                    i.execution_start,
                    'DD/MM/YY HH:mm:ss:SSSSSS',
                  ),
                  slideTitle: i.slide_title,
                  slideType: i.slide_type,
                  imageSource: i.image_source,
                  uuid: i.job_callbak_id,
                  uniqueID: i.slideDetails.unique_id,
                  category: i.slideDetails.categories[0],
                  construct: i.slideDetails.constructs[0],
                  assetID: i.slideDetails.unique_id,
                  s3: i.slide_path.startsWith('/newImageReplaceOutput/output/')
                    ? calyrexConfig.calyrex_bucket_name
                    : process.env.VUE_APP_MVP_ASSETS_BUCKET,
                  fileName: i.slide_path,
                  thumbnail: i.slide_thumbnail,
                  node: i.slide_node_count,
                  image: i.image_keyword,
                  status: 'success',
                },
          ),
        },
        ...(!changeReq && {
          files: this.getDeckContext.files,
          texts: [(({ text, ...o }) => o)(this.getDeckContext.texts)],
          links: this.getDeckContext.links,
        }),
        mlResponse: {
          json: {},
        },
      };
    },
  },
  beforeDestroy() {
    EventBus.$off('PREZ_PROMPT_EXAMPLES', this.openPromptExamples);
    if (this.actionCompletedInfo.uuid) {
      this.uuid = this.actionCompletedInfo.uuid;
    }
    if (this.uuid && this.slides.length === 0) {
      cancelGSRequest(this.uuid).then((resp) => {
        console.log(resp);
      });
    }
    if (this.slides.length) {
      this.slides.forEach((slide) => {
        if (!slide.completed && slide.job_callbak_id) {
          cancelGSRequest(slide.job_callbak_id).then((resp) => {
            console.log(resp);
          });
        }
      });
    }
    this.pollingInterval.forEach((i) => {
      clearInterval(i);
    });
  },
};
</script>

<style lang="scss" scoped>
.generate-slides {
  .heading-block {
    &__main {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .suggest-block {
    align-self: stretch;
    &__summary-text {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      margin-bottom: 0;
    }
  }

  .loader-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-container {
    text-align: center;

    .error-text {
      margin: 20px auto;
      font-size: 14px;
    }
    .try-again-btn {
      text-align: center;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }
  }

  .back-btn {
    padding: 0px !important;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    margin-bottom: 10px;
    margin-left: -10px;
  }

  .add-slide-container {
    padding: 0 !important;

    ::v-deep .action-container {
      position: absolute !important;
      left: 0;
      background-color: white;
      width: 100% !important;
      z-index: 1;
    }
  }

  .prompt-example-container,
  .magic-fill-container,
  .node-count-container,
  .replace-visual,
  .change-layout-container {
    padding: 0 !important;
  }

  ::v-deep .magic-fill-container .addContextTabs {
    min-width: unset !important;

    .v-tabs-bar {
      display: none;
    }
  }

  ::v-deep .action-container {
    position: absolute !important;
    left: 0;
    background-color: white;
    width: 100% !important;
    z-index: 1;

    .regenerate-btn {
      height: 36px !important;
      font-size: 18px !important;
    }
  }

  ::v-deep .grid-container {
    padding: 0 !important;
    gap: 8px !important;
  }
  ::v-deep .grid-row {
    padding: 0 !important;
    gap: 8px !important;
  }

  ::v-deep .generate-btn {
    font-size: 18px !important;
  }
}
</style>
