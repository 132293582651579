<template>
  <div>
    <div v-if="!loading">
      <v-btn class="close-btn" icon @click="close">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <div class="course-overview-section">
        <div class="course-overview-section__title">
          {{ courseInfo.display_name }}
        </div>
        <div class="course-duration-section">
          <div style="display: flex; align-items: center">
            <v-icon> mdi-clock-time-eight-outline </v-icon>
            <p>
              {{ courseInfo.duration_seconds | secondsToHoursAndMinutes }}
            </p>
          </div>
          <v-icon class="course-duration-section__dot">
            mdi-circle-small
          </v-icon>
          <p class="course-duration-section__skill-covered">
            {{ $t('learn.course.skillCovered') }}: {{ skillsCovered }}
          </p>
        </div>
        <v-row>
          <v-col cols="7">
            <div class="course-details-section">
              <div class="course-detail__title">
                {{ $t('learn.course.courseDetails') }}
              </div>
            </div>
            <div class="course-detail__description">
              {{ courseInfo.detail }}
            </div>
            <div :class="getDivWithImgClass(courseInfo.is_locked)">
              <img
                :src="courseInfo.illustration_high"
                :alt="courseInfo.title"
                loading="lazy"
              />
            </div>
          </v-col>
          <v-col cols="5">
            <div class="module-details-section">
              <NewFeatureBadge
                module-name="learncourse"
                :feature-id="courseInfo.id"
                :offsetX="'-5'"
                :offsetY="'25'"
                :content="'common.newContent'"
              >
                <div class="module-detail__title">
                  {{ $t('learn.course.modules') }}
                </div>
              </NewFeatureBadge>
              <div class="module-detail__list">
                <v-list-item
                  :disabled="courseInfo.is_locked"
                  v-for="(item, index) in courseInfo.modules"
                  :key="index"
                  class="module-detail__list--item"
                >
                  <v-list-item-content class="module-detail__list__content">
                    <v-list-item-title
                      @click="goToCourse(courseInfo.id, item.id, item)"
                      class="module-detail__list__content__title-section"
                    >
                      <v-icon
                        :color="item.status < 100 ? '#d8d8d8' : '#21a7e0'"
                        class="icon"
                        >mdi-shield-star</v-icon
                      >
                      <a
                        class="module-detail__list__content__title-section--title"
                      >
                        {{ item.display_name }}
                      </a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
            <div class="learning-objectives-section">
              <div class="learning-objectives__title">
                {{ $t('learn.course.learningObjectives') }}
              </div>
              <div class="learning-objectives__list">
                <v-list-item
                  v-for="(item, index) in courseInfo.objectives"
                  :key="index"
                  class="learning-objectives__list--item"
                >
                  <v-list-item-content
                    class="learning-objectives__list__content"
                  >
                    <v-list-item-title
                      class="learning-objectives__list__content__title-section"
                    >
                      <v-icon color="#d8d8d8" class="icon">
                        mdi-lightbulb-variant-outline
                      </v-icon>
                      <span
                        class="module-detail__list__content__title-section--title"
                      >
                        {{ item.display_name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
            <div class="fellow-learners-section">
              <div class="fellow-learners-section__title">
                {{ $t('learn.course.fellowLearners') }}
              </div>
              <div class="fellow-learners-section__list">
                <UsersGroup
                  :usersInfo="leanersInfo"
                  :courseCompletionStatus="courseInfo.status"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-btn
          v-if="!courseInfo.is_locked"
          rounded
          height="50"
          color="#21a7e0"
          class="go-to-course-btn"
          data-pendo-id="learn-go-to-course"
          @click="goToCourse(courseInfo.id)"
        >
          {{ $t('learn.course.goToCourse') }}
        </v-btn>
        <v-btn
          v-else
          rounded
          height="50"
          color="#21a7e0"
          class="go-to-course-btn"
          @click="goToAdminTab()"
        >
          {{ $t('learn.course.upgrade') }}
        </v-btn>
      </div>
    </div>
    <div class="loading-section" v-else>
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            type="table-heading, list-item-two-line, image, table-tfoot"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getFileURL } from '@/utils/calyrex';
import UsersGroup from '../../common/UsersGroup.vue';
import LearnApi from '../../../API/learn-api.js';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents';

export default {
  components: { UsersGroup, NewFeatureBadge },
  name: 'CourseOverviewModal',
  mixins: [LearnApi],
  props: {
    courseInfo: {
      type: Object,
      required: true,
    },
    courseInfoForMatomo: {
      type: Object,
      required: false,
    },
    skillsCovered: {
      type: String,
      required: true,
    },
    modalClosed: {
      type: Function,
      default: () => ({}),
    },
    learnSnapShot: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      loading: true,
      leanersInfo: {},
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('learndata', ['saveLearnStateSnapshot']),
    close() {
      this.modalClosed();
      this.$modal.hide('CourseOverviewModal');
    },

    async getFellowLearners(courseId) {
      await LearnApi.methods.getFellowLearnerns(courseId).then(
        (resp) => {
          const colorArray = [
            '#58A8DE',
            '#65788D',
            '#7B7B7B',
            '#78CAEB',
            '#B7B7B7',
          ];
          let colorIdx = 0;
          if (resp.learners) {
            for (let idx = 0; idx < resp.learners.length; idx++) {
              resp.learners[idx].color = colorArray[colorIdx % 5];
              colorIdx++;
            }
          }
          const learnersResp = resp;
          if (learnersResp.learners && learnersResp.learners.length > 0) {
            learnersResp.learners.forEach((learner) => {
              learner.profileImageUrl = '';
              if (learner.profileImage && !learner.profileImageUrl) {
                getFileURL(
                  null,
                  `public/${learner.profileImage}`,
                  null,
                  'storage',
                ).then((s3url) => {
                  learner.profileImageUrl = s3url;
                });
              }
            });
          }
          this.leanersInfo = learnersResp;
        },
        (error) => {
          console.log('error while fetching fellow learners -- ', error);
        },
      );
    },

    goToCourse(courseId, mId, courseModule) {
      this.close();
      this.saveLearnStateSnapshot(this.learnSnapShot);
      if (mId) {
        MatomoAnalyticsHandler.learnCourseViewedExpandModuleClick(
          {
            ...this.courseInfoForMatomo,
            moduleName: courseModule.display_name,
          },
          this.currentUser,
        );
        trackLearnEvents.learnExpandedViewModuleClick(
          this.currentUser,
          this.courseInfoForMatomo,
        );
        this.$router.push({
          name: 'course.detail',
          params: { id: courseId, moduleId: mId },
        });
      } else {
        MatomoAnalyticsHandler.learnCourseViewedExpandGoButton(
          this.courseInfoForMatomo,
          this.currentUser,
        );
        trackLearnEvents.learnExpandedViewGoToCourse(
          this.currentUser,
          this.courseInfoForMatomo,
        );
        this.$router.push({
          name: 'course.detail',
          params: { id: courseId },
        });
      }
    },

    goToAdminTab() {
      this.close();
      this.$router.push('/profile?tab=admin', () => {});
    },

    getDivWithImgClass(isLocked) {
      if (isLocked) {
        return 'course-detail__image-blured';
      }
      return 'course-detail__image';
    },
  },
  async mounted() {
    this.loading = false;
    this.getFellowLearners(this.courseInfo.id); // getting fellow learners for the course with course id.
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.loading-section {
  margin: 30px;
}

.course-overview-section {
  margin: 35px;
  border-radius: 20px;
}

.course-overview-section__title {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.course-duration-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  &__dot {
    font-size: 30px;
  }

  &__skill-covered {
    margin-left: 0 !important;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $gray-dark-login-text;
    margin-left: 10px;
  }
}

.course-detail__title,
.module-detail__title,
.learning-objectives__title,
.fellow-learners-section__title {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.learning-objectives__title {
  padding-top: 10px;
}

.course-detail__description {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.course-detail__image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 20px 20px 20px;

  img {
    width: 375px;
  }
}

.course-detail__image-blured {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 20px 20px 20px;

  img {
    width: 375px;
    opacity: 0.3;
  }
}

.module-details-section,
.learning-objectives-section {
  margin-bottom: 20px;
}

.module-detail__list {
  .module-detail__list__content__title-section--title {
    color: #21a7e0;
    text-decoration: none;
    cursor: pointer;
  }
}

.module-detail__list,
.learning-objectives__list {
  &__content {
    padding: 0;

    &__title-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &--title {
        font-size: 18px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 5px;
        white-space: normal;
      }

      .icon {
        align-self: baseline;
      }
    }
  }
  &--item {
    min-height: 0 !important;
  }
}

.fellow-learners-section {
  &__title {
    padding-top: 10px;
  }
}

.go-to-course-btn {
  background: #21a7e0;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 40px !important;
  float: right;
  min-width: 142px;
  margin: 0 0 0;
  position: absolute;
  right: 35px;
  bottom: 35px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  text-transform: unset !important;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
</style>
