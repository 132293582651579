<template>
  <div class="my-ws-btn-wrap">
    <v-tooltip
      bottom
      max-width="300"
      content-class="tooltip-content"
      fixed
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          rounded
          text
          :key="`button-ws`"
          @click="() => routeToMyWs()"
          :class="isActive ? 'active' : ''"
          :disabled="wsIcon.disabled"
        >
          <div class="main-action-content">
            <component
              :is="getIcon(wsIcon)"
              :iconWidth="20"
              :iconHeight="20"
              :iconColor="getIconColor(wsIcon)"
              :iconStrokeWidth="0.5"
            />
            <span>{{ wsIcon.labelText }}</span>
          </div>
        </v-btn>
      </template>
      <span>{{ wsIcon.tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import MyWorkspaceIcon from '../../common/Icons/MyWorkspaceIcon.vue';

export default {
  name: 'SelectMyWorkspace',
  data() {
    return {
      iconMap: { myWorkspace: MyWorkspaceIcon },
      wsIcon: {
        id: 'myWorkspace',
        labelText: 'My Workspace',
        icon: 'myWorkspace',
        disabled: false,
        tooltipText: this.$t('myWorkspace.wsBtnTooltip'),
      },
    };
  },
  computed: {
    isActive() {
      return this.$route.name === 'myWorkspace';
    },
  },
  methods: {
    routeToMyWs() {
      if (this.$route.name !== 'myWorkspace') {
        this.$router.push(`/home/my-workspace`);
      }
    },
    getIconColor(action) {
      if (action.disabled) {
        return 'rgba(0, 0, 0, 0.26)';
      }
      if (this.isActive) {
        return '#21a7e0';
      }
      return '#121619';
    },
    getIcon(action) {
      return this.iconMap[action?.icon];
    },
  },
  components: { MyWorkspaceIcon },
};
</script>

<style lang="scss" scoped>
.my-ws-btn-wrap {
  .v-btn {
    text-transform: none;
    letter-spacing: normal;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #121619;
    &.active {
      color: #21a7e0;
    }

    &.active::before {
      background: #f2f4f8;
      opacity: 1;
    }
  }
  .v-btn::before {
    background-color: white;
  }
}
.main-action-content {
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
}
</style>
