<template>
  <div class="my-projects-container">
    <v-row>
      <v-col
        class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
        v-for="(project, i) in myProjects"
        :key="i"
      >
        <MyWsCard
          :cardData="project"
          :cardType="'project'"
          @onClick="handleClick"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyWsCard from './MyWsCard.vue';

export default {
  name: 'MyWorkspaceProjects',
  data() {
    return { myProjects: [] };
  },
  components: {
    MyWsCard,
  },
  props: {
    projects: {
      required: true,
    },
    openProject: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleClick(data) {
      this.$emit('openProject', data);
    },
  },
  beforeMount() {
    this.myProjects = this.projects;
  },
};
</script>

<style lang="scss" scoped>
.my-projects-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 0;
  flex: 1;
  padding: 20px;
  // .row {
  //   height: 200px;
  //   overflow-y: auto;
  // }
}
</style>
