var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actions.length)?_c('div',{ref:"actionsContainer",staticClass:"main-actions-row"},[_c('div',{staticClass:"horz-actions"},_vm._l((_vm.horzActionList),function(action,index){return _c('div',{key:("item-" + index)},[(_vm.isPrezentSourceBestPractice && _vm.showTooltip)?_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{key:("button-" + index),class:action.id === _vm.selectedMainAction.id &&
                _vm.selectedMainAction.id !== 'editSlide'
                  ? 'active'
                  : '',attrs:{"rounded":"","color":"primary","text":"","data-pendo-id":action.dataPendoId ? action.dataPendoId : null,"name--auto":("" + (action.id) + (action.id === _vm.selectedMainAction.id ? '--active' : '')),"disabled":action.disabled},on:{"click":function () { return _vm.handleIconClick(action); }}},[_c('div',{staticClass:"main-action-content"},[(action.isCustomIcon)?_c(_vm.getIcon(action),{tag:"component",attrs:{"iconWidth":24,"iconHeight":24,"iconColor":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0',"iconStrokeWidth":0.5}}):_vm._e(),_c('v-icon',{staticClass:"main-action-icon",attrs:{"dark":"","size":"20"}},[_vm._v(" "+_vm._s(action.icon)+" ")]),(_vm.isBestPractices)?_c('NewFeatureBadge',{attrs:{"module-name":"release_4200","feature-id":_vm.getFeatureId(action),"offsetX":_vm.getOffsetValue(action),"offsetY":'-10',"size":'medium'}}):_vm._e(),_c('span',[_vm._v(_vm._s(action.labelText || _vm.$t(action.label)))])],1)])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.tooltipText))])]):_c('v-btn',{key:("button-" + index),class:action.id === _vm.selectedMainAction.id &&
          _vm.selectedMainAction.id !== 'editSlide'
            ? 'active'
            : '',attrs:{"rounded":"","color":"primary","text":"","data-pendo-id":action.dataPendoId ? action.dataPendoId : null,"name--auto":("" + (action.id) + (action.id === _vm.selectedMainAction.id ? '--active' : '')),"disabled":action.disabled},on:{"click":function () { return _vm.handleIconClick(action); }}},[_c('div',{staticClass:"main-action-content"},[(action.isCustomIcon)?_c(_vm.getIcon(action),{tag:"component",attrs:{"iconWidth":24,"iconHeight":24,"iconColor":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0',"iconStrokeWidth":0.5}}):_vm._e(),_c('v-icon',{staticClass:"main-action-icon",attrs:{"dark":"","size":"20"}},[_vm._v(" "+_vm._s(action.icon)+" ")]),(_vm.isBestPractices)?_c('NewFeatureBadge',{attrs:{"module-name":"release_4200","feature-id":_vm.getFeatureId(action),"offsetX":_vm.getOffsetValue(action),"offsetY":'-10',"size":'medium'}}):_vm._e(),_c('span',[_vm._v(_vm._s(action.labelText || _vm.$t(action.label)))])],1)]),(
          action.id === _vm.selectedMainAction.id &&
          _vm.selectedMainAction.id === 'editSlide'
        )?_c('div',{key:("divider-" + index),staticClass:"divider"}):_vm._e()],1)}),0),(_vm.moreOptionList.length)?_c('div',{ref:"moreOption",staticClass:"more-actions",attrs:{"data-pendo-id":"slide-action-more-options"}},[_c('v-menu',{attrs:{"top":"","absolute":"","offset-y":"","close-on-click":true,"position-x":0,"position-y":0},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","text":""}},Object.assign({}, menu.on)),[_c('v-tooltip',{attrs:{"top":"","max-width":"200","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-activator"},'div',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","size":"20"}},[_vm._v(" mdi-dots-vertical ")]),_c('span',[_vm._v("More options")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.moreActions')))])])],1)]}}],null,false,3907903431)},[_c('v-list',[_vm._l((_vm.moreOptionList),function(action,index){return [(action.id)?_c('v-list-item',{key:index,attrs:{"disabled":action.disabled,"name--auto":action.automationText},on:{"click":function () { return _vm.handleIconClick(action); }}},[_c('v-list-item-title',[_c('div',{staticClass:"otherActions",class:{ disabled: action.disabled }},[_c('div',{staticClass:"icon"},[(action.isCustomIcon)?_c(_vm.getIcon(action),{tag:"component",attrs:{"iconWidth":20,"iconHeight":20,"iconColor":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0',"iconStrokeWidth":0.5}}):_c('v-icon',{attrs:{"size":"20","color":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1),_c('div',{staticClass:"actionName",style:({
                    color: ("" + (action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0')),
                  })},[_vm._v(" "+_vm._s(action.labelText || _vm.$t(action.label))+" ")])])])],1):_vm._e()]})],2)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }