<template>
  <div class="add-new-td-detail-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="new-td-detail-img">
      <img src="/assets/img/templateDesign/AddTDImage.svg" alt />
    </div>
    <div class="new-td-detail-title">
      {{ $t('templateDesign.goToTDText') }}
    </div>
    <div class="new-td-detail-action">
      <v-btn @click="handleNext" class="next">
        {{ $t('templateDesign.nextBtn') }}
      </v-btn>
      <div
        class="back-button"
        @click="handleSubmit()"
        v-html="$t('overnightPresentations.getInTouch1')"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GetInTouchDetail from './GetInTouchDetail.vue';
import { sendEmail, createTemplateRequest } from '../../../utils/api-helper';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import { trackTemplateDesignerEvents } from '../../common/Analytics/TemplateDesignerEvents.js';
import {
  TD_FLOW,
  TD_PLACEMENT,
  TD_PROFILE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_DESIGNER_NEW_POPUP,
  TD_TEMPLATE,
  NA,
} from '../../common/Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'AddNewTDPopup',
  props: {
    onHandleSubmit: {
      type: Function,
      default: () => {},
    },
    templateRequestFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    handleSubmit() {
      trackTemplateDesignerEvents.designerGetInTouch(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_PROFILE,
        [TD_PLACEMENT]: TD_DESIGNER_NEW_POPUP,
      });
      // create template request
      createTemplateRequest({
        requestFrom: this.templateRequestFrom,
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });

      // send email api call
      try {
        const mBody =
          `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}(${this.currentUser.user.id})` +
          ` has expressed interest in additional templates`;
        const params = {
          type: 'template-request',
          meta: {
            message: mBody,
            requester: this.currentUser.user.firstName,
          },
        };
        sendEmail(params)
          .then(() => this.onHandleSubmit?.())
          .catch((err) => console.log(err));

        trackProfileEvents.profileTemplatesAddmoreGetinTouch(this.currentUser);
      } catch (err) {
        console.log(err);
      }

      this.$modal.hide('AddNewTDPopup');
      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thanksForYourInterestInTemplates',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    handleClose() {
      this.$modal.hide('AddNewTDPopup');
    },
    handleNext() {
      trackTemplateDesignerEvents.designerNextFlow(this.currentUser, {
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_PROFILE,
        [TD_PLACEMENT]: TD_DESIGNER_NEW_POPUP,
      });
      this.$modal.hide('AddNewTDPopup');
      this.$router.push('/addDesignerTemplate');
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-td-detail-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;

  .close-btn {
    color: #212121;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .new-td-detail-title {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }

  .new-td-detail-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 14px;

    .next {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 22px;
      gap: 8px;

      width: auto;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;
      color: #ffffff;
      background: #21a7e0;
      border-radius: 25px;
    }
    .back-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
      cursor: pointer;
      color: #29b6f6;
      display: flex;
      align-items: center;
    }
  }

  .new-td-detail-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 10px;
  }

  img {
    margin: auto;
  }
}
</style>
