import { render, staticRenderFns } from "./AddingMoreTemplateDetail.vue?vue&type=template&id=edd48f62&scoped=true&"
import script from "./AddingMoreTemplateDetail.vue?vue&type=script&lang=js&"
export * from "./AddingMoreTemplateDetail.vue?vue&type=script&lang=js&"
import style0 from "./AddingMoreTemplateDetail.vue?vue&type=style&index=0&id=edd48f62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edd48f62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
