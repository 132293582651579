/* eslint-disable class-methods-use-this */
import API from '../../../utils/api';

export class MyWorkspaceService {
  constructor() {
    console.log('MyWorkspaceService initiated');
  }

  async addFilesLinksToWs(body) {
    try {
      const resp = await API.post('api3/workspace', body);
      return resp;
    } catch (err) {
      return err;
    }
  }

  async updateWorkspace(body) {
    try {
      const resp = await API.put('api3/workspace', body);
      return resp;
    } catch (err) {
      return err;
    }
  }

  async getWsData(params) {
    try {
      const resp = await API.get('api3/workspace', { params });
      return resp;
    } catch (err) {
      return err;
    }
  }
}
