<template>
  <div class="ws-search-bar">
    <div class="'vs__input-group'">
      <!--  :class="[{ vs__selected: value }, inputWrapperClasses, 'vs__input-group']" -->
      <!-- :disabled="disabled"
      :maxlength="maxLen"
      :class="[inputClasses, 'vs__input']" -->
      <!-- class="'vs__input'" -->
      <v-text-field
        v-model="searchModel"
        :type="'search'"
        class="ws-search-bar__input pl-4 ws-search-bar--auto"
        :placeholder="placeholderText"
        @blur="blur"
        @focus="focus"
        @input="(e) => handleSearchChange(e)"
        @keydown.enter.prevent="keyEnter"
        @keydown.up.prevent="keyUp"
        @keydown.down.prevent="keyDown"
        id="file-search"
        autocomplete="off"
      >
        <!-- <v-progress-circular
          v-if="loading"
          :size="20"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular> -->

        <template #append>
          <v-icon
            class="icon"
            v-text="`mdi-close`"
            @click="clearTextFieldSearch"
            name="prez-cross-icon"
            color="#21a7e0"
            v-show="searchModel.length > 0"
          />
          <v-icon
            class="icon"
            v-text="`mdi-magnify`"
            name="prez-magnify-glass"
            :color="searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'"
          />
        </template>
      </v-text-field>
    </div>
    <slot v-if="showList && !loading" name="suggestionList">
      <div class="files-list" v-if="items.length">
        <div v-for="(selFile, i) in items" :key="i" class="files-list-item">
          <v-checkbox
            v-model="selFile.checked"
            hide-details
            color="#20a7e0"
            class="my-file"
            @change="updateCheckedFiles(selFile)"
            @mousedown="handleMousedown($event)"
          >
            <template #label>
              <div class="label-wrapper">
                <div class="icon-wrapper">
                  <WsFileIcon
                    :height="'16px'"
                    :width="'16px'"
                    :iconType="searchType"
                    :fileName="selFile.fileName || selFile.title || ''"
                  />
                </div>
                <div class="file-name">
                  {{ selFile.fileName || selFile.title }}
                </div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div v-else-if="showNoResults" class="no-result-found">
        {{ $t('build.step1.storylineFieldNoResult') }}
      </div>
    </slot>
  </div>
</template>

<script>
import WsFileIcon from './WsFileIcon.vue';

export default {
  name: 'SearchSelectMyWs',
  props: {
    onSearch: {
      type: Function,
      default: () => {},
    },
    onSearchedFileSelect: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    placeholderText: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'file',
    },
    items: {
      type: Array,
      default: () => [],
    },
    showNoResults: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WsFileIcon,
  },
  data() {
    return {
      searchModel: '',
      searchWithTextBoxActive: false,
      showList: false,
      cursor: -1,
      minLen: 2,
      maxLen: 100,
      selectedFiles: [],
      checkboxClicked: false,
    };
  },
  methods: {
    isAbleToShowList() {
      return (this.searchModel || '').length >= this.minLen;
    },
    updateCheckedFiles(selectedSearchedFile) {
      this.showList = true;
      this.$emit('onSearchedFileSelect', selectedSearchedFile);
    },
    handleMousedown(event) {
      // to prevent blur from being called
      this.checkboxClicked = true;
      event.stopPropagation();
      event.preventDefault();
      setTimeout(() => {
        this.checkboxClicked = false;
      }, 500);
    },
    async focus() {
      this.searchWithTextBoxActive = true;
      this.$emit('click', true);
      this.$emit('focus', this.searchModel);
      this.showList = this.isAbleToShowList();
    },
    blur() {
      this.searchWithTextBoxActive = false;
      if (!this.checkboxClicked) {
        this.$emit('click', false);
        this.$emit('blur', this.searchModel);
        // set timeout for the click event to work
        setTimeout(() => {
          this.showList = false;
        }, 400);
      }
    },
    keyUp() {
      this.$emit('key-up', this.searchModel);
      if (this.cursor > 0) {
        this.cursor -= 1;
      }
    },
    keyDown() {
      this.$emit('key-down', this.searchModel);
      if (this.cursor < this.items.length - 1) {
        this.cursor += 1;
      }
    },
    keyEnter($event) {
      $event.target.blur();
      this.$emit('enter', this.searchModel);
      this.showList = false;
    },
    handleSearchChange(e) {
      this.searchModel = e;
      this.$emit('onSearch', this.searchModel);
      setTimeout(() => {
        this.showList = true;
      }, 200);
    },
    clearTextFieldSearch() {
      this.handleSearchChange('');
      this.showList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ws-search-bar__input {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  display: flex;
  font-size: 14px;
  height: 40px !important;
  padding: 2px 14px 8px 20px;
  width: 100%;
  z-index: 3;
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin: 0;
        padding: 4px 0px 0px 4px;
      }
      .v-text-field__slot {
        input {
          caret-color: black !important;
        }
      }
    }
  }

  ::v-deep .v-input__slot::before {
    border-style: none !important;
    display: none;
  }
  ::v-deep .v-input__slot::after {
    border: none !important;
    display: none;
  }

  ::v-deep .v-input__icon {
    margin-right: -8px;
  }
}
.vue-suggestion {
  align-items: center;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  display: flex;
  height: 40px !important;
  justify-content: flex-start;
  margin-left: 2px;
  padding: 5px 5px 5px 5px;
  position: relative;
  width: 100%;

  .vs__input-group {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .vs__list {
    background-color: white;
    border: 0;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    width: 100%;
    margin-left: -2px !important;
    text-align: left;
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
    top: calc(100% + 5px);
    z-index: 10;
    position: absolute;

    .no-result-found {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      height: 50px;
    }
  }
}

.vue-suggestion .vs__input-group .vs__input {
  background-clip: padding-box;
  background-color: transparent;
  background-image: none;
  border: none;
  color: #464a4c;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.25;
  margin: 0 auto;
  padding: 0.5rem 0.7rem;
  outline: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
  margin-bottom: 12px;
}

.vue-suggestion .vs__list .vs__list-item:first-child {
  margin-top: 10px;
}

.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}

.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
}

.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}

.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}
.ws-search-bar {
  position: relative;
}
.files-list-item {
  ::v-deep .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    width: 100%;
    .v-input__control {
      .v-input__slot {
        margin-bottom: 0;
        .v-input--selection-controls__input {
          margin-right: 10px;
        }
      }
      .v-messages {
        display: none;
      }
    }
  }
  .label-wrapper {
    display: flex;
    column-gap: 5px;
    align-items: center;
    .icon-wrapper {
      display: flex;
    }
  }

  display: flex;
}
.files-list,
.no-result-found {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* padding: 10px; */
  position: absolute;
  background: white;
  /* width: 100%; */
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* margin-left: -2px !important; */
  text-align: left;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  /* top: calc(100% + 5px); */
  z-index: 10;
}
</style>
