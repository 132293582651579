<template>
  <div class="op-details">
    <div
      class="op-main-container"
      :style="{
        width: showRightPanel ? '68%' : '89%',
      }"
    >
      <div class="backToSlidesContainer">
        <!-- <v-btn @click="backToMyRequest">
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t('overnightPresentations.backToMyRequets') }}
        </v-btn> -->
        <v-btn class="back-btn" @click="goToSearchLandingPage">
          <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
          <span>{{
            $t(
              previousRoute === 'search'
                ? 'slides.backToSearch'
                : 'overnightPresentations.backToMyRequets',
            )
          }}</span>
        </v-btn>
      </div>
      <div class="title-wrap">
        <OPName
          :name="prezentation.name"
          :editName="selectedMainAction === 'rename'"
          @onRename="handleRename"
        />
        <QuickAccessActions
          v-if="
            prezentation.status === 'Completed' &&
            prezentation.opSlideData !== null
          "
          :opRequest="prezentation"
          :viewMode="viewMode"
          @onViewChange="handleViewMode"
          :updatePrezentation="updatePrezentation"
        />
      </div>
      <div class="banner-wrap">
        <OPDetailBanner :prezData="prezentation" />
      </div>
      <div class="main-action-wrap">
        <MainActions
          :prezData="prezentation"
          :selectedMainAction="selectedMainAction"
          :getMessageCount="getMessageCount"
          @onMainActionClick="handleMainAction"
        />
      </div>

      <div>
        <template v-if="prezentation.status === 'Completed'">
          <template v-if="prezentation.opSlideData === null">
            <OnGoingRequests
              :prezData="prezentation"
              :getMessageCount="getMessageCount"
              :hideViewMsgBtn="hideViewMsgBtn"
              @onMainActionClick="handleMainAction"
            />
            <div class="download-btn" v-if="!getMessageCount">
              <div class="spacer" v-if="!getMessageCount"></div>
              <Download
                :downloading="isLocalDownloading"
                :file-name="getFilename()"
                :download-file-name="getDownloadFilename()"
                :merged-url="() => getMergedUrlForSaveToGdrive()"
                :item="getDownloadItem()"
                :log-download="() => logDownload(prezentation.ticketId)"
                :on-download="() => download()"
                origin="prezentation"
                limitsKey="prezentation"
                :isDisabled="isLocalDownloading"
                :allowDownloadSettingsIcon="false"
              />
            </div>
          </template>
          <ListView
            v-else-if="viewMode === 'list'"
            :prezData="prezentation"
            :currentIndex="currentIndex"
          />
          <MosaicView
            :prezData="prezentation"
            @onMosaicSlideClick="handleMosaicSlideClick"
            v-else
          />
        </template>
        <template v-else>
          <OnGoingRequests
            :prezData="prezentation"
            :getMessageCount="getMessageCount"
            :hideViewMsgBtn="hideViewMsgBtn"
            @onMainActionClick="handleMainAction"
          />
        </template>
      </div>
    </div>
    <div class="op-right-panel" v-if="showRightPanel">
      <div class="right-panel-component">
        <ChangeRequests
          v-if="selectedMainAction === 'changeRequest'"
          :opRequest="prezentation"
          :viewType="viewMode"
        />
        <ManageRequests
          v-else-if="selectedMainAction === 'manageRequest'"
          :isManagedClosed="isManagedClosed"
          :id="prezentation.ticketId"
          :getUnReadMessageCount="getMessageCount"
          :currentPrezentation="prezentation"
          :viewType="viewMode"
          @onClose="loadMessages"
        />
      </div>
      <div class="smart-tools-icon-panel">
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn
                depressed
                small
                icon
                :ripple="false"
                @click="handleRightPanelClose"
                :color="hover ? '#21A7E0' : '#000'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon name="close-icon"> mdi-close </v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>
            {{ $t('common.close') }}
          </span>
        </v-tooltip>
      </div>
    </div>
    <!-- TODO: check conditions to add -->
    <div
      :class="`prez-detail-actions-wrapper`"
      :style="{
        width: showRightPanel ? '68%' : '100%',
      }"
      v-if="allPrezentationArr.length"
    >
      <v-menu top v-model="otherActionsOpen" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="action-btn"
          >
            {{ $t('userUploadPrezentation.otherActions') }}
            <v-icon color="primary">
              {{ `mdi-menu-${otherActionsOpen ? 'up' : 'down'}` }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(action, index) in otherActionsOptions"
            :key="index"
            @click="handleActionsOptionClick(action)"
            :disabled="disableOtherActions(action)"
          >
            <v-list-item-title> {{ $t(action.name) }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <Download
        :downloading="isLocalDownloading"
        :file-name="getFilename()"
        :download-file-name="getDownloadFilename()"
        :merged-url="() => getMergedUrlForSaveToGdrive()"
        :item="getDownloadItem()"
        :log-download="() => logDownload(prezentation.ticketId)"
        :on-download="() => download()"
        origin="prezentation"
        limitsKey="prezentation"
        :isDisabled="isLocalDownloading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '../../../common/event-bus';
import ChangeRequests from './components/panel/ChangeRequests.vue';
import ManageRequests from './components/panel/ManageRequests.vue';
import ListView from './components/ListView.vue';
import MosaicView from './components/MosaicView.vue';
import MainActions from './components/MainActions.vue';
import OnGoingRequests from './components/OnGoingRequests.vue';
import OPDetailBanner from './components/OPDetailBanner.vue';
import OPName from './components/OPName.vue';
import QuickAccessActions from './components/QuickAccessActions.vue';
import {
  updateOPTicket,
  getOPTicket,
  downloadFinalOPPPT,
  addToPrezentation,
  logOPDownload,
  getOPTicketsMessage,
  getFeatureUsage,
  postFeatureUsage,
  updateOPTicketsMessageStatus,
} from '../../../../utils/api-helper';
import Download from '../../../common/Download.vue';
import ConfirmationDialoge2 from '../ConfirmationDialoge2.vue';
import { callMergeSlidesApi } from '../../../../utils/merge-slide-helper';
import { miscConfig } from '../../../../runtimeConfig';
import { trackOvernightPrezentationsEvents } from '../../../common/Analytics/OvernightPrezentationsEvents';
import {
  TD_AUD,
  TD_EXPANDED_DETAIL_VIEW,
  TD_EXPANDED_STATUS_VIEW,
  TD_LIST,
  TD_MOSAIC,
  TD_PLACEMENT,
  TD_PREZENTATION_CATEGORY,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_VIEWTYPE,
  TD_INSTRUCTIONS,
  NA,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'OPDetail',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
    showConversationHistory: {
      type: Boolean,
      default: false,
    },
    changeShowConversation: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    OPName,
    QuickAccessActions,
    ListView,
    MosaicView,
    OnGoingRequests,
    OPDetailBanner,
    MainActions,
    ChangeRequests,
    ManageRequests,
    Download,
  },
  data() {
    return {
      previousRoute: '',
      currentIndex: 0,
      selectedMainAction: null,
      showRightPanel: false,
      intervalId: null,
      viewPrezentation: false,
      loading: false,
      isManagedClosed: true,
      viewMode: 'list',
      allPrezentationArr: [],
      viewPrezentationLoader: false,
      slideImageAvailble: true,
      prezentation: this.prezData,
      downloadItem: {},
      otherActionsOpen: false,
      messagesLoading: false,
      otherActionsOptions: [
        {
          id: 'submit-new-req',
          name: 'overnightPresentations.submitNewRequest',
          displayName: 'Submit new request',
        },
      ],
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'opUnreadMessageData',
      'limitsInfoObj',
    ]),
    ...mapGetters('users', ['getOPAccessLevel', 'getOPLimitedBanner']),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isOpRestrictedBannerActive() {
      return (
        (this.limitsInfoObj?.op?.access_level === 'full_access' &&
          this.currentUser.user.role.toLowerCase() === 'trialuser') ||
        (this.limitsInfoObj?.op?.access_level === 'restricted_access' &&
          this.currentUser.user.role.toLowerCase() !== 'trialuser') ||
        ((this.limitsInfoObj?.slide?.feature ||
          this.limitsInfoObj?.op?.feature) &&
          this.currentUser.user.role.toLowerCase() === 'trialuser')
      );
    },
    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.prezentation.ticketId,
      );
      return ldr && ldr.downloading;
    },
    hideViewMsgBtn() {
      return (
        this.selectedMainAction === 'manageRequest' || this.messagesLoading
      );
    },
    addToPrezLibraryText() {
      return this.prezentation?.addedToPrezentation
        ? this.$t('overnightPresentations.addedToLibrary')
        : this.$t('overnightPresentations.addToLibrary');
    },
    getMessageCount() {
      const getTicket = this.opUnreadMessageData?.find(
        (item) => item?.ticketId === this.prezentation?.ticketId,
      );
      if (getTicket) return getTicket?.unreadCount;
      return 0;
    },
  },
  methods: {
    ...mapActions('users', [
      'setOPTicketUnreadMessageData',
      'setLimitData',
      'setUpgradePopup',
    ]),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
    ]),
    ...mapActions('advancedFilterStore', ['setBackCta']),
    goToSearchLandingPage() {
      this.setBackCta(true);
      this.$router.back();
    },
    backToMyRequest() {
      this.setBackCta(true);
      this.$router.push({
        path: `/home/overnight-prezentations?tab=myRequest`,
      });
    },
    async loadMessages(type) {
      if (type === 'closeManageRequest') {
        this.messagesLoading = true;
        try {
          const payload = {
            ticketId: this.prezentation.ticketId,
            isRead: true,
          };
          await updateOPTicketsMessageStatus(payload);
          const result = await getOPTicketsMessage();
          this.setOPTicketUnreadMessageData(result?.data);
          this.messagesLoading = false;
        } catch (error) {
          this.messagesLoading = false;
          console.error('Error updating message status:', error);
        }
      }
    },
    handleAddToPrezentation() {
      this.$modal.show(
        ConfirmationDialoge2,
        {
          onSecondaryClick: this.handleAddedToPrezentation,
        },
        {
          name: 'ConfirmationDialoge2',
          classes: 'v-application',
          width: '500px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '700px',
            'min-height': '250px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    handleAddedToPrezentation() {
      addToPrezentation({
        ticketId: this.prezentation.ticketId,
      })
        .then((res) => {
          console.log(res);
          this.$modal.hide('ConfirmationDialoge2');
          this.prezentation = {
            ...this.prezentation,
            addedToPrezentation: true,
          };
          trackOvernightPrezentationsEvents.OPOtherActionsAddToLibrary(
            this.currentUser,
            this.getMatomoDetails(),
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async download() {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => console.log(error));
      if (
        this.currentUser.user.role.toLowerCase() !== 'trialuser' ||
        (this.currentUser.user.role.toLowerCase() === 'trialuser' &&
          !this.limitsInfoObj?.slide?.isLimitCrossed)
      ) {
        postFeatureUsage({
          feature: 'op-download',
          ticketId: this.prezentation.ticketId,
        })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));

        // this.downloading = true;
        // const isPresent = this.downloadSet.filter(
        //   (ele) => ele.ticketId === this.prezentation.ticketId,
        // );
        // if (isPresent.length) {
        //   return;
        // }
        // this.showDownload = true;
        // this.downloadStatus = true;
        // this.downloadSet.push({
        //   ...this.prezentation,
        //   status: 'assembling',
        // });

        this.setShowDownloadSnackbar(true);
        this.setDownloadLoaders({
          loaderId: this.prezentation.ticketId,
          downloading: true,
        });
        this.setDownloadItems({
          status: 'assembling',
          itemIndex: uuidv4(),
          originType: 'opDecksDetail',
          logDownloadCallback: (payload) => {
            // this.logDownload(this.prezentation.ticketId);

            logOPDownload(payload.prezTicketId)
              .then((resp) => {
                console.log(resp);
                trackOvernightPrezentationsEvents.OPMyRequestsDownload(
                  this.currentUser,
                  {
                    [TD_AUD]: payload.audienceEmail,
                    [TD_VIEWTYPE]:
                      payload.viewMode === 'list' ? TD_LIST : TD_MOSAIC,
                    [TD_PREZNAME]: payload.name || NA,
                    [TD_PREZTYPE]: payload.storyline || NA,
                    [TD_PLACEMENT]:
                      payload.status === 'Completed' &&
                      payload.opSlideData !== null
                        ? TD_EXPANDED_DETAIL_VIEW
                        : TD_EXPANDED_STATUS_VIEW,
                    [TD_PREZENTATION_CATEGORY]: payload.status,
                  },
                );
                // this.downloadSet[0].status = 'complete';
              })
              .catch((err) => {
                console.log(err);
                // this.downloadSet[0].status = 'canceled';
              });
          },
          downloadStatusCallback: (payload) => {
            // this.downloading = false;
            this.setDownloadLoaders({
              loaderId: payload.prezTicketId,
              downloading: false,
            });
          },
          errorCallback: (payload) => {
            // this.downloading = false;
            this.setDownloadLoaders({
              loaderId: payload.prezTicketId,
              downloading: false,
            });
            // if (this.$route.name === 'overnightPrezentations') {
            //   this.$modal.show(
            //     ManageFiles,
            //     {
            //       id: this.prezentation.ticketId,
            //       request: this.prezentation,
            //       viewType: TD_LIST,
            //     },
            //     {
            //       name: 'ManageFiles',
            //       classes: 'v-application',
            //       width: '1020px',
            //       height: 'auto',
            //       styles: {
            //         borderRadius: '15px',
            //         'max-height': '80%',
            //         'min-height': '300px',
            //         'overflow-y': 'auto',
            //       },
            //     },
            //   );
            // }
            console.log(payload.err);
          },
          metaData: {
            ...this.prezentation,
            fileName: this.prezentation.name,
            limitsKey: 'prezentation',
            origin: 'prezentation',
            errorCallbackPayload: {
              prezTicketId: this.prezentation.ticketId,
            },
            downloadStatusCallbackPayload: {
              prezTicketId: this.prezentation.ticketId,
            },
            logDownloadCallbackPayload: {
              prezTicketId: this.prezentation.ticketId,
              audienceEmail: this.prezData?.audienceEmail,
              viewMode: this.viewMode,
              name: this.prezData.name,
              storyline: this.prezData.storyline,
              status: this.prezData?.status,
              opSlideData: this.prezData?.opSlideData,
            },
          },
        });

        // const ticketIds = this.prezentation.ticketId;
        // await downloadFinalOPPPT(this.prezentation.ticketId)
        //   .then(async (resp) => {
        //     // this.downloadSet[0].status = 'downloading';
        //     console.log(resp);
        //     downloadFileWithCustomName(
        //       resp.attachmentUrl,
        //       resp.fileName,
        //       (val) => {
        //         if (!val) {
        //           this.logDownload(this.prezentation.ticketId);
        //           this.downloading = val;
        //         }
        //         // this.downloading = val;
        //       },
        //       false,
        //     );
        //   })
        //   .catch((err) => {
        //     this.downloading = false;
        //     this.$modal.show(
        //       ManageFiles,
        //       {
        //         id: ticketIds,
        //         request: this.prezentation,
        //         viewType: TD_LIST,
        //       },
        //       {
        //         name: 'ManageFiles',
        //         classes: 'v-application',
        //         width: '1020px',
        //         height: 'auto',
        //         styles: {
        //           borderRadius: '15px',
        //           'max-height': '80%',
        //           'min-height': '300px',
        //           'overflow-y': 'auto',
        //         },
        //       },
        //     );
        //     console.log(err);
        //   });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op-slide' });
      }
    },
    logDownload(id) {
      logOPDownload(id)
        .then((resp) => {
          console.log(resp);
          trackOvernightPrezentationsEvents.OPMyRequestsDownload(
            this.currentUser,
            {
              [TD_AUD]: this.prezData?.audienceEmail,
              [TD_VIEWTYPE]: this.viewMode === 'list' ? TD_LIST : TD_MOSAIC,
              [TD_PREZNAME]: this.prezData.name || NA,
              [TD_PREZTYPE]: this.prezData.storyline || NA,
              [TD_PLACEMENT]:
                this.prezData?.status === 'Completed' &&
                this.prezData?.opSlideData !== null
                  ? TD_EXPANDED_DETAIL_VIEW
                  : TD_EXPANDED_STATUS_VIEW,
              [TD_PREZENTATION_CATEGORY]: this.prezData.status,
            },
          );
          // this.downloadSet[0].status = 'complete';
        })
        .catch((err) => {
          console.log(err);
          // this.downloadSet[0].status = 'canceled';
        });
    },
    async getMergedUrlForSaveToGdrive() {
      try {
        let mergedUrl = '';
        const prezDetailsResponse = await downloadFinalOPPPT(
          this.prezentation.ticketId,
        );
        if (prezDetailsResponse) {
          const s = {
            slides: [],
          };
          prezDetailsResponse.slideData.slides.forEach((slide) => {
            s.slides.push({
              slideId: slide.filename,
              source: prezDetailsResponse.s3Bucket,
              isUploaded: false,
              isGenerated: false,
              isSynthesis: false,
              isRedesign: false,
              isComply: false,
              isOPUploaded: true,
            });
          });
          await callMergeSlidesApi(s)
            .then((res) => {
              const mergedslide = res.data;
              if (mergedslide.includes('.pptx')) {
                mergedUrl = mergedslide;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
      } catch (err) {
        console.log('error', err);
        return '';
      }
    },
    getFilename() {
      return this.prezData.name;
    },
    getDownloadFilename() {
      return `${this.prezData.name}_op.pptx`;
    },
    getDownloadItem() {
      return {
        name: this.prezData.name,
        categories: ['title'],
        prefs: {
          source: 'uploaded',
        },
        filename: this.prezData.name,
      };
    },
    disableOtherActions(action) {
      return (
        (this.prezentation &&
          this.prezentation.addedToPrezentation &&
          action.id === 'add-to-prezenation') ||
        (action.id === 'submit-new-req' && this.isOpRestrictedBannerActive)
      );
    },
    handleViewMode(view) {
      this.viewMode = view;
      this.currentIndex = 0;
    },
    handleMosaicSlideClick(index) {
      this.viewMode = 'list';
      this.currentIndex = index;
    },
    handleActionsOptionClick(action) {
      switch (action.id) {
        case 'submit-new-req':
          this.submitNewRequest();
          break;
        case 'add-to-prezenation':
          this.handleAddToPrezentation();
          break;
        default:
          break;
      }
    },
    submitNewRequest() {
      trackOvernightPrezentationsEvents.OPOtherActionsSubmitNewRequest(
        this.currentUser,
        this.getMatomoDetails(),
      );
      this.$router.push({
        path: `/home/overnight-prezentations?tab=newRequest`,
      });
    },
    updatePrezentation() {
      getOPTicket(this.prezentation.ticketId).then((resp) => {
        console.log(resp);
        this.prezentation = resp.data;
      });
    },
    handleMainAction(type) {
      this.selectedMainAction = type;
      this.handleMatomo(type);
      switch (type) {
        case 'rename':
          this.showRightPanel = false;
          break;
        case 'manageRequest':
          this.handleRightPanelOpen();
          break;
        case 'changeRequest':
          this.handleRightPanelOpen();
          break;
        default:
          break;
      }
    },
    handleMatomo(type) {
      if (type === 'changeRequest') {
        trackOvernightPrezentationsEvents.OPMyRequestsChangeRequest(
          this.currentUser,
          {
            [TD_INSTRUCTIONS]: this.prezData?.feedback
              ? this.prezData?.feedback
              : NA,
            ...this.getMatomoDetails(),
          },
        );
      } else if (type === 'manageRequest') {
        trackOvernightPrezentationsEvents.OPMyRequestsManageRequestClick(
          this.currentUser,
          this.getMatomoDetails(),
        );
      }
    },
    getMatomoDetails() {
      return {
        [TD_AUD]: this.prezData?.audienceEmail,
        [TD_PREZNAME]: this.prezData?.name,
        [TD_PREZTYPE]: this.prezData?.storyline,
        [TD_VIEWTYPE]: this.viewMode === 'list' ? TD_LIST : TD_MOSAIC,
        [TD_PLACEMENT]:
          this.prezData?.status === 'Completed'
            ? TD_EXPANDED_DETAIL_VIEW
            : TD_EXPANDED_STATUS_VIEW,
        [TD_PREZENTATION_CATEGORY]:
          this.prezData?.status && this.prezData?.opSlideData !== null
            ? this.prezentation?.status
            : NA,
      };
    },
    handleRightPanelOpen() {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      this.showRightPanel = true;
    },
    handleRightPanelClose() {
      this.showRightPanel = false;
      this.selectedMainAction = null;
    },
    handleRename(name) {
      if (name) {
        const payload = {
          ticketId: this.prezentation.ticketId,
          name,
        };
        updateOPTicket(payload)
          .then((res) => {
            console.log(res);
            if (res.status === 'success') {
              this.prezentation = { ...this.prezentation, name };
            }
            trackOvernightPrezentationsEvents.OPMyRequestsRename(
              this.currentUser,
              {
                [TD_AUD]: this.prezentation?.audienceEmail,
                [TD_VIEWTYPE]: this.viewMode === 'list' ? TD_LIST : TD_MOSAIC,
                [TD_PREZNAME]: this.prezentation.name,
                [TD_PREZTYPE]: this.prezentation.storyline || NA,
                [TD_PLACEMENT]:
                  this.prezentation?.status === 'Completed' &&
                  this.prezentation?.opSlideData !== null
                    ? TD_EXPANDED_DETAIL_VIEW
                    : TD_EXPANDED_STATUS_VIEW,
                [TD_PREZENTATION_CATEGORY]: this.prezentation.status,
              },
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.selectedMainAction = null;
    },
    handleMainActionParam() {
      let mainAction = '';
      if (this.$route.query?.request === 'manage') {
        this.handleRightPanelOpen();
        mainAction = 'manageRequest';
      } else if (this.$route.query?.request === 'change') {
        this.handleRightPanelOpen();
        mainAction = 'changeRequest';
      } else {
        this.handleRightPanelOpen();
        mainAction = 'manageRequest';
      }
      return mainAction;
    },
    async getOPTicketsUnreadMessage() {
      const result = await getOPTicketsMessage();
      this.setOPTicketUnreadMessageData(result?.data);
      this.intervalId = window.setInterval(async () => {
        const results = await getOPTicketsMessage();
        this.setOPTicketUnreadMessageData(results?.data);
      }, 30000);
    },
  },
  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    let prezentationArr = [];
    if (this.prezentation.opSlideData) {
      this.otherActionsOptions = [
        ...this.otherActionsOptions,
        {
          id: 'add-to-prezenation',
          name: this.addToPrezLibraryText,
          displayName: this.addToPrezLibraryText,
        },
      ];
      prezentationArr = this.prezentation.opSlideData.slides;
    }
    this.selectedMainAction = this.handleMainActionParam();
    this.allPrezentationArr = prezentationArr;
    this.getOPTicketsUnreadMessage();
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      if (this.showRightPanel) {
        this.showRightPanel = false;
        this.handleMainAction(null);
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('MINIMIZE_LEFTNAV');
  },
};
</script>

<style lang="scss" scoped>
.op-details {
  display: flex;
  width: 100%;

  .op-main-container {
    padding: 20px;
    max-width: 89%;
    margin: 0 auto;
    position: relative;
    .backToSlidesContainer {
      margin-top: 3px;
    }

    .title-wrap {
      display: flex;
      align-items: center;
    }
    .banner-wrap {
      margin-top: 10px;
    }
    .main-action-wrap {
      margin-top: 10px;
    }
    .download-btn {
      display: flex;
      justify-content: center;
      padding: 20px;
      .spacer {
        flex-grow: 0 !important;
        width: 30%;
      }
    }
  }
  .op-right-panel {
    position: relative;
    width: 32%;
    height: calc(100vh - 68px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    // border-left: 1px solid lightgray;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    .right-panel-component {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding-left: 10px;
    }
    .smart-tools-icon-panel {
      margin-top: 4px;
    }
  }
  .prez-detail-actions-wrapper {
    align-items: center;
    bottom: -14px;
    display: flex;
    justify-content: center;
    height: 82px;
    position: fixed;
    transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
    pointer-events: none;
    & > * {
      pointer-events: all;
    }

    .action-btn {
      background-color: $white-default;
      color: $zen-blue-default;
      height: 40px;
      font-size: 18px;
      text-transform: none;
      letter-spacing: normal;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    &.menu-expanded {
      width: calc(100% - 280px);
    }
    &.menu-minimized {
      width: 100%;
    }
  }
}
.backToSlidesContainer {
  .v-btn {
    border: none;
    text-transform: none;
    background-color: #ffffff;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0px 0px 7px 0px;
    margin-left: -10px;
  }
}
.v-menu__content {
  border-radius: 8px;
}
</style>
