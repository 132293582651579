<template>
  <div class="template-selector">
    <div class="title-wrapper" v-if="origin === 'build'">
      <p>{{ companyName }} {{ $t('profile.templates') }}</p>
      <v-btn v-if="isCloseBtn" class="close-btn" icon @click="handleClose">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
    </div>
    <template v-else>
      <div class="header-wrapper">
        <div class="heading">{{ $t('build.step1.templateLabel') }}</div>
        <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
      </div>
      <div class="sub-heading">
        {{ $t('build.step3.chooseTemplateForSlide') }}
      </div>
    </template>
    <v-text-field
      solo
      rounded
      v-model="templateSearch"
      single-line
      hide-details
      class="template-search-input"
      autocomplete="off"
      :placeholder="$t('build.step3.searchForTemplates')"
      @keyup.stop.native="sortedThemeArray()"
    >
      <template v-slot:append>
        <v-icon
          v-if="templateSearch.length"
          class="icon"
          v-text="'mdi-close'"
          @click="clearSearch()"
          name="global-cross-icon"
          color="#21a7e0"
        />
        <v-icon
          class="icon"
          v-text="`mdi-magnify`"
          name="global-search-icon"
          :color="templateSearch.length ? '#21a7e0' : ''"
        />
      </template>
    </v-text-field>
    <div v-if="sections.length" class="templates-list" ref="container">
      <template v-for="section in sections">
        <div :key="section.header" v-if="section.themes.length">
          <h4 class="section-title" v-if="section.header">
            {{ section.header }}
          </h4>
          <div class="section-list">
            <v-card
              class="template"
              :class="{
                'template-disabled': item.disabled || item.isDisabled,
                'template-selected': isCurrentSelected(item.code),
              }"
              v-for="item in section.themes"
              :key="item.id"
              :style="{
                'background-color': isCurrentSelected(item.code)
                  ? '#F5FCFF'
                  : '',
              }"
              :ripple="!item.disabled && !item.isDisabled"
              @click="handleClickTheme(item)"
            >
              <v-tooltip
                top
                attach
                absolute
                max-width="200"
                :disabled="!item.disabled && !item.isDisabled"
                :content-class="'center-tooltip'"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div class="template-header">
                      <div class="template-header__left">
                        <v-tooltip
                          top
                          max-width="200"
                          content-class="tooltip-content"
                          fixed
                        >
                          <template v-slot:activator="{ on }">
                            <div class="template-title" v-on="on">
                              {{ item.name }}
                            </div>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                        <span
                          class="template-header__icon"
                          v-if="item.isPrezentTemplate"
                        >
                          <img
                            class="prezent-icon"
                            src="/assets/img/prezent_avatar.svg"
                            alt
                            width="22px"
                          />
                        </span>
                      </div>

                      <div class="template-header__right">
                        <span
                          class="template-header__icon"
                          v-if="item.isMarked"
                        >
                          <v-tooltip
                            bottom
                            max-width="200"
                            content-class="tooltip-content"
                            fixed
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                class="template-header__marked"
                                @click="stopPropogation($event)"
                              >
                                <v-icon color="white" small>
                                  mdi-account-heart-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Template recommended by your team admin</span>
                          </v-tooltip>
                        </span>
                        <span
                          class="template-header__icon"
                          v-if="item.isPinned"
                        >
                          <v-tooltip
                            bottom
                            max-width="200"
                            content-class="tooltip-content"
                            fixed
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                icon
                                class="template-header__pinned"
                                @click="stopPropogation($event)"
                              >
                                <v-icon> mdi-pin </v-icon>
                              </v-btn>
                            </template>
                            <span>Pinned</span>
                          </v-tooltip>
                        </span>
                        <span
                          class="template-header__icon"
                          v-if="item.isDisabled"
                        >
                          <v-btn color="primary" icon>
                            <v-icon> mdi-lock </v-icon>
                          </v-btn>
                        </span>
                      </div>
                    </div>
                    <v-row>
                      <v-col class="available-theme-img">
                        <img :src="item.thumbnailURL" alt />
                      </v-col>
                      <v-col class="example-slide-img">
                        <img
                          :src="
                            item.sampleSlide
                              ? item.sampleSlideURL
                              : item.thumbnailURL
                          "
                          alt
                        />
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <span
                  v-if="item.isDisabled && currentUser.user.role === 'User'"
                >
                  This template is disabled by your team admin. Click to request
                  access.
                </span>
                <span v-else-if="item.isDisabled">
                  This template is disabled for your team. You can enable it in
                  Templates tab under Profile.
                </span>
                <span v-else>
                  {{ $t('common.comingSoon') }}
                </span>
              </v-tooltip>
            </v-card>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="not-found-container">
      <img
        src="/assets/img/no-results-found-templates.svg"
        height="250"
        width="250"
        alt
      />
      <div class="heading mb-1 mt-6">
        {{ $t('build.step3.noTemplatesFound') }}
      </div>
      <v-btn
        center
        class="primary-button"
        height="35"
        width="160"
        rounded
        filled
        color="primary"
        @click="handleAddMore"
      >
        {{ $t('build.step3.addTemplates') }}
      </v-btn>
    </div>
    <div class="tip-bar" :class="sections.length ? '' : 'no-results'">
      <p>
        {{ $t('tagSlides.templateProTipText') }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  BUILD,
  TD_QUERY,
  TD_NORESULTSFOUND,
  TD_TEMPLATE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import SlideThemeChangeDetail from '../Profile/SlideThemeChangeDetail.vue';

import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
// import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
import { handleDebounce } from '../SlideImageReplacement/slideImageReplacementUtils';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import usersApi from '../../../API/users-api';
import { requestThemeAccess } from '../../../utils/api-helper';
import RequestAccessModal from '../Profile/Templates/RequestAccessModal.vue';
import { templateSort } from '../../../utils/utility';

export default {
  name: 'TemplateMenu',
  data() {
    return {
      templateSelected: '',
      templateSearch: '',
      companyName: null,
      themeSections: [],
      sections: [],
    };
  },
  props: {
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
    origin: {
      type: String,
      default: 'build',
    },
    template: {
      type: String,
      default: () => null,
    },
  },
  watch: {
    currentTheme() {
      this.sortedThemeArray();
    },
    selectedTheme() {
      this.setThemeSections();
      this.sortedThemeArray();
    },
    templateSearch(val) {
      this.onSearch(val);
    },
  },
  mounted() {
    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
    this.templateSelected = this.theme;
    this.companyName = this.getCompanyName;
    this.setThemeSections();
    this.sortedThemeArray();
  },
  computed: {
    ...mapState('users', ['themes', 'currentTheme', 'currentUser']),
    // ...mapState('generateSlides', ['template']),
    selectedTheme() {
      if (this.template) {
        return (
          this.themes.find((i) => i.code === this.template) || this.currentTheme
        );
      }
      return this.currentTheme;
    },
    theme() {
      return this.origin === 'build' ? this.currentTheme : this.selectedTheme;
    },
    getCompanyName() {
      if (this.currentUser.company.displayName)
        return (
          this.currentUser.company.displayName.charAt(0).toUpperCase() +
          this.currentUser.company.displayName.slice(1)
        );
      return (
        this.currentUser.company.name.charAt(0).toUpperCase() +
        this.currentUser.company.name.slice(1)
      );
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setCurrentTheme',
      'setFilteredThemes',
    ]),
    isCurrentSelected(id) {
      return this.theme.code === id;
    },
    clearSearch() {
      this.templateSearch = '';
      this.sortedThemeArray();
    },
    setThemeSections() {
      const currentSelected = [];
      const pinnedThemes = [];
      const companyThemes = [];
      const prezentThemes = [];
      const archivedThemes = [];

      this.themes.forEach((theme) => {
        if (
          this.origin === 'generate' &&
          !theme.enabled_feature_list.includes('slide-generate')
        ) {
          theme.disabled = true;
        }

        if (theme.isArchived) {
          archivedThemes.push(theme);
        } else if (theme.code === this.selectedTheme?.code) {
          currentSelected.push(theme);
        } else if (theme.isPinned) {
          pinnedThemes.push(theme);
        } else if (theme.isPrezentTemplate) {
          prezentThemes.push(theme);
        } else {
          companyThemes.push(theme);
        }
      });
      this.themeSections = [
        {
          header: 'Current selection',
          themes: currentSelected,
          type: 'CURRENT_SELECTION',
        },
        {
          header: 'My Pinned Templates',
          themes: templateSort(pinnedThemes),
          type: 'PINNED_TEMPLATES',
        },
        {
          header: `${this.currentUser?.company?.displayName} Brand Templates`,
          themes: templateSort(companyThemes),
          type: 'BRAND_TEMPLATES',
        },
        {
          header: 'Prezent Templates',
          themes: templateSort(prezentThemes),
          type: 'PREZENT_TEMPLATES',
        },
      ];
    },
    sortedThemeArray() {
      const sections = [];

      this.themeSections.forEach((section) => {
        let { themes } = section;

        if (this.templateSearch) {
          themes = section.themes.filter((theme) =>
            theme.name
              .toLowerCase()
              .includes(this.templateSearch.toLowerCase()),
          );
        }

        if (themes.length) {
          sections.push({
            ...section,
            themes,
          });
        }
      });

      this.sections = sections;
      return sections;
    },
    stopPropogation($event) {
      $event.stopPropagation();
    },
    handleClickTheme(theme) {
      if (theme.isDisabled) {
        if (this.currentUser.user.role === 'User') {
          this.handleRequestAccess(theme);
        }
        return;
      }
      if (theme.code === this.theme.code || theme.disabled) {
        return;
      }
      if (this.origin === 'build') {
        this.$modal.show(
          SlideThemeChangeDetail,
          {
            index: theme,
            updateThemeIndex: this.updateThemeIndex,
          },
          {
            name: 'SlideThemeChangeDetail',
            width: '540px',
            height: '165px',
            styles: { borderRadius: '18px' },
          },
        );
      } else {
        this.$emit('change', { type: 'template_change', val: theme.code });
      }
    },
    async handleRequestAccess(theme) {
      try {
        const props = {
          templateName: theme.name,
        };
        const layout = {
          name: 'RequestAccessModal',
          width: 350,
          height: 360,
          styles: { borderRadius: '20px' },
        };
        this.$modal.show(RequestAccessModal, props, layout);
        await requestThemeAccess({ themeId: theme.id });
      } catch (err) {
        console.error(err);
      }
    },
    async updateThemeIndex(theme) {
      try {
        MatomoAnalyticsHandler.templateSwitched(this.currentUser, theme);
        // trackProfileEvents.profileTemplatesSwitch(this.currentUser, {
        //   [TD_TEMPLATE]: theme?.name,
        // });
        trackBuildEvents.buildTemplateInfoYesIamSure(this.currentUser, {
          [TD_TEMPLATE]: theme?.name,
        });

        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetail;
            userInfo.theme = theme;
            await this.setCurrentUser(userInfo);
            this.$refs.container.scrollTo({ top: 0, behavior: 'smooth' });
          });
        await this.setCurrentTheme(theme);
      } catch (err) {
        console.error('Error in updateThemeIndex:', err);
      }
    },
    handleClose() {
      this.$emit('handleClose');
    },
    onSearch(searchTerm) {
      handleDebounce(() => this.trackTemplateSearchEvent(searchTerm.trim()));
    },
    trackTemplateSearchEvent(searchTerm) {
      trackBuildEvents.buildTemplateInfoSearch(this.currentUser, {
        [TD_QUERY]: searchTerm,
        [TD_NORESULTSFOUND]: this.sections.length > 0 ? '0' : '1',
      });
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          origin: BUILD,
          templateRequestFrom: 'Build',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.center-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.template-selector {
  background: $white-default;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1), -2px -2px 16px rgba(0, 0, 0, 0.1);
  height: 100%;
  ::v-deep .v-input__slot {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%) !important;
  }
  ::v-deep .v-input__control {
    min-height: 40px !important;
  }

  .header-wrapper {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;
    padding: 20px 5px 0 20px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    padding: 0px 5px 0 20px;
    margin-bottom: 16px;
  }
  .title-wrapper {
    display: flex;
    font-weight: 700;
    line-height: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 34px 15px 0px 15px;
    p {
      font-size: 18px;
      margin-bottom: 0 !important;
    }
  }
  .template-search-input {
    padding: 10px 15px;
    font-size: 14px;
    color: #464a4c;
    ::v-deep .v-text-field__slot input::placeholder {
      color: #757575;
    }
  }
  .templates-list {
    height: calc(100% - 165px);
    overflow: scroll;
    padding: 0px 15px 15px 15px;
    .template {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 16px 12px;
      // width: 477px;
      // height: 179px;
      box-shadow: none;
      position: relative;

      &.template-disabled {
        cursor: default;

        > div {
          opacity: 0.4;
        }

        .template-title {
          color: #a2a9b0;
        }

        &:hover {
          background: #f2f4fb;

          .template-title {
            color: #697077;
          }
        }
      }
    }
    ::v-deep .v-card {
      border-radius: 0 !important;
    }
  }
  .not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;
    margin-top: 30px;
    .heading {
      color: #000000;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin: 10px 0px;
      text-align: center;
    }
    button {
      background: #21a7e0;
      border-radius: 25px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 15px 0;
      color: white;
      text-transform: none;
    }
  }
}
.tip-bar {
  position: sticky;
  bottom: 0;
  background: white;
  color: #7b7b7b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 65px;
  line-height: 17px;
  letter-spacing: 0.01em;
  &.no-results {
    position: absolute;
  }
  p {
    margin-bottom: 0 !important;
    padding: 15px;
  }
}
.available-theme-img,
.example-slide-img {
  img {
    background: #f2f2f2;
    box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
      -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}
.section-title {
  margin-bottom: 12px;
  font-size: 16px;
}
.section-list {
  margin-bottom: 22px;
}

.template-header {
  display: grid;
  grid-template-columns: 1fr auto;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 14px;

  &__left {
    grid-template-columns: auto auto;
    display: grid;
    align-items: center;
    justify-content: start;
    gap: 8px;
  }
  &__right {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__marked {
    background: #21a7e0;
    cursor: text;
  }
  &__pinned {
    cursor: text;
  }
  .template-title {
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
