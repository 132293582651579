/* eslint-disable class-methods-use-this */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getFileURL } from '@/utils/calyrex';
import {
  cancelGSRequest,
  checkGSStatus,
  checkTaskStatus,
  checkBulkStatus,
  generateSlideRequest,
  getRecommendedSlidesV2,
  // getRecommendedSlides,
  // getSlideDataForDetailView,
} from '../../../utils/api-helper';
// import { RedesignOperation } from './RedesignUtils';

import API from '../../../utils/api';

export class RedesignService {
  jobIds = [];

  intervals = [];

  abortController = new AbortController();

  constructor() {
    console.log('RedesignService initiated');
  }

  async startRedesignJob(context) {
    const id = uuidv4();
    const payload = {
      callback_id: id,
      report_id: id,
      workflow_name: 'redesign',
      workflow_context: context,
    };

    const jobResponse = await generateSlideRequest(payload);
    console.debug('Job Started:', jobResponse);
    this.jobIds.push(id);
    return {
      jobId: id,
    };
  }

  async checkSlidesStatus(callback) {
    return new Promise((resolve, reject) => {
      let pollInterval;
      let responseArrived = false;
      const getStatus = async () => {
        try {
          const resp = await checkBulkStatus(this.jobIds);
          responseArrived = true;
          const slideResp = [];
          const ids = [...this.jobIds];
          ids.forEach((id) => {
            if (
              resp[id] &&
              (resp[id].workflow_status === 'success' ||
                resp[id].workflow_status === 'failed')
            ) {
              slideResp.push({
                id,
                resp:
                  resp[id].workflow_status === 'failed'
                    ? {}
                    : resp[id].outputs.SlideAggregator.output,
                error: resp[id].workflow_status === 'failed',
              });
              this.jobIds.splice(this.jobIds.indexOf(id), 1);
            }
          });
          if (this.jobIds.length === 0) {
            resolve(resp);
            clearInterval(pollInterval);
          }
          callback(slideResp);
        } catch (err) {
          console.log(err);
          responseArrived = true;
          clearInterval(pollInterval);
          reject(err);
        }
      };
      getStatus();
      pollInterval = setInterval(() => {
        if (responseArrived) {
          getStatus();
          responseArrived = false;
        }
      }, 2000);
      this.intervals.push(pollInterval);
    });
  }

  async getSlideInfo(context) {
    const time = new Date().getTime();

    const id = uuidv4();
    const payload = {
      callback_id: id,
      report_id: id,
      workflow_name: 'redesign',
      workflow_context: context,
    };

    const jobResponse = await generateSlideRequest(payload);
    console.debug('Job Started:', jobResponse);
    this.jobIds.push(id);

    const jobResult = await checkGSStatus(id, (interval) => {
      this.intervals.push(interval);
    });

    const idx = this.jobIds.indexOf(id);
    if (idx >= 0) {
      this.jobIds.splice(idx, 1);
    }

    const timeTaken = (new Date().getTime() - time) / 1000;
    console.debug(`Job Completed in ${timeTaken}:`, { jobResult, payload });

    return {
      slideOutput: jobResult.outputs.SlideAggregator.output,
      time: moment(jobResult.execution_end, 'DD/MM/YY HH:mm:ss:SS').diff(
        moment(jobResult.execution_start, 'DD/MM/YY HH:mm:ss:SS'),
        'seconds',
      ),
      jobId: id,
    };
  }

  async startUpload(context, task) {
    const time = new Date().getTime();

    const id = uuidv4();
    const payload = {
      callback_id: id,
      report_id: id,
      workflow_name: 'redesign-upload',
      workflow_context: context,
    };

    const jobResponse = await generateSlideRequest(payload);
    console.debug('Job Started:', jobResponse);

    const jobResult = await checkTaskStatus(
      id,
      (interval) => {
        this.intervals.push(interval);
      },
      task,
    );

    const timeTaken = (new Date().getTime() - time) / 1000;
    console.debug(`Job Completed in ${timeTaken}:`, { jobResult, payload });

    return { output: jobResult, jobId: id };
  }

  refreshAbortController() {
    this.abortController = new AbortController();
  }

  async fetchSlideMetadata(output, currentUser) {
    try {
      // if (output.table_slide) {
      //   const jobResult = await checkGSStatus(
      //     output.parallel_workflow_callback_id,
      //     (interval) => {
      //       this.intervals.push(interval);
      //     },
      //   );
      //   return this.handleSlide(
      //     jobResult.outputs.SlideAggregator.output,
      //     currentUser,
      //     'reformatted',
      //   );
      // }
      const data = await this.handleSlide(output, currentUser);

      return {
        slideInfo: data.slideInfo,
        callback: output.parallel_workflow_callback_id,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchOtherRecommendation(callback, currentUser) {
    try {
      const jobResult = await checkGSStatus(callback, (interval) => {
        this.intervals.push(interval);
      });
      this.refreshAbortController();
      const reccos = await getRecommendedSlidesV2(
        jobResult.outputs.SlideAggregator.output.slides,
        currentUser,
        this.abortController.signal,
      );
      return {
        reccos,
        confidenceScore:
          jobResult.outputs.SlideAggregator.output.confidence_score,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async handleSlide(output, currentUser) {
    try {
      const slideInfo = {
        redesign: {
          slide_path: output.pptx,
        },
      };
      const landscape = await getFileURL(
        currentUser.user.cognitoID,
        output.thumbnail,
        currentUser.userIp,
        'IOCUpload',
      );
      slideInfo.redesign.confidenceScore = output.confidence_score;
      slideInfo.redesign.design_quotient = output?.metadata?.design_quotient;
      slideInfo.redesign.images = output?.images;
      slideInfo.redesign.thumbnail = output.thumbnail;
      slideInfo.redesign.landscape = landscape;
      this.refreshAbortController();
      const reccos = await getRecommendedSlidesV2(
        output.slides,
        currentUser,
        this.abortController.signal,
      );
      if (!reccos.length) {
        throw new Error('No recommendations');
      }
      slideInfo.redesign.meta = {
        ...output.metadata,
        title: output.metadata.image_keyword || output.metadata.category || '',
        id: reccos[0].id,
        construct: output?.metadata?.prefs?.construct, // Using slide construct from slide aggregator response
      };
      slideInfo.redesign.slideDetails = {
        ...output.metadata,
        title: output.metadata.image_keyword || output.metadata.category || '',
        id: reccos[0].id,
      };

      slideInfo.redesign.redesignedSlides = {
        slides: reccos.filter((i) => i.source !== 'option_0'),
        loading: false,
      };
      slideInfo.redesign.reformattedSlides = {
        slides: reccos.filter((i) => i.source === 'option_0'),
        loading: true,
      };

      // else {
      //   slideInfo.redesign.redesignedSlides = {
      //     slides: [],
      //     loading: false,
      //   };
      //   slideInfo.redesign.reformattedSlides = {
      //     slides: reccos,
      //     loading: false,
      //   };
      // }
      return { slideInfo };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  // getSortedReccos(id, reccos) {
  //   const idx = reccos.findIndex((i) => i.id === id);
  //   if (idx > 0) {
  //     const selected = reccos.splice(idx, 1);
  //     return [...selected, ...reccos];
  //   }
  //   return reccos;
  // }

  async cancel() {
    this.abortController.abort();
    this.intervals.forEach((interval) => clearInterval(interval));
    this.intervals = [];

    await Promise.all(
      this.jobIds.map(async (jobId) => {
        await cancelGSRequest(jobId);
      }),
    ).then(() => {
      this.jobIds = [];
    });
  }

  async createStats(data) {
    const uuid = uuidv4();

    const payload = {
      uuid,
      ...data,
    };

    await API.post('/api3/redesign/add', payload);
    console.debug('Redesign Log: Create Successfull');

    return uuid;
  }

  async updateStats(uuid, data) {
    const payload = {
      uuid,
      ...data,
    };

    await API.put('/api3/redesign/update', payload);
    console.debug('Redesign Log: Update Successfull');
  }
}
