<template>
  <div class="my-workspace-container">
    <div class="search-actions-container">
      <div class="search-wrap">
        <SearchMyWorkspace @onSearch="handleSearch" />
      </div>
      <div class="action-wrap">
        <!-- <v-btn class="secondary-action" elevation="0" rounded :disabled="true">
          <v-icon v-text="`mdi-cloud-sync-outline`" />
          {{ $t('myWorkspace.mainPage.syncFiles') }}</v-btn
        > -->
        <v-btn
          class="secondary-action"
          elevation="0"
          rounded
          @click="openCreateProject()"
        >
          <v-icon v-text="`mdi-plus`" />
          {{ $t('myWorkspace.mainPage.createProject') }}</v-btn
        >
        <v-btn
          color="#21A7E0"
          class="primary-action"
          rounded
          @click="openFileUpload()"
        >
          <v-icon v-text="`mdi-tray-arrow-up`" />
          {{ $t('myWorkspace.mainPage.addFiles') }}</v-btn
        >
      </div>
    </div>
    <div class="filter-view-container">
      <div class="filter-wrap">
        <div class="chip-container ws-filter">
          <v-chip-group
            v-model="selectedFilterChip"
            mandatory
            cols="1"
            active-class="active-chip"
          >
            <v-chip
              class="img-chip"
              :ripple="false"
              :disabled="chip.disabled"
              v-for="chip in filterChips"
              :key="chip.value"
              @click="(e) => onFilterChipClicked(chip)"
            >
              {{ chip.name }}
            </v-chip>
          </v-chip-group>
        </div>
        <div
          class="reset"
          :class="isBreadCrumb ? 'disable-reset' : ''"
          @click="resetFilters()"
        >
          {{ 'Reset all filters' }}
        </div>
      </div>
      <!-- TODO: see if you can use vue icon -->
      <!-- <div class="view-wrap">
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="`view-mode mosaic ${
                viewMode === 'grid-small' ? 'active' : ''
              }`"
              v-bind="attrs"
              v-on="on"
              @click="handleViewMode('grid-small')"
            >
              <img
                class="normal"
                src="../../../../public/assets/img/myWorkspace/grid_view_small.svg"
                alt
              />
            </div>
          </template>
          <span>{{ $t('build.step3.mosaicViewText') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="`view-mode mosaic ${
                viewMode === 'grid-large' ? 'active' : ''
              }`"
              v-bind="attrs"
              v-on="on"
              @click="handleViewMode('grid-large')"
            >
              <img
                class="normal"
                src="../../../../public/assets/img/myWorkspace/grid_view_large.svg"
                alt
              />
            </div>
          </template>
          <span>{{ $t('build.step3.mosaicViewText') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="`view-mode mosaic ${
                viewMode === 'table' ? 'active' : ''
              }`"
              v-bind="attrs"
              v-on="on"
              @click="handleViewMode('table')"
            >
              <img
                class="normal"
                src="../../../../public/assets/img/myWorkspace/table_rows.svg"
                alt
              />
            </div>
          </template>
          <span>{{ $t('build.step3.mosaicViewText') }}</span>
        </v-tooltip>
      </div> -->
    </div>
    <div class="my-workspace-wrap">
      <div class="my-files-wrap" v-if="showFiles">
        <div class="workspace-title bread-crumb" v-if="isBreadCrumb">
          <div class="back" @click="backHome">{{ 'Home' }}</div>
          <div class="back">&nbsp;/&nbsp;</div>
          <div class="project-name">{{ projectName }}</div>
        </div>
        <div class="workspace-title" v-else>
          {{ $t('myWorkspace.mainPage.filesTitle') }}
        </div>
        <div class="loader-container" v-if="loading">
          <clip-loader
            :loading="loading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
          />
        </div>

        <MyWorkspaceFiles
          v-else-if="uploadedFiles.length > 0"
          :files="uploadedFiles"
        />
        <div
          class="workspace-placeholder"
          v-else-if="uploadedFiles.length === 0 && searchText"
        >
          <div class="placeholder-img-container">
            <v-img
              class="placeholder-img"
              :aspect-ratio="16 / 9"
              src="../../../../public/assets/img/myWorkspace/project_placeholder.svg"
              alt="image"
              contain
            >
            </v-img>
          </div>
          <div class="placeholder-wrap">
            <p>{{ 'No Files Found' }}</p>
          </div>
        </div>
        <div class="workspace-placeholder" v-else>
          <div class="placeholder-img-container">
            <v-img
              class="placeholder-img"
              :aspect-ratio="16 / 9"
              src="../../../../public/assets/img/myWorkspace/files_placeholder.svg"
              alt="image"
              contain
            >
            </v-img>
          </div>
          <div class="placeholder-wrap">
            <p>{{ $t('myWorkspace.mainPage.placeholderFiles') }}</p>
            <v-btn
              class="secondary-action"
              elevation="0"
              rounded
              @click="openFileUpload()"
            >
              {{ addFileText }}</v-btn
            >
          </div>
        </div>
      </div>
      <div class="my-project-wrap" v-if="showProjects">
        <div class="workspace-title">
          {{ $t('myWorkspace.mainPage.projectTitle') }}
        </div>
        <div class="loader-container" v-if="areProjectsLoading">
          <clip-loader
            :loading="areProjectsLoading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
          />
        </div>
        <MyProjects
          v-else-if="createdProjects.length > 0"
          :projects="createdProjects"
          @openProject="handleOpenProject"
        />
        <div
          class="workspace-placeholder"
          v-else-if="createdProjects.length === 0 && searchText"
        >
          <div class="placeholder-img-container">
            <v-img
              class="placeholder-img"
              :aspect-ratio="16 / 9"
              src="../../../../public/assets/img/myWorkspace/project_placeholder.svg"
              alt="image"
              contain
            >
            </v-img>
          </div>
          <div class="placeholder-wrap">
            <p>{{ 'No Projects Found' }}</p>
          </div>
        </div>
        <div class="workspace-placeholder" v-else>
          <div class="placeholder-img-container">
            <v-img
              class="placeholder-img"
              :aspect-ratio="16 / 9"
              src="../../../../public/assets/img/myWorkspace/project_placeholder.svg"
              alt="image"
              contain
            >
            </v-img>
          </div>
          <div class="placeholder-wrap">
            <p>{{ $t('myWorkspace.mainPage.placeholderProject') }}</p>
            <v-btn
              class="secondary-action"
              elevation="0"
              rounded
              @click="openCreateProject()"
            >
              {{ $t('myWorkspace.mainPage.createYourFirstProject') }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import SearchMyWorkspace from './SearchMyWorkspace.vue';
import UploadToMyWsModal from './UploadToMyWsModal.vue';
import CreateProjectModal from './CreateProjectModal.vue';
import MyWorkspaceFiles from './MyWorkspaceFiles.vue';
import { MyWorkspaceService } from './MyWorkspaceService';
import MyProjects from './MyProjects.vue';
import AddToProjectModal from './AddToProjectModal.vue';

export default {
  name: 'MyWorkspace',
  components: {
    SearchMyWorkspace,
    ClipLoader,
    MyWorkspaceFiles,
    MyProjects,
  },
  data() {
    return {
      loading: false,
      selectedFilterChip: 0,
      uploadedFiles: [],
      createdProjects: [],
      showProjects: true,
      areProjectsLoading: false,
      showFiles: true,
      isBreadCrumb: false,
      projectName: '',
      searchText: '',
      currentProjectId: '',
      defaultFilterChips: [
        { name: 'All', value: 'all' },
        { name: 'Files', value: 'files' },
        { name: 'Projects', value: 'project' },
        { name: 'Author', value: 'author', disabled: true },
        { name: 'Other filters', value: 'other', disabled: true },
        { name: 'Sort by', value: 'sort', disabled: true },
      ],
      filterChips: [
        { name: 'All', value: 'all' },
        { name: 'Files', value: 'files' },
        { name: 'Projects', value: 'project' },
        { name: 'Author', value: 'author', disabled: true },
        { name: 'Other filters', value: 'other', disabled: true },
        { name: 'Sort by', value: 'sort', disabled: true },
      ],
      viewMode: '',
      query: '',
      debouncedQuery: '',
      debounceTimer: null,
      showFilterFiles: false,
    };
  },
  async beforeMount() {
    this.getWorkspaceData();
  },
  watch: {
    selectedDropdownAction(val) {
      this.handleDropdownSelection(val);
    },
  },
  methods: {
    ...mapActions('myWorkspace', ['setAllProjects']),
    handleViewMode(mode) {
      console.log(mode);
    },
    handleDropdownSelection(action) {
      switch (action) {
        case 'addToProject':
          this.$modal.show(
            AddToProjectModal,
            {
              updateLocalProjects: this.getWorkspaceData,
            },
            {
              name: 'AddToProjectModal',
              width: '440px',
              height: '480px',
              styles: { borderRadius: '8px' },
            },
          );
          break;
        default:
          break;
      }
    },
    backHome() {
      this.showProjects = true;
      this.isBreadCrumb = false;
      this.projectName = '';
      this.currentProjectId = '';
      this.resetFilters();
      this.getFiles();
      this.getProjects();
    },
    getWorkspaceData() {
      if (this.currentProjectId) {
        this.getFiles({ workspaceProjectId: this.currentProjectId });
      } else {
        this.getFiles();
        this.getProjects();
      }
    },
    handleOpenProject(projectData) {
      this.loading = true;
      this.showProjects = false;
      this.isBreadCrumb = true;
      this.projectName = projectData.title;
      this.selectedFilterChip = 1;
      this.showFiles = true;
      this.filterChips = this.filterChips.map((item) => {
        if (item.value !== 'files') {
          item = { ...item, disabled: true };
        }
        return item;
      });
      this.currentProjectId = projectData.id;
      this.getFiles({ workspaceProjectId: this.currentProjectId });
    },
    handleSearch(searchText) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.debouncedQuery = this.query;
        this.searchText = searchText;
        console.log(searchText, 'searchText');
        if (this.isBreadCrumb) {
          this.getFiles({
            search: searchText,
            workspaceProjectId: this.currentProjectId,
          });
        } else {
          this.getFiles({ search: searchText });
          this.getProjects({ search: searchText });
        }
      }, 500);
    },
    resetFilters() {
      this.onFilterChipClicked({ name: 'All', value: 'all' });
      this.filterChips = this.defaultFilterChips;
    },
    onFilterChipClicked(chip) {
      this.selectedFilterChip = chip.value;
      if (this.selectedFilterChip === 'files') {
        this.showFiles = true;
        this.showProjects = false;
        this.getFiles();
      } else if (this.selectedFilterChip === 'project') {
        this.showProjects = true;
        this.showFiles = false;
        this.getProjects();
      } else {
        this.showFiles = true;
        this.showProjects = true;
      }
    },
    async getFiles(params) {
      this.loading = true;
      this.myWorkspaceService
        .getWsData({ ...params, type: 'files', limit: 50, offset: 0 })
        .then((res) => {
          this.loading = false;
          this.uploadedFiles = res?.data || [];
          this.showFilterFiles = this.uploadedFiles.length > 0;
        });
    },
    async getProjects(params) {
      this.areProjectsLoading = true;
      this.myWorkspaceService
        .getWsData({ ...params, type: 'projects', limit: 50, offset: 0 })
        .then((res) => {
          this.areProjectsLoading = false;
          this.createdProjects = res?.data || [];
          if (!params) {
            this.setAllProjects(this.createdProjects);
          }
        });
    },
    openFileUpload() {
      this.$modal.show(
        UploadToMyWsModal,
        {
          workspaceId: this.currentProjectId,
          updateLocalFiles: this.addFiles,
        },
        {
          name: 'UploadToMyWsModal',
          width: '952px',
          height: '587px',
          styles: { borderRadius: '8px' },
        },
      );
    },
    addFiles() {
      if (this.isBreadCrumb) {
        this.getFiles({ workspaceProjectId: this.currentProjectId });
      } else {
        this.getFiles();
      }
    },
    openCreateProject() {
      this.$modal.show(
        CreateProjectModal,
        {
          showFilterFiles: this.showFilterFiles,
          updateLocalFiles: this.getFiles,
          updateLocalProjects: this.getProjects,
        },
        {
          name: 'CreateProjectModal',
          width: '90%',
          height: '700px',
          styles: { borderRadius: '8px' },
        },
      );
    },
  },
  computed: {
    ...mapState('myWorkspace', ['selectedDropdownAction']),
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
    addFileText() {
      return this.isBreadCrumb
        ? 'Add file'
        : this.$t('myWorkspace.mainPage.addYourFiles');
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px 60px 0;
  width: 50%;
  flex-basis: 50%;
}
.my-workspace-container {
  padding: 20px;
  margin: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .search-actions-container {
    display: flex;
    justify-content: space-between;
    .search-wrap {
    }
    .action-wrap {
      display: flex;
      gap: 10px;
      align-items: center;
      .primary-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 22px;
        gap: 8px;

        width: auto;
        height: 32px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: none;
        letter-spacing: normal;
        line-height: 19px;
        color: #ffffff;
        background: #21a7e0;
        border-radius: 25px;
        .v-icon {
          margin-right: 6px;
        }
      }
      .secondary-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        gap: 8px;
        height: 32px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: none;
        letter-spacing: normal;
        line-height: 19px;

        /* Color Styles (Old)/White */

        color: #21a7e0;

        background: #ffffff;
        border: 1px solid #21a7e0;
        border-radius: 25px;
        .v-icon {
          margin-right: 6px;
        }
        &.v-btn--disabled {
          border: 1px solid rgba(0, 0, 0, 0.26) !important;
        }
      }
    }
  }
  .filter-view-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    .filter-wrap {
      display: flex;
      gap: 10px;
      align-items: center;
      .chip-container {
        // margin-bottom: 12px;

        &.ws-filter {
          ::v-deep .v-slide-group__content {
            margin-top: 0;
            padding-bottom: 0;
          }
        }
        ::v-deep .v-slide-group__content {
          gap: 12px;
          // margin-top: 28px;
          padding-top: 0;
          .active-chip {
            background: #e1f5fb !important;
            border: 1px solid transparent !important;
            &::before {
              opacity: 0;
            }
          }
          v-chip,
          .v-chip--outlined,
          .v-chip.v-chip {
            background: white;
            border: 1px solid rgba(105, 112, 119, 1);
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .img-chip {
          font-family: Lato;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.10000000149011612px;
          text-align: center;
          border: 1px solid #697077;
          color: #343a3f;
          border-radius: 8px;
        }
        img {
          width: 100%;
        }
      }
      .reset {
        cursor: pointer;
        color: #7b7b7b;
      }
      .disable-reset {
        pointer-events: none;
        color: #697077;
      }
    }
    .view-wrap {
      display: flex;
      gap: 25px;
      padding-right: 10px;
    }
  }
  .my-workspace-wrap {
    gap: 20px;
    display: flex;
    flex-direction: column;
    .my-files-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .my-project-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 30px;
    }
  }
}
.workspace-title {
  color: #343a3f;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.bread-crumb {
  display: flex;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  .back {
    color: #878d96;
    cursor: pointer;
    &:hover {
      color: #343a3f;
    }
  }
}
.workspace-placeholder {
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #ffffff;
  padding: 60px 20px 60px 0;
  flex-basis: 50%;
  width: 50%;
  box-shadow: -1px -1px 4px 0px #0000001a, 1px 1px 4px 0px #0000001a;

  .placeholder-wrap {
    p {
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}
.secondary-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  height: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  letter-spacing: normal;
  line-height: 19px;

  /* Color Styles (Old)/White */

  color: #21a7e0;

  background: #ffffff !important;
  border: 1px solid #21a7e0;
  border-radius: 25px;
  .v-icon {
    margin-right: 6px;
  }
}
</style>
