<template>
  <img :height="height" :width="width" :src="getIcon" alt="file-icon" />
</template>

<script>
import pptIcon from '../../../../public/assets/img/myWorkspace/powerpoint.svg';
import pdfIcon from '../../../../public/assets/img/myWorkspace/pdf.svg';
import excelIcon from '../../../../public/assets/img/myWorkspace/excel.svg';
import docIcon from '../../../../public/assets/img/myWorkspace/docs.svg';
import folder from '../../../../public/assets/img/myWorkspace/folder.svg';

export default {
  name: 'WsFileIcon',
  props: {
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
    },
    fileName: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    getIcon() {
      const name = this.fileName;
      let iconPath = '';
      if (this.iconType === 'project') {
        iconPath = folder;
      } else {
        const fileType = name?.split('.')[1];
        switch (fileType) {
          case 'ppt':
          case 'pptx':
            iconPath = pptIcon;
            break;
          case 'doc':
          case 'docx':
            iconPath = docIcon;
            break;
          case 'xlsx':
            iconPath = excelIcon;
            break;
          case 'pdf':
            iconPath = pdfIcon;
            break;
          default:
            iconPath = pptIcon;
            break;
        }
      }
      return iconPath;
    },
  },
};
</script>

<style lang="scss" scoped></style>
