<template>
  <div
    class="root"
    :class="
      (!isBothSlidesVisible ||
        error ||
        !loader.isIdle() ||
        (slide && !slide.completed)) &&
      'root--single'
    "
    :data-pendo-id="
      !loader.isIdle() ||
      !!error ||
      (slide && (slide.error || !slide.completed))
        ? ''
        : 'redesign-executive-summary'
    "
  >
    <template v-if="error">
      <div class="rdc__slide offset-top">
        <v-img class="rdc__image" :src="errorLandscape">
          <div class="error-btn-container" v-if="error === 'RDSGN_ERR'">
            <v-btn
              class="error-btn"
              elevation="2"
              rounded
              color="primary"
              @click="onErrorBtnClick('RDGSN_ERR')"
              >{{ $t('redesign.title') }}</v-btn
            >
          </div>
        </v-img>
      </div>
    </template>
    <template v-else-if="!loader.isIdle()">
      <div class="rdc__slide offset-top">
        <VideoLoader v-model="loader" class="rdc__image" />
      </div>
    </template>
    <template v-else-if="slide && !slide.completed">
      <div class="rdc__slide offset-top">
        <VideoLoader v-model="smartToolsLoader" class="rdc__image" />
      </div>
    </template>
    <template v-else>
      <!-- Uploaded Slide -->
      <div class="rdc" v-if="visible.uploadedSlide">
        <div class="rdc__header">
          <div class="rdc__header-left">
            <h4 class="rdc__title">
              {{ $t('redesign.viewer.originalSlide') }}
            </h4>
            <v-btn
              icon
              :style="{
                pointerEvents: slide ? '' : 'none',
              }"
              :disabled="slide && slide.error"
              @click="handleVisibility('redesignedSlide', !isBothSlidesVisible)"
            >
              <v-icon class="rdc__expend" v-if="isBothSlidesVisible">
                mdi-arrow-expand
              </v-icon>
              <v-icon class="rdc__expend" v-else>mdi-arrow-collapse</v-icon>
            </v-btn>
          </div>
          <span></span>
          <!-- <v-btn
            text
            color="primary"
            class="rdc__show"
            v-if="!isBothSlidesVisible"
            @click="handleVisibility('redesignedSlide', true)"
          >
            {{ $t('synthesize.viewer.showExecutiveSummary') }}
          </v-btn>
          <a href="javascript:void(0)" class=""></a> -->
        </div>
        <div class="rdc__slide">
          <img
            class="rdc__image"
            v-if="slide"
            :src="slide.thumbnail_png.thumbnail"
          />
          <img class="rdc__image" v-else :src="exampleUploadedLandscapeUrl" />
        </div>
      </div>

      <!-- Redesigned Slide -->
      <div
        class="rdc"
        v-if="visible.redesignedSlide"
        :key="slide ? slide.id : 1"
      >
        <div class="rdc__header">
          <div class="rdc__header-left">
            <h4 class="rdc__title">
              {{ $t('redesign.viewer.redesignedSlide') }}
            </h4>
            <v-btn
              :style="{
                pointerEvents: slide ? '' : 'none',
              }"
              :disabled="slide && slide.error"
              icon
              @click="handleVisibility('uploadedSlide', !isBothSlidesVisible)"
            >
              <v-icon class="rdc__expend" v-if="isBothSlidesVisible">
                mdi-arrow-expand
              </v-icon>
              <v-icon class="rdc__expend" v-else>mdi-arrow-collapse</v-icon>
            </v-btn>
          </div>
          <span></span>
          <!-- <v-btn
            text
            color="primary"
            class="rdc__show"
            v-if="!isBothSlidesVisible"
            @click="handleVisibility('uploadedSlide', true)"
            data-pendo-id="synthesis-show-current-slide"
          >
            {{ $t('synthesize.viewer.showUploadedSlide') }}
          </v-btn> -->
        </div>
        <div class="rdc__slide">
          <img
            class="rdc__image"
            v-if="!slide"
            :src="exampleRedesignedLandscapeUrl"
          />
          <img
            class="rdc__image"
            v-else-if="slide && slide.keepOriginal"
            :src="slide.thumbnail_png.thumbnail"
          />
          <img
            class="rdc__image"
            v-else-if="slide && !slide.keepOriginal && !slide.error"
            :src="slide.redesign.landscape"
          />

          <v-img
            class="rdc__image"
            v-else-if="slide && !slide.keepOriginal && slide.error"
            :src="errorLandscape"
          >
            <div
              class="img-content"
              :class="isBothSlidesVisible ? 'collapse-view' : ''"
            >
              <div class="text-container">
                <p class="error-title">
                  {{ $t('redesign.content.errorTitle') }}
                </p>
                <p class="body">
                  {{
                    isOPEnabled
                      ? $t('redesign.content.errorBody')
                      : $t('redesign.content.errorBodyNonOP')
                  }}
                </p>
              </div>
              <div class="error-btn-container">
                <v-btn
                  v-if="isOPEnabled"
                  class="error-btn"
                  elevation="2"
                  rounded
                  color="primary"
                  @click="onErrorBtnClick('OP_EN')"
                  >{{ $t('redesign.content.opBtnText') }}</v-btn
                >
                <v-btn
                  v-if="!isOPEnabled"
                  class="error-btn get-in-touch"
                  elevation="2"
                  rounded
                  color="primary"
                  :loading="buttonLoader"
                  @click="onErrorBtnClick('GET_IN_TOUCH')"
                  >{{ $t('redesign.content.contactBtnText') }}</v-btn
                >
                <v-btn
                  class="error-btn secondary-btn ma-4 py-2"
                  elevation="0"
                  rounded
                  @click="onErrorBtnClick('RDSGN_SLIDE')"
                  >{{ $t('redesign.content.redesignSlideBtnText') }}</v-btn
                >
              </div>
            </div>
          </v-img>
        </div>
      </div>
    </template>
    <div class="root__actions" v-if="slide && slide.completed">
      <div
        class="root__actions_confidence"
        v-if="isRedesignSlideScoresFullAccess"
      >
        <span>
          {{ getSlidesScoresStr }}
        </span>
      </div>
      <v-btn
        text
        color="primary"
        class="rdc__show"
        v-if="!isBothSlidesVisible"
        @click="toggleVisibility"
        data-pendo-id="redesign-show-original"
      >
        {{ visible.uploadedSlide ? 'Show redesigned' : 'Show original' }}
      </v-btn>
      <!-- <v-btn
        text
        color="primary"
        class="rdc__show"
        :disabled="slide.keepOriginal"
        @click="keepOriginalSlide"
      >
        Keep original
      </v-btn> -->
    </div>
    <!-- <template v-else>
      <div class="rdc__slide offset-top">
        <img class="rdc__image" :src="exampleLandscapeUrl" />
      </div>
    </template> -->

    <div class="root__disclaimer">
      <div class="root__disclaimer__sub">
        {{ $t('synthesize.viewer.warningAstrid') }}
      </div>
      <div>
        {{ $t('synthesize.viewer.disclaimer') }}
        <a @click="learnMore">
          {{ $t('synthesize.viewer.disclaimerMore') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
// import RedesignLoader from './RedesignLoader.vue';
import { trackRedesignEvents } from '../../common/Analytics/RedesignEvents';
import {
  TD_TEMPLATE,
  TD_CLICKED_TO,
  TD_MAXIMIZE,
  TD_MINIMIZE,
  NA,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import VideoLoader from '../../common/VideoLoader.vue';
import { Loader } from '../../../utils/loader-helper';

export default {
  name: 'RedesignContent',
  data() {
    return {
      visible: {
        uploadedSlide: true,
        redesignedSlide: true,
      },
      errorImageMap: {
        UPL_ERR: '/assets/img/redesign/load-failure.svg',
        SIZE_ERR: '/assets/img/redesign/file-above-limit-redesign.svg',
        UPL_FILE_ERR: '/assets/img/comply/file-upload-30-slides.svg',
        RDSGN_ERR: '/assets/img/redesign/load-failure.svg',
      },
      smartToolsLoader: new Loader('redesignSmartTools', 1),
    };
  },
  props: {
    slide: {
      type: Object,
      default: () => null,
    },
    error: {
      type: String,
      default: null,
    },
    loader: {
      type: Object,
      required: true,
    },
    buttonLoader: {
      default: false,
      type: Boolean,
    },
  },
  components: { VideoLoader },
  watch: {
    slide(val) {
      if (val) {
        this.visible = {
          uploadedSlide: false,
          redesignedSlide: true,
        };
      } else {
        this.visible = {
          uploadedSlide: true,
          redesignedSlide: true,
        };
      }
    },
  },

  computed: {
    ...mapGetters('users', [
      'getOPAccessLevel',
      'isRedesignSlideScoresFullAccess',
    ]),
    isOPEnabled() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    errorLandscape() {
      if (this.slide === null) {
        return this.errorImageMap[this.error];
      }
      if (this.slide.error) {
        return '/assets/img/redesign/cant-redesign-no-text.svg';
      }
      return '/assets/img/redesign/send-slide-to-op.svg';
    },
    isBothSlidesVisible() {
      if (this.visible.uploadedSlide && this.visible.redesignedSlide) {
        return true;
      }
      return false;
    },
    ...mapState('users', ['currentUser']),
    exampleRedesignedLandscapeUrl() {
      return this.currentUser.user.visualPreference &&
        this.currentUser.user.visualPreference[0].toLowerCase() === 'vibrant'
        ? `/assets/img/redesign/vibrant-redesigned-slide-placeholder.svg`
        : `/assets/img/redesign/minimal-redesigned-slide-placeholder.svg`;
    },
    exampleUploadedLandscapeUrl() {
      return this.currentUser.user.visualPreference &&
        this.currentUser.user.visualPreference[0].toLowerCase() === 'vibrant'
        ? `/assets/img/redesign/vibrant-original-slide-placeholder.svg`
        : `/assets/img/redesign/minimal-original-slide-placeholder.svg`;
    },

    // Property to get display string based on slide design quotient and confidence score
    getSlidesScoresStr() {
      /*
        Falsyfying design_quotient and confidenceScore values
        if they are 'NA' in workflow response.
      */
      const dsgnQuotient =
        this.slide.redesign.design_quotient === 'NA'
          ? 0
          : this.slide.redesign.design_quotient;
      const confScore =
        this.slide.redesign.confidenceScore === 'NA'
          ? 0
          : this.slide.redesign.confidenceScore;

      if (confScore && dsgnQuotient) {
        return `${confScore}% | ${Number(dsgnQuotient).toFixed(2)}`;
      }
      if (confScore) {
        return `${confScore}% | ${this.$t('common.na')}`;
      }
      if (dsgnQuotient) {
        return `${this.$t('common.na')} | ${Number(dsgnQuotient).toFixed(2)}`;
      }
      return `${this.$t('common.na')}`;
    },
  },
  mounted() {},

  methods: {
    keepOriginalSlide() {
      this.$emit('keepOriginal');
    },
    toggleVisibility() {
      this.visible = {
        uploadedSlide: !this.visible.uploadedSlide,
        redesignedSlide: !this.visible.redesignedSlide,
      };
      const otherData = { [TD_TEMPLATE]: NA };
      if (this.visible.uploadedSlide) {
        trackRedesignEvents.redesignShowOriginal(this.currentUser, otherData);
      } else {
        trackRedesignEvents.redesignShowRedesigned(this.currentUser, otherData);
      }
    },
    handleVisibility(slide, show) {
      this.$set(this.visible, slide, show);
      const otherData = {
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_NAME]: TD_CLICKED_TO,
        [TD_COMMON_COLUMN_VALUE]: show ? TD_MINIMIZE : TD_MAXIMIZE,
      };
      trackRedesignEvents.redesignCollapseExpand(this.currentUser, otherData);
    },

    learnMore() {
      const otherData = { [TD_TEMPLATE]: NA };
      trackRedesignEvents.redesignLearnMore(this.currentUser, otherData);
      window.open('https://www.prezent.ai/security', '_blank');
    },
    onErrorBtnClick(type) {
      this.$emit('redesignError', {
        type,
        key: this.slide ? this.slide?.id : '',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  gap: 8px;
  transition: 0.4s;
  // overflow: hidden;

  &__disclaimer {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 20px;
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #697077;
    &__sub {
      margin-bottom: 2px;
    }
  }

  &--single {
    grid-template-columns: 1fr;
    gap: 0;
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 40px;
    gap: 8px;

    &_confidence {
      font-weight: bold;
    }
  }
}

.offset-top {
  margin-top: 42px;
}

.loading-spinner {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rdc {
  display: grid;
  grid-template-rows: 40px 1fr;
  gap: 2px;
  // overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: start;
    align-items: center;

    &-left {
      display: grid;
      grid-auto-flow: column;
      gap: 16px;
      align-items: center;
    }
  }
  &__title {
    padding: 0 6px;
  }

  &__expend {
    font-size: 22px !important;
  }

  &__show {
    text-transform: unset;
    font-weight: 600;
    letter-spacing: normal;
  }

  &__slide {
    overflow: hidden;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__image {
    max-height: 100%;
    box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
    border-radius: 8px;
    aspect-ratio: 16/9;
    width: 100%;
    overflow: hidden;
    .img-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .text-container {
        flex-basis: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        font-family: 'lato';
        .error-title {
          font-size: 18px;
        }
        .body {
          font-size: 16px;
          width: 50%;
          text-align: center;
        }
      }
      .error-btn-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .error-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 16px;
          gap: 8px;

          width: auto;
          height: 32px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
          letter-spacing: normal;
          line-height: 19px;

          /* Color Styles (Old)/White */

          color: #ffffff;

          background: #21a7e0;
          border-radius: 25px;
        }
        .secondary-btn {
          background-color: transparent;
          border: 1px solid #21a7e0;
          color: #21a7e0;
          text-transform: none;
        }
        .get-in-touch {
          text-transform: none;
        }
      }

      &.collapse-view {
        .text-container {
          .error-title {
            font-size: 16px;
          }
          .body {
            font-size: 13px;
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
