<template>
  <div class="main-actions-row" ref="actionsContainer" v-if="actions.length">
    <div class="horz-actions">
      <div v-for="(action, index) in horzActionList" :key="`item-${index}`">
        <v-tooltip
          top
          content-class="tooltip-content"
          v-if="isPrezentSourceBestPractice && showTooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                rounded
                color="primary"
                text
                :key="`button-${index}`"
                :data-pendo-id="action.dataPendoId ? action.dataPendoId : null"
                :name--auto="`${action.id}${
                  action.id === selectedMainAction.id ? '--active' : ''
                }`"
                @click="() => handleIconClick(action)"
                :class="
                  action.id === selectedMainAction.id &&
                  selectedMainAction.id !== 'editSlide'
                    ? 'active'
                    : ''
                "
                :disabled="action.disabled"
              >
                <div class="main-action-content">
                  <component
                    :is="getIcon(action)"
                    :iconWidth="24"
                    :iconHeight="24"
                    :iconColor="
                      action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'
                    "
                    :iconStrokeWidth="0.5"
                    v-if="action.isCustomIcon"
                  />
                  <v-icon dark size="20" class="main-action-icon">
                    {{ action.icon }}
                  </v-icon>
                  <NewFeatureBadge
                    v-if="isBestPractices"
                    module-name="release_4200"
                    :feature-id="getFeatureId(action)"
                    :offsetX="getOffsetValue(action)"
                    :offsetY="'-10'"
                    :size="'medium'"
                  >
                  </NewFeatureBadge>
                  <span>{{ action.labelText || $t(action.label) }}</span>
                </div>
              </v-btn>
            </div>
          </template>
          <span>{{ action.tooltipText }}</span>
        </v-tooltip>
        <v-btn
          rounded
          color="primary"
          text
          :key="`button-${index}`"
          :data-pendo-id="action.dataPendoId ? action.dataPendoId : null"
          :name--auto="`${action.id}${
            action.id === selectedMainAction.id ? '--active' : ''
          }`"
          @click="() => handleIconClick(action)"
          :class="
            action.id === selectedMainAction.id &&
            selectedMainAction.id !== 'editSlide'
              ? 'active'
              : ''
          "
          :disabled="action.disabled"
          v-else
        >
          <div class="main-action-content">
            <component
              :is="getIcon(action)"
              :iconWidth="24"
              :iconHeight="24"
              :iconColor="action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'"
              :iconStrokeWidth="0.5"
              v-if="action.isCustomIcon"
            />
            <v-icon dark size="20" class="main-action-icon">
              {{ action.icon }}
            </v-icon>
            <NewFeatureBadge
              v-if="isBestPractices"
              module-name="release_4200"
              :feature-id="getFeatureId(action)"
              :offsetX="getOffsetValue(action)"
              :offsetY="'-10'"
              :size="'medium'"
            >
            </NewFeatureBadge>
            <span>{{ action.labelText || $t(action.label) }}</span>
          </div>
        </v-btn>
        <div
          v-if="
            action.id === selectedMainAction.id &&
            selectedMainAction.id === 'editSlide'
          "
          :key="`divider-${index}`"
          class="divider"
        ></div>
      </div>
    </div>
    <div
      class="more-actions"
      data-pendo-id="slide-action-more-options"
      ref="moreOption"
      v-if="moreOptionList.length"
    >
      <v-menu
        top
        absolute
        offset-y
        :close-on-click="true"
        :position-x="0"
        :position-y="0"
      >
        <template #activator="menu">
          <v-btn rounded color="primary" text v-on="{ ...menu.on }">
            <v-tooltip top max-width="200" fixed>
              <template v-slot:activator="{ on, attrs }">
                <div class="tooltip-activator" v-bind="attrs" v-on="on">
                  <v-icon dark size="20"> mdi-dots-vertical </v-icon>
                  <span>More options</span>
                </div>
              </template>
              <span>{{ $t('slides.moreActions') }}</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(action, index) in moreOptionList">
            <v-list-item
              :key="index"
              :disabled="action.disabled"
              @click="() => handleIconClick(action)"
              v-if="action.id"
              :name--auto="action.automationText"
            >
              <v-list-item-title>
                <div
                  class="otherActions"
                  :class="{ disabled: action.disabled }"
                >
                  <div class="icon">
                    <component
                      :is="getIcon(action)"
                      :iconWidth="20"
                      :iconHeight="20"
                      :iconColor="
                        action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'
                      "
                      :iconStrokeWidth="0.5"
                      v-if="action.isCustomIcon"
                    />
                    <v-icon
                      v-else
                      size="20"
                      :color="
                        action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'
                      "
                    >
                      {{ action.icon }}
                    </v-icon>
                  </div>
                  <div
                    class="actionName"
                    :style="{
                      color: `${
                        action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'
                      }`,
                    }"
                  >
                    {{ action.labelText || $t(action.label) }}
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from '../../../common/event-bus';
import ComplyIcon from '../../../common/Icons/ComplyIcon.vue';
import { mainActions } from '../config/actionConfig';
import { getFeatureUsage } from '../../../../utils/api-helper';
import NewFeatureBadge from '../../../common/NewFeatureBadge.vue';
import {
  MY_PREZENTATION,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATIONAUTHOR,
  TD_NUMBEROFSLIDES,
  TD_TOP_PANE,
  TD_PLACEMENT,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_CATEGORY,
  TD_PREZENTATION_LIBRARY,
  TD_CROSS_FEATURE,
  BUILD,
  getSlideCount,
  TD_DECK_ID,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import SynthesizeIcon from '../../../common/Icons/SynthesizeIcon.vue';
import { trackKnowEvents } from '../../../common/Analytics/KnowEvents';

export default {
  components: {
    NewFeatureBadge,
  },
  props: {
    rightPanelOpen: {
      type: Function,
      default: () => {},
    },
    rightPanelClose: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
    showRightPanel: {
      type: Boolean,
    },
  },
  data() {
    return {
      actions: [],
      horzActionList: [],
      moreOptionList: [],
      currentAudienceID: null,
      showTooltip: false,
      iconMap: {
        'comply-icon': ComplyIcon,
        'synthesize-icon': SynthesizeIcon,
      },
      autoGenerateEnabledTemplatesList: [],
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'startLoadingSlidesFlag',
      'themes',
      'limitsInfoObj',
    ]),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedMainAction',
      'inListViewMode',
      'slideActions',
      'selectedSlideAction',
      'actionCompletedInfo',
    ]),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'currentItemIsEditable',
      'isEditor',
    ]),
    ...mapGetters('users', [
      'isUploadFullAccess',
      'isPrezAnonymizeFullAccess',
      'isPrezSynthesisFullAccess',
      'isPrezComplyFullAccess',
      'isBPEPrezComplyFullAccess',
      'allowReplaceImageOverall',
      'allowReplaceIconOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
      'isGenerateFullAccess',
      'isPrezGenerateFullAccess',
    ]),

    isOwnerOfPrezentation() {
      return this.prezentationData.ownerID === this.currentUser.user.id;
    },

    isTemplateAGEnabled() {
      return this.autoGenerateEnabledTemplatesList.filter(
        (theme) => theme?.code === this.prezentationData?.theme,
      ).length;
    },
    setDefaultAudience() {
      /**
       * if there is an audienceOwnerID exist for a prezentation
       * then match both id and ownerid of the audience else then pick the audience which is of not a type of shared and private
       */
      if (this.prezentationData.audienceOwnerID) {
        return {
          defaultAudience: `${this.prezentationData?.audienceID}-private_audience`,
        };
      }
      return {
        defaultAudience: `${this.prezentationData?.audienceID}-user:audience:standard`,
      };
    },
  },
  watch: {
    prezentationData: {
      handler() {
        this.getFilteredActions();
      },
      deep: true,
    },
    themes: {
      handler() {
        this.prepareThemesList();
        this.getFilteredActions();
      },
      deep: true,
    },
    selectedSlide: {
      handler() {
        this.getDimensions();
      },
      deep: true,
    },
    selectedSlideAction() {
      setTimeout(() => {
        this.getDimensions();
      }, 1);
    },
    inListViewMode() {
      this.getDimensions();
    },
    showRightPanel() {
      setTimeout(() => {
        this.getDimensions();
      }, 300);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  mounted() {
    this.prepareThemesList();

    this.getFilteredActions();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    ...mapActions('prezentationDetails', [
      'setSelectedMainAction',
      'setSelectedQuickAction',
      'setInListViewMode',
      'setSelectedSlideAction',
    ]),
    prepareThemesList() {
      const autoGenerateEnabledTemplatesList = [];
      this.themes.forEach((theme) => {
        if (theme.enabled_feature_list.indexOf('slide-generate') !== -1) {
          autoGenerateEnabledTemplatesList.push(theme);
        }
      });
      this.autoGenerateEnabledTemplatesList = autoGenerateEnabledTemplatesList;
    },
    getFeatureId(action) {
      if (action?.id === 'changeVisual') return 'replace_visuals_bpl';
      if (action?.id === 'convertTemplate') return 'convert_template_bpl';
      return '';
    },
    getOffsetValue(action) {
      return action?.id === 'changeVisual' ? '-80' : '-90';
    },
    checkDuplicateAction() {
      if (
        this.slideActions[0].id === 'addToSlides' ||
        this.slideActions[0].id === 'duplicateSlide' ||
        this.slideActions[0].id === 'deleteSlide'
      ) {
        return false;
      }
      return true;
    },

    handleMatomoEvents(mainAction) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_TEMPLATE]: this.prezentationData?.theme,
      };
      if (this.origin === 'build') {
        switch (mainAction) {
          case 'editSlide':
            trackBuildEvents.buildEditSlideClick(this.currentUser, otherData);
            break;
          case 'addSlide':
            trackBuildEvents.buildAddSlideClick(this.currentUser, {
              ...otherData,
              [TD_PREZENTATIONAUTHOR]: this.prezentationData?.author,
              [TD_NUMBEROFSLIDES]: getSlideCount(this.prezentationData),
              [TD_PLACEMENT]: TD_TOP_PANE,
            });
            break;
          case 'changeAudiance':
            trackBuildEvents.buildChangeAudienceClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'convertTemplate':
            trackPrezentationEvents.convertTemplateClick(this.currentUser, {
              ...otherData,
              [TD_PREZENTATION_ORIGIN]:
                this.prezentationData?.prezentationSource,
              [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
              [TD_CROSS_FEATURE]: BUILD,
            });
            break;
          case 'anonymize':
            trackBuildEvents.buildAnonymizeClick(this.currentUser, otherData);
            break;
          default:
            break;
        }
      } else {
        switch (mainAction) {
          case 'editSlide':
            trackPrezentationEvents.prezentationsEditSlideClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'addSlide':
            trackPrezentationEvents.prezentationAddSlideClick(
              this.currentUser,
              this.prezentationData,
              {
                ...otherData,
                [TD_PREZENTATIONAUTHOR]: this.prezentationData?.author,
                [TD_NUMBEROFSLIDES]: getSlideCount(this.prezentationData),
                [TD_PLACEMENT]: TD_TOP_PANE,
              },
            );
            break;
          case 'changeAudiance':
            trackPrezentationEvents.prezentationsChangeAudienceClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'anonymize':
            trackPrezentationEvents.prezentationsAnonymizeClick(
              this.currentUser,
              {
                ...otherData,
                [TD_PREZENTATION_ORIGIN]:
                  this.prezentationData?.prezentationSource,
                [TD_PREZENTATION_CATEGORY]:
                  this.prezentationData?.prezentationType,
              },
            );
            break;
          case 'convertTemplate':
            if (this.$route.name === 'bestPractice.detail') {
              trackKnowEvents.convertTemplateClick(this.currentUser, {
                ...otherData,
                [TD_PREZENTATION_ORIGIN]:
                  this.prezentationData?.prezentationSource ||
                  this.prezentationData?.prezentationType,
                [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
                [TD_CROSS_FEATURE]:
                  this.$route.name === 'bestPractice.detail'
                    ? 'Best Practice Library (Decks)'
                    : TD_PREZENTATION_LIBRARY,
              });
            } else {
              trackPrezentationEvents.convertTemplateClick(this.currentUser, {
                ...otherData,
                [TD_PREZENTATION_ORIGIN]:
                  this.prezentationData?.prezentationSource ||
                  this.prezentationData?.prezentationType,
                [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
                [TD_CROSS_FEATURE]:
                  this.$route.name === 'bestPractice.detail'
                    ? 'Best Practice Library (Decks)'
                    : TD_PREZENTATION_LIBRARY,
              });
            }
            break;
          case 'changeVisual':
            if (this.$route.name === 'bestPractice.detail') {
              const otherData1 = {
                [TD_DECK_ID]: this.prezentationData.id,
              };
              trackKnowEvents.knowDecksReplaceVisualsClick(
                this.selectedSlide,
                this.currentUser,
                otherData1,
              );
            }
            break;
          default:
            break;
        }
      }
    },
    getIcon(action) {
      return this.iconMap[action?.icon];
    },
    async handleIconClick(data) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (data.id !== 'addSlide' && !this.inListViewMode) {
        this.setInListViewMode(true);
      }
      if (this.rightPanelOpen) {
        if (data.id !== 'editSlide') {
          this.rightPanelOpen();
        }
      }
      this.handleMatomoEvents(data.id);
      this.setSelectedQuickAction({});
      this.setSelectedMainAction(data);

      if (['convertTemplate', 'synthesize'].includes(data.id)) {
        const map = {
          convertTemplate: 'comply',
          synthesize: 'synthesis',
        };
        await getFeatureUsage()
          .then((response) => {
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        if (
          !this.limitsInfoObj?.[map[data.id]]?.isLimitCrossed ||
          this.limitsInfoObj?.[map[data.id]]?.isFullAccess
        ) {
          // this.setSelectedMainAction(data);
        } else {
          this.rightPanelOpen(false);
          this.setUpgradePopup({
            upgradePopup: true,
            popupType: map[data.id],
          });
          return;
        }
      }

      setTimeout(() => {
        if (
          data.id === 'editSlide' &&
          this.slideActions.length > 0 &&
          this.checkDuplicateAction()
        ) {
          let tempAction = null;
          this.slideActions.forEach((action) => {
            if (!tempAction && action.visible && !action.disabled) {
              tempAction = action;
            }
          });
          this.setSelectedSlideAction(tempAction);
          this.rightPanelOpen();
        } else {
          this.setSelectedSlideAction({});
        }
      }, 100);
    },

    getDimensions() {
      this.moreOptionList = [];

      this.horzActionList = [...this.actions];

      if (this.$refs.actionsContainer) {
        setTimeout(() => {
          const totalWidth = this.$refs.actionsContainer.clientWidth;

          let finalWidth = 0;
          const horzActionList = [];
          const e = this.$refs.actionsContainer.children[0];

          e.children.forEach((ele, i) => {
            finalWidth += ele.clientWidth + 2;
            if (finalWidth <= totalWidth) {
              horzActionList.push(this.horzActionList[i]);
            }
          });

          if (horzActionList.length < this.horzActionList.length) {
            horzActionList.pop();

            this.moreOptionList = [
              ...this.horzActionList.slice(horzActionList.length),
            ];
          }

          this.horzActionList = horzActionList;
        }, 1);
      }
    },
    getFilteredActions() {
      this.actions = [];
      const mainActionsTemp = JSON.parse(JSON.stringify(mainActions));
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      mainActionsTemp.forEach((action) => {
        switch (action.id) {
          case 'addSlide':
            if (
              (this.isOwnerOfPrezentation || this.isEditor) &&
              (this.isGenerated ||
                this.prezentationData.prezentationSource === 'Product') &&
              !this.isTemplateRemoved(this.themes)
            ) {
              this.actions.push(action);
            }
            break;

          case 'generateSlide':
            if (
              !this.isViewAccess &&
              this.isPrezGenerateFullAccess &&
              this.isGenerateFullAccess
            ) {
              if (!this.isTemplateAGEnabled) {
                action.disabled = true;
              } else {
                action.disabled = false;
              }
              this.actions.push(action);
            }
            break;

          case 'modifyStoryline':
            if (
              !this.isOpPrezentation &&
              !this.isTemplateRemoved(this.themes) &&
              !this.isUserUploadedPrezentation &&
              !this.isGenerated &&
              !this.isIOCUpload &&
              !this.isRedesigned &&
              !this.isComply &&
              this.setDefaultAudience &&
              (this.currentUser.user.cognitoID ===
                this.prezentationData.owner ||
                this.isOwnerOfPrezentation ||
                this.isEditor)
            ) {
              if (
                this.prezentationData.convertedTo === 'Comply' ||
                this.prezentationData.convertedTo === 'Prez Generated' ||
                (this.prezentationData.prezentationSource !== 'Product' &&
                  this.prezentationData.convertedTo === 'Anonymize') ||
                this.prezentationData.convertedTo === 'Synthesize'
              ) {
                action.disabled = true;
              }
              this.actions.push(action);
            }
            break;

          case 'changeAudiance':
            if (
              !this.isViewAccess &&
              this.prezentationData.prezentationSource === 'Product' &&
              !this.isTemplateRemoved(this.themes)
            ) {
              if (
                this.prezentationData.convertedTo === 'Comply' ||
                this.prezentationData.convertedTo === 'Prez Generated' ||
                (this.prezentationData.prezentationSource !== 'Product' &&
                  this.prezentationData.convertedTo === 'Anonymize') ||
                this.prezentationData.convertedTo === 'Synthesize'
              ) {
                action.disabled = true;
              }
              this.actions.push(action);
            }
            break;

          case 'anonymize':
            if (
              !this.isViewAccess &&
              this.isUploadFullAccess &&
              !this.isTemplateRemoved(this.themes) &&
              this.isPrezAnonymizeFullAccess
            ) {
              this.actions.push(action);
            }
            break;

          case 'synthesize':
            if (
              !this.isViewAccess &&
              !this.isTemplateRemoved(this.themes) &&
              this.isPrezSynthesisFullAccess &&
              (this.prezentationData.prezentationSource === 'Generated' ||
                this.prezentationData.prezentationSource === 'IOCUpload' ||
                this.prezentationData.prezentationSource === 'User Uploaded' ||
                this.prezentationData.prezentationSource === 'Comply')
            ) {
              this.actions.push(action);
            }
            break;

          case 'editSlide':
            if (
              !this.isTemplateRemoved(this.themes) &&
              !this.isViewAccess &&
              ((this.currentItemIsEditable &&
                !this.isOpPrezentation &&
                !this.isTemplateRemoved(this.themes) &&
                !this.isBestPractices) ||
                ['User Uploaded', 'Generated', 'Redesign', 'Comply'].includes(
                  this.prezentationData.prezentationSource,
                ))
            ) {
              this.actions.push(action);
            }
            break;
          case 'changeVisual':
            if (
              !this.isTemplateRemoved(this.themes) &&
              this.isPrezentSourceBestPractice &&
              ((this.allowReplaceImageOverall && this.allowReplaceImageTabs) ||
                (this.allowReplaceIconOverall && this.allowReplaceIconTabs))
            ) {
              this.actions.push(action);
            }
            break;
          case 'convertTemplate':
            if (
              !this.isViewAccess &&
              !this.isTemplateRemoved(this.themes) &&
              this.isPrezComplyFullAccess
            ) {
              this.actions.push(action);
            } else if (
              !this.isTemplateRemoved(this.themes) &&
              this.isPrezentSourceBestPractice &&
              this.isBPEPrezComplyFullAccess
            ) {
              if (this.prezentationData.noofslides > 30 || countOfSlides > 30) {
                this.showTooltip = true;
                action.disabled = true;
                action.tooltipText =
                  'Template Conversion is optimized for Best Practice Decks containing up to 30 slides.';
              }
              this.actions.push(action);
            }
            break;

          default:
            this.actions.push(action);
            break;
        }
      });

      this.getDimensions();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-actions-row {
  margin-top: 10px;
  display: flex;
  width: 100%;

  .horz-actions {
    display: flex;

    .slide-action-icon {
      margin-right: 2px;
    }
    .v-btn {
      padding-left: 13px;
    }
  }

  ::v-deep .audience-select .audience-select-title {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .main-action-icon {
    // margin-bottom: 4px;
    margin-right: 2px;
  }

  .v-btn {
    text-transform: none;
    letter-spacing: normal;
    margin-right: 2px;

    &.active::before {
      background-color: #21a7e0;
      opacity: 0.08;
    }
  }
  .divider {
    border: 1px solid #21a7e0;
    width: 80%;
    transform: translateX(13px);
  }

  .tooltip-activator {
    display: flex;
    align-items: center;
  }

  ::v-deep .fingerprint-modal {
    padding: 0 0 0 0 !important;
  }
}

.otherActions {
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;

    ::v-deep .v-icon {
      color: rgba(0, 0, 0, 0.38) !important;
      caret-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

.main-action-content {
  display: flex;
  align-items: center;

  svg {
    width: 20px !important;
  }
}
</style>
