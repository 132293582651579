var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingThemes)?_c('div',{staticClass:"template-spinner-wrapper"},[(_vm.loadingThemes)?_c('Loading'):_vm._e()],1):_c('div',{staticClass:"pt-wrapper"},[(_vm.previousRoute === 'search')?_c('div',{staticClass:"back-btn-cntr"},[_c('v-btn',{staticClass:"btn-back",on:{"click":_vm.goToSearchLandingPage}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('slides.backToSearch')))])],1)],1):_vm._e(),_c('div',{staticClass:"pt-header pt-wrapper__header"},[_c('div',{staticClass:"pt-wrapper__headercontent"},[_c('div',{staticClass:"pt-header__title"},[_vm._v(" "+_vm._s(_vm.$t('profile.templates'))+" "),_c('div',{staticClass:"pt-header__titleicon"},[_c('v-tooltip',{attrs:{"right":"","max-width":"250","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title",domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('profile.chooseTheTemplate')))])])],1)])])]),_c('div',{staticClass:"pt-header pt-wrapper__header"},[_c('div',{staticClass:"pt-wrapper__headercontent"},[_c('div',{staticClass:"pt-search"},[_c('v-text-field',{staticClass:"pt-search__scontrol",attrs:{"solo":"","single-line":"","hide-details":"","autocomplete":"off","placeholder":!!_vm.globalSearchResult
              ? _vm.$t('profile.displaying')
              : _vm.$t('profile.searchTemplates'),"disabled":_vm.globalSearchResult},on:{"input":function($event){return _vm.handleSearchInput()},"focus":function($event){_vm.searchFocused = true},"blur":function($event){_vm.searchFocused = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.searchFocused ? 'primary' : undefined},on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v(" "+_vm._s(!!_vm.globalSearchResult || _vm.templateSearch ? "mdi-close" : "mdi-magnify")+" ")])]},proxy:true}]),model:{value:(_vm.templateSearch),callback:function ($$v) {_vm.templateSearch=$$v},expression:"templateSearch"}}),_c('v-btn',{staticClass:"pt-search__addmore",attrs:{"color":"primary","rounded":"","data-pendo-id":"add-new-template"},on:{"click":function($event){return _vm.handleAddMore()}}},[_c('span',[_vm._v(_vm._s('Add more templates'))])])],1),_c('div',{staticClass:"pt-quickfilters"},[_c('v-chip-group',{staticClass:"ptq",attrs:{"show-arrows":"","mandatory":"","cols":"1"},model:{value:(_vm.sectionFilterValue),callback:function ($$v) {_vm.sectionFilterValue=$$v},expression:"sectionFilterValue"}},[_c('v-chip',{staticClass:"ptq__tab",attrs:{"value":"ALL","outlined":'ALL' != _vm.sectionFilterValue,"color":"#21a7e0"}},[_vm._v(" All ")]),_vm._l((_vm.themeSections),function(section){return [(
                section.themes.length ||
                (section.type === 'BRAND_TEMPLATES' &&
                  !!_vm.designerThemesVisible.length)
              )?_c('v-chip',{key:section.type,staticClass:"ptq__tab",attrs:{"value":section.type,"outlined":section.type != _vm.sectionFilterValue,"color":"#21a7e0"}},[_vm._v(" "+_vm._s(_vm.chipLabels[section.type])+" ")]):_vm._e()]})],2)],1)])]),_c('div',{staticClass:"pt-wrapper__body"},[_c('TemplateList',{class:[(_vm.globalSearchResult || _vm.templateSearch) && 'd-none'],attrs:{"sections":_vm.themeSections,"designerThemes":_vm.designerThemesVisible,"sectionFilterValue":_vm.sectionFilterValue}}),_c('TemplateList',{class:[!(_vm.globalSearchResult || _vm.templateSearch) && 'd-none'],attrs:{"sections":_vm.searchThemeSections,"designerThemes":_vm.searchDesignerThemes},on:{"addMoreTemplate":function($event){return _vm.addMoreTemplate()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGoTop),expression:"showGoTop"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"go-top",attrs:{"id":"go-top"},on:{"click":_vm.toTop}},[_c('v-icon',{staticClass:"top-arrow",attrs:{"color":"#fff","size":"15"}},[_vm._v(" mdi-arrow-up")]),_c('span',[_vm._v(_vm._s(_vm.$t('common.backToTop')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }