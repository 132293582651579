var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.sections),function(section){return _c('div',{key:section.header},[(
        !!section.themes.length ||
        ((section.type === 'BRAND_TEMPLATES' || section.type === 'SEARCH') &&
          !!_vm.designerThemes.length)
      )?_c('div',{class:[
        'pt-section',
        _vm.sectionFilterValue !== 'ALL' &&
          _vm.sectionFilterValue !== section.type &&
          'd-none' ]},[(section.header)?_c('h4',{staticClass:"pt-section__header"},[_vm._v(" "+_vm._s(section.header)+" ")]):_vm._e(),_c('div',{staticClass:"pt-list"},[(
            section.type === 'BRAND_TEMPLATES' || section.type === 'SEARCH'
          )?_vm._l((_vm.designerThemes),function(theme){return _c('TemplateCard',{key:theme.id,staticClass:"pt-list__item",attrs:{"theme":theme,"isInProgress":true}})}):_vm._e(),_vm._l((section.themes),function(theme){return _c('TemplateCard',{key:theme.id,staticClass:"pt-list__item",attrs:{"theme":theme}})})],2)]):_vm._e(),_c('div',{staticClass:"pt-noresult"},[(
          (section.type === 'SEARCH' || section.type === 'GLOBAL_SEARCH') &&
          !section.themes.length
        )?_c('EmptyState',{attrs:{"img-url":"/assets/img/no-results-found-templates.svg","is-btn-visible":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('profile.noTemplatesFound'))),_c('br'),_c('a',{on:{"click":function($event){return _vm.$emit('addMoreTemplate')}}},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.getInTouch'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('profile.addMoreTemplates'))+" ")])]},proxy:true}],null,true)}):_vm._e()],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }