<template>
  <div class="search-select-wrap">
    <div class="ws-select-title-wrap">
      <div class="ws-title">
        <img :src="'/assets/img/myWorkspace/ws-blue-icon.svg'" alt />
        <div class="panelHeader">{{ 'My Workspace files' }}</div>
      </div>
      <div class="ws-selected">
        {{ selectedFiles.length }} {{ 'selected' }}
        <div>&nbsp;|&nbsp;</div>
        <div
          class="selected-btn"
          :class="`${selectedFiles.length ? 'enabled' : 'disabled'}`"
          @click="toggleSelectedFilesSection"
        >
          {{ selectedText }}
        </div>
      </div>
    </div>
    <SearchSelectMyWs
      @onSearch="handleSearch"
      :items="searchedFiles"
      :placeholderText="$t('myWorkspace.mainPage.searchPlaceHolder')"
      :loading="isSearchLoading"
      :showNoResults="showNoResults"
      @onSearchedFileSelect="updateSearchSelectedFiles"
    />
    <div class="files-result">
      <div class="list-wrap" v-if="showSelected">
        <div class="list-title">Selected</div>
        <div class="files-list">
          <div
            v-for="(selFile, i) in selectedFiles"
            :key="i + selFile.id"
            class="files-list-item"
          >
            <v-checkbox
              v-model="selFile.checked"
              hide-details
              color="#20a7e0"
              class="my-file"
              @change="updateCheckedFiles(selFile, 'selected')"
            >
              <template #label>
                <div class="label-wrapper">
                  <div class="icon-wrapper">
                    <WsFileIcon
                      :height="'16px'"
                      :width="'16px'"
                      :fileName="selFile.fileName || ''"
                    />
                  </div>
                  <div class="file-name">
                    {{ selFile.fileName }}
                  </div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="list-wrap" v-if="!hideFav">
          <div class="list-title">Favorites</div>
          <div class="files-list favourite-scroll" v-if="favouriteFiles.length">
            <div
              v-for="(favFile, i) in favouriteFiles"
              :key="i"
              class="files-list-item"
            >
              <v-checkbox
                v-model="favFile.checked"
                hide-details
                color="#20a7e0"
                class="my-file"
                @change="updateCheckedFiles(favFile, 'fav')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="icon-wrapper">
                      <WsFileIcon
                        :height="'16px'"
                        :width="'16px'"
                        :fileName="favFile.fileName || ''"
                      />
                    </div>
                    <div class="file-name">
                      {{ favFile.fileName }}
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
          <div class="loader-container" v-else-if="favLoading">
            <clip-loader
              :loading="favLoading"
              :color="`#21a7e0`"
              :width="'60'"
              :height="'60'"
              :size="'50px'"
              class="cliploader"
            />
          </div>
        </div>

        <div class="list-wrap" v-if="!hideRecent">
          <div class="list-title">Recent</div>
          <div class="files-list" v-if="recentFiles.length">
            <div
              v-for="(recFile, i) in recentFiles"
              :key="i"
              class="files-list-item"
            >
              <v-checkbox
                v-model="recFile.checked"
                hide-details
                color="#20a7e0"
                class="my-file"
                @change="updateCheckedFiles(recFile, 'recent')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="icon-wrapper">
                      <WsFileIcon
                        :height="'16px'"
                        :width="'16px'"
                        :fileName="recFile.fileName || ''"
                      />
                    </div>
                    <div class="file-name">
                      {{ recFile.fileName }}
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
          <div class="loader-container" v-else-if="recLoading">
            <clip-loader
              :loading="recLoading"
              :color="`#21a7e0`"
              :width="'60'"
              :height="'60'"
              :size="'50px'"
              class="cliploader"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import SearchSelectMyWs from './SearchSelectMyWs.vue';
import { MyWorkspaceService } from './MyWorkspaceService';
import WsFileIcon from './WsFileIcon.vue';

export default {
  name: 'FilterMyWsFiles',
  components: {
    SearchSelectMyWs,
    WsFileIcon,
    ClipLoader,
  },
  data() {
    return {
      selectedCount: 0,
      showSelected: false,
      selectedFileIds: [],
      selectedFiles: [],
      recentFiles: [],
      favouriteFiles: [],
      searchedFiles: [],
      isSearchLoading: false,
      recLoading: false,
      favLoading: false,
      hideFav: false,
      hideRecent: false,
      showNoResults: false,
    };
  },
  props: {
    onFileSelect: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    selectedText() {
      return this.showSelected ? 'Hide Selected' : 'Show Selected';
    },
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
  },
  beforeMount() {
    this.favLoading = true;
    this.getFiles({ favourites: true })
      .then((res) => {
        if (res?.data?.length) {
          this.favouriteFiles = res.data.map(({ id, fileName }) => ({
            id,
            fileName,
            checked: false,
          }));
        }
        this.hideFav = this.favouriteFiles.length === 0;
        this.favLoading = false;
      })
      .catch(() => {
        this.favLoading = false;
      });
    this.recLoading = true;
    this.getFiles({ limit: 3 })
      .then((res) => {
        if (res?.data?.length) {
          this.recentFiles = res.data.map(({ id, fileName }) => ({
            id,
            fileName,
            checked: false,
          }));
        }
        this.hideRecent = this.recentFiles.length === 0;
        this.recLoading = false;
      })
      .catch(() => {
        this.recLoading = false;
      });
  },
  methods: {
    async getFiles(params) {
      return this.myWorkspaceService.getWsData({
        ...params,
        type: 'files',
        offset: 0,
      });
    },
    toggleSelectedFilesSection() {
      this.showSelected = !this.showSelected;
    },
    updateCheckedFiles(changedFile, sourceArray) {
      // Sync the checked state between all three arrays
      if (sourceArray === 'recent') {
        this.syncOtherFiles(changedFile, 'recent');
      } else if (sourceArray === 'fav') {
        this.syncOtherFiles(changedFile, 'fav');
      } else if (sourceArray === 'search') {
        this.syncOtherFiles(changedFile, 'search');
      } else if (sourceArray === 'selected') {
        this.syncOtherFiles(changedFile, 'recent');
        this.syncOtherFiles(changedFile, 'fav');
        this.syncOtherFiles(changedFile, 'search');
      }
      if (changedFile.checked) {
        this.addFileToSelectedFiles(changedFile);
      } else {
        this.removeFileFromSelectedFiles(changedFile.id, sourceArray);
      }
    },
    addFileToSelectedFiles(file) {
      const alreadySelected = this.selectedFiles.some((f) => f.id === file.id);
      if (!alreadySelected) {
        this.selectedFiles.push(file);
      }
      this.selectedFileIds = this.selectedFiles.map((item) => item.id);
      this.$emit('onFileSelect', [...this.selectedFileIds]);
    },

    removeFileFromSelectedFiles(fileId, sourceArray) {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== fileId);
      this.selectedFileIds = this.selectedFiles.map((item) => item.id);
      if (this.selectedFiles.length === 0 && sourceArray === 'selected') {
        this.toggleSelectedFilesSection();
      }
      this.$emit('onFileSelect', [...this.selectedFileIds]);
    },
    syncOtherFiles(changedFile, sourceArray) {
      if (sourceArray !== 'recFiles') {
        const recFile = this.recentFiles.find((f) => f.id === changedFile.id);
        if (recFile) recFile.checked = changedFile.checked;
      }

      if (sourceArray !== 'favFiles') {
        const favFile = this.favouriteFiles.find(
          (f) => f.id === changedFile.id,
        );
        if (favFile) favFile.checked = changedFile.checked;
      }

      if (sourceArray !== 'searchedFiles') {
        const searchedFile = this.searchedFiles.find(
          (f) => f.id === changedFile.id,
        );
        if (searchedFile) searchedFile.checked = changedFile.checked;
      }
    },
    updateSearchSelectedFiles(searchSelectedFile) {
      this.updateCheckedFiles(searchSelectedFile, 'search');
    },
    handleSearch(searchText) {
      clearTimeout(this.debounceTimer);
      this.isSearchLoading = true;
      this.debounceTimer = setTimeout(() => {
        this.debouncedQuery = this.query;
        this.searchText = searchText;
        if (searchText) {
          this.getFiles({ search: searchText }).then((res) => {
            if (res?.data) {
              const searchFiles = res?.data;
              if (this.selectedFiles.length) {
                // map selected files to the search result
                this.selectedFiles.forEach((file) => {
                  const searchedFile = searchFiles.find(
                    (sfile) => sfile.id === file.id,
                  );
                  if (searchedFile) {
                    searchedFile.checked = file.checked;
                  }
                });
              }
              this.searchedFiles = searchFiles;
              this.showNoResults = this.searchedFiles.length === 0;
            }
            this.isSearchLoading = false;
          });
        } else {
          this.isSearchLoading = false;
          this.searchedFiles = [];
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-select-wrap {
  display: flex;
  flex-direction: column;
  border: 1px solid #dde1e6;
  border-radius: 8px;
  padding: 15px 20px 20px 10px;
  .ws-select-title-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-left: 10px;
    .ws-title {
      display: flex;
      align-items: center;
      img {
        height: 40px;
        width: 40px;
      }
      .panelHeader {
        color: #075689;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
      }
    }
    .ws-selected {
      display: flex;
      align-items: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4d5358;
      .disabled {
        pointer-events: none;
        color: #4d5358;
      }
      .enabled {
        cursor: pointer;
        color: #21a7e0;
      }
    }
  }
  .ws-search-bar {
    padding-left: 10px;
  }
  .files-result {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .list-wrap {
      margin-bottom: 10px;
      .list-title {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-left: 10px;
      }
      .files-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding-left: 10px;
        padding-top: 8px;
        margin-left: 5px;
        word-break: break-word;
        white-space: normal;
        overflow-wrap: break-word;
        .files-list-item {
          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;
            width: 100%;
            .v-input__control {
              .v-input__slot {
                margin-bottom: 0;
                .v-input--selection-controls__input {
                  margin-right: 10px;
                }
              }
              .v-messages {
                display: none;
              }
            }
          }
          .label-wrapper {
            display: flex;
            column-gap: 5px;
            align-items: center;
            .icon-wrapper {
              display: flex;
              margin-right: 5px;
            }
          }

          display: flex;
        }
      }
      .favourite-scroll {
        height: 100px;
        overflow-y: auto;
      }
    }
  }
}
.file-name {
  width: calc(100% - 21px);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121619;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 0;
}
</style>
