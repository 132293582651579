<template>
  <div>
    <div v-for="section in sections" :key="section.header">
      <div
        :class="[
          'pt-section',
          sectionFilterValue !== 'ALL' &&
            sectionFilterValue !== section.type &&
            'd-none',
        ]"
        v-if="
          !!section.themes.length ||
          ((section.type === 'BRAND_TEMPLATES' || section.type === 'SEARCH') &&
            !!designerThemes.length)
        "
      >
        <h4 class="pt-section__header" v-if="section.header">
          {{ section.header }}
        </h4>

        <div class="pt-list">
          <!-- Designer Templates -->
          <template
            v-if="
              section.type === 'BRAND_TEMPLATES' || section.type === 'SEARCH'
            "
          >
            <TemplateCard
              v-for="theme in designerThemes"
              class="pt-list__item"
              :key="theme.id"
              :theme="theme"
              :isInProgress="true"
            />
          </template>

          <!-- Regular Templates -->
          <TemplateCard
            class="pt-list__item"
            v-for="theme in section.themes"
            :key="theme.id"
            :theme="theme"
          />
        </div>
      </div>
      <div class="pt-noresult">
        <EmptyState
          v-if="
            (section.type === 'SEARCH' || section.type === 'GLOBAL_SEARCH') &&
            !section.themes.length
          "
          img-url="/assets/img/no-results-found-templates.svg"
          :is-btn-visible="false"
        >
          <template v-slot:description>
            <p>
              {{ $t('profile.noTemplatesFound') }}<br /><a
                @click="$emit('addMoreTemplate')"
              >
                {{ $t('fingerprint.getInTouch') }}
              </a>
              {{ $t('profile.addMoreTemplates') }}
            </p>
          </template>
        </EmptyState>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyState from '../../../common/EmptyState.vue';
import TemplateCard from './TemplateCard.vue';

export default {
  name: 'TemplateList',
  components: {
    TemplateCard,
    EmptyState,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    designerThemes: {
      type: Array,
      default: () => [],
    },
    sectionFilterValue: {
      type: String,
      default: 'ALL',
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.pt-section {
  margin-bottom: 22px;
  &__header {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.pt-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.pt-noresult {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  .v-image {
    margin: 20px;
  }
  a {
    text-decoration: none;
  }
}
</style>
