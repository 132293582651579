<template>
  <div class="upload-to-ws-wrapper">
    <div class="modal-title">{{ $t('myWorkspace.mainPage.addFiles') }}</div>
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="upload-to-ws-wrapper-content">
      <UploadFilesAndLinks @input="handleUploadUpdates" />
      <div class="upload-to-ws-action">
        <v-btn
          class="upload-to-ws-add-files"
          rounded
          color="#20a7e0"
          width="94"
          height="32"
          :disabled="disableAddFile"
          :loading="inProgress"
          @click="handleAddFiles"
        >
          {{ $t('myWorkspace.mainPage.addFiles') }}
        </v-btn>
      </div>
      <div class="ws-confidentiality">
        <div>
          {{ $t('myWorkspace.confidentialityHeading') }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
        <div>
          {{ $t('myWorkspace.confidentialitySubHeading') }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFilesAndLinks from './UploadFilesAndLinks.vue';
import { MyWorkspaceService } from './MyWorkspaceService';

export default {
  name: 'UploadToMyWsModal',
  components: {
    UploadFilesAndLinks,
  },
  props: {
    workspaceId: {
      type: String,
      default: '',
    },
    updateLocalFiles: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      uploadedData: [],
      uploadedFileData: [],
      uploadedFileLinkData: [],
      linkAccess: true,
      linkUploadError: false,
      inProgress: false,
    };
  },
  computed: {
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
    disableAddFile() {
      return !(
        this.linkAccess &&
        this.uploadedData.length > 0 &&
        !this.linkUploadError
      );
    },
  },
  beforeDestroy() {
    this.uploadedData = [];
    this.uploadedFileData = [];
    this.uploadedFileLinkData = [];
  },
  methods: {
    handleAddFiles() {
      this.inProgress = true;
      const linkData = this.uploadedFileLinkData.filter((fl) => !fl.error);
      if (this.workspaceId) {
        this.myWorkspaceService
          .updateWorkspace({
            workspaceProjectID: this.workspaceId,
            newFiles: [...this.uploadedFileData, ...linkData],
          })
          .then((data) => {
            console.log('wsdata', data);
            this.updateLocalFiles();
            setTimeout(() => {
              this.inProgress = false;
              this.handleClose();
            }, 1500);
          });
      } else {
        this.myWorkspaceService
          .addFilesLinksToWs({
            type: 'files',
            files: [...this.uploadedFileData, ...linkData],
          })
          .then((data) => {
            console.log('wsdata', data);
            this.updateLocalFiles();
            setTimeout(() => {
              this.inProgress = false;
              this.handleClose();
            }, 1500);
          });
      }
    },
    handleUploadUpdates(data) {
      let links = [];
      let files = [];
      if (data.files.length) {
        this.linkAccess = true;
        files = this.mapFilesArray(data.files);
        this.uploadedFileData = [...files];
      }
      if (data.fileLinks.length) {
        if (data.fileLinks.some((link) => link.error)) {
          this.linkUploadError = true;
        } else {
          if (data.fileLinks.every((link) => !link.error)) {
            this.linkUploadError = false;
          }
          this.linkAccess = data.linkAccess;
          links = this.mapLinksArray(data.fileLinks);
          this.uploadedFileLinkData = [...links];
        }
      }
      this.uploadedData = [...files, ...links];
    },
    mapFilesArray(files) {
      return files.map((file) => ({
        id: file.fileID,
        bucketName: file.s3Bucket,
        path: file.s3Prefix,
        sizeKb: file.size,
        fileName: file.fileName,
        numOfPages: file.numOfPages,
        type: file?.fileName?.split('.')[1],
        source: 'My Workspace',
        weblink: null,
      }));
    },
    mapLinksArray(links) {
      const fileTypeMap = {
        '.pptx': 'GSLIDE',
        '.docx': 'GDOC',
        '.xlsx': 'GSHEET',
        '.pdf': 'GPDF',
        '.txt': 'GTXT',
      };

      return links.map((link) => ({
        id: link.fileID,
        bucketName: link.s3Bucket,
        path: link.s3Prefix,
        sizeKb: link.size,
        fileName: link.fileName,
        numOfPages: link.numOfPages,
        source: 'My Workspace',
        type: fileTypeMap[link.type] || link.type,
        weblink: link.web_url,
      }));
    },
    handleClose() {
      this.$modal.hide('UploadToMyWsModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-to-ws-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  .modal-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-left: 20px;
    padding-top: 15px;
  }
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .upload-to-ws-wrapper-content {
    width: 100%;
    padding: 20px;
    .upload-to-ws-image-wrapper {
      margin: auto;
      margin-top: 55px;
      margin-bottom: 22px;
      width: fit-content;
    }
    .upload-to-ws-desc {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 31px;
    }
    .upload-to-ws-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 15px;
      .upload-to-ws-add-files {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        text-transform: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        margin-bottom: 10px;
      }
    }
    .ws-confidentiality {
      color: #7b7b7b;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      a {
        color: #21a7e0;
        cursor: pointer;
      }
    }
  }
}
</style>
