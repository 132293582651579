<template>
  <div class="text-center">
    <template v-if="!waitForAllPrezToLoad">
      <v-item-group
        active-class="primary"
        class="text-center"
        v-if="prezentations && prezentations.length > 0"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(prezentation, i) in prezentationsInPage"
              :key="`${prezentation.id}__${i}`"
              class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
              :style="[
                $vuetify.breakpoint.xs ||
                $vuetify.breakpoint.sm ||
                $vuetify.breakpoint.md
                  ? { 'min-width': '300px' }
                  : {},
              ]"
            >
              <v-item v-slot="{ active, toggle }">
                <PrezentationCard
                  :prezentation="prezentation"
                  :index="i"
                  :active="active"
                  :toggle-fn="toggle"
                  :is-editable="isEditable"
                  :on-clone-fn="onClone"
                  :on-delete-fn="onDelete"
                  :on-update-fn="onUpdate"
                  :prezentation-status="prezentationStatus"
                  :origin="origin"
                  :thumbnailAsset="prezentation.thumbnailAsset || ''"
                  :advancedFilterActive="advancedFilterActive"
                  @select="
                    $emit('select', {
                      prezentation: $event,
                      listComponentStateSnapshot: $data,
                    })
                  "
                  :isSearchPage="isSearchPage"
                />
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-btn
        v-if="
          prezentations && prezentations.length && prezentationsInPage.length
        "
        id="loadmore-btn"
        class="loadmore-btn"
        style="text-transform: none"
        @click="loadNext"
        color="#21a7e0"
        rounded
        outlined
        height="32"
        :disabled="
          (prezentationsInPage.length >= prezentations.length &&
            !canLoadMore) ||
          isLoadingMore
        "
      >
        {{
          canLoadMore
            ? $t('prezentationsList.seeMoreResult')
            : $t('prezentationsList.noMoreResult')
        }}
      </v-btn>
      <div v-if="prezentations.length === 0" class="empty-state__container">
        <slot name="empty-state" />
      </div>
    </template>
    <template v-else>
      <Loading />
    </template>
    <span> </span>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import API from '../../../utils/api';
import PrezentationCard from './PrezentationCard.vue';
import Loading from '../../common/Loading.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { getPrezentationThumbnaiAssetId } from '../../../store/modules/helper';
import { getPrezentationBulkByID } from '../../../utils/api-helper';

export default {
  name: 'PrezentationCardList',
  components: {
    PrezentationCard,
    Loading,
  },
  props: {
    prezentationStatus: {
      type: String,
      default: '',
    },
    waitForAllPrezToLoad: {
      type: Boolean,
      default: false,
    },
    isAdvancedFilterChanged: {
      type: Boolean,
      default: true,
    },
    loadPrezentationsFn: {
      type: Function,
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isResetClicked: {
      type: Boolean,
      default: false,
    },
    prezentationType: {
      type: String,
      default: '',
    },
    origin: {
      type: String,
      required: false,
    },
    loadedPrezentations: {
      type: Array,
      required: false,
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
    advancedFilterActive: {
      type: Boolean,
      default: false,
    },
    snapshot: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    deep: true,
    immediate: true,
    isResetClicked() {
      this.currentPage = 1;
    },
    // loadedPrezentationsByOwner: {
    //   handler(val) {
    //     if (!this.loadedPrezentations && !this.isAdvancedFilterChanged) {
    //       this.prezentations = [...val];
    //       this.loadPrezentationsForPage();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    getAllPrezentations: {
      handler(val) {
        this.prezentations = val;
        this.loadPrezentationsForPage();
      },
      deep: true,
      immediate: true,
    },
    getIsLoadMorePrezentations: {
      handler(val) {
        this.canLoadMore = val;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      canLoadMore: true,
      isLoading: true,
      isLoadingMore: false,
      prezentations: [],
      prezentationsInPage: [],
      totalPrezentations: 0,
      itemsPerPage: 6,
      currentPage: 1,
      totalLoadedPrezentations: [],
      // compkey: 0,
    };
  },
  computed: {
    ...mapGetters('prezentations', [
      'getAllPrezentations',
      'getIsLoadMorePrezentations',
      'getDeckPrezentationPage',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapState('prezentations', [
      'nextTokenToFetchPrezenations',
      'allPrezentations',
      'filteredAndSearchedPrezentations',
      'prezentationBackClicked',
    ]),
  },
  async mounted() {
    // Setting snapshot for non-loaded prezentations
    if (this.prezentationBackClicked && this.snapshot) {
      const { allPrezentations, ...state } = this.snapshot;
      this.setAllPrezentationsForLandingPage(allPrezentations || []);
      this.prezentations = allPrezentations;
      this.prezentationsInPage = state.prezentationsInPage;
      this.$nextTick(() => {
        Object.assign(this.$data, state);
      });

      return;
    }

    this.isLoading = true;
    this.setAllPrezentationsForLandingPage([]);
    try {
      if (!this.loadedPrezentations) {
        await this.loadPrezentationsFn()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.setAllPrezentationsForLandingPage(this.loadedPrezentations);
        this.prezentations = this.loadedPrezentations;
        // this.canLoadMore = false;
        this.loadPrezentationsForPage();
        this.isLoading = false;
      }
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    ...mapActions('prezentations', [
      'setAllPrezentationsForLandingPage',
      'updatePrezentationWithAction',
      'setPrezentationStateSnapshot',
      'setPrezentationBackClicked',
      'setDeckPrezentationsPage',
    ]),
    ...mapActions('search', ['setSearchStateSnapshot']),
    async loadNext() {
      this.isLoadingMore = true;
      this.currentPage++;
      await this.loadPrezentationsFn();
    },
    async loadPrezentationsForPage() {
      const ids = [];
      this.prezentations.forEach((ele) => {
        if (ele.prezentationType === 'OP') {
          ids.push(ele.prezentationId);
        }
      });
      try {
        if (ids.length) {
          const prezentations =
            await this.getOpPrezentationThumbnailsForHybridSearch(ids);
          this.prezentationsInPage = this.prezentations.map((prez) => {
            if (prez.prezentationType === 'OP') {
              const prezentation = prezentations.find(
                (ele) => ele.id === prez.prezentationId,
              );
              return {
                ...prez,
                sections: prezentation.sections,
                thumbnailAsset: getPrezentationThumbnaiAssetId({
                  ...prez,
                  sections: prezentation.sections,
                }),
              };
            }
            return {
              ...prez,
              thumbnailAsset: getPrezentationThumbnaiAssetId(prez),
            };
          });
        } else {
          this.prezentationsInPage = this.prezentations?.map((prez) => ({
            ...prez,
            thumbnailAsset: getPrezentationThumbnaiAssetId(prez),
          }));
        }
      } catch (err) {
        console.log(err);
        this.prezentationsInPage = this.prezentations?.map((prez) => ({
          ...prez,
          thumbnailAsset: getPrezentationThumbnaiAssetId(prez),
        }));
      }
      if (this.isLoadingMore) {
        this.isLoadingMore = false;
      }
    },
    async getOpPrezentationThumbnailsForHybridSearch(ids) {
      const prezentation = await getPrezentationBulkByID(ids);
      return prezentation;
    },
    async onClone(prezentationToBeCloned) {
      trackPrezentationEvents.prezentationsThumbnailCopyIconClick(
        this.currentUser,
        prezentationToBeCloned,
      );
      const prezDataForMatomo = {
        accessType: prezentationToBeCloned.shared ? 'Shared' : 'Private',
        ...prezentationToBeCloned,
      };
      const clone = { ...prezentationToBeCloned };
      delete clone.owner;
      delete clone.audience;
      delete clone.comments;
      delete clone.slides;
      delete clone.thumbnail;
      clone.id = uuidv4();
      clone.prezentationId = clone.id;
      clone.name = `${prezentationToBeCloned.name}--copy`;
      if (prezentationToBeCloned.prezentationSource === 'User Uploaded') {
        clone.isDuplicateForUserUploaded = true;
      }
      await this.createPrezentation(clone).then((value) => {
        MatomoAnalyticsHandler.trackPrezCopyIconClick(
          this.currentUser.user,
          prezDataForMatomo,
        );
        this.updatePrezentationWithAction({
          prezentation: {
            ...value.prezentationData,
            prezType: 'get_my_prezentations',
            shared: false,
            isFavorite: false,
            prezentationId: value.prezentationData.id,
            isDownloaded: false,
            audience: prezentationToBeCloned.audience,
            prezentationCarouselsThumbnails:
              !prezentationToBeCloned.prezentationCarouselsThumbnails
                ? undefined
                : prezentationToBeCloned.prezentationCarouselsThumbnails,
          },
          action: 'duplicate',
          parentPrezId: prezentationToBeCloned.prezentationId,
        });
      });
    },
    async onDelete(prezentationToBeDeleted) {
      trackPrezentationEvents.prezentationsThumbnailDelete(
        this.currentUser,
        prezentationToBeDeleted,
      );
      this.setDeckPrezentationsPage(this.getDeckPrezentationPage - 1);
      await this.loadPrezentationsFn('*', true);
    },
    async onUpdate(newName, prezentationToBeUpdated) {
      trackPrezentationEvents.prezentationsThumbnailRename(
        this.currentUser,
        prezentationToBeUpdated,
      );
      const prezDataForMatomo = {
        accessType: prezentationToBeUpdated.shared ? 'Shared' : 'Private',
        ...prezentationToBeUpdated,
      };
      const prezentationData = {
        id: prezentationToBeUpdated.prezentationId,
        name: newName,
      };
      await this.updatePrezentation(prezentationData).then(async () => {
        MatomoAnalyticsHandler.trackPrezRenameIconClick(
          this.currentUser.user,
          prezDataForMatomo,
        );
        this.updatePrezentationWithAction({
          prezentation: {
            ...prezentationToBeUpdated,
            name: newName,
          },
          action: 'rename',
        });
      });
    },
    async createPrezentation(prezentationData) {
      const path = '/prezentation';
      return API.post(path, prezentationData);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API.put(path, prezentationData);
    },
    async deletePrezentation(toDeleteId) {
      const path = `/prezentation/${toDeleteId}`;
      return API.delete(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.slides-filter__sort {
  display: flex;
  margin-left: auto;
  width: 20rem;
}
.mosaic-view,
.list-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::v-deep .v-input__slot {
  border-bottom: 0.8px solid #b6b6b6;
  box-shadow: none !important;
}

.prez-load__wrapper {
  margin-bottom: 37px;
  padding-bottom: 24px;
}
.loadmore-btn {
  margin-top: 22px;
}
</style>
