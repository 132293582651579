<template>
  <div class="ra">
    <div class="ra__close">
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <figure class="ra__figure">
      <img
        class="ra__img"
        src="/assets/img/profile/email-sent2.svg"
        alt="Request Sent"
      />
    </figure>
    <div class="ra__content">
      <h4 class="ra__title">Success!</h4>
      <p class="ra__text">
        We have sent the access request for “{{ templateName }}” template to
        your admin.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RequestAccessModal',
  methods: {
    close() {
      this.$modal.hide('RequestAccessModal');
    },
  },
  props: {
    templateName: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.ra {
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__figure {
    display: flex;
    justify-content: center;
    padding: 22px;
  }

  &__content {
    text-align: center;
    padding: 0 22px 22px;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 6px;
  }
}
</style>
