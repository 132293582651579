<template>
  <div class="change-layout-container">
    <div
      class="heading"
      :style="{
        marginBottom:
          origin === 'Synthesis' || origin === 'Redesign' ? '8px' : '24px',
      }"
    >
      {{ $t('generate.smartLayout') }}
      <div v-if="showPoweredByText" class="sub">
        {{ $t('build.step3.poweredByZenSence') }}
      </div>
    </div>
    <div class="sub-heading">{{ $t('generate.slideWithDifferentLayout') }}</div>
    <div>
      <v-radio-group
        class="similar-slides-radiobtns"
        v-model="type"
        :disabled="similarSlidesLoading"
      >
        <v-row data-pendo-id="slide-similar-options">
          <v-col cols="4">
            <v-radio
              label="Prezent"
              value="default"
              :disabled="
                isTemplateRemoved === true || isAudienceAvailable === false
              "
          /></v-col>

          <v-col cols="4">
            <v-radio
              :label="companyName"
              :value="currentUser.company.name"
              :disabled="
                isTemplateRemoved === true || isAudienceAvailable === false
              "
          /></v-col>
        </v-row>
      </v-radio-group>
    </div>
    <slot>
      <div class="icon-container" v-if="relatedSlides.length">
        <div class="node-count-dropdown">
          <v-icon :color="'#000'" class="node-count-slides-icon">
            {{ 'mdi-file-tree-outline' }}
          </v-icon>
          <div class="node-count-slides-dropdown">
            <span class="node-label">{{ $t('generate.nodeCount') }}: </span>
            <v-select
              v-model="selectedSimilarSlideNodeCount"
              @change="handleSimilarSlideNodeChange"
              :items="similarSlidesNodesList"
              :item-text="(item) => $t(item.display_text)"
              item-value="value"
              hide-details
            ></v-select>
          </div>
        </div>
        <div @click="handleView(false)">
          <v-icon
            :color="isColumnView ? '#000' : '#21A7E0'"
            v-tooltip="{
              content: $t('prezentationGallery.gridView'),
              placement: 'top-center',
            }"
          >
            {{ isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid' }}
          </v-icon>
        </div>

        <div @click="handleView(true)">
          <v-icon
            :color="isColumnView ? '#21A7E0' : '#000'"
            v-tooltip="{
              content: $t('prezentationGallery.columnView'),
              placement: 'top-center',
            }"
          >
            {{ isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline' }}
          </v-icon>
        </div>
      </div>
      <div class="layout-content">
        <div
          v-for="(slide, index) in filteredSlideByNode"
          :class="[{ 'column-view': isColumnView }, 'slide']"
          :key="index"
          :data-pendo-id="index === 0 ? 'smart-layout-slide' : null"
        >
          <v-img
            contain
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            :aspect-ratio="16 / 9"
            :class="{ current: slide.unique_id === selectedSlideID }"
            :src="getImgPath(slide)"
            loading="lazy"
            class="layout-image"
            @click="handleChangeLayout(slide)"
          />
        </div>
        <div>
          <clip-loader
            v-if="similarSlidesLoading"
            :loading="similarSlidesLoading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
            style="margin-top: 200px"
          />
        </div>
        <div
          v-if="!similarSlidesLoading && filteredSlideByNode.length <= 0"
          class="no-results-wrapper"
        >
          <EmptyState
            v-if="!filteredSlideByNodeError"
            :img-url="'/assets/img/no-files-available.svg'"
            :is-btn-visible="false"
          >
            <template v-slot:description>
              <p class="no-results-description">
                {{ $t('slideDetails.noResultFound') }}
              </p>
            </template>
          </EmptyState>
          <EmptyState
            v-else
            :img-url="'/assets/img/sso-user_not_found.svg'"
            :is-btn-visible="false"
          >
            <template v-slot:description>
              <p class="no-results-description">
                {{ $t('slideDetails.noResultsForSelectedNodeCount') }}
              </p>
            </template>
          </EmptyState>
        </div>
      </div>
    </slot>

    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="dialogSecondary"
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import EmptyState from '../../../common/EmptyState.vue';
import { GraphQLService } from '../../../../services/GraphQLService';
import { getFileURL } from '@/utils/calyrex';
import { performSearch } from '@/graphql/queries';
// import audienceApi from '../../../../API/audience-api';
import { getSlideDataForDetailView } from '../../../../utils/api-helper';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_SLIDEID,
  TD_NODECOUNT,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_VIEW_SELECTED,
  TD_COLUMN_VIEW,
  TD_GRID_VIEW,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';

export default {
  name: 'SmartLayout',
  components: { ClipLoader, EmptyState, ConfirmationDialog },
  watch: {
    type(newVal) {
      this.handleLoadRelatedSlides(newVal, this.selectedSimilarSlideNodeCount);
    },
    selectedSlide(val) {
      if (val) {
        this.fetchSlideMetaData();
        this.handleLoadRelatedSlides(this.type);
      }
    },
    selectedNode() {
      this.handleLoadRelatedSlides(this.type);
    },
  },
  props: {
    selectedSlideID: {
      type: String,
    },
    origin: {
      type: String,
      default: '',
    },
    updateSlide: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isColumnView: true,
      type: 'default',
      similarSlidesLoading: true,
      currentAudienceID: '',
      audience: {},
      userCompany: {
        name: null,
        value: null,
      },
      filteredSlideByNode: [],
      selectedSimilarSlideNodeCount: null,
      filteredSlideByNodeError: false,
      similarSlidesNodesList: [
        { value: '1', text: '1', display_text: '1' },
        { value: '2', text: '2', display_text: '2' },
        { value: '3', text: '3', display_text: '3' },
        { value: '4', text: '4', display_text: '4' },
        { value: '5', text: '5', display_text: '5' },
        { value: '6', text: '6', display_text: '6' },
        { value: '7', text: '7', display_text: '7' },
        { value: '8', text: '8', display_text: '8' },
        { value: '9', text: '9', display_text: '9' },
        { value: '', text: '10 or more', display_text: 'filters.more' },
      ],
      relatedSlides: [],
      currentPrezentationTheme: '',
      selectedSlideData: {},
      showDialog: false,
      dialogTitle: '',
      dialogText: '',
      dialogPrimary: '',
      dialogSecondary: '',
    };
  },
  computed: {
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedAudienceData',
    ]),
    ...mapState('users', ['currentUser', 'typesenseRealtimeAudiences']),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'isPartialAccess',
      'getCurrentSlideBPList',
    ]),
    originalRealtimeAudiences() {
      if (this.origin === 'generate') {
        return this.typesenseRealtimeAudiences.filter(
          (i) => i.type !== 'standard',
        );
      }
      return this.typesenseRealtimeAudiences;
    },
    showPoweredByText() {
      return this.selectedSlideData && this.selectedSlideData.isSynthesis;
    },
    companyName() {
      return (
        this.currentUser?.company?.displayName ||
        this.currentUser?.company?.name
      );
    },
    isSimilarSlidesSourcePrezent() {
      return this.type === 'default' || this.type === 'prezent';
    },
    getNodeCountForSimilarSlidesSearch() {
      if (this.isSimilarSlidesSourcePrezent) {
        return this.selectedNode ? [this.selectedNode.toString()] : [];
      }
      return [];
    },
    isAudienceAvailable() {
      return !(
        this.currentAudienceID === null || this.currentAudienceID === undefined
      );
    },
  },
  methods: {
    onDialogConfirm() {
      this.showDialog = false;
      this.changeLayout();
    },
    onDialogCancel() {
      this.showDialog = false;
      this.slide = null;
    },
    handleChangeLayout(slide) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_SLIDEID]: this.selectedSlideData?.prefs?.sid || '',
        [TD_NODECOUNT]: this.selectedSlideData?.prefs?.node || '',
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildChangeLayoutSimilarSlideClicked(
          this.currentUser,
          otherData,
        );
      } else {
        trackPrezentationEvents.prezentationsSimilarSlideClicked(
          this.currentUser,
          otherData,
        );
      }
      this.updateSlide({ type: 'layout_change' }, slide);
      // this.$emit('change', { type: 'slide_change', slide });
    },
    handleSimilarSlideNodeChange(value) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_SLIDEID]: this.selectedSlideData?.prefs?.sid || '',
        [TD_NODECOUNT]: value || '',
        [TD_COMMON_COLUMN_NAME]: TD_VIEW_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: this.isColumnView
          ? TD_COLUMN_VIEW
          : TD_GRID_VIEW,
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildChangeLayoutNodeCountChanged(
          this.currentUser,
          otherData,
        );
      } else {
        trackPrezentationEvents.prezentationsChangeLayoutNodeChanged(
          this.currentUser,
          otherData,
        );
      }
      this.selectedSimilarSlideNodeCount = value;
      this.handleLoadRelatedSlides(this.type, value);
    },
    handleView(value) {
      this.isColumnView = value;
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_COMMON_COLUMN_NAME]: TD_VIEW_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: this.isColumnView
          ? TD_COLUMN_VIEW
          : TD_GRID_VIEW,
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildChangeLayoutViewChanged(
          this.currentUser,
          otherData,
        );
      } else {
        trackPrezentationEvents.prezentationsChangeLayoutViewChanged(
          this.currentUser,
          otherData,
        );
      }
    },
    getImgPath(slide) {
      let path = '';
      if (slide && slide.landscapePath && this.isColumnView) {
        path = slide.landscapePath;
      } else if (slide && slide.thumbnailPath && !this.isColumnView) {
        path = slide.thumbnailPath;
      } else {
        path = '/assets/img/slides/placeholder-slide.svg';
      }
      return path;
    },

    setDefaultAudience() {
      /**
       * if there is an audienceOwnerID exist for a prezentation
       * then match both id and ownerid of the audience else then pick the audience which is of not a type of shared and private
       */
      if (this.prezentationData.audienceOwnerID) {
        this.currentAudienceID = {
          defaultAudience: `${this.prezentationData.audienceID}-private_audience`,
        };
      } else {
        this.currentAudienceID = {
          defaultAudience: `${this.prezentationData.audienceID}-user:audience:standard`,
        };
      }
      // this.handleDefaultAudienceData(this.currentAudienceID);
    },
    // handleDefaultAudienceData(val) {
    //   const lastDashIndex = val.defaultAudience.lastIndexOf('-');
    //   const audienceTypes = val.defaultAudience
    //     .slice(lastDashIndex + 1)
    //     .split(':');
    //   const email = val.defaultAudience.slice(0, lastDashIndex);
    //   const searchAudienceTypes = audienceTypes;
    //   const audienceTypeFilter =
    //     this.origin === 'generate'
    //       ? (searchAudienceTypes &&
    //           searchAudienceTypes.filter((i) => i !== 'standard')) || [
    //           'audience',
    //           'private_audience',
    //           'user',
    //         ]
    //       : searchAudienceTypes;
    //   const realtimeAudiencesMap = {};
    //   this.originalRealtimeAudiences.forEach((a) => {
    //     realtimeAudiencesMap[a.audienceId] = a;
    //   });
    //   audienceApi.methods
    //     .audienceSearch({
    //       query: email,
    //       companyId: this.currentUser.user.companyID,
    //       userId: this.currentUser.user.id,
    //       realtimeAudiences: realtimeAudiencesMap,
    //       searchAudienceTypes: audienceTypeFilter,
    //       filterByCollection: {
    //         private_audiences: 'hasFingerPrint:=1',
    //       },
    //     })
    //     .then((audiences) => {
    //       audiences.forEach((audience) => {
    //         audience.isPublicLimited = !(
    //           audience.accessible_to.includes('a__0') ||
    //           audience.accessible_to.includes(
    //             `c__${this.currentUser.company.num_id}`,
    //           ) ||
    //           audience.accessible_to.includes(
    //             `u__${this.currentUser.user.num_id}`,
    //           )
    //         );
    //       });
    //       // When default audience is being set programtically.
    //       if (audiences.length) {
    //         // eslint-disable-next-line prefer-destructuring
    //         this.audience = audiences[0];
    //       }
    //     });
    // },
    getSelectedNode(node) {
      if (node && node !== '') {
        if (node !== '') {
          return [node.toString()];
        }
        return this.getNodeCountForSimilarSlidesSearch;
      }
      return ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    },
    getChoice(fingerprint) {
      let choice = { visual: 6.0, data: 5.0 };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'performer':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'navigator':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'surgeon':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'architect':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'scholar':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'scientist':
          choice = { visual: 0.0, data: 5.0 };
          return choice;
        default:
          return choice;
      }
    },
    async handleLoadRelatedSlides(source, node) {
      this.similarSlidesLoading = true;
      this.filteredSlideByNodeError = false;

      const payload = {};

      const fingerprint = this.audience?.fingerPrint
        ? this.audience?.fingerPrint.toLowerCase()
        : 'director';
      payload.choice = this.getChoice(fingerprint);
      payload.category = this.selectedSlideData.category;
      payload.username = this.currentUser.user.cognitoID;
      payload.limit = 24;
      payload.skip = 0;
      payload.morprefs = {};
      payload.morprefs.source = source === 'default' ? 'prezent' : source;
      payload.morprefs.construct = [];
      payload.morprefs.node = node !== '' ? this.getSelectedNode(node) : [];
      payload.notprefs = {};
      payload.notprefs.node = node === '' ? this.getSelectedNode(node) : [];
      payload.similar = this.selectedSlideData.unique_id;
      payload.audience_id = this.audience?.email;
      payload.audience_type = this.audience?.type;

      this.filteredSlideByNode = [];
      try {
        await this.getRecommendedSlides(payload, {
          code: this.prezentationData?.theme,
        }).then(async (slides) => {
          if (slides) {
            for (const slide of slides) {
              if (slide.thumbnail) {
                slide.thumbnailPath = await getFileURL(
                  this.currentUser.user.id,
                  slide.thumbnail,
                  this.currentUser.userIp,
                );
              }
              if (slide.landscape) {
                slide.landscapePath = await getFileURL(
                  this.currentUser.user.id,
                  slide.landscape,
                  this.currentUser.userIp,
                );
              }
              this.relatedSlides = slides.filter(
                (e) => e.unique_id !== this.selectedSlideData.uniqueID,
              );
              this.filteredSlideByNode = JSON.parse(
                JSON.stringify(this.relatedSlides),
              );
            }
          } else {
            this.relatedSlides = [];
            this.filteredSlideByNode = [];
          }
        });
      } catch (err) {
        console.log(err);
        this.filteredSlideByNodeError = true;
      } finally {
        this.similarSlidesLoading = false;
        this.$forceUpdate();
      }
    },
    getRecommendedSlides(payload) {
      const getSlides = (resolve, reject) => {
        if (!payload.andprefs) {
          payload.andprefs = {};
        }
        payload.andprefs.theme = this.prezentationData?.theme || 'default';
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          prefs: payload.prefs ? payload.prefs : [],
          andprefs: payload.andprefs ? payload.andprefs : [],
          notprefs: payload.notprefs ? payload.notprefs : [],
          company: this.currentUser?.company?.name?.toLowerCase(),
          limit: payload.limit,
          audience_id: payload.audience_id,
          audience_type: payload.audience_type,
          skip: payload.skip >= 0 ? payload.skip : 0,
        };
        const serialized = JSON.stringify(params);

        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
            sessionkey: this.sessionKey,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200) {
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                reject([]);
              } else {
                resolve(slides);
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },
    async fetchSlideMetaData() {
      this.selectedSlideData = {
        ...this.selectedSlide,
      };
      if (
        this.selectedSlide &&
        !(
          this.selectedSlide?.assetId?.includes('public/') ||
          this.selectedSlide?.assetId?.includes('private/') ||
          this.selectedSlide?.assetId?.includes('protected/')
        ) &&
        this.selectedSlide?.prefs?.is_know !== 'yes'
      ) {
        let slideExtraData = {};
        let { originalUniqueID } = this.selectedSlide;
        if (
          this.selectedSlide?.assetId?.includes('newImageReplaceOutput/') &&
          originalUniqueID
        ) {
          originalUniqueID = originalUniqueID.includes('replaced-image/')
            ? originalUniqueID.replaceAll('replaced-image/', '')
            : originalUniqueID;
          slideExtraData = await getSlideDataForDetailView(originalUniqueID);
        } else if (this.selectedSlide?.uniqueID) {
          slideExtraData = await getSlideDataForDetailView(
            this.selectedSlide.uniqueID,
          );
        }
        this.selectedSlideData = {
          ...this.selectedSlide,
          prefs: slideExtraData?.data?.prefs,
        };
      }
      this.selectedSimilarSlideNodeCount =
        this.selectedSlideData?.nodeCount ||
        this.selectedSlideData?.prefs?.node?.toString();
      this.handleLoadRelatedSlides(
        this.type,
        this.selectedSimilarSlideNodeCount,
      );
    },
  },
  beforeMount() {
    this.audience = this.selectedAudienceData;
    this.setDefaultAudience();
    this.fetchSlideMetaData();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.change-layout-container {
  padding-right: 5px;
  height: calc(100% - 100px);

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .icon-container {
    display: flex;
    // margin-right: 30px;
    column-gap: 10px;
    justify-content: flex-end;
    margin-bottom: 20px;

    div {
      cursor: pointer;
    }
  }

  .layout-content {
    overflow: auto;
    height: calc(100% - 70px);
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      height: 120px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      .layout-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;

        &.current {
          border: 1.5px solid #21a7e0;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .column-view {
      width: calc(100% - 20px);
      height: auto;
    }
  }
}
.error-layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .no-layout-text {
    text-align: center;
    margin-top: 16px;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}
.similar-slides-radiobtns {
  ::v-deep .v-label {
    color: black;
  }
}
.node-count-dropdown {
  text-align: end;
  // padding-right: 25px;
  height: 35px;

  .node-count-slides-dropdown {
    display: none;

    .node-label {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-align: left;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .v-select {
      padding: 0;
      margin: 0;
      max-width: 50px;

      ::v-deep .v-select__selection {
        margin: 0 !important;
      }

      ::v-deep input {
        padding: 3px 0 8px !important;
      }
    }
  }
  .node-count-slides-icon {
    font-size: 24px;
  }
  .v-input__icon {
    display: block;
  }
  &:hover .node-count-slides-dropdown {
    display: flex;
    margin-top: -5px;
  }
  &:hover .node-count-slides-icon {
    display: none;
  }
}
</style>
