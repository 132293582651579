<template>
  <div class="my-ws-card-wrap" @click="handleCardClick">
    <div class="card-data">
      <div class="icon-wrap">
        <WsFileIcon
          :height="'58px'"
          :width="'58px'"
          :fileName="myWsCard.fileName || ''"
          :iconType="cardType"
        />
      </div>
      <div class="card-text">
        <p class="card-title">
          <v-tooltip bottom max-width="400px">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ myWsCard.fileName || myWsCard.title }}
              </span>
            </template>
            <div class="tooltip-text">
              {{ myWsCard.fileName || myWsCard.title }}
            </div>
          </v-tooltip>
        </p>
        <div class="author-count-wrap">
          <!-- TODO WS: Add author name -->
          <p class="card-author">
            {{
              $t('prezentationsList.authorLabel', {
                authorName:
                  currentUser.user.id === myWsCard.userID
                    ? $t('build.step1.me')
                    : myWsCard.userID,
              })
            }}
          </p>
          <template v-if="cardType === 'project'">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <p>
              {{ myWsCard.filesCount }}
              {{ 'files' }}
            </p>
          </template>
        </div>
        <p class="card-date">
          {{
            `${$t('myWorkspace.uploadedOnLabel')} ${formatDateWithMoment(
              myWsCard.createdAt,
            )}`
          }}
        </p>
      </div>
    </div>
    <div class="actions">
      <div class="permission">
        <!-- <PrezentationShareStatus
          :prezentation="prezData"
          :isEditable="isEditable"
          :prezentationUpdatedFn="handleShareSettingsUpdate"
          :origin="
            prezentation.prezentationSource === 'User Uploaded'
              ? 'upload-prezentation'
              : `${origin}-${matomo_thumbnail}-button`
          "
          :source="`prezentationcard`"
          :current-user="currentUser"
        /> -->
      </div>
      <div class="fav-container">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="isChangeFavoriteInProgress === true"
          :size="22"
        />
        <template v-else>
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!isFavorite"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="handleAddWsCardAsFav($event, cardData)"
              >
                mdi-heart-outline
              </v-icon>
              <v-icon
                v-else
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="handleRemoveWsCardAsFav($event, cardData)"
              >
                mdi-heart
              </v-icon>
            </template>
            <span>
              {{
                `${
                  cardData.isFavorite
                    ? $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                    : $t('bestPracticesGuide.knowSlideCard.addFavorites')
                }`
              }}
            </span>
          </v-tooltip>
        </template>
      </div>
      <v-menu
        v-if="getActions.length"
        top
        absolute
        offset-y
        :close-on-click="true"
        :close-on-content-click="closeEllipsisMenu"
        :position-x="menuPos.x"
        :position-y="menuPos.y"
        :key="menuKey"
      >
        <template #activator="menu">
          <v-tooltip bottom>
            <template #activator="tooltip">
              <v-icon v-on="{ ...menu.on, ...tooltip.on }" color="primary">
                mdi-dots-vertical
              </v-icon>
            </template>
            <span>{{ $t('slides.moreActions') }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item
            v-for="(action, index) in getActions"
            :key="index"
            :disabled="action.disabled"
            @click="handleDotOptionClick(action.name)"
          >
            <v-list-item-title>
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on" class="otherActions">
                    <div
                      class="icon"
                      :class="action.disabled ? 'disable-icon' : ''"
                    >
                      <v-icon>{{ action.icon }}</v-icon>
                    </div>
                    <div class="actionName">
                      {{ $t(action.display_title) }}
                    </div>
                  </div>
                </template>
                <span>
                  {{ $t(action.display_title) }}
                </span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { MyWorkspaceService } from './MyWorkspaceService';
import WsFileIcon from './WsFileIcon.vue';
// import SharePrezentation from '../Prezentations/SharePrezentation.vue';
// import PrezentationShareStatus from '../Prezentations/PrezentationShareStatus.vue';

export default {
  name: 'MyWsCard',
  props: {
    cardData: {
      required: true,
    },
    cardType: {
      type: String,
      default: 'file',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    WsFileIcon,
    // SharePrezentation,
    // PrezentationShareStatus,
  },
  mounted() {
    if (this.cardType === 'file') {
      this.isFavorite = this.myWsCard.isFavourite;
      this.filesDotActions = this.filesDotActions.map((item) => {
        if (item.name === 'addToProject' && this.createdProjects.length === 0) {
          return { ...item, disabled: true };
        }
        return item;
      });
    } else {
      this.isFavorite = this.myWsCard.isFavouriteProject;
    }
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('myWorkspace', ['createdProjects']),
    getActions() {
      if (this.cardType === 'file') {
        return this.filesDotActions;
      }
      return this.projectActions;
    },
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
  },
  data() {
    return {
      myWsCard: this.cardData,
      isChangeFavoriteInProgress: false,
      isFavorite: false,
      projectActions: [],
      filesDotActions: [
        {
          name: 'addToProject',
          display_title: 'myWorkspace.mainPage.fileActions.addToProject',
          icon: 'mdi-folder-open-outline',
          disabled: false,
        },
      ],
      closeEllipsisMenu: true,
      menuKey: 0,
      menuPos: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    ...mapActions('myWorkspace', [
      'setDropdownAction',
      'setCurrentSelectedFileId',
    ]),
    async handleAddWsCardAsFav(e, cardData) {
      e.stopPropagation();
      e.preventDefault();
      this.isChangeFavoriteInProgress = true;
      let favPayload = {};
      if (this.cardType === 'file') {
        favPayload = {
          workspaceFileID: cardData.id,
          favourite: true,
        };
      } else {
        favPayload = {
          workspaceProjectID: cardData.id,
          favourite: true,
        };
      }
      this.myWorkspaceService
        .updateWorkspace(favPayload)
        .then((data) => {
          if (data.status === 'success') {
            this.isChangeFavoriteInProgress = false;
            this.isFavorite = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isChangeFavoriteInProgress = false;
        });
    },
    async handleRemoveWsCardAsFav(e, cardData) {
      e.stopPropagation();
      e.preventDefault();
      this.isChangeFavoriteInProgress = true;
      let favPayload = {};
      if (this.cardType === 'file') {
        favPayload = {
          workspaceFileID: cardData.id,
          favourite: false,
        };
      } else {
        favPayload = {
          workspaceProjectID: cardData.id,
          favourite: false,
        };
      }
      this.myWorkspaceService
        .updateWorkspace(favPayload)
        .then((data) => {
          if (data.status === 'success') {
            this.isChangeFavoriteInProgress = false;
            this.isFavorite = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isChangeFavoriteInProgress = false;
        });
    },
    handleDotOptionClick(action) {
      this.setDropdownAction(action);
      if (this.cardType === 'file') {
        this.setCurrentSelectedFileId(this.myWsCard.id);
      }
    },
    handleCardClick() {
      this.$emit('onClick', this.cardData);
    },
    openSharingOptions(prezentation) {
      console.log(prezentation);
      // let origin = `${this.origin}-${this.matomo_thumbnail}-icon`;
      // if (prezentation.prezentationSource === 'User Uploaded') {
      //   origin = 'upload-prezentation';
      // }
      // this.$modal.show(
      //   SharePrezentation,
      //   {
      //     prezentation,
      //     prezentationUpdatedFn: this.handleShareSettingsUpdate,
      //     origin,
      //     source: 'prezentationcard',
      //   },
      //   {
      //     name: 'SharePrezentation',
      //     width: 700,
      //     height: 'auto',
      //     minHeight: 300,
      //     styles: { borderRadius: '20px' },
      //   },
      // );
    },
    formatDateWithMoment(date) {
      return moment(date).format('MM/DD/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.my-ws-card-wrap {
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  box-shadow: -1px -1px 4px 0px #0000001a, 1px 1px 4px 0px #0000001a;
  display: flex;
  flex-direction: column;
  .card-data {
    display: flex;
    gap: 10px;
    .icon-wrap {
      display: flex;
      align-items: flex-end;
    }
    .card-text {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      width: calc(100% - 73px);
      .card-title {
        display: flex;
        width: 95%;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        span {
          display: inline-block;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .author-count-wrap {
        display: flex;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4d5358;
      }
      .card-author,
      .card-date {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4d5358;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
.v-menu__content {
  .v-list {
    width: 300px;
    .v-list-item {
      min-height: 30px;
      cursor: pointer;
      .v-list-item__title {
        .otherActions {
          display: flex;

          .icon {
            i {
              color: black;
              &:hover {
                color: #21a7e0;
              }
            }
          }
          .disable-icon {
            i {
              color: rgba(0, 0, 0, 0.38);
            }
          }
          .actionName {
            padding: 2px 4px 0px 4px;
          }
        }
      }
      &:hover {
        background-color: #e1f5fb;
      }
    }
  }
}

.tooltip-text {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
