<template>
  <div class="slides-container">
    <h1 class="title" data-pendo-id="upload-tagslides">
      {{ $t('tagSlides.title') }}
    </h1>
    <p class="pt-2 virbage mb-0">
      {{ $t('tagSlides.virbage1') }}
    </p>
    <p class="virbage">
      {{ $t('tagSlides.virbage2') }}
    </p>
    <div class="no-of-slides-section" v-if="prezentationDetails">
      <div class="mb-title">
        {{
          $t('tagSlides.noOfSlidesText', {
            selectedIndex: selectedSlideIndex + 1,
            totalSlides: prezentationDetails.selectedSanitizedSlideList.length,
          })
        }}
      </div>
    </div>
    <v-row>
      <v-col cols="2">
        <div
          class="related-products"
          :style="{
            height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
          }"
        >
          <div class="related-products-thumbnails" :key="componentKey">
            <v-img
              :class="`product ${
                selectedSlideIndex === index ? 'selected' : ''
              } ${errorSlides[index] ? 'error-border' : ''}`"
              :key="n.index"
              v-for="(n, index) in slides"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              :aspect-ratio="16 / 9"
              :src="n.thumbnail"
              loading="lazy"
              @click="setSelectedSlideAndIndex(n, index)"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div id="main-slide-id" class="selected-slide-block">
          <v-img
            width="100%"
            class="selected-slide-block__slide"
            :src="selectedSlide.thumbnail"
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            loading="lazy"
          ></v-img>
          <!-- <template>
            <v-sheet class="mx-auto" max-width="800">
              <v-slide-group class="pa-2" active-class="success" show-arrows>
                <v-slide-item
                  v-for="(n, index) in slides"
                  :key="index"
                  v-slot="{ active }"
                >
                  <v-card
                    :color="active ? undefined : 'grey lighten-1'"
                    class="ma-4"
                    width="150"
                    @click="setSelectedSlideAndIndex(n, index + 1)"
                  >
                    <v-img width="150" :src="n.thumbnailUrl"></v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template> -->
        </div>
      </v-col>
      <v-col cols="4" class="right-section">
        <div
          :style="{
            height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '0 10px',
          }"
        >
          <h3 class="field-label">{{ $t('tagSlides.slideNameLabel') }}</h3>

          <v-text-field
            v-model="selectedSlide.name"
            :placeholder="$t('tagSlides.slideNameLabel')"
            :rules="nameRules"
            required
            @input="handleNameChange"
            max
            data-pendo-id="slide-name"
          />
          <div class="ideas-title-section" data-pendo-id="slide-idea">
            <h3 class="ideas-title-section__title">
              {{ $t('tagSlides.ideasLabel') }}
            </h3>
            <label class="ideas-title-section__subtitle">
              {{ $t('tagSlides.ideasLabelSubtitle') }}
            </label>
            <v-autocomplete
              v-model="selectedSlide.ideas"
              :disabled="isUpdating"
              :items="ideas"
              item-text="display_name"
              item-value="category"
              :hide-selected="true"
              :menu-props="{ maxHeight: 200 }"
              :rules="ideasRule"
              @change="changeIdea"
              return-object
              chips
              :placeholder="$t('tagSlides.ideasPlaceholder')"
              :persistent-placeholder="true"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  class="selected-chips"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeIdea(data.item)"
                >
                  {{ data.item.display_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
          <div class="node-count-title-section">
            <h3 class="node-count-title-section__title">
              {{ $t('tagSlides.nodeCountLabel') }}
            </h3>
            <label class="node-count-title-section__subtitle">
              {{ $t('tagSlides.nodeCountSubtitle') }}
            </label>
            <div class="node pt-0">
              <div class="node-list" data-pendo-id="slide-nodes">
                <span v-for="item in selectedSlide.maxNodeCount" :key="item">
                  <span
                    :class="`node-list-item ${
                      selectedSlide.nodeCount === item.toString()
                        ? 'outlined'
                        : ''
                    } ${
                      selectedSlide.maxNodeCount.includes(item)
                        ? ''
                        : 'disabled'
                    }`"
                    @click="handleNodeClick(item)"
                  >
                    {{ item }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="tags-title-section" data-pendo-id="slide-keywords">
            <h3 class="tags-title-section__title">
              {{ $t('tagSlides.keywordLabel') }}
            </h3>
            <label class="tags-title-section__subtitle">
              {{ $t('tagSlides.keywordSubtitle') }}
            </label>
            <v-combobox
              v-model="selectedSlide.tags"
              :disabled="isUpdating"
              :items="tags"
              hide-selected
              :menu-props="{ maxHeight: 200 }"
              @change="changeTag()"
              chips
              :placeholder="$t('tagSlides.keywordPlaceholder')"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  class="selected-chips"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeTag(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>

          <div class="tags-title-section">
            <h3 class="tags-title-section__title">
              {{ $t('tagSlides.templateLabel') }}
            </h3>
            <v-autocomplete
              :value="selectedSlide.theme_code"
              :items="templatesList || []"
              return-object
              item-value="code"
              :placeholder="$t('tagSlides.templatePlaceholder')"
              item-text="name"
              class="templates-dropdown"
              :search-input.sync="templateSearch"
              :attach="true"
              :menu-props="{
                maxHeight: '400',
                padding: '0px',
                rounded: 'lg',
              }"
              item-disabled="isDisabled"
              :rules="dropdownRules"
              @focus="trackTemplateMenuClickEvent"
              @change="handleClickTheme"
              @blur="handleTemplateBlur"
              data-pendo-id="slide-template"
            >
              <template #append>
                <v-icon class="icon search-icon">mdi-magnify</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-tooltip
                  attach
                  absolute
                  max-width="200"
                  :disabled="!item.isDisabled"
                  :content-class="'center-tooltip'"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="template-list-item"
                      :class="{
                        'template-list-item--disabled': item.isDisabled,
                        'template-selected': isCurrentSelected(item.id),
                      }"
                      v-on="on"
                    >
                      <div class="template-header">
                        <div class="template-header__left">
                          <v-tooltip
                            top
                            max-width="200"
                            content-class="tooltip-content"
                            fixed
                          >
                            <template v-slot:activator="{ on }">
                              <div class="template-title" v-on="on">
                                {{ item.name }}
                              </div>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                          <span
                            class="template-header__icon"
                            v-if="item.isPrezentTemplate"
                          >
                            <img
                              class="prezent-icon"
                              src="/assets/img/prezent_avatar.svg"
                              alt
                              width="22px"
                            />
                          </span>
                        </div>

                        <div class="template-header__right">
                          <span
                            class="template-header__icon"
                            v-if="item.isMarked"
                          >
                            <v-tooltip
                              bottom
                              max-width="200"
                              content-class="tooltip-content"
                              fixed
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  class="template-header__marked"
                                  @click="stopPropogation($event)"
                                >
                                  <v-icon color="white" small>
                                    mdi-account-heart-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span
                                >Template recommended by your team admin</span
                              >
                            </v-tooltip>
                          </span>
                          <span
                            class="template-header__icon"
                            v-if="item.isPinned"
                          >
                            <v-tooltip
                              bottom
                              max-width="200"
                              content-class="tooltip-content"
                              fixed
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="template-header__pinned"
                                  @click="stopPropogation($event)"
                                >
                                  <v-icon> mdi-pin </v-icon>
                                </v-btn>
                              </template>
                              <span>Pinned</span>
                            </v-tooltip>
                          </span>
                          <span
                            class="template-header__icon"
                            v-if="item.isDisabled"
                          >
                            <v-btn color="primary" icon>
                              <v-icon> mdi-lock </v-icon>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                      <v-row class="mb-2">
                        <v-col class="available-theme-img">
                          <img :src="item.thumbnailURL" alt />
                        </v-col>
                        <v-col class="example-slide-img">
                          <img
                            :src="
                              item.sampleSlide
                                ? item.sampleSlideURL
                                : item.thumbnailURL
                            "
                            alt
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <span
                    v-if="item.isDisabled && currentUser.user.role === 'User'"
                  >
                    This template is disabled by your team admin.
                  </span>
                  <span v-else-if="item.isDisabled">
                    This template is disabled for your team. You can enable it
                    in Templates tab under Profile.
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <div class="no-templates-fount">
                  <p class="no-result-text">
                    {{ $t('tagSlides.noResultFound') }}
                  </p>
                  <div class="add-more-templates-btn" @click="handleAddMore">
                    <v-icon color="#20a7e0" class="add-icon">mdi-plus</v-icon>
                    <p>{{ $t('tagSlides.addMoreTemplatesText') }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:append-item>
                <div class="tip-bar">
                  <p>
                    {{ $t('tagSlides.templateProTipText') }}
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="ideas-title-section">
            <h3 class="ideas-title-section__title">
              {{ $t('tagSlides.speakerNotesLabel') }}
            </h3>
            <label class="tags-title-section__subtitle">
              {{ $t('tagSlides.speakerNotesSubtext') }}
            </label>
            <v-textarea
              v-model="selectedSlide.speaker_notes"
              solo
              name="input-7-4"
              :placeholder="$t('tagSlides.speakerNotesPlaceholder')"
              @change="handleNoteChange"
              data-pendo-id="slide-notes"
            ></v-textarea>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="action-wrapper">
      <v-btn
        rounded
        width="67"
        height="32"
        class="mt-7 primary upload-prez-btn"
        @click="handleNextStep"
        :loading="isLoadingTags"
        :disabled="isLoadingTags || Object.keys(this.errorSlides).length > 0"
        v-if="!editMode"
        data-pendo-id="presentation-next"
      >
        {{ $t('common.next') }}
      </v-btn>
      <v-btn
        rounded
        width="81"
        height="32"
        class="mt-5 primary upload-prez-btn"
        @click="handleAcceptTags"
        :loading="isLoadingTags || isUpdating"
        :disabled="isUpdating || Object.keys(this.errorSlides).length > 0"
        v-else-if="origin === 'prezentation'"
      >
        {{ $t('common.accept') }}
      </v-btn>
      <v-btn
        rounded
        width="135"
        height="48"
        class="primary upload-prez-btn"
        @click="onBackClick"
        :loading="isLoadingTags"
        v-else
      >
        {{ $t('common.back') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  getPipeineStatus,
  getSuperhawkCategories,
} from '../../../utils/api-helper';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import {
  SLIDES,
  TD_AUD,
  getAudienceCompanyName,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
import { isEmojiExist } from '../../utils';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import { templateSort } from '../../../utils/utility';

export default {
  name: 'TagSlides',
  props: {
    nextStep: {
      type: Function,
      default: () => ({}),
    },
    prezentationDetails: {
      type: Object,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    onPrezentationUpload: {
      type: Function,
      default: () => ({}),
    },
    onBackClick: {
      type: Function,
      default: () => ({}),
    },
    handleAcceptEditedTags: {
      type: Function,
      default: () => ({}),
    },
    origin: {
      type: String,
      default: 'upload',
    },
    isTransferFlow: Boolean,
  },
  data() {
    return {
      componentEnterTime: 0,
      componentExitTime: 0,
      timeSpent: 0,
      slides: [],
      ideas: [],
      isUpdating: false,
      selectedNode: 0,
      tags: [],
      selectedTags: [],
      slidesArrayInitial: [],
      selectedSlide: {},
      selectedSlideIndex: 0,
      componentKey: 0,
      mainSlideHeight: 0,
      errorSlides: {},
      speaker_notes: '',
      templateSearch: '',
      templatesList: [],
      isLoadingTags: false,
      nameRules: [
        (v) => !!v || this.$t('warnings.enterRequiredField'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) =>
          v?.length <= 99 ||
          this.$t('warnings.inputMustBeLessThan99Characters'),
      ],
      dropdownRules: [(v) => !!v || this.$t('warnings.electRequiredField')],
      ideasRule: [
        (v) => v?.length >= 1 || this.$t('warnings.addMinimumOneBusinessIdea'),
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes', 'currentTheme']),
    selectedTemplate() {
      return this.selectedSlide?.theme_code;
    },
  },
  watch: {
    selectedSlide(val) {
      if (val) {
        this.setErrorData(val);
      }
    },
    selectedTemplate() {
      this.templatesList = this.sortedThemeArray();
    },
  },
  methods: {
    ...mapActions('users', ['setFilteredThemes']),
    sortedThemeArray() {
      const currentSelected = [];
      const pinnedThemes = [];
      const companyThemes = [];
      const prezentThemes = [];

      const themesArr = this.themes;
      themesArr.forEach((theme) => {
        if (theme.isArchived) {
          return;
        }
        if (theme.code === this.selectedSlide?.theme_code) {
          currentSelected.push(theme);
        } else if (theme.isPinned) {
          pinnedThemes.push(theme);
        } else if (theme.isPrezentTemplate) {
          prezentThemes.push(theme);
        } else {
          companyThemes.push(theme);
        }
      });
      const themes = [];
      if (currentSelected.length) {
        themes.push({ header: 'Current selection' });
        themes.push(...currentSelected);
      }

      themes.push({ header: 'My Pinned Templates' });
      themes.push(...templateSort(pinnedThemes));

      themes.push({
        header: `${this.currentUser?.company?.displayName} Brand Templates`,
      });
      themes.push(...templateSort(companyThemes));

      themes.push({ header: 'Prezent Templates' });
      themes.push(...templateSort(prezentThemes));

      return themes;
    },
    isCurrentSelected(id) {
      return this.selectedSlide?.theme_code === id;
    },
    handleTemplateBlur() {
      if (!this.selectedSlide?.theme_code) {
        this.selectedSlide.theme_code =
          this.prezentationDetails.templateSelected.code;
        this.setErrorData(this.selectedSlide);
      }
    },
    handleClickTheme(theme) {
      this.selectedSlide.theme_code = theme?.code;
      this.setErrorData(this.selectedSlide);
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    handleNoteChange(speakerNotes) {
      this.selectedSlide.speaker_notes = speakerNotes;
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    handleNameChange() {
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
      this.setErrorData(this.selectedSlide);
    },

    trackTemplateMenuClickEvent() {
      trackSlideEvents.slidesTemplateInfoClick(this.currentUser, {
        [TD_AUD]: this.getAudienceDataToTrack()?.email,
      });
    },

    handleAcceptTags() {
      this.isUpdating = true;
      this.handleAcceptEditedTags(
        {
          ...this.prezentationDetails,
          selectedSanitizedSlideList: [...this.slides],
        },
        () => {
          this.isUpdating = false;
        },
      );
    },

    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          origin: SLIDES,
          tailorID: this.getAudienceDataToTrack()?.email,
          audienceCompany: getAudienceCompanyName(
            this.prezentationDetails.selectedAudience,
            this.currentUser,
          ),
          templateRequestFrom: 'Slides',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    getAudienceDataToTrack() {
      try {
        return {
          email: this.currentUser?.id || this.currentUser?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },
    handleNextStep() {
      this.componentExitTime = Date.now();
      this.timeSpent =
        (this.componentExitTime - this.componentEnterTime) / 1000;
      this.nextStep(5);
      this.trackTagChangeEvents();
      this.onPrezentationUpload({
        ...this.prezentationDetails,
        selectedSanitizedSlideList: [...this.slides],
      });
    },
    trackTagChangeEvents() {
      this.slidesArrayInitial.forEach((slide, i) => {
        if (
          JSON.stringify(this.slidesArrayInitial[i]?.ideas) !==
          JSON.stringify(this.slides[i]?.ideas)
        ) {
          trackUploadEvents.uploadTagBusinessIdeaChanged(this.currentUser);
        }
        if (this.slidesArrayInitial[i]?.name !== this.slides[i]?.name) {
          trackUploadEvents.uploadTagSlideNameChanged(this.currentUser);
        }
        if (
          this.slidesArrayInitial[i]?.nodeCount !== this.slides[i]?.nodeCount
        ) {
          trackUploadEvents.uploadTagNodeCountChanged(this.currentUser);
        }
        if (
          this.slidesArrayInitial[i]?.speaker_notes !==
          this.slides[i]?.speaker_notes
        ) {
          trackUploadEvents.uploadTagSpeakerNotesChanged(this.currentUser);
        }
        if (
          this.slidesArrayInitial[i]?.theme_code !== this.slides[i]?.theme_code
        ) {
          trackUploadEvents.uploadTagSlideTemplateChanged(this.currentUser);
        }
        if (
          JSON.stringify(this.slidesArrayInitial[i]?.tags) !==
          JSON.stringify(this.slides[i]?.tags)
        ) {
          trackUploadEvents.uploadTagKeywordsChanged(this.currentUser);
        }
      });
    },
    handleNodeClick(node) {
      this.selectedSlide.nodeCount = `${node}`;
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
      this.$forceUpdate();
    },
    changeTag() {
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    removeTag(item) {
      const index = this.selectedSlide.tags.indexOf(item);
      if (index >= 0) this.selectedSlide.tags.splice(index, 1);

      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    changeIdea() {
      this.setErrorData(this.selectedSlide);
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    removeIdea(item) {
      this.selectedSlide.ideas = this.selectedSlide.ideas.filter(
        (idea) => idea.category !== item.category,
      );
      this.setErrorData(this.selectedSlide);
      this.slides[this.selectedSlideIndex] = { ...this.selectedSlide };
    },
    setSelectedSlideAndIndex(slideItem, index) {
      if (this.selectedSlide) {
        this.setErrorData(this.selectedSlide);
      }
      this.tags = [...(this.selectedSlide?.tags || [])];
      this.selectedSlideIndex = index;
      this.selectedSlide = { ...slideItem };
      trackUploadEvents.uploadSanitizeSlideThumbnailClick(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: 'Location',
        [TD_COMMON_COLUMN_VALUE]: 'Tag',
      });
    },
    setErrorData(val) {
      if (
        !val.ideas ||
        val.ideas?.length === 0 ||
        !val.name ||
        !val.theme_code ||
        !val.nodeCount
      ) {
        this.errorSlides[this.selectedSlideIndex] = true;
      } else {
        delete this.errorSlides[this.selectedSlideIndex];
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },
  async beforeMount() {
    const categoriesRecords = await getSuperhawkCategories();
    this.ideas = Object.keys(categoriesRecords).map((category) => ({
      display_name: categoriesRecords[category],
      category,
    }));

    if (this.prezentationDetails) {
      this.slides = [...this.prezentationDetails.selectedSanitizedSlideList];

      this.selectedSlide = this.slides[this.selectedSlideIndex];

      if (this.origin === 'upload') {
        this.isLoadingTags = true;
        let status = this.prezentationDetails.pipelineStatus;
        while (
          !status ||
          (Object.keys(status.outputs).length > 0 &&
            status.outputs['U-Tags'] &&
            status.outputs['U-Tags'].status !== 'success')
        ) {
          await this.sleep(3000);
          const resp = await getPipeineStatus(
            this.prezentationDetails.pipelineCallbackId,
          );
          status = resp;
        }

        this.slides.forEach((slide, i) => {
          let item = {};
          for (let idx = 0; idx < status.outputs['U-Tags'].data.length; idx++) {
            if (status.outputs['U-Tags'].data[idx].index === slide.index) {
              item = status.outputs['U-Tags'].data[idx];
              break;
            }
          }

          let ideas = [];
          if (item.category) {
            ideas = item.category?.split(',');
          }
          this.slides[i] = {
            selected: i === 0,
            nodeCount: item.node,
            tags: item.keywords,
            ideas,
            theme_code: this.prezentationDetails?.templateSelected?.code,
            speaker_notes: item.speaker_notes ? item.speaker_notes : '',
            ...slide,
            ml_detected_tags: { ...item },
          };
          this.slidesArrayInitial[i] = {
            nodeCount: item.node,
            tags: item.keywords,
            ideas: ideas?.map((category) => ({
              display_name: categoriesRecords[category],
              category: category?.category || category,
            })),
            ...slide,
            theme_code: this.prezentationDetails?.templateSelected?.code,
            speaker_notes: item.speaker_notes ? item.speaker_notes : '',
          };
        });
        this.isLoadingTags = false;
      }
      this.slides = this.slides.map((slide, index) => {
        const maxNodeCount = Math.max(Number(slide.nodeCount), 10);
        slide.maxNodeCount = Array.from(
          { length: maxNodeCount },
          (_, i) => i + 1,
        );
        if (slide.ideas) {
          slide.ideas = slide.ideas?.map((category) => ({
            display_name: categoriesRecords[category],
            category: category?.category || category,
          }));
          slide.outline = slide?.outline || slide?.ideas[0]?.display_name;
          slide.category = slide?.category || slide?.ideas[0]?.category;
        }
        if (slide.selected) {
          this.selectedSlideIndex = index;
          this.selectedSlide = slide;
        }
        if (slide.ideas?.length === 0) {
          this.errorSlides[index] = true;
        }
        return slide;
      });
      this.tags = [...(this.selectedSlide?.tags || [])];

      this.$forceUpdate();
    }
  },
  async mounted() {
    this.componentEnterTime = Date.now();
    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
    if (window.ResizeObserver && document.getElementById('main-slide-id')) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(document.getElementById('main-slide-id'));
    }
    if (this.isTransferFlow) {
      this.templatesList = this.sortedThemeArray();
    } else {
      this.templatesList = this.prezentationDetails.templatesList || [];
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.slides-container {
  width: 100%;

  ::v-deep .v-input__icon {
    display: inline-flex;
  }

  .title,
  .sub-title,
  .virbage {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .title {
    font-size: 28px !important;
    font-family: 'Lato' !important;
    font-weight: bold;
  }

  .sub-title {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .virbage {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .center {
    display: flex;
    justify-content: center;

    .v-btn {
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      height: 40px !important;
      line-height: normal;
      letter-spacing: normal;
      min-width: 64px;
      text-transform: none !important;
    }
  }
  .mb-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .node {
    display: flex;
    font-size: 16px;
    margin-top: 6px;
    .node-text {
      padding-right: 10px;
    }
    .node-list {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }
    .node-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 28px;
      text-align: center;
      width: 28px;

      &:hover {
        background-color: $light-gray;
        border-radius: 20px;
      }
    }
    .outlined {
      background: $zen-blue-default;
      border-radius: 20px;
      color: white;
    }
    .disabled {
      color: #b6b6b6;
      pointer-events: none;
    }
  }
  .action-wrapper {
    margin-top: 10px;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    .show-detail {
      cursor: pointer;
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .selected-slide-block {
    margin-right: 20px;
    &__slide {
      border-radius: 10px;
    }
  }

  .title-section {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }
  .subtitle-section {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    &__title {
      font-weight: 200 !important;
    }
  }
  .no-of-slides-section {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }
  .right-section {
    label {
      font-size: 13px;
    }
  }
  .ideas-title-section {
    &__subtitle {
      font-size: 15px;
      color: #00000061;
    }
  }

  .primary-button {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }

  .selected-slide-block__slide {
    box-shadow: $item-box-shadow;
  }

  .node-count-title-section {
    padding-bottom: 20px;
    &__subtitle {
      font-size: 15px;
      color: #00000061;
    }
  }

  .tags-title-section {
    &__subtitle {
      font-size: 15px;
      color: #00000061;
    }
  }

  .selected-chips {
    background-color: white !important;
    color: $zen-blue-default;
    font-size: 14px;
    height: 25px;
    border: 1px solid $zen-blue-default;
    > button {
      color: $zen-blue-default;
    }
  }

  .v-select__selections {
    height: 36px;
  }

  ::v-deep .v-select__selections input {
    max-height: 25px !important;
  }

  ::v-deep .v-input__icon--clear {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  ::v-deep .v-chip__close {
    color: $zen-blue-default;
  }
  ::v-deep .v-input__icon > button {
    color: $zen-blue-default;
  }

  ::v-deep .v-slide-group__next {
    margin-right: -11px;
  }
  ::v-deep .v-slide-group__prev {
    margin-left: -25px;
  }

  .v-text-field {
    padding-top: 0px;
  }

  .related-products {
    display: flex;
    flex-direction: column;
    flex: 1;

    .related-products-header {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
    .related-products-thumbnails {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px;
      padding-top: 0px;
    }

    .product {
      border-radius: 8px;
      box-shadow: $item-box-shadow;
      cursor: pointer;
      margin-bottom: 18px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }

      &.error-border {
        border: 1px solid red;
      }

      &.selected {
        border: 1px solid #21a7e0;
      }
    }
    .product:hover {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3),
        -1px -1px 4px rgba(0, 0, 0, 0.3);
    }
  }

  .upload-prez-btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 !important;
    text-transform: none !important;
    color: white !important;
  }

  @-moz-document url-prefix() {
    .related-products-thumbnails {
      padding-right: 20px !important;
    }
    .right-section > div {
      padding-right: 20px !important;
    }
  }
}
.templates-dropdown {
  flex: none;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19px;
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-subheader {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none;
    font-family: 'Lato';
  }
  ::v-deep .v-list-item--disabled {
    pointer-events: unset;
  }
}
.template-header {
  display: grid;
  grid-template-columns: 1fr auto;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 14px;

  &__left {
    grid-template-columns: auto auto;
    display: grid;
    align-items: center;
    justify-content: start;
    gap: 8px;
  }
  &__right {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__marked {
    background: #21a7e0;
    cursor: text;
  }
  &__pinned {
    cursor: text;
  }
  .template-title {
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.template-list-item {
  padding: 0 16px;
  &--disabled {
    opacity: 0.6;
  }
}
.tip-bar {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  color: #7b7b7b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 54px;
  line-height: 17px;
  letter-spacing: 0.01em;
  p {
    height: 34px;
    padding: 15px;
  }
}
</style>
