<template>
  <div class="ws-container">
    <MyWorkSpace v-if="showWorkspace" />
    <WelcomeToMyWs
      :updateInProgress="updateInProgress"
      @onGetStartedClicked="handleGetStarted"
      v-else
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import WelcomeToMyWs from './WelcomeToMyWs.vue';
import MyWorkSpace from './MyWorkspace.vue';
import usersApi from '../../../API/users-api';
import EventBus from '../../common/event-bus';

export default {
  name: 'MyWorkspaceContainer',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.isWorkspaceEnabled) {
        next('/home');
      }
    });
  },
  data() {
    return {
      updateInProgress: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isWorkspaceFullAccess']),
    showWorkspace() {
      // TODO: Remove comment once dev is done
      // return true;
      return this.currentUser?.user?.isWorkspaceVisited;
    },
    isWorkspaceEnabled() {
      return this.isWorkspaceFullAccess;
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    handleGetStarted(updateUserWsFlag) {
      if (updateUserWsFlag) {
        this.updateInProgress = true;
        const updatedUserData = {
          isWorkspaceVisited: true,
        };
        usersApi.methods
          .updateUserProfile(updatedUserData)
          .then((updatedUser) => {
            this.updateInProgress = false;
            this.setCurrentUser({ ...this.currentUser, user: updatedUser });
          })
          .catch((err) => console.log(err));
      }
    },
  },
  components: {
    WelcomeToMyWs,
    MyWorkSpace,
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
  },
};
</script>

<style lang="scss" scoped>
.ws-container {
  display: flex;
  height: calc(100vh - 68px);
  flex-direction: column;
}
</style>
