<template>
  <div class="upload-to-ws-wrapper">
    <div class="modal-title">
      {{ $t('myWorkspace.createProjectModalTitle') }}
    </div>
    <div class="project-name-wrap">
      <span> Project name:</span>

      <v-text-field
        v-model="projectName"
        label="Project name"
        required
        single-line
        :rules="nameRules"
        color="#000"
        class="mb-4"
        id="projectName"
      />
    </div>
    <div class="desc-wrap">
      {{
        'Select existing files and add files or Google Drive links to your Project'
      }}
    </div>
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div
      class="upload-to-ws-wrapper-content"
      :class="showFilterFilesComp ? 'upload-grid' : ''"
    >
      <FilterMyWsFiles
        v-if="showFilterFilesComp"
        @onFileSelect="handleSelectedFiles"
      />
      <UploadFilesAndLinks @input="handleUploadUpdates" />
    </div>
    <div class="create-project-actions">
      <div class="upload-to-ws-action">
        <v-btn
          class="upload-to-ws-add-files"
          rounded
          color="#20a7e0"
          width="133px"
          height="32"
          :loading="inProgress"
          @click="handleCreateProject"
          :disabled="isCreateDisabled"
        >
          {{ 'Create project' }}
        </v-btn>
      </div>
      <div class="ws-confidentiality">
        <div>
          {{ $t('myWorkspace.confidentialityHeading') }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
        <div>
          {{ $t('myWorkspace.confidentialitySubHeading') }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFilesAndLinks from './UploadFilesAndLinks.vue';
import FilterMyWsFiles from './FilterMyWsFiles.vue';
import { MyWorkspaceService } from './MyWorkspaceService';

export default {
  name: 'CreateProjectModal',
  components: {
    UploadFilesAndLinks,
    FilterMyWsFiles,
  },
  data() {
    return {
      projectName: '',
      uploadedData: [],
      linkAccess: true,
      linkUploadError: false,
      inProgress: false,
      selectedFileIds: [],
      showFilterFilesComp: this.showFilterFiles,
      nameRules: [(v) => v.length < 255 || 'Maximum character limit exceeded'],
    };
  },
  props: {
    showFilterFiles: {
      type: Boolean,
      default: true,
    },
    updateLocalFiles: {
      type: Function,
      default: () => {},
    },
    updateLocalProjects: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isCreateDisabled() {
      return !(
        this.linkAccess &&
        this.projectName &&
        this.projectName.length < 255 &&
        (this.selectedFileIds.length > 0 || this.uploadedData.length > 0) &&
        !this.linkUploadError
      );
    },
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('CreateProjectModal');
    },
    handleSelectedFiles(data) {
      this.selectedFileIds = data;
    },
    handleCreateProject() {
      this.inProgress = true;
      this.myWorkspaceService
        .addFilesLinksToWs({
          type: 'projects',
          newFiles: this.uploadedData,
          title: this.projectName,
          fileIds: this.selectedFileIds,
        })
        .then((data) => {
          console.log('wsdata', data);
          this.updateLocalFiles();
          this.updateLocalProjects();
          setTimeout(() => {
            this.inProgress = false;
            this.handleClose();
          }, 1500);
        });
    },
    handleUploadUpdates(data) {
      console.log(data);
      let links = [];
      let files = [];
      if (data.files.length) {
        this.linkAccess = true;
        files = this.mapFilesArray(data.files);
      }
      if (data.fileLinks.length) {
        if (data.fileLinks.some((link) => link.error)) {
          this.linkUploadError = true;
        } else {
          if (data.fileLinks.every((link) => !link.error)) {
            this.linkUploadError = false;
          }
          if (data.fileLinks.length) {
            this.linkAccess = data.linkAccess;
          }
          links = this.mapLinksArray(data.fileLinks);
          this.uploadedFileLinkData = [...links];
        }
      }
      this.uploadedData = [...files, ...links];
    },
    mapFilesArray(files) {
      return files.map((file) => ({
        id: file.fileID,
        bucketName: file.s3Bucket,
        path: file.s3Prefix,
        sizeKb: file.size,
        fileName: file.fileName,
        numOfPages: file.numOfPages,
        type: file?.fileName?.split('.')[1],
        source: 'My Workspace',
        weblink: null,
      }));
    },
    mapLinksArray(links) {
      const fileTypeMap = {
        '.pptx': 'GSLIDE',
        '.docx': 'GDOC',
        '.xlsx': 'GSHEET',
        '.pdf': 'GPDF',
        '.txt': 'GTXT',
      };

      return links.map((link) => ({
        id: link.fileID,
        bucketName: link.s3Bucket,
        path: link.s3Prefix,
        sizeKb: link.size,
        fileName: link.fileName,
        numOfPages: link.numOfPages,
        source: 'My Workspace',
        type: fileTypeMap[link.type] || link.type,
        weblink: link.web_url,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-to-ws-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // justify-content: center;
  .modal-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-left: 25px;
    padding-top: 30px;
  }
  .project-name-wrap {
    display: flex;
    align-items: center;
    width: 50%;
    padding-left: 25px;
    span {
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-right: 10px;
    }

    ::v-deep .error--text {
      color: #f70000 !important;
      caret-color: #f70000 !important;
    }
  }
  .desc-wrap {
    padding-left: 25px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .upload-to-ws-wrapper-content {
    width: 100%;
    padding: 20px 25px;
    display: grid;
    column-gap: 20px;
    .upload-to-ws-image-wrapper {
      margin: auto;
      margin-top: 55px;
      margin-bottom: 22px;
      width: fit-content;
    }
    .upload-to-ws-desc {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 31px;
    }
  }
  .upload-grid {
    grid-template-columns: calc(33% - 10px) 66%;
  }
  .create-project-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .upload-to-ws-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 15px;
      .upload-to-ws-add-files {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        text-transform: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        margin-bottom: 10px;
      }
    }
    .ws-confidentiality {
      color: #7b7b7b;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      a {
        color: #21a7e0;
        cursor: pointer;
      }
      margin-bottom: 10px;
    }
  }
}
</style>
