var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[(!_vm.waitForAllPrezToLoad)?[(_vm.prezentations && _vm.prezentations.length > 0)?_c('v-item-group',{staticClass:"text-center",attrs:{"active-class":"primary"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.prezentationsInPage),function(prezentation,i){return _c('v-col',{key:((prezentation.id) + "__" + i),staticClass:"col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4",style:([
              _vm.$vuetify.breakpoint.xs ||
              _vm.$vuetify.breakpoint.sm ||
              _vm.$vuetify.breakpoint.md
                ? { 'min-width': '300px' }
                : {} ])},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('PrezentationCard',{attrs:{"prezentation":prezentation,"index":i,"active":active,"toggle-fn":toggle,"is-editable":_vm.isEditable,"on-clone-fn":_vm.onClone,"on-delete-fn":_vm.onDelete,"on-update-fn":_vm.onUpdate,"prezentation-status":_vm.prezentationStatus,"origin":_vm.origin,"thumbnailAsset":prezentation.thumbnailAsset || '',"advancedFilterActive":_vm.advancedFilterActive,"isSearchPage":_vm.isSearchPage},on:{"select":function($event){return _vm.$emit('select', {
                    prezentation: $event,
                    listComponentStateSnapshot: _vm.$data,
                  })}}})]}}],null,true)})],1)}),1)],1)],1):_vm._e(),(
        _vm.prezentations && _vm.prezentations.length && _vm.prezentationsInPage.length
      )?_c('v-btn',{staticClass:"loadmore-btn",staticStyle:{"text-transform":"none"},attrs:{"id":"loadmore-btn","color":"#21a7e0","rounded":"","outlined":"","height":"32","disabled":(_vm.prezentationsInPage.length >= _vm.prezentations.length &&
          !_vm.canLoadMore) ||
        _vm.isLoadingMore},on:{"click":_vm.loadNext}},[_vm._v(" "+_vm._s(_vm.canLoadMore ? _vm.$t('prezentationsList.seeMoreResult') : _vm.$t('prezentationsList.noMoreResult'))+" ")]):_vm._e(),(_vm.prezentations.length === 0)?_c('div',{staticClass:"empty-state__container"},[_vm._t("empty-state")],2):_vm._e()]:[_c('Loading')],_c('span')],2)}
var staticRenderFns = []

export { render, staticRenderFns }