<template>
  <div class="welcome-wrap">
    <div class="row1">
      <div class="ws-heading">
        {{ $t('myWorkspace.welcome.heading') }}
      </div>
      <div class="ws-sub-header">
        {{ $t('myWorkspace.welcome.subHeader') }}
      </div>
    </div>
    <div class="row2">
      <div class="col1">
        <div class="img-container">
          <v-img
            :aspect-ratio="16 / 9"
            src="../../../../public/assets/img/myWorkspace/effortless-file-mgmt.svg"
            alt="image"
            contain
          >
          </v-img>
        </div>
        <div class="ws-title">
          {{ $t('myWorkspace.welcome.fileMgmtTitle') }}
        </div>
        <div class="ws-desc">
          {{ $t('myWorkspace.welcome.fileMgmtDesc') }}
        </div>
      </div>
      <div class="col2">
        <div class="img-container">
          <v-img
            :aspect-ratio="16 / 9"
            src="../../../../public/assets/img/myWorkspace/seamless-org.svg"
            alt="image"
            contain
          >
          </v-img>
        </div>
        <div class="ws-title">
          {{ $t('myWorkspace.welcome.orgTitle') }}
        </div>
        <div class="ws-desc">
          {{ $t('myWorkspace.welcome.orgDesc') }}
        </div>
      </div>
      <div class="col3">
        <div class="img-container">
          <v-img
            :aspect-ratio="16 / 9"
            src="../../../../public/assets/img/myWorkspace/personalized-prez.svg"
            alt="image"
            contain
          >
          </v-img>
        </div>
        <div class="ws-title">
          {{ $t('myWorkspace.welcome.creationTitle') }}
        </div>
        <div class="ws-desc">
          {{ $t('myWorkspace.welcome.creationDesc') }}
        </div>
      </div>
    </div>

    <div class="row3">
      <div class="button-container">
        <v-btn
          elevation="1"
          class="get-started"
          color="#21a7e0"
          :loading="updateInProgress"
          @click="handleGetStarted"
        >
          {{ $t('myWorkspace.welcome.getStarted') }}
        </v-btn>
      </div>
      <div class="gs-privacy">
        <div>
          {{
            'We take confidentiality very seriously and we’ll keep your information secure.'
          }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
        <div>
          {{ 'We do not use confidential data for ML training purposes' }}
          <a
            href="https://www.prezent.ai/security"
            target="_blank"
            class="primary--text"
            >{{ $t('fingerprint.learnMore') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeToMyWs',
  props: {
    updateInProgress: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    handleGetStarted() {
      this.$emit('onGetStartedClicked', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-wrap {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 20px;
  margin: 20px;
  height: 100%;
}
.ws-heading {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ws-sub-header {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ws-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 10px;
}
.ws-desc {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.row1,
.row3 {
  display: grid;
  grid-template-rows: auto auto;
  grid-column: span 3;
  text-align: center;
}
.row2 {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 10px));
  grid-column: span 3;
  column-gap: 10px;
}
.col1,
.col2,
.col3 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
  .img-container {
    width: 100%;
    padding-bottom: 10px;
    .v-img {
      width: 100%;
      height: auto;
    }
  }
}
.button-container {
  padding: 20px;
  margin: 10px;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: center;
  .get-started {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 22px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}
.gs-privacy {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #697077;

  a {
    color: #21a7e0;
    cursor: pointer;
  }
}
</style>
