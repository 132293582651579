var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-ws-card-wrap",on:{"click":_vm.handleCardClick}},[_c('div',{staticClass:"card-data"},[_c('div',{staticClass:"icon-wrap"},[_c('WsFileIcon',{attrs:{"height":'58px',"width":'58px',"fileName":_vm.myWsCard.fileName || '',"iconType":_vm.cardType}})],1),_c('div',{staticClass:"card-text"},[_c('p',{staticClass:"card-title"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.myWsCard.fileName || _vm.myWsCard.title)+" ")])]}}])},[_c('div',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.myWsCard.fileName || _vm.myWsCard.title)+" ")])])],1),_c('div',{staticClass:"author-count-wrap"},[_c('p',{staticClass:"card-author"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.authorLabel', { authorName: _vm.currentUser.user.id === _vm.myWsCard.userID ? _vm.$t('build.step1.me') : _vm.myWsCard.userID, }))+" ")]),(_vm.cardType === 'project')?[_c('div',[_vm._v("  |  ")]),_c('p',[_vm._v(" "+_vm._s(_vm.myWsCard.filesCount)+" "+_vm._s('files')+" ")])]:_vm._e()],2),_c('p',{staticClass:"card-date"},[_vm._v(" "+_vm._s(((_vm.$t('myWorkspace.uploadedOnLabel')) + " " + (_vm.formatDateWithMoment( _vm.myWsCard.createdAt ))))+" ")])])]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"permission"}),_c('div',{staticClass:"fav-container"},[(_vm.isChangeFavoriteInProgress === true)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":22}}):[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isFavorite)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleAddWsCardAsFav($event, _vm.cardData)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleRemoveWsCardAsFav($event, _vm.cardData)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(("" + (_vm.cardData.isFavorite ? _vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites') : _vm.$t('bestPracticesGuide.knowSlideCard.addFavorites'))))+" ")])])]],2),(_vm.getActions.length)?_c('v-menu',{key:_vm.menuKey,attrs:{"top":"","absolute":"","offset-y":"","close-on-click":true,"close-on-content-click":_vm.closeEllipsisMenu,"position-x":_vm.menuPos.x,"position-y":_vm.menuPos.y},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},Object.assign({}, menu.on, tooltip.on)),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.moreActions')))])])]}}],null,false,907564574)},[_c('v-list',_vm._l((_vm.getActions),function(action,index){return _c('v-list-item',{key:index,attrs:{"disabled":action.disabled},on:{"click":function($event){return _vm.handleDotOptionClick(action.name)}}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"otherActions"},on),[_c('div',{staticClass:"icon",class:action.disabled ? 'disable-icon' : ''},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1),_c('div',{staticClass:"actionName"},[_vm._v(" "+_vm._s(_vm.$t(action.display_title))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(action.display_title))+" ")])])],1)],1)}),1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }