<template>
  <div class="op-wrapper" id="op-wrapper">
    <div class="tabs-wrapper">
      <v-tabs
        color="#21a7e0"
        height="57"
        slider-size="7"
        grow
        v-model="currentTab"
      >
        <v-tab
          class="vtab"
          href="#overview"
          @click="setTab('overview')"
          id="op-overview"
          data-pendo-id="opOverviewTab"
        >
          <b>{{ $t('overnightPresentations.overview') }}</b>
        </v-tab>
        <v-tab
          :disabled="disableNewReq"
          class="vtab"
          href="#newRequest"
          @click="setTab('newRequest')"
          id="op-new-request"
          data-pendo-id="opNewRequestTab"
        >
          <b>{{ $t('overnightPresentations.newRequest') }}</b>
        </v-tab>
        <v-tab
          :disabled="getOPAccessLevel === 'no_access'"
          class="vtab"
          href="#myRequest"
          @click="setTab('myRequest')"
          id="op-my-request"
          data-pendo-id="opMyRequestTab"
        >
          <b>{{ $t('overnightPresentations.myRequest') }}</b>
        </v-tab>
        <v-tab-item value="overview" :key="tabKey">
          <Overview @contactAdmin="contactAdmin"></Overview>
        </v-tab-item>
        <v-tab-item value="newRequest" :key="tabKey + 1">
          <NewRequest></NewRequest>
        </v-tab-item>
        <v-tab-item value="myRequest" class="tab-item" :key="tabKey + 2">
          <MyRequest class="my-req" :key="renderKey"></MyRequest>
        </v-tab-item>
      </v-tabs>
    </div>
    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="btnText"
      :title="$t('overnightPresentations.contactAdmin')"
      :text="dialogText"
      :isConfirming="isConfirming"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NewRequest from './NewRequest.vue';
import Overview from './Overview.vue';
import MyRequest from './MyRequest.vue';
import RequestAccess from './RequestAccess.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import ContactSoonModal from '../../common/ContactSoonModal.vue';
import ThankYouModal from './ThankYouModal.vue';
import EventBus from '../../common/event-bus';
import {
  getFeatureUsage,
  getLowFundFlag,
  requestAddFunds,
  sendAccessRequest,
} from '../../../utils/api-helper';
import usersApi from '../../../API/users-api';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';

export default {
  name: 'OvernightPrezentationContainer',
  components: {
    NewRequest,
    Overview,
    MyRequest,
    ConfirmationDialog,
  },
  data() {
    return {
      currentTab: 'overview',
      showDialog: false,
      isLowFunds: false,
      dialogText:
        'This will send a request to your team admin to enable Overnight Prezentations.',
      btnText: 'Send request',
      isConfirming: false,
      tabKey: 0,
      renderKey: 0,
    };
  },
  watch: {
    '$route.query': {
      async handler(profileTabs) {
        const { tab } = profileTabs;
        if (tab !== this.currentTab) {
          this.currentTab = tab;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('users', ['getOPAccessLevel', 'getOPLimitedBanner']),
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
    disableNewReq() {
      if (
        this.getOPAccessLevel === 'restricted_access' &&
        this.limitsInfoObj?.op?.isLimitCrossed
      ) {
        return true;
      }
      return (
        !!this.getOPLimitedBanner ||
        ['disabled', 'limited_access', 'no_access'].includes(
          this.getOPAccessLevel,
        ) ||
        (this.getOPAccessLevel === 'restricted_access' &&
          this.limitsInfoObj?.op?.isLimitCrossed)
      );
    },
    getText() {
      if (this.getOPAccessLevel === 'disabled') {
        return 'Your access to Overnight Prezentations is disabled.';
      }
      if (this.getOPAccessLevel === 'restricted_access') {
        if (!this.limitsInfoObj?.op?.isLimitCrossed) {
          return '';
        }
        return 'Your trial of the Overnight Prezentations service has ended.';
      }
      if (this.getOPAccessLevel === 'limited_access') {
        return "Your team's access to Overnight Prezentations is disabled.";
      }
      return "You'll need to add funds to submit an Overnight Prezentations request.";
    },
    getSubText() {
      if (this.getOPAccessLevel === 'disabled') {
        if (this.currentUser.user.role.toLowerCase() === 'admin') {
          return 'To get full access, upgrade your subscription.';
        }
        return 'Please contact your team admin to request access.';
      }
      if (this.getOPAccessLevel === 'restricted_access') {
        if (this.currentUser.user.role.toLowerCase() === 'admin') {
          return 'To get full access, upgrade your subscription.';
        }
        return 'To enable your services, contact your admin';
      }
      if (this.getOPAccessLevel === 'limited_access') {
        if (this.currentUser.user.role.toLowerCase() === 'admin') {
          return 'Contact us to enable this service.';
        }
        return 'Contact your admin to enable this service.';
      }
      return null;
    },
  },
  async mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    await this.setIsLowFund();
    EventBus.$on('RE-RENDER-MY-REQUESTS', this.rerender);
  },
  methods: {
    ...mapActions('users', ['setOPFundLow', 'setCurrentUser', 'setLimitData']),
    rerender() {
      this.renderKey++;
      console.log('renderKey', this.renderKey);
    },
    setTab(tabName) {
      this.$router
        .push({
          path: `/home/overnight-prezentations?tab=${tabName}`,
          replace: true,
        })
        .catch(() => {});
      switch (tabName) {
        case 'overview':
          trackOvernightPrezentationsEvents.OPOverviewClick(this.currentUser);
          break;
        case 'newRequest':
          trackOvernightPrezentationsEvents.OPNewRequestClick(this.currentUser);
          break;
        case 'myRequest':
          trackOvernightPrezentationsEvents.OPMyrequestsClick(this.currentUser);
          break;
        default:
          break;
      }
      this.currentTab = tabName;
      this.tabKey++;
    },
    async setIsLowFund() {
      try {
        const response = await getLowFundFlag();
        this.setOPFundLow(response.data.isLowFund);
      } catch (err) {
        console.log(err);
      }
    },
    gotoPayment() {
      this.$modal.hide('RequestAccess');
      this.$router
        .push({
          path: `/profile?tab=admin&popup=team-add-fund`,
        })
        .catch(() => {});
    },
    contactAdmin(lowFunds = false) {
      this.isLowFunds = lowFunds;
      if (lowFunds) {
        if (this.currentUser.user.role.toLowerCase() === 'admin') {
          this.$modal.show(
            RequestAccess,
            {
              text: "You'll need to add funds to submit an Overnight Prezentations request.",
              subText: '',
              btnText: 'Add funds',
              btnClicked: this.gotoPayment,
            },
            {
              name: 'RequestAccess',
              width: '340px',
              height: '340px',
              styles: {
                borderRadius: '12px',
              },
            },
          );
        } else {
          this.dialogText =
            'This will send a request to your team admin to add funds.';
          this.btnText = 'Request funds';
          this.showDialog = true;
        }
      } else {
        this.$modal.show(
          RequestAccess,
          {
            text: this.getText,
            subText: this.getSubText,
            btnText:
              this.currentUser.user.role.toLowerCase() === 'admin'
                ? 'Get in touch'
                : 'Request access',
            btnClicked: this.requestAccess,
          },
          {
            name: 'RequestAccess',
            width: '340px',
            height:
              this.getOPAccessLevel === 'restricted_access' &&
              this.currentUser.user.OPRequestCount === 0
                ? '340px'
                : '390px',
            styles: {
              borderRadius: '12px',
            },
          },
        );
      }
    },
    async requestAccess() {
      if (this.currentUser.user.role.toLowerCase() === 'admin') {
        this.isConfirming = true;
        await sendAccessRequest(this.getOPAccessLevel);
        this.isConfirming = false;
        this.$modal.hide('RequestAccess');
        this.$modal.show(
          ContactSoonModal,
          {
            origin: 'op',
          },
          {
            name: 'ContactSoonModal',
            width: '449px',
            height: '523px',
            styles: {
              borderRadius: '12px',
            },
          },
        );
      } else {
        this.$modal.hide('RequestAccess');
        this.dialogText =
          'This will send a request to your team admin to enable Overnight Prezentations.';
        this.btnText = 'Send request';
        this.showDialog = true;
      }
    },
    async onDialogConfirm() {
      if (this.isLowFunds) {
        this.isConfirming = true;
        await requestAddFunds();
        this.isConfirming = false;
      } else {
        this.isConfirming = true;
        await sendAccessRequest(this.getOPAccessLevel);
        this.isConfirming = false;
      }
      this.showDialog = false;
      this.$modal.show(
        ThankYouModal,
        {
          text: this.isLowFunds
            ? 'We sent your admin a request to add funds.'
            : 'We sent your Overnight Prezentations access request to your admin.',
        },
        {
          name: 'ThankYouModal',
          width: '340px',
          height: '390px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    onDialogCancel() {
      this.showDialog = false;
    },
  },
  async beforeMount() {
    if (!this.getOPAccessLevel || this.getOPAccessLevel === 'no_access') {
      this.$router.push('/home', () => {});
    } else {
      const tab = this.$route?.query?.tab;
      if (tab && tab === 'newRequest' && this.disableNewReq) {
        this.$router.push({
          path: `/home/overnight-prezentations?tab=overview`,
          replace: true,
        });
      } else {
        this.currentTab = tab;
      }

      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => console.log(error));
      const userData = await usersApi.methods.getUserInfo();
      this.setCurrentUser(userData);
    }
  },
};
</script>
<style lang="scss" scoped>
.op-wrapper {
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.op-wrapper::-webkit-scrollbar {
  display: none;
}

.vtab {
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: normal;
  &:hover {
    text-decoration: none;
  }
}
.tabs-wrapper {
  margin: auto;
  margin-top: 15px;

  ::v-deep .v-tabs-slider-wrapper {
    height: 2px !important;
  }

  .fingerprint-wrapper {
    ::v-deep .v-tabs-slider-wrapper {
      height: 48px !important;
    }
  }

  .v-tab {
    text-transform: none !important;
    color: black;
  }

  // ::v-deep .tab-content {
  //   overflow-y: auto;
  // }

  ::v-deep .v-window {
    overflow: visible;
  }
}
</style>
