<template>
  <div class="pt-rename">
    <v-text-field
      v-model="name"
      class="pt-rename__control"
      :rules="rules"
      :error-messages="errorMessage"
      :error="!!errorMessage"
      @keydown="onRenameKeypress()"
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              small
              color="primary"
              :disabled="!name || theme.name === name || !!errorMessage"
              @click="handleRename()"
              :loading="loading"
            >
              <v-icon class="pt-rename__actionicon">mdi-check</v-icon>
            </v-btn>
          </template>
          <span>"Save" to confirm changes</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              small
              color="primary"
              @click="close()"
              :disabled="loading"
            >
              <v-icon class="pt-rename__actionicon">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>"Cancel" to discard changes</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { updateThemeName } from '../../../../utils/api-helper';

export default {
  name: 'TemplateRenameControl',
  props: {
    theme: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: null,
      rules: [(value) => !!value || 'Required.'],
      errorMessage: null,
      loading: false,
    };
  },
  mounted() {
    this.name = this.theme.name;
  },
  computed: {
    ...mapState('users', ['themes']),
  },
  methods: {
    ...mapActions('users', ['setFilteredThemes']),
    async handleRename() {
      this.loading = true;
      const payload = {
        themeId: this.theme.id,
        themeName: this.name,
      };
      try {
        await updateThemeName(payload);
        const themes = cloneDeep(this.themes);
        const currentTheme = themes.find((theme) => theme.id === this.theme.id);
        currentTheme.name = this.name;
        this.setFilteredThemes(themes);
        this.close();
      } catch (error) {
        if (error.response.data.isAlreadyExists === true) {
          this.errorMessage = 'Duplicate template name already exists';
        }
      } finally {
        this.loading = false;
      }
    },
    onRenameKeypress() {
      this.errorMessage = null;
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.pt-rename {
  &__control {
    margin-top: 0;
    padding-top: 0;
  }
  &__actionicon {
    font-size: 20px !important;
  }
}
</style>
