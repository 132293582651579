<template>
  <v-card class="pt-list__item pt-card">
    <div class="pt-card__figure">
      <div class="pt-card__figurecontent">
        <TemplateInProgress v-if="isInProgress" :theme="theme" />
        <template v-else>
          <a @click="onPreview()">
            <img class="pt-card__thumbnail" :src="theme.thumbnailURL" />
            <span
              v-if="
                theme.isMarked ||
                (theme.isNew && currentUser.user.createdAt < theme.createdAt)
              "
              class="pt-card__badgewrapper"
            >
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
                v-if="theme.isMarked"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    class="pt-card__marked"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white" small>
                      mdi-account-heart-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Template recommended by your team admin</span>
              </v-tooltip>
              <span
                class="pt-card__badge"
                v-if="
                  theme.isNew && currentUser.user.createdAt < theme.createdAt
                "
              >
                {{ $t('filters.new') }}
              </span>
            </span>
            <span v-if="theme.isDisabled" class="pt-card__figurelock">
              <v-icon large color="#000">mdi-lock</v-icon>
            </span>
            <span v-if="theme.isArchived" class="pt-card__figurelock">
              <v-icon large color="#000">mdi-archive</v-icon>
            </span>
          </a>
        </template>
      </div>
    </div>
    <div class="pt-card__meta">
      <div class="pt-card__details">
        <div class="pt-card__detailsleft">
          <TemplateRenameControl
            :theme="theme"
            v-if="rename"
            @close="rename = false"
          />
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <h4 class="pt-card__title" v-bind="attrs" v-on="on">
                {{ theme.name }}
              </h4>
            </template>
            <span>
              {{ theme.name }}
            </span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="
              theme.isUserUploaded &&
              theme.uploadedByName &&
              theme.uploadedByName.trim().length
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <p class="pt-card__subtitle" v-bind="attrs" v-on="on">
                Created by {{ theme.uploadedByName }}
              </p>
            </template>
            <span> Created by {{ theme.uploadedByName }} </span>
          </v-tooltip>
        </div>
        <span class="pt-card__titleicon">
          <img
            src="/assets/img/prezent_avatar.svg"
            alt
            width="22px"
            v-if="theme.isPrezentTemplate"
          />
        </span>
        <div
          :class="[
            'pt-card__actions',
            (theme.isDisabled || theme.isArchived || isInProgress) &&
              'pt-card__actions--disabled',
          ]"
        >
          <div class="pt-card__action">
            <v-tooltip
              bottom
              max-width="200"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="0"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading.pin"
                  @click="handlePin()"
                >
                  <v-icon>
                    {{ theme.isPinned ? 'mdi-pin' : 'mdi-pin-outline' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ theme.isPinned ? 'Unpin' : 'Pin' }}</span>
            </v-tooltip>
          </div>
          <div class="pt-card__action">
            <v-tooltip
              bottom
              max-width="200"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="0"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading.download"
                  @click="handleDownload()"
                >
                  <v-icon color="primary"> mdi-tray-arrow-down </v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
          </div>
          <div class="pt-card__action" v-if="isAdminWithViewerAccess">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="0"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="onMarkOrUnmark()">
                  <v-list-item-icon style="margin: 12px 12px 12px 0">
                    <v-icon>mdi-account-heart-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title v-if="theme.isMarked">
                    Unmark as team template
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    Mark as team template
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="onEnableOrDisable()">
                  <v-list-item-icon style="margin: 12px 12px 12px 0">
                    <v-icon>mdi-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Disable for team </v-list-item-title>
                </v-list-item>
                <v-list-item @click="onRename()" v-if="isAdminWithEditorAccess">
                  <v-list-item-icon style="margin: 12px 12px 12px 0">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Rename </v-list-item-title>
                </v-list-item>
                <v-list-item @click="onShare()" v-if="isAdminWithEditorAccess">
                  <v-list-item-icon style="margin: 12px 12px 12px 0">
                    <v-icon>mdi-account-multiple-plus-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Share </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="onArchiveOrRestore()"
                  v-if="isAdminWithEditorAccess"
                >
                  <v-list-item-icon style="margin: 12px 12px 12px 0">
                    <v-icon>mdi-archive</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Archive </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="pt-card__primaryactions">
        <template v-if="theme.isDisabled">
          <v-btn
            class="pt-card__primarybtn"
            color="primary"
            outlined
            rounded
            v-if="isAdminWithViewerAccess"
            @click="onEnableOrDisable()"
          >
            Enable for team
          </v-btn>
          <v-btn
            class="pt-card__primarybtn"
            color="primary"
            outlined
            rounded
            :loading="loading.requestAccess"
            v-else
            @click="handleRequestAccess()"
          >
            Request access
          </v-btn>
        </template>
        <v-btn
          class="pt-card__primarybtn"
          color="primary"
          outlined
          rounded
          v-else-if="theme.isArchived && isAdminWithEditorAccess"
          @click="onArchiveOrRestore()"
        >
          Restore
        </v-btn>
        <div
          v-else-if="currentUser.theme.code === theme.code"
          class="pt-card__cselection"
          data-pendo-id="selected-template"
        >
          <v-icon color="primary">mdi-check-circle</v-icon>
          <span>Current Selection</span>
        </div>
        <v-btn
          class="pt-card__primarybtn"
          color="primary"
          outlined
          rounded
          @click="onSelect()"
          :disabled="isInProgress"
          v-else
        >
          Select
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  updateThemeMarker,
  sendEmail,
  requestThemeAccess,
} from '../../../../utils/api-helper';
import TemplateInProgress from './TemplateInProgress.vue';
import SlideThemeChangeDetail from '../SlideThemeChangeDetail.vue';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import usersApi from '../../../../API/users-api';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';
import { TD_TEMPLATE } from '../../../common/Analytics/MatomoTrackingDataHelper';
import EventBus from '../../../common/event-bus';
import GetInTouchDetail from '../GetInTouchDetail.vue';
import ConfirmationDialogLite from '../../../common/ConfirmationDialogLite.vue';
import ShareTemplateAccess from '../ShareTemplateAccess.vue';
import TemplateRenameControl from './TemplateRenameControl.vue';
import TemplatePreviewPopup from '../../../../pages/components/TemplateDesigner/TemplatePreviewPopup.vue';
import { TemplateService } from '../../../../services/TemplateService';
import RequestAccessModal from './RequestAccessModal.vue';

export default {
  name: 'TemplateCard',
  components: {
    TemplateInProgress,
    TemplateRenameControl,
  },
  props: {
    theme: {
      type: Object,
      default: () => {},
    },
    isInProgress: Boolean,
  },
  data() {
    return {
      loading: {
        pin: false,
        mark: false,
        disable: false,
        archive: false,
        download: false,
        requestAccess: false,
      },
      rename: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    isAdminWithEditorAccess() {
      const role = this.currentUser.user.role.toLowerCase();
      return (
        this.theme.permissionLevel === 'Editor' &&
        (role === 'admin' || role === 'trialuser')
      );
    },
    isAdminWithViewerAccess() {
      const role = this.currentUser.user.role.toLowerCase();
      return (
        (this.theme.permissionLevel === 'Viewer' ||
          this.theme.permissionLevel === 'Editor') &&
        (role === 'admin' || role === 'trialuser')
      );
    },
  },
  methods: {
    ...mapActions('users', [
      'updateThemeMarkers',
      'setCurrentUser',
      'setCurrentTheme',
    ]),
    async handlePin() {
      this.$set(this.loading, 'pin', true);
      const payload = {
        themeId: this.theme.id,
        marker: 'pinned',
        value: !this.theme.isPinned,
      };
      await updateThemeMarker(payload);
      this.updateThemeMarkers({ ...payload, marker: 'isPinned' });
      this.$set(this.loading, 'pin', false);
    },
    async handleDownload() {
      this.$set(this.loading, 'download', true);
      await TemplateService.download(
        this.theme.code,
        this.currentUser.company.name,
        this.theme.name,
      );
      this.$set(this.loading, 'download', false);
    },
    async handleMarkAsTeam() {
      const payload = {
        themeId: this.theme.id,
        marker: 'marked',
        value: !this.theme.isMarked,
      };
      await updateThemeMarker(payload);
      this.updateThemeMarkers({ ...payload, marker: 'isMarked' });
    },
    async handleDisable() {
      if (this.theme.isDefault) {
        // Send request if company default template is being disabled
        const params = {
          type: 'template-disable-request',
          meta: {
            themeName: this.theme.name,
          },
        };
        await sendEmail(params);
        this.showThankYouDialog();
      } else {
        const payload = {
          themeId: this.theme.id,
          marker: 'disabled',
          value: !this.theme.isDisabled,
        };
        await updateThemeMarker(payload);
        if (this.theme.id === this.currentUser.theme.id && payload.value) {
          await this.setCompanyDefaultTemplateAsCurrentTemplate();
        }
        this.updateThemeMarkers({ ...payload, marker: 'isDisabled' });
      }
    },
    async handleArchive() {
      if (this.theme.isDefault) {
        const params = {
          type: 'template-archive-request',
          meta: {
            themeName: this.theme.name,
          },
        };
        await sendEmail(params);
        this.showThankYouDialog();
      } else {
        const payload = {
          themeId: this.theme.id,
          marker: 'archived',
          value: !this.theme.isArchived,
        };
        await updateThemeMarker(payload);
        if (this.theme.id === this.currentUser.theme.id && payload.value) {
          await this.setCompanyDefaultTemplateAsCurrentTemplate();
        }
        this.updateThemeMarkers({ ...payload, marker: 'isArchived' });
      }
    },
    async handleRequestAccess() {
      this.$set(this.loading, 'requestAccess', true);
      try {
        await requestThemeAccess({ themeId: this.theme.id });
        const props = {
          templateName: this.theme.name,
        };
        const layout = {
          name: 'RequestAccessModal',
          width: 350,
          height: 360,
          styles: { borderRadius: '20px' },
        };
        this.$modal.show(RequestAccessModal, props, layout);
      } catch (err) {
        console.error(err);
      } finally {
        this.$set(this.loading, 'requestAccess', false);
      }
    },
    async updateThemeIndex(theme) {
      try {
        MatomoAnalyticsHandler.templateSwitched(this.currentUser, theme);
        trackProfileEvents.profileTemplatesSwitch(this.currentUser, {
          [TD_TEMPLATE]: theme?.name,
        });

        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = { ...userInfo.user, ...updatedUserDetail };
            userInfo.theme = theme;
            await this.setCurrentUser(userInfo);
            EventBus.$emit('CLEAR_SEARCH');
          });
        this.setCurrentTheme(theme);
      } catch (err) {
        console.error(err);
      }
    },
    async setCompanyDefaultTemplateAsCurrentTemplate() {
      const companyDefaultTemplate = this.themes.find(
        (theme) => theme.isDefault,
      );
      const userInfo = this.currentUser;
      userInfo.user = {
        ...userInfo.user,
        defaultThemeID: companyDefaultTemplate.id,
      };
      userInfo.theme = cloneDeep(companyDefaultTemplate);
      await this.setCurrentUser(userInfo);
    },
    onSelect() {
      this.$modal.show(
        SlideThemeChangeDetail,
        {
          index: this.theme,
          updateThemeIndex: this.updateThemeIndex,
        },
        {
          name: 'SlideThemeChangeDetail',
          width: '540px',
          height: '165px',
          styles: { borderRadius: '18px' },
        },
      );
    },
    onMarkOrUnmark() {
      const formats = {
        mark: {
          title: 'Mark as team template',
          text: `The “${this.theme.name}” template will be tagged as recommended template for your team members.`,
          btn: 'Mark',
        },
        unmark: {
          title: 'Unmark as team template',
          text: `The template “${this.theme.name}” will no longer be tagged as recommended template for your team members.`,
          btn: 'Unmark',
        },
      };
      const format = this.theme.isMarked ? formats.unmark : formats.mark;
      this.showConfirmationDialog(format, 'handleMarkAsTeam');
    },
    onArchiveOrRestore() {
      const formats = {
        default: {
          title: `Cannot archive default template`,
          text: 'By default, everyone in your company has access to this template and it cannot be archived.',
          subText: 'Need help?',
          btn: 'Get in touch',
        },
        archive: {
          title: 'Archive Template',
          text: `The “${this.theme.name}” template will not be visible to any user in your company.`,
          btn: 'Archive',
        },
        restore: {
          title: 'Restore Template',
          text: `The “${this.theme.name}” template will be visible to all users in your company.`,
          btn: 'Restore',
        },
      };
      let format = this.theme.isArchived ? formats.restore : formats.archive;

      if (this.theme.isDefault) {
        format = formats.default;
      }

      this.showConfirmationDialog(format, 'handleArchive');
    },
    onEnableOrDisable() {
      const formats = {
        default: {
          title: 'Cannot disable default template',
          text: 'By default, everyone in your company has access to this template and it cannot be disabled.',
          subText: 'Need help?',
          btn: 'Get in touch',
        },
        disable: {
          title: 'Disable template for team',
          text: `The “${this.theme.name}” template will not be accessible to your team members.`,
          btn: 'Disable',
        },
        enable: {
          title: 'Enable template for team',
          text: `The “${this.theme.name}”  template will be accessible to your team members.`,
          btn: 'Enable',
        },
      };
      let format = this.theme.isDisabled ? formats.enable : formats.disable;

      if (this.theme.isDefault) {
        format = formats.default;
      }

      this.showConfirmationDialog(format, 'handleDisable');
    },
    onRename() {
      const format = {
        title: 'Rename template',
        text: `The “${this.theme.name}” template will be renamed for all users in your company.`,
        btn: 'Rename',
      };
      this.showConfirmationDialog(format, 'showRenameControl');
    },
    onShare() {
      this.$modal.show(
        ShareTemplateAccess,
        {
          templateName: this.theme.name,
          templateId: this.theme.id,
          companyName: this.currentUser.company.displayName,
        },
        {
          name: 'ShareTemplateAccess',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    onPreview() {
      if (this.theme.isDisabled || this.theme.isArchived) {
        return;
      }
      this.$modal.show(
        TemplatePreviewPopup,
        {
          selectedCard: this.theme,
          fetchTemplateMetadata: true,
          showActions: true,
        },
        {
          name: 'TemplatePreviewPopup',
          width: '780px',
          height: '700px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    showRenameControl() {
      this.rename = true;
    },
    showThankYouDialog() {
      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'profile.shareTemplateAccess.thanks',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    showConfirmationDialog(format, handler) {
      this.$modal.show(
        ConfirmationDialogLite,
        {
          title: format.title,
          text: format.text,
          subText: format.subText,
          primaryButtonText: format.btn,
          secondaryButtonText: 'Cancel',
          handler: this[handler],
        },
        {
          name: 'ConfirmationDialogLite',
          width: '550',
          height: '210',
          styles: { borderRadius: '20px' },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.pt-card__subtitle {
  white-space: nowrap;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pt-card {
  display: grid;
  grid-template-rows: auto 1fr;
  transition: all 0.2s;
  border-radius: 10px;

  &:hover {
    box-shadow: -4px 4px 25px 1px #0000004d;
  }

  &__figure {
    position: relative;
    padding: 10px;
  }
  &__figurecontent {
    aspect-ratio: 16 / 9;
  }
  &__figurelock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 10px 10px 0px 0px;
  }
  &__thumbnail {
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    width: 100%;
  }
  &__badgewrapper {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    padding: 13px 14px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__badge {
    display: block;
    background: $new-tag-bg;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 18px;
    z-index: 1;
    color: #fff;
  }

  &__marked {
    background: #21a7e0;
    cursor: text;
  }

  &__meta {
    display: grid;
    align-content: space-between;
    border-top: 1px solid #e2e2e2;
    min-height: 80px;
  }

  &__details {
    padding: 6px 14px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 8px;
  }

  &__detailsleft {
    overflow: hidden;
  }

  &__titleicon {
    min-width: 22px;
  }

  &__title {
    font-size: 16px;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__subtitle {
    color: #444444;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__primaryactions {
    padding: 0 12px 12px;
    text-align: center;
  }

  &__primarybtn {
    text-transform: unset;
    font-weight: 600;
    border-width: 1.5px;
    font-size: 15px;
    font-family: Lato;
    letter-spacing: normal;
  }

  &__cselection {
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 10px 0px;
  }
}
</style>
