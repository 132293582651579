<template>
  <div class="upload-wrap">
    <div class="upload-file-wrap">
      <div class="upload-title">
        <img :src="'/assets/img/laptop.svg'" alt />
        <div class="panelHeader">{{ $t('myWorkspace.localFilesTitle') }}</div>
      </div>

      <div class="uploadContent">
        <div class="uploadHeading">
          {{
            'Select your files (Supported file extensions: .pdf, docx, .pptx, or .xlsx)'
          }}
        </div>
        <div class="sizeDesc">
          <span>
            {{
              'You can add multiple files at a time. Individual files should be under 200MB'
            }}
          </span>
        </div>
        <div class="file-upload-action">
          <label for="file-upload" class="custom-file-upload">
            <span>
              {{ 'Select files' }}
            </span>
          </label>
          <input
            id="file-upload"
            type="file"
            ref="hiddenInput"
            :accept="`${allowedUploadTypes.join(', ')}`"
            @change="previewFiles"
            multiple
          />
        </div>
        <div class="upload-chips-wrapper">
          <div
            class="upload-placeholder"
            v-if="!currVal.files || currVal.files.length === 0"
            @click="uploadFileClick"
          >
            {{ 'Your selected files will be displayed here.' }}
          </div>
          <template v-else>
            <div class="chips-list">
              <div
                class="file-chip"
                v-for="(lFile, i) in currVal.files"
                :key="i"
                :style="{
                  height: `${getFileItemsHeight(lFile)}`,
                }"
              >
                <div class="fileNameWrapper">
                  {{ lFile.fileName ? lFile.fileName : lFile.name }}
                </div>
                <v-icon :size="18" :color="'#7B7B7B'" @click="removeFile(i)"
                  >mdi-close</v-icon
                >
              </div>
            </div>
            <div class="clear-button-cont">
              <v-btn
                text
                class="clear-button"
                rounded
                v-if="currVal.files && currVal.files.length > 0"
                color="#20a7e0"
                height="30"
                @click="onClearFiles()"
                >{{ $t('common.clear') }}</v-btn
              >
            </div>
          </template>
        </div>
        <!-- <div v-if="currVal.files.length > 3" class="file-error error--text">
          Please note adding more than three files may increase the slide
          generation time
        </div> -->
      </div>
    </div>
    <div class="upload-link-wrap">
      <div class="upload-title">
        <img :src="'/assets/img/link-variant.svg'" alt />
        <div class="panelHeader">{{ $t('myWorkspace.webLinksTitle') }}</div>
      </div>
      <div class="linkHeading">
        {{ 'Add your Google Drive links (Slides, Sheets, or Docs)' }}
        <p class="link-sub-heading">
          {{
            'For any private Google document links, please share access with '
          }}
          <span class="blue-highlight">
            {{ 'files@prezent.ai' }}
          </span>
        </p>
      </div>
      <v-form ref="form" lazy-validation>
        <v-textarea
          v-model="link"
          @keydown.enter.prevent="onEnter"
          @paste.prevent="onPaste"
          class="share-links"
          solo
          :rules="linkRules"
          :placeholder="'Paste links to Google Slides, Sheets, or Docs.'"
          :no-resize="true"
        >
          <template #prepend-inner v-if="currVal.links.length">
            <div class="links-container">
              <template v-for="(link, i) in currVal.links">
                <div
                  class="file-chip"
                  :class="`${link.error ? 'link-error' : ''}`"
                  :key="link.web_id"
                >
                  <div class="fileNameWrapper">
                    {{ link.web_url }}
                  </div>

                  <v-icon :size="18" :color="'#7B7B7B'" @click="removeLink(i)"
                    >mdi-close</v-icon
                  >
                </div>
              </template>
            </div>
          </template>
          <template #append>
            <v-btn
              text
              class="clear-button"
              rounded
              v-if="currVal.links && currVal.links.length > 0"
              color="#20a7e0"
              height="30"
              @click="clearLinks"
              >{{ $t('common.clear') }}</v-btn
            >
          </template>
        </v-textarea>
      </v-form>
      <div
        :class="`${
          currVal.links.length > 0 && !currVal.linkAccess
            ? 'check-access-error'
            : 'check-access'
        }`"
      >
        <v-checkbox
          v-model="currVal.linkAccess"
          @change="onChange"
          :hide-details="true"
          color="#20a7e0"
        >
          <template v-slot:label>
            <span v-html="checkboxLabel"></span>
          </template>
        </v-checkbox>
        <span class="guide-link" @click="handleHowtoShareAccess">
          {{ 'How to share access?' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import UploadMultiFiles from './UploadMultiFiles.vue';
import HowToShareAccess from '../GenerateSlides/magic-fill/HowToShareAccess.vue';

export default {
  name: 'UploadFilesAndLinks',
  components: {},
  data() {
    return {
      checkboxLabel:
        'I confirm that all links above are accessible to <span style="font-weight: 600; color: #21a7e0;">files@prezent.ai</span>',
      sharedAccess: false,
      allowedUploadTypes: [
        '.ppt',
        '.pptx',
        '.doc',
        '.docx',
        '.txt',
        '.pdf',
        '.xlsx',
      ],
      currVal: {
        files: [],
        links: [],
        fileLinks: [],
        linkAccess: false,
      },
      link: '',
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
        () =>
          this.currVal.links.length <= 3 ||
          'Please note adding more than three links may increase the slide generation time',
      ],
    };
  },
  methods: {
    clearLinks() {
      this.currVal.links = [];
      this.currVal.fileLinks = [];
      this.link = '';
      this.currVal.linkAccess = false;
      this.$refs.form.validate();
      this.onChange();
    },
    onClearFiles() {
      this.currVal.files = [];
      this.onChange();
    },
    removeFile(i) {
      this.currVal.files.splice(i, 1);
      this.onChange();
    },
    onChange(e) {
      console.log(e);
      this.$emit('input', { ...this.currVal });
    },
    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '32px';
        }
        return 'auto';
      }
      return file.name.length <= 40 ? '32px' : 'auto';
    },
    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },
    uploadFileClick() {
      this.$refs.hiddenInput[0].click();
    },
    updateLocalFiles(val) {
      const filesToUpload = [...this.currVal.files, ...val];
      this.currVal.files = filesToUpload;
      this.onChange();
    },
    updateLocalLinks(val) {
      const currLinks = [...this.currVal.links];
      currLinks.forEach((bItem) => {
        const isErrorItem = val.find(
          (aItem) => aItem.web_url === bItem.web_url,
        );

        if (isErrorItem) {
          bItem.error = isErrorItem.error || false;
        }
      });
      this.currVal.links = currLinks;
      const filesToUpload = [...this.currVal.fileLinks, ...val];
      this.currVal.fileLinks = filesToUpload;
      this.onChange();
    },
    onPaste(evt) {
      if (this.isValidHttpUrl(evt.clipboardData.getData('text/plain'))) {
        const linkItem = {
          requestIdentifier: uuidv4(),
          web_url: evt.clipboardData.getData('text/plain'),
        };
        this.currVal.links.push(linkItem);
        this.link = '';
        this.currVal.linkAccess = false;
        if (linkItem.web_url) {
          this.$modal.show(
            UploadMultiFiles,
            {
              link: linkItem,
              updateUploadedLinks: this.updateLocalLinks,
            },
            {
              name: 'UploadMultiFiles',
              width: '600px',
              height: 'auto',
              styles: {
                'max-height': '45%',
                'max-width': '600px',
                'border-radius': '12px',
                overflow: 'auto',
              },
              clickToClose: false,
            },
          );
        }
      } else {
        this.link = evt.clipboardData.getData('text/plain');
      }
      this.$refs.form.validate();
    },
    onEnter() {
      if (this.isValidHttpUrl(this.link)) {
        const linkItem = {
          requestIdentifier: uuidv4(),
          web_url: this.link,
        };
        this.currVal.links.push(linkItem);
        this.link = '';
        this.currVal.linkAccess = false;
        this.$modal.show(
          UploadMultiFiles,
          {
            link: linkItem,
            updateUploadedLinks: this.updateLocalLinks,
          },
          {
            name: 'UploadMultiFiles',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '8px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        this.$refs.form.validate();
      }
    },
    isValidHttpUrl(string) {
      if (!string || string.trim().length === 0) {
        return false;
      }
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },
    removeLink(i) {
      this.currVal.links.splice(i, 1);
      this.currVal.fileLinks.splice(i, 1);
      if (this.currVal.fileLinks.length === 0) {
        this.currVal.linkAccess = false;
      }
      this.onChange();
      this.$refs.form.validate();
    },

    handleHowtoShareAccess() {
      this.$modal.show(
        HowToShareAccess,
        { imgPath: '/assets/img/myWorkspace/share_access_files.png' },
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          width: '900px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    async previewFiles(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.currVal.files, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          UploadMultiFiles,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
            size: this.currVal.files.reduce((acc, curr) => acc + curr.size, 0),
          },
          {
            name: 'UploadMultiFiles',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '8px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-wrap {
  // display: flex;
  /* width: 50%; */
  /* flex-basis: 50%; */
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  background: white;
  column-gap: 20px;
  .upload-file-wrap {
    display: flex;
    flex-direction: column;
    border: 1px solid #dde1e6;
    border-radius: 8px;
    padding: 15px 15px 20px 15px;
    .upload-title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        height: 40px;
        width: 40px;
      }
      .panelHeader {
        color: #075689;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    .uploadContent {
      .uploadHeading {
        font-size: 16px;
        margin: 0px 0px 16px 0px;
        line-height: 20px;
        margin-bottom: 2px;
      }
      .file-error {
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        padding: 0 12px;
      }
      .sizeDesc {
        font-size: 16px;
        margin: 0px 0px 16px 0px;
        line-height: 20px;
      }
      .file-upload-action {
        margin: 24px 0px 24px 0px;
        input[type='file'] {
          display: none;
        }

        .custom-file-upload {
          span {
            gap: 8px;
            padding: 6px 16px;
            height: 31px;
            border: 1.5px solid #21a7e0;
            border-radius: 25px;
            font-size: 16px;
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #21a7e0;
            cursor: pointer;
            letter-spacing: normal;
          }
        }
      }
      .upload-chips-wrapper {
        .upload-placeholder {
          font-size: 16px;
          color: #757575;
        }
        position: relative;
        padding: 12px;
        padding-bottom: 40px;
        height: 140px;
        border-radius: 6px;
        background: white;
        // filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
        //   drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
          -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        .chips-list {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          overflow: auto;
          width: 100%;
        }
      }
    }
  }
  .upload-link-wrap {
    display: flex;
    flex-direction: column;
    border: 1px solid #dde1e6;
    border-radius: 8px;
    padding: 15px 15px 20px 15px;
    .upload-title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        height: 40px;
        width: 40px;
      }
      .panelHeader {
        color: #075689;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    .linkHeading {
      font-size: 16px;
      margin: 0px 0px 16px 0px;
      line-height: 20px;
      .link-sub-heading {
        margin-bottom: 0;
        .blue-highlight {
          font-family: Lato;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #21a7e0;
        }
      }
    }
    .linkLabel {
      margin: 0px 0px 8px 0px;
      font-weight: 600;
      font-size: 16px;
    }
    .v-messages__message {
      line-height: 14px;
      word-break: normal;
      overflow-wrap: normal;
      word-wrap: normal;
      hyphens: none;
    }

    .check-access {
      margin-top: 16px;
      .guide-link {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #21a7e0;
        margin-left: 30px;
        margin-top: 4px;
        display: inline-block;
        cursor: pointer;
      }

      ::v-deep .v-input {
        margin-top: 0;
        padding-top: 0;
        .v-input__control {
          .v-input__slot {
            align-items: flex-start;
            .v-input--selection-controls__input {
              margin-right: 6px;
              .v-icon {
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            .v-label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }
      }
    }
    .check-access-error {
      margin-top: 16px;
      .guide-link {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #21a7e0;
        margin-left: 30px;
        margin-top: 4px;
        display: inline-block;
        cursor: pointer;
      }

      ::v-deep .v-input {
        margin-top: 0;
        padding-top: 0;
        .v-input__control {
          .v-input__slot {
            align-items: flex-start;
            .v-input--selection-controls__input {
              margin-right: 6px;
              .v-icon {
                svg {
                  width: 20px;
                  height: 20px;
                  color: red;
                }
              }
            }
            .v-label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
.share-links {
  ::v-deep .v-input__control {
    .v-input__slot {
      margin: 0;
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      // box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
      //   -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
      textarea::placeholder {
        color: #757575;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
.file-chip {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  max-width: 395px;
  align-items: center;
  // height: 32px;
  gap: 8px;
  position: relative;
  background: #ffffff;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
  //   -1px -1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #71787e !important;
  border-radius: 8px;
  cursor: default;
  color: #41484d !important;
  height: min-content;

  // &:hover {
  //   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1),
  //     -1px -1px 8px rgba(0, 0, 0, 0.1);
  // }

  .fileNameWrapper {
    max-width: calc(100% - 24px);
    line-break: anywhere;
  }

  i {
    cursor: pointer;
  }
}
.link-error {
  border: 1px solid red !important;
  color: red !important;
  button {
    color: red !important;
  }
}
.clear-button {
  position: absolute;
  bottom: 5px;
  left: 0;
  margin-left: 8px;
  padding: 5px;
  font-family: 'Lato';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: none;
}
.links-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
</style>
