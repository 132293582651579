<template>
  <div class="ws-search-bar">
    <v-text-field
      :value="searchModel"
      append-icon=""
      single-line
      hide-details
      :placeholder="$t('myWorkspace.mainPage.searchPlaceHolder')"
      class="ws-search-bar__input pl-4 ws-search-bar--auto"
      @input="(e) => handleSearchChange(e)"
      @focus="searchWithTextBoxActive = true"
      @blur="searchWithTextBoxActive = false"
    >
      <template #append>
        <v-icon
          class="icon"
          v-text="`mdi-close`"
          @click="clearTextFieldSearch"
          name="prez-cross-icon"
          color="#21a7e0"
          v-show="searchModel.length > 0"
        />
        <v-icon
          class="icon"
          v-text="`mdi-magnify`"
          name="prez-magnify-glass"
          :color="searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'SearchMyWorkspace',
  props: {
    onSearch: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      searchModel: '',
      searchWithTextBoxActive: false,
    };
  },
  methods: {
    handleSearchChange(e) {
      this.searchModel = e;
      this.$emit('onSearch', this.searchModel);
    },
    clearTextFieldSearch() {
      this.handleSearchChange('');
    },
  },
};
</script>

<style lang="scss" scoped>
.ws-search-bar__input {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  display: flex;
  font-size: 14px;
  height: 40px !important;
  padding: 2px 14px 8px 20px;
  width: 380px;
  margin: 0px 42px 0px 0px;
  z-index: 3;
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin: 0;
        padding: 4px 0px 0px 4px;
      }
      .v-text-field__slot {
        input {
          caret-color: black !important;
        }
      }
    }
  }

  ::v-deep .v-input__slot::before {
    border-style: none !important;
    display: none;
  }
  ::v-deep .v-input__slot::after {
    border: none !important;
    display: none;
  }

  ::v-deep .v-input__icon {
    margin-right: -8px;
  }
}
</style>
