var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{key:_vm.searchKey,staticClass:"learn-landing-wrapper"},[_c('div',{staticClass:"learn-type-wrapper"},[_c('v-sheet',{attrs:{"max-width":"100%"}},[_c('v-chip-group',{attrs:{"show-arrows":"","mandatory":"","cols":"1"}},_vm._l((_vm.learnCourseChips),function(keyword,index){return _c('v-chip',{key:index,class:_vm.learnCourseChipClicked.toLowerCase() ===
            keyword.display_name.toLowerCase()
              ? 'learnCourseChipActive'
              : 'learnCourseChipAvailable',attrs:{"outlined":"","name":keyword.display_name,"exact-active-class":""},on:{"click":function (e) { return _vm.clickChipIdeas(e, keyword); }}},[_vm._v(" "+_vm._s(keyword.display_name)+" ")])}),1)],1)],1),_c('div',{staticClass:"learn-search-row"},[_c('learnSuggestions',{attrs:{"items":_vm.itemFiltered,"placeholder":_vm.$t('learn.searchPlaceHolder'),"minLen":0,"item-template":_vm.headerItemTemplate,"set-label":_vm.setLabel,"values":_vm.searchModel},on:{"changed":_vm.debounceInputChange,"click":_vm.onClickHandle,"selected":_vm.itemSelected,"enter":_vm.debounceOnEnterKeyPress},scopedSlots:_vm._u([{key:"searchSlot",fn:function(){return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.crossButton),expression:"crossButton"}],staticClass:"icon",attrs:{"name":"learn-cross-icon","color":"#21a7e0"},domProps:{"textContent":_vm._s("mdi-close")},on:{"click":_vm.clearSearch}}),_c('v-icon',{staticClass:"icon",attrs:{"name":"learn-magnify-glass","color":_vm.isSearchColor},domProps:{"textContent":_vm._s("mdi-magnify")},on:{"click":_vm.debounceOnEnterKeyPress}})]},proxy:true}],null,false,4226630038),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),(
      _vm.getTheCoursesList.problemSolving.courses.length > 0 &&
      (_vm.learnCourseChipClicked === 'All' ||
        _vm.learnCourseChipClicked === 'Problem-Solving')
    )?_c('div',{staticClass:"learn-card-wrapper"},[_c('div',{staticClass:"learn-landing-sub-header"},[_vm._v(" "+_vm._s(_vm.domains.problemSolving.display_name)+" ")]),_c('v-row',{staticClass:"learn-landing-items"},_vm._l((_vm.getTheCoursesList.problemSolving.courses),function(item,index){return _c('v-col',{key:index,attrs:{"lg":4,"md":6,"sm":12}},[_c('CourseCard',{attrs:{"course-info":item,"domain":_vm.getTheCoursesList.problemSolving.display_name,"learnSnapShot":_vm.createLearnSnapShot,"isActiveCourse":_vm.isActive(item),"openPanel":_vm.getTheCoursesList.problemSolving.expandPanel ? true : false,"data-pendo-id":index === 0 ? 'learn-card' : null},on:{"removeActiveCourse":function($event){return _vm.removeActiveCourse()}}})],1)}),1)],1):_vm._e(),(
      _vm.getTheCoursesList.storyLining.courses.length > 0 &&
      (_vm.learnCourseChipClicked === 'All' ||
        _vm.learnCourseChipClicked === 'Storylining')
    )?_c('div',{staticClass:"learn-card-wrapper"},[_c('div',{staticClass:"learn-landing-sub-header"},[_vm._v(" "+_vm._s(_vm.domains.storyLining.display_name)+" ")]),_c('v-row',{staticClass:"learn-landing-items"},_vm._l((_vm.getTheCoursesList.storyLining.courses),function(item,index){return _c('v-col',{key:index,attrs:{"lg":4,"md":6,"sm":12}},[_c('CourseCard',{attrs:{"course-info":item,"domain":_vm.getTheCoursesList.storyLining.display_name,"learnSnapShot":_vm.createLearnSnapShot,"isActiveCourse":_vm.isActive(item),"openPanel":_vm.getTheCoursesList.storyLining.expandPanel ? true : false},on:{"removeActiveCourse":function($event){return _vm.removeActiveCourse()}}})],1)}),1)],1):_vm._e(),(
      _vm.getTheCoursesList.creatingPresentations.courses.length > 0 &&
      (_vm.learnCourseChipClicked === 'All' ||
        _vm.learnCourseChipClicked === 'Building Presentations')
    )?_c('div',{staticClass:"learn-card-wrapper"},[_c('div',{staticClass:"learn-landing-sub-header"},[_vm._v(" "+_vm._s(_vm.domains.creatingPresentations.display_name)+" ")]),_c('v-row',{staticClass:"learn-landing-items"},_vm._l((_vm.getTheCoursesList.creatingPresentations
          .courses),function(item,index){return _c('v-col',{key:index,attrs:{"lg":4,"md":6,"sm":12}},[_c('CourseCard',{attrs:{"course-info":item,"domain":_vm.getTheCoursesList.creatingPresentations.display_name,"learnSnapShot":_vm.createLearnSnapShot,"isActiveCourse":_vm.isActive(item),"openPanel":_vm.getTheCoursesList.creatingPresentations.expandPanel ? true : false},on:{"removeActiveCourse":function($event){return _vm.removeActiveCourse()}}})],1)}),1)],1):_vm._e(),(
      _vm.getTheCoursesList.deliveringPresentations.courses.length > 0 &&
      (_vm.learnCourseChipClicked === 'All' ||
        _vm.learnCourseChipClicked === 'Delivering Presentations')
    )?_c('div',{staticClass:"learn-card-wrapper"},[_c('div',{staticClass:"learn-landing-sub-header"},[_vm._v(" "+_vm._s(_vm.domains.deliveringPresentations.display_name)+" ")]),_c('v-row',{staticClass:"learn-landing-items"},_vm._l((_vm.getTheCoursesList.deliveringPresentations
          .courses),function(item,index){return _c('v-col',{key:index,attrs:{"lg":4,"md":6,"sm":12}},[_c('CourseCard',{attrs:{"course-info":item,"domain":_vm.getTheCoursesList.deliveringPresentations.display_name,"learnSnapShot":_vm.createLearnSnapShot,"isActiveCourse":_vm.isActive(item),"openPanel":_vm.getTheCoursesList.deliveringPresentations.expandPanel
              ? true
              : false},on:{"removeActiveCourse":function($event){return _vm.removeActiveCourse()}}})],1)}),1)],1):_vm._e(),(_vm.getNoResultsFound)?_c('div',{staticClass:"no-outline-access"},[_vm._m(0),_c('p',{staticClass:"no-access-text"},[_vm._v(" "+_vm._s(_vm.$t('build.step1.storylineFieldNoResult'))+" ")]),_c('div',{staticClass:"go-to-course-btn"},[_c('v-btn',{staticClass:"go-to-btn",attrs:{"rounded":"","height":"36","min-width":"64","color":"#21a7e0","primary":""},on:{"click":_vm.clearSearch}},[_vm._v(_vm._s(_vm.$t('learn.goToCourses'))+" ")])],1)]):_vm._e(),(_vm.isLoading)?_c('div',[_c('Loading',{attrs:{"classes":'loading-spinner'}})],1):_vm._e()]):_c('div',[_c('Loading')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-center img"},[_c('img',{attrs:{"src":"/assets/img/no-results-found-generic.svg","alt":""}})])}]

export { render, staticRenderFns }