<template>
  <div class="template-spinner-wrapper" v-if="loadingThemes">
    <Loading v-if="loadingThemes" />
  </div>
  <div v-else class="pt-wrapper">
    <div class="back-btn-cntr" v-if="previousRoute === 'search'">
      <v-btn class="btn-back" @click="goToSearchLandingPage">
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        <span>{{ $t('slides.backToSearch') }}</span>
      </v-btn>
    </div>
    <div class="pt-header pt-wrapper__header">
      <div class="pt-wrapper__headercontent">
        <div class="pt-header__title">
          {{ $t('profile.templates') }}
          <div class="pt-header__titleicon">
            <v-tooltip
              right
              max-width="250"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="icon-title"
                  v-text="`mdi-information-outline`"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t('profile.chooseTheTemplate') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-header pt-wrapper__header">
      <div class="pt-wrapper__headercontent">
        <div class="pt-search">
          <v-text-field
            solo
            v-model="templateSearch"
            single-line
            hide-details
            class="pt-search__scontrol"
            autocomplete="off"
            :placeholder="
              !!globalSearchResult
                ? $t('profile.displaying')
                : $t('profile.searchTemplates')
            "
            :disabled="globalSearchResult"
            @input="handleSearchInput()"
            @focus="searchFocused = true"
            @blur="searchFocused = false"
          >
            <template v-slot:append>
              <v-icon
                :color="searchFocused ? 'primary' : undefined"
                @click="resetSearch()"
              >
                {{
                  !!globalSearchResult || templateSearch
                    ? `mdi-close`
                    : `mdi-magnify`
                }}
              </v-icon>
            </template>
          </v-text-field>
          <v-btn
            @click="handleAddMore()"
            color="primary"
            rounded
            class="pt-search__addmore"
            data-pendo-id="add-new-template"
          >
            <span>{{ 'Add more templates' }}</span>
          </v-btn>
        </div>
        <div class="pt-quickfilters">
          <v-chip-group
            show-arrows
            mandatory
            cols="1"
            class="ptq"
            v-model="sectionFilterValue"
          >
            <v-chip
              class="ptq__tab"
              value="ALL"
              :outlined="'ALL' != sectionFilterValue"
              color="#21a7e0"
            >
              All
            </v-chip>
            <template v-for="section in themeSections">
              <v-chip
                v-if="
                  section.themes.length ||
                  (section.type === 'BRAND_TEMPLATES' &&
                    !!designerThemesVisible.length)
                "
                class="ptq__tab"
                :key="section.type"
                :value="section.type"
                :outlined="section.type != sectionFilterValue"
                color="#21a7e0"
              >
                {{ chipLabels[section.type] }}
              </v-chip>
            </template>
          </v-chip-group>
        </div>
      </div>
    </div>
    <div class="pt-wrapper__body">
      <TemplateList
        :sections="themeSections"
        :designerThemes="designerThemesVisible"
        :class="[(globalSearchResult || templateSearch) && 'd-none']"
        :sectionFilterValue="sectionFilterValue"
      />
      <TemplateList
        :sections="searchThemeSections"
        :designerThemes="searchDesignerThemes"
        :class="[!(globalSearchResult || templateSearch) && 'd-none']"
        @addMoreTemplate="addMoreTemplate()"
      />
      <div
        id="go-top"
        class="go-top"
        v-show="showGoTop"
        v-scroll="onScroll"
        @click="toTop"
      >
        <v-icon color="#fff" size="15" class="top-arrow"> mdi-arrow-up</v-icon>
        <span>{{ $t('common.backToTop') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import Loading from '../../common/Loading.vue';
import GetInTouchDetail from './GetInTouchDetail.vue';
import AddingMoreTemplateDetail from './AddingMoreTemplateDetail.vue';
import AddNewTDPopup from './AddNewTDPopup.vue';
import commonApi from '../../../API/common-api';
import {
  sendEmail,
  logSearchQuery,
  createTemplateRequest,
} from '../../../utils/api-helper';
import EventBus from '../../common/event-bus';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import { TD_QUERY } from '../../common/Analytics/MatomoTrackingDataHelper';
import { designerTemplateLimit } from '../../../pages/components/TemplateDesigner/constants.js';
import TemplateList from './Templates/TemplateList.vue';
import { getFileURL } from '../../../utils/calyrex';

export default {
  name: 'TemplateSelector',
  components: {
    Loading,
    TemplateList,
  },
  data() {
    return {
      previousRoute: '',
      companyName: null,
      loadingThemes: true,
      templateSearch: '',
      user: {},
      globalSearchResult: false,
      searchThemeSections: [],
      searchDesignerThemes: [],
      sectionFilterValue: 'ALL',
      searchFocused: false,
      chipLabels: {
        CURRENT_SELECTION: 'Current selection',
        PINNED_TEMPLATES: 'My Pinned Templates',
        BRAND_TEMPLATES: `Brand Templates`,
        PREZENT_TEMPLATES: 'Prezent Templates',
        ARCHIVE_TEMPLATES: 'Archived Templates',
      },
      designerThemesVisible: [],
      showGoTop: false,
    };
  },
  props: {
    selectedTab: {
      type: String,
      required: true,
      default: 'basics',
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'themes',
      'themeSections',
    ]),
    ...mapState('templateDesigner', ['templateDesignerData']),
    ...mapGetters('templateDesigner', ['getTDData']),
    ...mapGetters('users', ['getIsNonBrandedCompany']),
    isTDFeatureEnabled() {
      const feature = this.currentUser.features.find(
        (f) => f.feature_name === 'template-designer',
      );
      return feature && feature.enabled;
    },
    isNonBrandedTrialUser() {
      return (
        this.currentUser?.user?.role?.toLowerCase() === 'trialuser' &&
        this.currentUser?.company?.isNonBranded &&
        this.currentUser?.subscriptions?.[0]?.daysLeft >= 0
      );
    },
  },
  async mounted() {
    this.setDesignerThemesVisible();

    this.previousRoute = this.$router.app.previousRoute.name;
    EventBus.$on('GOTO_TEMPLATES', (query) => {
      this.templateSearch = query;
    });
    this.loadingThemes = true;

    await this.updateThemes();
    if (this.$route.query.query) {
      this.searchInitialize();
    }
    this.companyName = this.getCompanyName();
    this.setCurrentTheme(this.currentTheme);
    this.loadingThemes = false;

    this.chipLabels.BRAND_TEMPLATES = `${this.companyName} Brand Templates`;
  },
  watch: {
    $route() {
      if (this.$route.query.query) {
        this.searchInitialize();
      }
    },
    selectedTab() {
      if (this.selectedTab === 'templates') {
        this.searchThemeSections = [];
        this.searchDesignerThemes = [];
        this.globalSearchResult = false;
      }
    },
    themes() {
      if (this.globalSearchResult) {
        this.searchInitialize();
      } else {
        this.handleSearch();
      }
    },
    templateSearch() {
      this.handleSearch();
      this.handleSearchEventTrack();
    },
    templateDesignerData() {
      this.setDesignerThemesVisible();
    },
    sectionFilterValue: {
      immediate: false,
      handler() {
        this.templateSearch = '';
        this.globalSearchResult = false;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentTheme',
      'loadThemes',
      'setFilteredThemes',
    ]),
    goToSearchLandingPage() {
      this.$router.back();
    },
    async setDesignerThemesVisible() {
      const themes = [];
      for (const theme of this.templateDesignerData) {
        const isVisible =
          theme.status === 'failed' ||
          (theme.jobSubmitted &&
            theme.status === 'inprogress' &&
            theme.percentage < 100);
        if (isVisible && theme.thumbnail?.filepath) {
          theme.thumbnailURL = await getFileURL(
            this.currentUser.user.id,
            theme?.thumbnail?.filepath,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
        if (isVisible) {
          themes.push(theme);
        }
      }
      this.designerThemesVisible = themes;
    },
    handleSearch() {
      if (!this.globalSearchResult) {
        const designerThemes = this.designerThemesVisible.filter(({ name }) =>
          name.toLowerCase().includes(this.templateSearch.toLowerCase()),
        );
        const themes = this.themes.filter(({ name }) =>
          name.toLowerCase().includes(this.templateSearch.toLowerCase()),
        );
        this.searchDesignerThemes = designerThemes;
        this.searchThemeSections = [
          {
            type: 'SEARCH',
            header: null,
            themes,
          },
        ];
      }
    },
    handleSearchInput() {
      this.sectionFilterValue = 'ALL';
    },
    onScroll(event) {
      this.showGoTop = event.target.scrollingElement.scrollTop > 400;
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    // eslint-disable-next-line func-names
    handleSearchEventTrack: debounce(function () {
      this.trackTemplateSearchEvent(this.templateSearch.trim());
    }, 1000),
    async updateThemes() {
      const themes = await commonApi.methods.getThemesList();
      const filteredThemes = themes.filter(
        (item) => item.companyID === this.currentUser.user.companyID,
      );
      this.setFilteredThemes(filteredThemes);
    },
    trackTemplateSearchEvent(searchTerm) {
      trackProfileEvents.profileTemplatesSearch(this.currentUser, {
        [TD_QUERY]: searchTerm,
      });
    },
    resetSearch() {
      this.templateSearch = '';
      this.globalSearchResult = false;
      this.searchThemeSections = [];
      this.searchDesignerThemes = [];
      if (this.$route.fullPath !== '/profile?tab=templates') {
        this.$router.push('/profile?tab=templates');
      }
      EventBus.$emit('CLEAR_SEARCH');
    },
    searchInitialize() {
      const logPayload = {
        searchTerm: this.$route.query.query,
        searchTermResult: false,
        type: 'Templates',
      };
      const filteredArray = this.themes.filter(({ name }) =>
        name.toLowerCase().includes(this.$route.query.query.toLowerCase()),
      );
      this.globalSearchResult = true;
      this.templateSearch = this.$route.query.query;
      this.searchThemeSections = [
        {
          type: 'GLOBAL_SEARCH',
          header: null,
          themes: filteredArray,
        },
      ];
      if (filteredArray.length) {
        logPayload.searchTermResult = true;
      }
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    },
    // displayBadgeInTheme(theme) {
    //   if (theme) {
    //     const currentUserCreatedDate = this.currentUser?.user?.createdAt;
    //     const currentDate = moment(new Date());
    //     const expiryInDays = 15;
    //     const templateReleaseDate = moment(theme.createdAt, 'YYYY-MM-DD');
    //     if (moment(currentUserCreatedDate).isAfter(templateReleaseDate)) {
    //       return false;
    //     }
    //     const duration = moment.duration(currentDate.diff(templateReleaseDate));
    //     return duration.asDays() >= 0 && duration.asDays() <= expiryInDays;
    //   }
    //   return false;
    // },
    handleAddMore() {
      if (
        this.isTDFeatureEnabled &&
        this.isNonBrandedTrialUser &&
        this.getTDData.length < designerTemplateLimit
      ) {
        this.$modal.show(
          AddNewTDPopup,
          {
            templateRequestFrom: 'Profile',
          },
          {
            name: 'AddNewTDPopup',
            width: '400px',
            height: '346.91px',
            styles: { borderRadius: '12px' },
          },
        );
      } else {
        this.$modal.show(
          AddingMoreTemplateDetail,
          {
            templateRequestFrom: 'Profile',
          },
          {
            name: 'AddingMoreTemplateDetail',
            width: '564px',
            height: '507px',
            styles: { borderRadius: '12px' },
          },
        );
      }
    },

    addMoreTemplate() {
      // template request call
      createTemplateRequest({
        requestFrom: 'Profile',
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });

      // email api call
      try {
        const mBody =
          `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}(${this.currentUser.user.id})` +
          ` has expressed interest in additional templates`;
        const params = {
          type: 'template-request',
          meta: {
            message: mBody,
            requester: this.currentUser.user.firstName,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }

      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thanksForYourInterestInTemplates',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },
    getCompanyName() {
      if (this.currentUser.company.displayName)
        return (
          this.currentUser.company.displayName.charAt(0).toUpperCase() +
          this.currentUser.company.displayName.slice(1)
        );
      return (
        this.currentUser.company.name.charAt(0).toUpperCase() +
        this.currentUser.company.name.slice(1)
      );
    },
  },
  beforeDestroy() {
    EventBus.$off('GOTO_TEMPLATES');
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.pt-wrapper {
  padding: 0 0 0;

  &__headercontent {
    padding: 0 28px;
  }

  &__body {
    padding: 0 28px;
    text-align: left;
  }
}

.pt-header {
  background: #fff;
  padding: 20px 0 10px;
  position: sticky;
  top: 68px;
  z-index: 2;

  &__title {
    align-items: center;
    display: flex;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    justify-content: flex-start;
    line-height: normal;
    letter-spacing: normal;
  }

  &__titleicon {
    margin-left: 8px;
    .icon {
      font-size: 24px;
    }
  }
}

.pt-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  gap: 10px;

  &__scontrol {
    max-width: 500px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    margin-bottom: 0;
    margin-top: 0px;
    z-index: 3;
    ::v-deep .v-input__slot {
      box-shadow: none !important;
      .v-text-field__slot {
        input {
          color: #464a4c;
        }
        input::placeholder {
          color: #464a4c;
        }
      }
    }
    ::v-deep .v-input__slot::before {
      border: unset;
    }
  }

  &::v-deep .v-input--is-disabled {
    pointer-events: unset;
  }

  &__addmore {
    text-transform: unset;
    font-weight: 600;
    letter-spacing: normal;
    font-size: 15px;
  }
}

.ptq {
  &__tab {
    border-color: #21a7e0 !important;
    border-radius: 40px;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    &.v-chip--active {
      background: #21a7e0 !important;
      color: #fff;
    }
  }
}

.back-btn-cntr {
  width: fit-content;

  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff !important;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }
}
.template-spinner-wrapper {
  height: 300px;
}
.go-top {
  position: fixed;
  bottom: 6px;
  left: 48%;
  background-color: #21a7e0;
  padding: 3px 10px;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff !important;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-shadow: 0 -1px 5px 1px rgb(64 60 67 / 16%);
  .top-arrow {
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 14px;
  }
}
</style>
