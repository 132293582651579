<template>
  <div class="requestChangeModalContainer">
    <div class="closeModalIconContainer">
      <v-icon @click="handleModalClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="contentContainer">
      <div class="modalHeaderContainer">
        {{
          `${
            !isReqSubmitted
              ? $t('overnightPresentations.changeRequest')
              : $t('overnightPresentations.requestSubmitted')
          }`
        }}
      </div>
      <div class="initiatingRequest" v-if="!isReqSubmitted">
        <div class="instructions">
          <div class="instructionsHeader">
            {{ $t('overnightPresentations.detailsOfChanges') }}
          </div>
          <v-textarea
            v-model="instructions"
            class="instructionsArea"
            solo
            :label="$t('overnightPresentations.typeChangeDetails')"
            :no-resize="true"
          />
          <div class="instructionsHelperText">
            {{ $t('overnightPresentations.pleaseTypeInstructions') }}
          </div>
        </div>
        <div class="uploads">
          <div class="uploadsHeader">
            {{ $t('overnightPresentations.uploadFiles') }}
          </div>
          <div class="uploadsSubHeader">
            {{ $t('overnightPresentations.provideDocumets') }}
          </div>
          <div class="uploadsSubHeader">
            {{ $t('overnightPresentations.confidentiality') }}
          </div>
          <div class="uploadFilesAndLinksContainer">
            <div class="header">
              {{ $t('overnightPresentations.localFiles') }}
            </div>
            <img :src="'/assets/img/local-files.png'" />
            <div class="desc">
              <div>{{ $t('overnightPresentations.filesUpload') }}</div>
              <div>
                <span>
                  {{ $t('overnightPresentations.maximumFileSize') }}
                  <v-tooltip
                    right
                    max-width="300"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="icon-title"
                        v-text="`mdi-information-outline`"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>
                      {{ $t('overnightPresentations.fileType') }}
                    </span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <div class="user-input">
              <div class="label">
                {{ $t('overnightPresentations.addAsManyAsYouNeed') }}
              </div>
              <div class="file-chips-wrapper">
                <template v-if="localFiles.length">
                  <div
                    class="file-chip"
                    :class="{ 'size-error': lFile.size / 1024 / 1024 > 200 }"
                    v-for="(lFile, i) in localFiles"
                    :key="i"
                    :style="{
                      height: `${getFileItemsHeight(lFile)}`,
                    }"
                  >
                    <div class="fileNameWrapper">
                      {{ lFile.fileName ? lFile.fileName : lFile.name }}
                    </div>
                    <v-icon :color="'#7B7B7B'" @click="removeFile(i)">
                      {{ 'mdi-close-circle-outline' }}
                    </v-icon>
                  </div>
                </template>
                <div class="file-chip-placeholder" v-else>
                  {{ $t('overnightPresentations.filesWillAppear') }}
                </div>
              </div>
            </div>
            <div class="file-upload-action">
              <label for="file-upload" class="custom-file-upload">
                {{ $t('overnightPresentations.selectFiles') }}
              </label>
              <input
                id="file-upload"
                type="file"
                :accept="`${allowedUploadTypes.join(', ')}`"
                @change="previewFiles"
                multiple
              />
            </div>
          </div>
          <div class="uploadFilesAndLinksContainer">
            <div class="header">
              {{ $t('overnightPresentations.cloudlinks') }}
            </div>
            <img :src="'/assets/img/cloud-links.png'" />
            <div class="desc">
              <div>
                {{ $t('overnightPresentations.pasteLinks') }}
              </div>
              <div>{{ $t('overnightPresentations.filesLargerThan200MB') }}</div>
            </div>
            <div class="user-input">
              <div class="label">
                {{ $t('overnightPresentations.shareableLinkInNewLink') }}
              </div>
              <v-textarea
                v-model="sharedLinks"
                class="share-links"
                solo
                :rules="linkRules"
                :label="$t('overnightPresentations.pasteLinksHere')"
                :no-resize="true"
              />
            </div>
            <div
              :class="`${
                sharedLinks.length > 0 && !sharedAccess
                  ? 'check-access-error'
                  : 'check-access'
              }`"
            >
              <v-checkbox
                v-model="sharedAccess"
                :hide-details="true"
                color="#21a7e0"
                :label="$t('overnightPresentations.sharedAccessWithPrezentium')"
              />
              <span class="guide-link" @click="handleHowtoShareAccess">
                {{ $t('overnightPresentations.howToShareAccess') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="reqSubmittedContainer" v-else>
        <div class="successMessage">
          <v-icon color="#21a7e0">{{ 'mdi-check-circle' }}</v-icon>
          <div class="msg">
            {{ $t('overnightPresentations.recievedYourRequest1') }}
          </div>
        </div>
        <div class="subMsg">
          {{ $t('overnightPresentations.reachOutViaMail') }}
          <span class="linkToMyRequests" @click="handleGoToMyRequests">
            {{ $t('overnightPresentations.myRequest') }}
          </span>
        </div>
      </div>
    </div>
    <div class="submitCTA">
      <v-btn
        v-if="!isReqSubmitted"
        :disabled="
          (localFiles.length <= 0 &&
            instructions.length <= 9 &&
            sharedLinks.length <= 0) ||
          (sharedLinks.length > 0 &&
            (!isValidHttpUrl(sharedLinks) || sharedAccess === false))
        "
        @click="handleSubmitChangeRequest"
        :loading="submitting"
      >
        {{ $t('common.submit') }}
      </v-btn>
      <v-btn v-else @click="handleModalClose">
        {{ $t('common.done') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
// import { Storage } from 'aws-amplify';
import { mapActions, mapState } from 'vuex';
import {
  submitOPRequest,
  getOPManageFiles,
  postFeatureUsage,
  getFeatureUsage,
} from '../../../utils/api-helper';
import Upload from './Upload.vue';
import HowToShareAccess from './HowToShareAccess.vue';
import EventBus from '../../common/event-bus';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_INSTRUCTIONS,
  TD_PLACEMENT,
  TD_THUMBNAIL_VIEW,
  TD_EXPANDED_DETAIL_VIEW,
  TD_PREZENTATION_CATEGORY,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_VIEWTYPE,
  NA,
  TD_AUD,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'RequestChangeModal',
  data() {
    return {
      instructions: '',
      localFiles: [],
      sharedLinks: '',
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
      ],
      sharedAccess: false,
      submitting: false,
      isReqSubmitted: false,
      preferences: {
        imagePreferences: [
          {
            name: 'Incorporate images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustraion1.svg',
            value: 'HAS_IMAGE',
          },
          {
            name: 'Avoid images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustrations2.svg',
            value: 'NO_IMAGE',
          },
        ],
        colorPreferences: [
          {
            name: 'Use more color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference1.svg',
            value: 'HAS_COLOR',
          },
          {
            name: 'Use less color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference2.svg',
            value: 'NO_COLOR',
          },
        ],
        illustrationPreferences: [
          {
            name: 'Include illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustraionsPreference1.svg',
            value: 'HAS_ILLUSTRATION',
          },
          {
            name: 'Skip illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustrationPreference2.svg',
            value: 'NO_ILLUSTRATION',
          },
        ],
        dataPreferences: [
          {
            name: 'Prefers charts',
            img: '/assets/img/overnight-prezentations/preferences/dataPreferance1.svg',
            value: 'HAS_DATA',
          },
          {
            name: 'Prefers tables',
            img: '/assets/img/overnight-prezentations/preferences/dataPreference2.svg',
            value: 'NO_DATA',
          },
        ],
      },
      allowedUploadTypes: [
        '.ppt',
        '.pptx',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.txt',
        '.pdf',
        '.jpg',
        '.png',
      ],
    };
  },
  props: {
    opRequest: {
      type: Object,
      default: () => ({}),
    },
    reqOrigin: {
      type: String,
      default: '',
    },
    viewType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
  },
  methods: {
    ...mapActions('users', [
      'setFilteredThemes',
      'setOPTicketUnreadMessageData',
      'setLimitData',
      'setUpgradePopup',
    ]),
    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '32px';
        }
        return 'auto';
      }
      return file.name.length <= 40 ? '32px' : 'auto';
    },
    handleModalClose() {
      this.$modal.hide('RequestChangeModal');
    },

    removeFile(i) {
      this.localFiles.splice(i, 1);
    },
    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },
    async previewFiles(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.localFiles, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          Upload,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
          },
          {
            name: 'upload',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '12px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },

    updateLocalFiles(val) {
      this.localFiles = [...this.localFiles, ...val];
    },

    isValidHttpUrl(string) {
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },

    handleHowtoShareAccess() {
      trackOvernightPrezentationsEvents.OPHowToShareAccessClick(
        this.currentUser,
      );
      this.$modal.show(
        HowToShareAccess,
        {},
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          // width: '1330px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },

    get_prefs_mapping(pref) {
      const preference = {
        'Incorporate images': 'HAS_IMAGE',
        'Avoid images': 'NO_IMAGE',
        'Use more color': 'HAS_COLOR',
        'Use less color': 'NO_COLOR',
        'Include illustrations': 'HAS_ILLUSTRATION',
        'Skip illustrations': 'NO_ILLUSTRATION',
        'Prefers charts': 'HAS_DATA',
        'Prefers tables': 'NO_DATA',
      };
      return preference[pref];
    },

    async handleSubmitChangeRequest() {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.op?.isLimitCrossed ||
        this.currentUser.user.role.toLowerCase() !== 'trialuser'
      ) {
        postFeatureUsage({ feature: 'op', ticketId: this.opRequest.ticketId })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));

        this.submitting = true;
        const opPreferences = {
          audience: {},
          selected: {},
        };
        if (this.opRequest.personalPreference) {
          const prefs = this.opRequest.personalPreference;
          // constructing preferences from array
          for (let i = 0; i < prefs.length; i++) {
            switch (prefs[i][0]) {
              case 'Image':
                opPreferences.audience.imagePreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.imagePreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              case 'Color':
                opPreferences.audience.colorPreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.colorPreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              case 'Illustration':
                opPreferences.audience.illustrationPreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.illustrationPreferences =
                //   this.get_prefs_mapping(prefs[i][2]);
                break;
              case 'Data visualisation':
                opPreferences.audience.dataPreferences = this.get_prefs_mapping(
                  prefs[i][1],
                );
                // opPreferences.selected.dataPreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              default:
                console.log('some mismatch');
                break;
            }
          }
        }
        const body = {
          name: `${this.opRequest.name} - Change Request`,
          audienceId: this.opRequest.audienceId,
          audienceType: this.opRequest.audienceType,
          isCustomStoryline: this.opRequest.isCustomStoryline
            ? this.opRequest.isCustomStoryline
            : false,
          templateId: this.opRequest.templateId,
          personalPreference: this.opRequest.personalPreference
            ? [
                {
                  audience: opPreferences.audience,
                  selected: opPreferences.audience,
                },
              ]
            : undefined, // Omitting this payload property for email created op requests
          attachment: {
            files: [],
            links: [],
          },
          instructions: [this.instructions.replace(/\n/g, '<br />')],
          htmlBody: `<div>Change request created for <a href="${this.opRequest.ticketId}">${this.opRequest.name}</a></div>`,
        };

        if (this.sharedLinks) {
          body.attachment.links = this.sharedLinks
            .split('\n')
            .filter((ele) => ele !== '')
            .map((ele) => ({
              linkUrl: ele,
              isShared: true,
            }));
        }
        if (this.opRequest.storyline) {
          body.storyline = this.opRequest.storyline;
        }
        if (this.localFiles.length) {
          body.attachment.files = [...this.localFiles];
        }

        // getting attachments and links of parent ticket
        await getOPManageFiles(this.opRequest.ticketId)
          .then((resp) => {
            // append links
            const opLinks = resp?.data?.link;
            if (opLinks) {
              body.attachment.links = [
                ...body.attachment.links,
                ...opLinks.map((lnk) => ({
                  linkUrl: lnk.linkUrl,
                  isShared: lnk.isLinkShared,
                })),
              ];
            }

            // append attachment files
            const attachments = resp?.data?.s3;
            if (attachments && attachments.length) {
              const opFiles = attachments
                .filter((attch) => attch.type === 's3')
                .map((fl) => ({
                  fileName: fl.fileName,
                  type: 's3',
                  progress: 100,
                  s3Bucket: fl.s3Bucket,
                  s3Prefix: fl.s3Prefix,
                }));
              const existingFiles = [...body.attachment.files];
              console.log('existingFiles', existingFiles);
              console.log('opFiles', opFiles);
              body.attachment.files = [...existingFiles, ...opFiles];
            }

            // adding template info if own template was chosen for parent ticket
            if (!body.templateId) {
              const templateAttachment = attachments.find(
                (attchmnt) => attchmnt.type === 's3-template',
              );
              body.attachment.template = {
                fileName: templateAttachment.fileName,
                type: 's3-template',
                s3Bucket: templateAttachment.s3Bucket,
                s3Prefix: templateAttachment.s3Prefix,
              };
            }
          })
          .catch((err) => {
            console.log(err);
            // body.attachment.template = {};
          });

        console.log(body);
        await submitOPRequest(body)
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
        this.submitting = false;
        this.isReqSubmitted = true;
        trackOvernightPrezentationsEvents.OPMyRequestsChangeRequestSubmit(
          this.currentUser,
          {
            [TD_AUD]: this.opRequest?.audienceEmail,
            [TD_PREZNAME]: this.opRequest?.name,
            [TD_PREZTYPE]: this.opRequest?.storyline,
            [TD_VIEWTYPE]: this.viewType
              ? this.viewType
              : this.$route?.query?.viewType,
            [TD_PLACEMENT]: this.$route?.query?.viewType
              ? TD_EXPANDED_DETAIL_VIEW
              : TD_THUMBNAIL_VIEW,
            [TD_PREZENTATION_CATEGORY]: this.opRequest?.status
              ? this.opRequest?.status
              : NA,
            [TD_COMMON_COLUMN_NAME]:
              'I have shared access with slides@prezentium.com',
            [TD_COMMON_COLUMN_VALUE]: this.sharedAccess,
            [TD_INSTRUCTIONS]:
              this.instructions.length > 0 ? this.instructions : NA,
          },
        );
        EventBus.$emit('RE-RENDER-MY-REQUESTS');
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op-slide' });
      }
    },

    handleGoToMyRequests() {
      this.$router
        .push({
          path: `/home/overnight-prezentations?tab=myRequest`,
        })
        .catch(() => {});
      this.handleModalClose();
      if (this.reqOrigin === 'myRequests') {
        EventBus.$emit('REFRESH_MY_OP_REQUESTS');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.requestChangeModalContainer {
  padding: 20px;
  width: 100%;
  .closeModalIconContainer {
    width: 100%;
    .v-icon {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .contentContainer {
    width: 100%;
    .modalHeaderContainer {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      width: 100%;
      margin: 0px 0px 44px 0px;
    }
    .initiatingRequest {
      .instructions {
        width: 100%;
        margin: 0px 0px 32px 0px;
        .instructionsHeader {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          width: 100%;
        }
        .instructionsArea {
          margin-top: 12px;
          width: 100%;
          border-radius: 16px;

          ::v-deep .v-input__slot {
            filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
              drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            box-shadow: none;
            margin: 0px 0px 4px 0px !important;
            textarea {
              height: 100px;
            }
          }
          ::v-deep .v-text-field__details {
            display: none;
          }
        }
        .instructionsHelperText {
          text-align: right;
          font-size: 13px;
        }
      }
      .uploads {
        width: 100%;
        .uploadsHeader {
          width: 100%;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          margin: 0px 0px 12px 0px;
        }
        .uploadsSubHeader {
          font-size: 16px;
          line-height: 22px;
        }
        .uploadFilesAndLinksContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 64px;
          gap: 12px;
          height: 480px;
          background: #ffffff;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
            -1px -1px 4px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          margin: 22px 0px 38px 0px;

          .header {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #212121;
          }

          img {
            height: 120px;
          }

          .desc {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #212121;
          }

          .user-input {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 372px;
            // height: 149px;

            .label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
            }

            ::v-deep .share-links {
              width: 100%;
              border-radius: 16px;
              .v-input__control {
                .v-input__slot {
                  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
                    drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
                  box-shadow: none;
                  textarea {
                    height: 101px;
                  }
                }
                .v-text-field__details {
                  .v-messages {
                    color: #f70000 !important;
                    .v-messages_wrapper {
                      .v-messages_message {
                        color: #f70000 !important;
                      }
                    }
                  }
                }
              }
            }
            .file-chips-wrapper {
              padding: 12px;
              height: 113px;
              border-radius: 16px;
              background: white;
              filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
                drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
              overflow: auto;
              margin: 0px 0px 24px 0px;

              .file-chip {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 12px;
                // height: 32px;
                gap: 8px;
                position: relative;
                background: #ffffff;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
                  -1px -1px 4px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                max-width: 96%;
                margin: 4px;
                .fileNameWrapper {
                  max-width: 90%;
                  word-wrap: break-word;
                }
                .loading-file {
                  width: 90%;
                  position: absolute;
                  bottom: 0;
                }

                &.size-error {
                  border: 1px solid #b00020;
                }

                i {
                  cursor: pointer;
                }
              }

              .file-chip-placeholder {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                color: #7b7b7b;
              }
            }
          }

          .check-access {
            width: 378px;
            margin: -16px 0px 0px 0px;

            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            ::v-deep .v-input--selection-controls {
              margin-top: 0;
              padding-top: 0;

              .v-input--selection-controls__input {
                margin-right: 6px;
              }
              svg {
                width: 20px;
                height: 20px;
              }

              label {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                color: #000000;
              }
            }
          }
          .check-access-error {
            width: 378px;
            margin: -16px 0px 0px 0px;

            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            ::v-deep .v-input--selection-controls {
              margin-top: 0;
              padding-top: 0;

              .v-input--selection-controls__input {
                margin-right: 6px;
              }
              svg {
                width: 20px;
                height: 20px;
                color: red;
              }

              label {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                color: #000000;
              }
            }
          }

          .file-upload-action {
            input[type='file'] {
              display: none;
            }

            .custom-file-upload {
              padding: 6px 16px;
              height: 31px;
              border: 1.5px solid #21a7e0;
              border-radius: 25px;
              color: #21a7e0;
              cursor: pointer;
            }
          }
        }
      }
    }
    .reqSubmittedContainer {
      width: 100%;
      .successMessage {
        display: flex;
        margin: 0px 0px 16px 0px;
        .msg {
          font-size: 16px;
          padding: 2px 0px 0px 6px;
          font-weight: 600;
        }
      }
      .subMsg {
        font-size: 16px;
        .linkToMyRequests {
          color: #21a7e0;
          cursor: pointer;
        }
      }
    }
  }
  .submitCTA {
    display: flex;
    justify-content: flex-end;
    margin: 28px 0px 0px 0px;
    .v-btn {
      background-color: #21a7e0;
      box-shadow: none;
      color: #fff;
      letter-spacing: normal;
      text-transform: none;
      font-size: 17px;
      border-radius: 20px;
    }
  }
}
</style>
