var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-container"},[_c('v-btn',{staticClass:"close-btn",attrs:{"disabled":_vm.uploadInprogress,"icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),(_vm.filess.length)?_c('h1',{staticClass:"manage-heading"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.uploadLocalFiles'))+" ")]):(_vm.link.web_url)?_c('h1',{staticClass:"manage-heading"},[_vm._v(" "+_vm._s('Processing current link')+" ")]):_vm._e(),(_vm.filess.length)?_c('div',{staticClass:"rating-cont"},[_c('v-data-table',{staticClass:"user-panel",attrs:{"headers":_vm.listHeaders,"items":_vm.files,"hide-default-footer":"","no-results-text":_vm.$t('build.step1.storylineFieldNoResult')},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.fileName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fileName))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status"},[_c('div',[(item.error)?_c('div',{staticClass:"error"},[_c('v-icon',{attrs:{"color":'rgb(211 48 47)'}},[_vm._v(_vm._s('mdi-close-circle'))])],1):(item.processingCompleted)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":'#21a7e0'}},[_vm._v(_vm._s('mdi-check-circle'))])],1):_c('v-progress-circular',{attrs:{"value":item.progress,"color":'#21a7e0',"background-color":"#f2f2f2","width":3,"size":20}})],1),_c('div',[_vm._v(" "+_vm._s(item.status)+" ")])])]}}],null,true)})],1):(_vm.link.web_url)?_c('div',{staticClass:"rating-cont"},[_c('v-data-table',{staticClass:"user-panel",attrs:{"headers":_vm.listHeaders,"items":_vm.uploadedFileLink,"hide-default-footer":"","no-results-text":_vm.$t('build.step1.storylineFieldNoResult')},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [(item.fileName)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.fileName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fileName))])])]:[_vm._v(" "+_vm._s('Fetching file name...'))]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status"},[_c('div',[(item.error)?_c('div',{staticClass:"error"},[_c('v-icon',{attrs:{"color":'rgb(211 48 47)'}},[_vm._v(_vm._s('mdi-close-circle'))])],1):(item.processingCompleted)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":'#21a7e0'}},[_vm._v(_vm._s('mdi-check-circle'))])],1):_c('v-progress-circular',{attrs:{"indeterminate":"","color":'#21a7e0',"background-color":"#f2f2f2","width":3,"size":20}})],1),_c('div',[_vm._v(" "+_vm._s(item.status)+" ")])])]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }