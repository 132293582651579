<template>
  <div class="slides-container">
    <h1 class="title">
      {{ 'Edit tags' }}
    </h1>
    <v-row>
      <v-col class="right-section">
        <div v-if="isLoading">
          <clip-loader
            :loading="isLoading"
            :color="`#21a7e0`"
            :width="40"
            :height="40"
          />
        </div>
        <div
          v-else
          :style="{
            height: '100%',
            padding: '0 10px',
          }"
        >
          <h3 class="field-label">{{ $t('tagSlides.slideNameLabel') }}</h3>

          <v-text-field
            v-model="selectedSlideData.name"
            :placeholder="$t('tagSlides.slideNameLabel')"
            :rules="nameRules"
            required
            @input="handleNameChange"
            max
          />
          <div class="ideas-title-section">
            <h3 class="ideas-title-section__title">
              {{ $t('tagSlides.ideasLabel') }}
            </h3>
            <label class="ideas-title-section__subtitle">
              {{ $t('tagSlides.ideasLabelSubtitle') }}
            </label>
            <v-autocomplete
              v-model="selectedSlideData.ideas"
              :disabled="isUpdating"
              :items="ideas"
              item-text="display_name"
              item-value="category"
              :hide-selected="true"
              :menu-props="{ maxHeight: 200 }"
              :rules="ideasRule"
              @change="changeIdea"
              return-object
              chips
              :placeholder="$t('tagSlides.ideasPlaceholder')"
              :persistent-placeholder="true"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  class="selected-chips"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeIdea(data.item)"
                >
                  {{ data.item.display_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
          <div class="node-count-title-section">
            <h3 class="node-count-title-section__title">
              {{ $t('tagSlides.nodeCountLabel') }}
            </h3>
            <label class="node-count-title-section__subtitle">
              {{ $t('tagSlides.nodeCountSubtitle') }}
            </label>
            <div class="node pt-0">
              <div class="node-list">
                <span
                  v-for="item in selectedSlideData.maxNodeCount"
                  :key="item"
                >
                  <span
                    :class="`node-list-item ${
                      (Array.isArray(selectedSlideData.nodeCount)
                        ? selectedSlideData.nodeCount[0].toString()
                        : selectedSlideData.nodeCount) === item.toString()
                        ? 'outlined'
                        : ''
                    } ${
                      !selectedSlideData.maxNodeCount.includes(item) ||
                      Array.isArray(selectedSlideData.nodeCount)
                        ? 'disabled'
                        : ''
                    }`"
                    @click="handleNodeClick(item)"
                  >
                    {{ item }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="tags-title-section">
            <h3 class="tags-title-section__title">
              {{ $t('tagSlides.keywordLabel') }}
            </h3>
            <label class="tags-title-section__subtitle">
              {{ $t('tagSlides.keywordSubtitle') }}
            </label>
            <v-combobox
              v-model="selectedSlideData.tags"
              :disabled="isUpdating"
              :items="tags"
              hide-selected
              :menu-props="{ maxHeight: 200 }"
              @change="changeTag()"
              chips
              :placeholder="$t('tagSlides.keywordPlaceholder')"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  class="selected-chips"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeTag(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>

          <div class="tags-title-section">
            <h3 class="tags-title-section__title">
              {{ $t('tagSlides.templateLabel') }}
            </h3>
            <v-autocomplete
              :value="selectedSlideData.template"
              :items="templatesList || []"
              return-object
              item-value="code"
              :placeholder="$t('tagSlides.templatePlaceholder')"
              item-text="name"
              class="templates-dropdown"
              :search-input.sync="templateSearch"
              :attach="true"
              :menu-props="{
                maxHeight: '400',
                padding: '0px',
                rounded: 'lg',
              }"
              :rules="dropdownRules"
              @change="handleClickTheme"
              @blur="handleTemplateBlur"
              item-disabled="isDisabled"
            >
              <template #append>
                <v-icon class="icon search-icon">mdi-magnify</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-tooltip
                  attach
                  absolute
                  max-width="200"
                  :disabled="!item.isDisabled"
                  :content-class="'center-tooltip'"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="template-list-item"
                      :class="{
                        'template-list-item--disabled': item.isDisabled,
                        'template-selected': isCurrentSelected(item.id),
                      }"
                      v-on="on"
                    >
                      <div class="template-header">
                        <div class="template-header__left">
                          <v-tooltip
                            top
                            max-width="200"
                            content-class="tooltip-content"
                            fixed
                          >
                            <template v-slot:activator="{ on }">
                              <div class="template-title" v-on="on">
                                {{ item.name }}
                              </div>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                          <span
                            class="template-header__icon"
                            v-if="item.isPrezentTemplate"
                          >
                            <img
                              class="prezent-icon"
                              src="/assets/img/prezent_avatar.svg"
                              alt
                              width="22px"
                            />
                          </span>
                        </div>

                        <div class="template-header__right">
                          <span
                            class="template-header__icon"
                            v-if="item.isMarked"
                          >
                            <v-tooltip
                              bottom
                              max-width="200"
                              content-class="tooltip-content"
                              fixed
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  class="template-header__marked"
                                  @click="stopPropogation($event)"
                                >
                                  <v-icon color="white" small>
                                    mdi-account-heart-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span
                                >Template recommended by your team admin</span
                              >
                            </v-tooltip>
                          </span>
                          <span
                            class="template-header__icon"
                            v-if="item.isPinned"
                          >
                            <v-tooltip
                              bottom
                              max-width="200"
                              content-class="tooltip-content"
                              fixed
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="template-header__pinned"
                                  @click="stopPropogation($event)"
                                >
                                  <v-icon> mdi-pin </v-icon>
                                </v-btn>
                              </template>
                              <span>Pinned</span>
                            </v-tooltip>
                          </span>
                          <span
                            class="template-header__icon"
                            v-if="item.isDisabled"
                          >
                            <v-btn color="primary" icon>
                              <v-icon> mdi-lock </v-icon>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                      <v-row class="mb-2">
                        <v-col class="available-theme-img">
                          <img :src="item.thumbnailURL" alt />
                        </v-col>
                        <v-col class="example-slide-img">
                          <img
                            :src="
                              item.sampleSlide
                                ? item.sampleSlideURL
                                : item.thumbnailURL
                            "
                            alt
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <span
                    v-if="item.isDisabled && currentUser.user.role === 'User'"
                  >
                    This template is disabled by your team admin.
                  </span>
                  <span v-else-if="item.isDisabled">
                    This template is disabled for your team. You can enable it
                    in Templates tab under Profile.
                  </span>
                </v-tooltip>
                <!-- <div class="template-list-item">
                  <div class="template-header">
                    <v-tooltip
                      top
                      max-width="200"
                      content-class="tooltip-content"
                      fixed
                    >
                      <template v-slot:activator="{ on }">
                        <div class="template-title" v-on="on">
                          {{ item.name }}
                        </div>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                    <div
                      v-if="isCurrentSelected(item.code)"
                      class="current-selection"
                    >
                      <v-tooltip
                        top
                        max-width="200"
                        content-class="tooltip-content"
                        fixed
                      >
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            {{ $t('tagSlides.currentSelection') }}
                          </div>
                        </template>
                        <span>{{ $t('tagSlides.currentSelection') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-row class="mb-2">
                    <v-col class="available-theme-img">
                      <img :src="item.thumbnailURL" alt />
                    </v-col>
                    <v-col class="example-slide-img">
                      <img
                        :src="
                          item.sampleSlide
                            ? item.sampleSlideURL
                            : item.thumbnailURL
                        "
                        alt
                      />
                    </v-col>
                  </v-row>
                </div> -->
              </template>
              <template v-slot:no-data>
                <div class="no-templates-fount">
                  <p class="no-result-text">
                    {{ $t('tagSlides.noResultFound') }}
                  </p>
                  <div class="add-more-templates-btn" @click="handleAddMore">
                    <v-icon color="#20a7e0" class="add-icon">mdi-plus</v-icon>
                    <p>{{ $t('tagSlides.addMoreTemplatesText') }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:append-item>
                <div class="tip-bar">
                  <p>
                    {{ $t('tagSlides.templateProTipText') }}
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="ideas-title-section">
            <h3 class="ideas-title-section__title">
              {{ $t('tagSlides.speakerNotesLabel') }}
            </h3>
            <label class="tags-title-section__subtitle">
              {{ $t('tagSlides.speakerNotesSubtext') }}
            </label>
            <div class="speaker-notes-textarea">
              <v-textarea
                v-model="selectedSlideData.speaker_notes"
                solo
                name="input-7-4"
                :placeholder="$t('tagSlides.speakerNotesPlaceholder')"
                @change="handleNoteChange"
              ></v-textarea>
            </div>
          </div>
          <div class="action-wrapper">
            <v-btn
              rounded
              width="81"
              height="32"
              class="save-prez-btn"
              @click="handleAcceptTags"
              :loading="isUpdating"
              :disabled="isUpdating || errorSlides"
            >
              {{ $t('common.save') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  getSuperhawkCategories,
  getSlideDataForDetailView,
} from '../../../../utils/api-helper';
import {
  SLIDES,
  getAudienceCompanyName,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_CATEGORY,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import AddingMoreTemplateDetail from '../../Profile/AddingMoreTemplateDetail.vue';
import { isEmojiExist } from '../../../utils';
import { trackUploadEvents } from '../../../common/Analytics/UploadEvents';
import { templateSort } from '../../../../utils/utility';

export default {
  name: 'EditTag',
  components: {
    ClipLoader,
  },
  props: {
    updateSlide: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slides: [],
      ideas: [],
      isUpdating: false,
      isLoading: false,
      tags: [],
      selectedSlideData: {},
      slideInitialData: {},
      errorSlides: false,
      templateSearch: '',
      templatesList: [],
      nameRules: [
        (v) => !!v || this.$t('warnings.enterRequiredField'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) =>
          v?.length <= 99 ||
          this.$t('warnings.inputMustBeLessThan99Characters'),
      ],
      dropdownRules: [(v) => !!v || this.$t('warnings.selectRequiredField')],
      ideasRule: [
        (v) => v?.length >= 1 || this.$t('warnings.addMinimumOneBusinessIdea'),
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes', 'currentTheme']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedSectionIndex',
      'selectedSlideIndex',
    ]),
  },
  watch: {
    selectedSectionIndex() {
      this.fetchSlideMetaData();
    },
    selectedSlideIndex() {
      this.fetchSlideMetaData();
    },
    selectedSlideData(val) {
      if (val) {
        this.setErrorData(val);
      }
    },
  },
  methods: {
    ...mapActions('users', ['setFilteredThemes']),
    ...mapActions('upload', ['setUploadPlacement']),
    checkIsIdeasAreSame(oldVal, newVal, category) {
      let oldIdeas = oldVal;
      let newIdeas = newVal;
      if (category === 'ideas') {
        oldIdeas = oldVal.map((obj) => obj.category);
        newIdeas = newVal.map((obj) => obj.category);
      }
      if (oldIdeas.length !== newIdeas.length) {
        return false;
      }
      return oldIdeas.sort().join() === newIdeas.sort().join();
    },
    trackTagChangeEvents() {
      const otherData = {
        [TD_AUD]: this.prezentationData?.audienceID,
        [TD_PREZNAME]: this.prezentationData?.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_PREZENTATION_ORIGIN]: this.prezentationData?.prezentationSource,
        [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
      };
      if (
        this.slideInitialData?.ideas &&
        this.selectedSlideData?.ideas &&
        !this.checkIsIdeasAreSame(
          this.slideInitialData.ideas,
          this.selectedSlideData.ideas,
          'ideas',
        )
      ) {
        trackUploadEvents.uploadTagBusinessIdeaChanged(
          this.currentUser,
          otherData,
        );
      }
      if (this.slideInitialData?.name !== this.selectedSlideData?.name) {
        trackUploadEvents.uploadTagSlideNameChanged(
          this.currentUser,
          otherData,
        );
      }
      if (
        this.slideInitialData?.nodeCount !== this.selectedSlideData?.nodeCount
      ) {
        trackUploadEvents.uploadTagNodeCountChanged(
          this.currentUser,
          otherData,
        );
      }
      if (
        this.slideInitialData?.speaker_notes !==
        this.selectedSlideData?.speaker_notes
      ) {
        trackUploadEvents.uploadTagSpeakerNotesChanged(
          this.currentUser,
          otherData,
        );
      }
      if (
        this.slideInitialData?.template !== this.selectedSlideData?.template
      ) {
        trackUploadEvents.uploadTagSlideTemplateChanged(
          this.currentUser,
          otherData,
        );
      }
      if (
        this.slideInitialData?.tags &&
        this.selectedSlideData?.tags &&
        !this.checkIsIdeasAreSame(
          this.slideInitialData.tags,
          this.selectedSlideData.tags,
          'tags',
        )
      ) {
        trackUploadEvents.uploadTagKeywordsChanged(this.currentUser, otherData);
      }
    },
    sortedThemeArray() {
      const currentSelected = [];
      const pinnedThemes = [];
      const companyThemes = [];
      const prezentThemes = [];

      const themesArr = this.themes;
      themesArr.forEach((theme) => {
        if (theme.isArchived) {
          return;
        }
        if (theme.code === this.selectedSlideData.template) {
          currentSelected.push(theme);
        } else if (theme.isPinned) {
          pinnedThemes.push(theme);
        } else if (theme.isPrezentTemplate) {
          prezentThemes.push(theme);
        } else {
          companyThemes.push(theme);
        }
      });
      const themes = [];
      if (currentSelected.length) {
        themes.push({ header: 'Current selection' });
        themes.push(...currentSelected);
      }

      themes.push({ header: 'My Pinned Templates' });
      themes.push(...templateSort(pinnedThemes));

      themes.push({
        header: `${this.currentUser?.company?.displayName} Brand Templates`,
      });
      themes.push(...templateSort(companyThemes));

      themes.push({ header: 'Prezent Templates' });
      themes.push(...templateSort(prezentThemes));

      return themes;
    },
    isCurrentSelected(id) {
      return this.selectedSlideData?.template === id;
    },
    handleTemplateBlur() {
      if (!this.selectedSlideData?.template) {
        this.selectedSlideData.template = this.currentTheme.code;
      }
    },
    handleClickTheme(theme) {
      this.selectedSlideData.template = theme?.code;
      this.setErrorData(this.selectedSlideData);
      this.templatesList = this.sortedThemeArray();
    },
    handleNoteChange(speakerNotes) {
      this.selectedSlideData.speaker_notes = speakerNotes;
    },
    handleNameChange() {
      this.selectedSlideData = { ...this.selectedSlideData };
      this.setErrorData(this.selectedSlideData);
    },

    handleAcceptTags() {
      this.isUpdating = true;
      if (this.origin === 'build') {
        this.setUploadPlacement('Build');
      } else {
        this.setUploadPlacement('Prezentations Library');
      }
      this.trackTagChangeEvents();
      this.updateSlide({ type: 'edit_tags' }, this.selectedSlideData, () => {
        this.isUpdating = false;
        this.slideInitialData = JSON.parse(
          JSON.stringify(this.selectedSlideData),
        );
      });
    },

    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          origin: SLIDES,
          tailorID: this.getAudienceDataToTrack()?.email,
          audienceCompany: getAudienceCompanyName(
            this.prezentationData.selectedAudience,
            this.currentUser,
          ),
          templateRequestFrom: 'Slides',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    getAudienceDataToTrack() {
      try {
        return {
          email: this.currentUser?.id || this.currentUser?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },

    handleNodeClick(node) {
      this.selectedSlideData.nodeCount = `${node}`;
      this.$forceUpdate();
    },
    changeTag() {
      this.selectedSlideData = { ...this.selectedSlideData };
    },
    removeTag(item) {
      const index = this.selectedSlideData.tags.indexOf(item);
      if (index >= 0) this.selectedSlideData.tags.splice(index, 1);
    },
    changeIdea() {
      this.setErrorData(this.selectedSlideData);
    },
    removeIdea(item) {
      if (this.selectedSlideData?.ideas) {
        this.selectedSlideData.ideas = this.selectedSlideData.ideas.filter(
          (idea) => idea.category !== item.category,
        );
      } else {
        this.selectedSlideData.ideas = this.selectedSlideData.ideas.filter(
          (idea) => idea.category !== item.category,
        );
      }
      this.setErrorData(this.selectedSlideData);
    },
    setErrorData(val) {
      if (
        val.ideas?.length === 0 ||
        !val.name ||
        !val.template ||
        !val.nodeCount
      ) {
        this.errorSlides = true;
      } else {
        this.errorSlides = false;
      }
    },

    async fetchSlideData(slide) {
      if (slide) {
        this.isLoading = true;
        const categoriesRecords = await getSuperhawkCategories();
        this.isLoading = false;
        this.ideas = Object.keys(categoriesRecords).map((category) => ({
          display_name: categoriesRecords[category],
          category,
        }));

        const maxNodeCount = Math.max(
          Number(
            Array.isArray(this.selectedSlideData.nodeCount)
              ? this.selectedSlideData.nodeCount[0]
              : this.selectedSlideData.nodeCount,
          ),
          10,
        );
        this.selectedSlideData.maxNodeCount = Array.from(
          { length: maxNodeCount },
          (_, i) => i + 1,
        );
        this.selectedSlideData.tags =
          this.selectedSlideData.tags || this.selectedSlideData.keys || [];
        this.selectedSlideData.speaker_notes =
          this.selectedSlideData.description || '';
        this.selectedSlideData.ml_detected_tags =
          this.selectedSlideData.mlTags || {};
        this.selectedSlideData.template =
          this.selectedSlideData.template ||
          this.selectedSlideData?.prefs?.theme ||
          '';
        if (this.selectedSlideData?.businessIdeas) {
          if (this.selectedSlideData?.ideas) {
            this.selectedSlideData.ideas = this.selectedSlideData.ideas?.map(
              (category) => ({
                display_name: categoriesRecords[category],
                category: category?.category || category,
              }),
            );
          } else {
            this.selectedSlideData.ideas =
              this.selectedSlideData.businessIdeas?.map((category) => ({
                display_name: categoriesRecords[category],
                category: category?.category || category,
              }));
          }
          this.selectedSlideData.outline =
            this.selectedSlideData?.outline ||
            this.selectedSlideData?.ideas[0]?.display_name;
          this.selectedSlideData.category =
            this.selectedSlideData?.category ||
            this.selectedSlideData?.ideas[0]?.category;
        }
        if (
          (this.selectedSlideData?.ideas &&
            this.selectedSlideData?.ideas.length === 0) ||
          this.selectedSlideData.ideas?.length === 0
        ) {
          this.errorSlides = true;
        }

        this.tags = [...(this.selectedSlideData?.tags || [])];

        this.$forceUpdate();

        this.slideInitialData = JSON.parse(
          JSON.stringify(this.selectedSlideData),
        );
      }
    },
    async fetchSlideMetaData() {
      if (
        this.selectedSlide &&
        !(
          this.selectedSlide.assetId.includes('public/') ||
          this.selectedSlide.assetId.includes('private/') ||
          this.selectedSlide.assetId.includes('protected/')
        ) &&
        this.selectedSlide?.prefs?.is_know !== 'yes'
      ) {
        this.isLoading = true;
        let slideExtraData = {};
        let { originalUniqueID } = this.selectedSlide;
        try {
          if (
            this.selectedSlide.assetId.includes('newImageReplaceOutput/') &&
            originalUniqueID
          ) {
            originalUniqueID = originalUniqueID?.includes('replaced-image/')
              ? originalUniqueID.replaceAll('replaced-image/', '')
              : originalUniqueID;
            slideExtraData = await getSlideDataForDetailView(originalUniqueID);
          } else if (this.selectedSlide?.uniqueID) {
            slideExtraData = await getSlideDataForDetailView(
              this.selectedSlide.uniqueID,
            );
          }
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
        this.selectedSlideData = {
          ...slideExtraData.data,
          ...this.selectedSlide,
        };
      } else {
        this.selectedSlideData = {
          ...this.selectedSlide,
        };
      }
      this.fetchSlideData(this.selectedSlideData);
    },
  },
  async mounted() {
    this.fetchSlideMetaData();
    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
    this.templatesList = this.sortedThemeArray();
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.slides-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::v-deep .v-input__icon {
    display: inline-flex;
  }

  .title {
    font-size: 21px !important;
    font-family: 'Lato' !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 9px;
    padding-bottom: 8px;
    //  text-align: center;
  }

  .node {
    display: flex;
    font-size: 16px;
    margin-top: 6px;
    .node-text {
      padding-right: 10px;
    }
    .node-list {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    .node-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 28px;
      text-align: center;
      width: 28px;

      &:hover {
        background-color: $light-gray;
        border-radius: 20px;
      }
    }
    .outlined {
      background: $zen-blue-default;
      border-radius: 20px;
      color: white;
    }
    .disabled {
      pointer-events: none;
    }
    .disabled.outlined {
      color: #000;
      background-color: #dde1e6 !important;
    }
  }

  .right-section {
    label {
      font-size: 13px;
    }
  }
  .ideas-title-section {
    &__subtitle {
      font-size: 15px;
      color: #00000061;
    }
    .speaker-notes-textarea {
      margin-top: 8px;
    }
  }

  .node-count-title-section {
    padding-bottom: 20px;
    &__subtitle {
      font-size: 15px;
      color: #00000061;
    }
  }

  .tags-title-section {
    &__subtitle {
      font-size: 15px;
      padding-bottom: 8px;
      color: #00000061;
    }
  }

  .selected-chips {
    background-color: white !important;
    color: $zen-blue-default;
    font-size: 14px;
    height: 25px;
    border: 1px solid $zen-blue-default;
    > button {
      color: $zen-blue-default;
    }
  }

  .v-select__selections {
    height: 36px;
  }

  ::v-deep .v-select__selections input {
    max-height: 25px !important;
  }

  ::v-deep .v-input__icon--clear {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  ::v-deep .v-chip__close {
    color: $zen-blue-default;
  }
  ::v-deep .v-input__icon > button {
    color: $zen-blue-default;
  }

  ::v-deep .v-slide-group__next {
    margin-right: -11px;
  }
  ::v-deep .v-slide-group__prev {
    margin-left: -25px;
  }

  .v-text-field {
    padding-top: 0px;
  }

  .upload-prez-btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 !important;
    text-transform: none !important;
    color: white !important;
  }

  @-moz-document url-prefix() {
    .right-section > div {
      padding-right: 20px !important;
    }
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: left;
    background-color: white;
    bottom: -14px;
    height: 77px;
    position: fixed;
    transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;

    .save-prez-btn {
      color: $white-default;
      background-color: $zen-blue-default;
      height: 40px;
      font-size: 18px;
      font-weight: bold;
      text-transform: none;
      letter-spacing: normal;
    }
  }
}
.template-header {
  display: grid;
  grid-template-columns: 1fr auto;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 14px;

  &__left {
    grid-template-columns: auto auto;
    display: grid;
    align-items: center;
    justify-content: start;
    gap: 8px;
  }
  &__right {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__marked {
    background: #21a7e0;
    cursor: text;
  }
  &__pinned {
    cursor: text;
  }
  .template-title {
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.template-list-item {
  padding: 0 8px;
  &--disabled {
    opacity: 0.6;
  }
}
.tip-bar {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  color: #7b7b7b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 54px;
  line-height: 17px;
  letter-spacing: 0.01em;
  p {
    height: 34px;
    padding: 15px;
  }
}
.templates-dropdown {
  flex: none;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19px;
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-subheader {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none;
    font-family: 'Lato';
  }
  ::v-deep .v-list-item--disabled {
    pointer-events: unset;
  }
}
</style>
