<template>
  <div class="add-to-project-container">
    <div class="add-to-project-wrapper">
      <div class="ws-select-title-wrap">
        <div class="modal-title">
          <div class="panelHeader">{{ 'Add to Project' }}</div>
        </div>
      </div>
      <v-btn class="close-btn" icon @click="handleClose">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <SearchSelectMyWs
        :placeholderText="'Search project in my workspace'"
        @onSearch="handleSearch"
        :searchType="'project'"
        :items="searchedFiles"
        :loading="isSearchLoading"
        @onSearchedFileSelect="updateSearchSelectedFiles"
      />
      <div class="ws-selected">
        {{ selectedFiles.length }} {{ 'selected' }}
        <div>&nbsp;|&nbsp;</div>
        <div
          class="selected-btn"
          :class="`${selectedFiles.length ? 'enabled' : 'disabled'}`"
          @click="toggleSelectedFilesSection"
        >
          {{ selectedText }}
        </div>
      </div>
      <div class="files-result">
        <div class="list-wrap" v-if="showSelected">
          <div class="list-title">Selected</div>
          <div class="files-list">
            <div
              v-for="(selFile, i) in selectedFiles"
              :key="i + selFile.id"
              class="files-list-item"
            >
              <v-checkbox
                v-model="selFile.checked"
                hide-details
                color="#20a7e0"
                class="my-file"
                @change="updateCheckedFiles(selFile, 'selected')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="icon-wrapper">
                      <WsFileIcon
                        :height="'16px'"
                        :width="'16px'"
                        :iconType="'project'"
                        :fileName="selFile.title || ''"
                      />
                    </div>
                    <div class="file-name">
                      {{ selFile.title }}
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="list-wrap" v-if="!hideFav">
            <div class="list-title">Favorites</div>
            <div
              class="files-list favourite-scroll"
              v-if="favouriteFiles.length"
            >
              <div
                v-for="(favFile, i) in favouriteFiles"
                :key="i"
                class="files-list-item"
              >
                <v-checkbox
                  v-model="favFile.checked"
                  hide-details
                  color="#20a7e0"
                  class="my-file"
                  @change="updateCheckedFiles(favFile, 'fav')"
                >
                  <template #label>
                    <div class="label-wrapper">
                      <div class="icon-wrapper">
                        <WsFileIcon
                          :height="'16px'"
                          :width="'16px'"
                          :iconType="'project'"
                          :fileName="favFile.title || ''"
                        />
                      </div>
                      <div class="file-name">
                        {{ favFile.title }}
                      </div>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="loader-container" v-else-if="favLoading">
              <clip-loader
                :loading="favLoading"
                :color="`#21a7e0`"
                :width="'60'"
                :height="'60'"
                :size="'50px'"
                class="cliploader"
              />
            </div>
          </div>

          <div class="list-wrap" v-if="!hideRecent">
            <div class="list-title">Recent</div>
            <div class="files-list" v-if="recentFiles.length">
              <div
                v-for="(recFile, i) in recentFiles"
                :key="i"
                class="files-list-item"
              >
                <v-checkbox
                  v-model="recFile.checked"
                  hide-details
                  color="#20a7e0"
                  class="my-file"
                  @change="updateCheckedFiles(recFile, 'recent')"
                >
                  <template #label>
                    <div class="label-wrapper">
                      <div class="icon-wrapper">
                        <WsFileIcon
                          :height="'16px'"
                          :width="'16px'"
                          :iconType="'project'"
                          :fileName="recFile.title || ''"
                        />
                      </div>
                      <div class="file-name">
                        {{ recFile.title }}
                      </div>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="loader-container" v-else-if="recLoading">
              <clip-loader
                :loading="recLoading"
                :color="`#21a7e0`"
                :width="'60'"
                :height="'60'"
                :size="'50px'"
                class="cliploader"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!recLoading || !favLoading" class="upload-to-ws-action">
      <v-btn
        class="ws-add-to-project"
        rounded
        color="#20a7e0"
        width="134"
        height="32"
        :disabled="disableAddToProject"
        :loading="inProgress"
        @click="handleAddToProject"
      >
        {{ 'Add to project' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import SearchSelectMyWs from './SearchSelectMyWs.vue';
import { MyWorkspaceService } from './MyWorkspaceService';
import WsFileIcon from './WsFileIcon.vue';

export default {
  name: 'AddToProjectModal',
  components: {
    SearchSelectMyWs,
    WsFileIcon,
    ClipLoader,
  },
  data() {
    return {
      selectedCount: 0,
      showSelected: false,
      selectedFileIds: [],
      selectedFiles: [],
      recentFiles: [],
      favouriteFiles: [],
      searchedFiles: [],
      inProgress: false,
      isSearchLoading: false,
      recLoading: false,
      favLoading: false,
      hideFav: false,
      hideRecent: false,
    };
  },
  props: {
    updateLocalProjects: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('myWorkspace', ['currentSelectedFileId']),
    disableAddToProject() {
      return this.selectedFileIds.length !== 1;
    },
    selectedText() {
      return this.showSelected ? 'Hide Selected' : 'Show Selected';
    },
    myWorkspaceService() {
      return new MyWorkspaceService();
    },
  },
  beforeMount() {
    this.favLoading = true;
    this.getProjects({ favourites: true })
      .then((res) => {
        if (res.data.length) {
          this.favouriteFiles = res.data.map(({ id, title }) => ({
            id,
            title,
            checked: false,
          }));
        }
        this.hideFav = res.data.length === 0;
        this.favLoading = false;
      })
      .catch(() => {
        this.favLoading = false;
      });
    this.recLoading = true;
    this.getProjects({ limit: 3 })
      .then((res) => {
        if (res.data.length) {
          this.recentFiles = res.data.map(({ id, title }) => ({
            id,
            title,
            checked: false,
          }));
        }
        this.hideRecent = res.data.length === 0;
        this.recLoading = false;
      })
      .catch(() => {
        this.recLoading = false;
      });
  },
  methods: {
    ...mapActions('myWorkspace', [
      'setDropdownAction',
      'setCurrentSelectedFileId',
    ]),
    async getProjects(params) {
      return this.myWorkspaceService.getWsData({
        ...params,
        type: 'projects',
        offset: 0,
      });
    },
    handleClose() {
      this.setDropdownAction('');
      this.setCurrentSelectedFileId('');
      this.$modal.hide('AddToProjectModal');
    },
    toggleSelectedFilesSection() {
      this.showSelected = !this.showSelected;
    },
    updateCheckedFiles(changedFile, sourceArray) {
      // Sync the checked state between all three arrays
      if (sourceArray === 'recent') {
        this.syncOtherFiles(changedFile, 'recent');
      } else if (sourceArray === 'fav') {
        this.syncOtherFiles(changedFile, 'fav');
      } else if (sourceArray === 'search') {
        this.syncOtherFiles(changedFile, 'search');
      } else if (sourceArray === 'selected') {
        this.syncOtherFiles(changedFile, 'recent');
        this.syncOtherFiles(changedFile, 'fav');
        this.syncOtherFiles(changedFile, 'search');
      }
      if (changedFile.checked) {
        this.addFileToSelectedFiles(changedFile);
      } else {
        this.removeFileFromSelectedFiles(changedFile.id, sourceArray);
      }
    },
    addFileToSelectedFiles(file) {
      const alreadySelected = this.selectedFiles.some((f) => f.id === file.id);
      if (!alreadySelected) {
        this.selectedFiles.push(file);
      }
      this.selectedFileIds = this.selectedFiles.map((item) => item.id);
    },

    removeFileFromSelectedFiles(fileId, sourceArray) {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== fileId);
      this.selectedFileIds = this.selectedFiles.map((item) => item.id);
      if (this.selectedFiles.length === 0 && sourceArray === 'selected') {
        this.toggleSelectedFilesSection();
      }
    },
    handleAddToProject() {
      this.inProgress = true;
      this.myWorkspaceService
        .updateWorkspace({
          workspaceProjectID: this.selectedFileIds[0], // Single project for internal release
          newFileIds: [this.currentSelectedFileId],
        })
        .then((data) => {
          console.log('wsdata', data);
          this.updateLocalProjects();
          setTimeout(() => {
            this.inProgress = false;
            this.handleClose();
          }, 1500);
        });
    },
    syncOtherFiles(changedFile, sourceArray) {
      if (sourceArray !== 'recFiles') {
        const recFile = this.recentFiles.find((f) => f.id === changedFile.id);
        if (recFile) recFile.checked = changedFile.checked;
      }

      if (sourceArray !== 'favFiles') {
        const favFile = this.favouriteFiles.find(
          (f) => f.id === changedFile.id,
        );
        if (favFile) favFile.checked = changedFile.checked;
      }

      if (sourceArray !== 'searchedFiles') {
        const searchedFile = this.searchedFiles.find(
          (f) => f.id === changedFile.id,
        );
        if (searchedFile) searchedFile.checked = changedFile.checked;
      }
    },
    updateSearchSelectedFiles(searchSelectedFile) {
      this.updateCheckedFiles(searchSelectedFile, 'search');
    },
    handleSearch(searchText) {
      clearTimeout(this.debounceTimer);
      this.isSearchLoading = true;
      this.debounceTimer = setTimeout(() => {
        this.debouncedQuery = this.query;
        this.searchText = searchText;
        if (searchText) {
          this.getProjects({ search: searchText }).then((res) => {
            if (res.data) {
              const searchFiles = res.data;
              if (this.selectedFiles.length) {
                // map selected files to the search result
                this.selectedFiles.forEach((file) => {
                  const searchedFile = searchFiles.find(
                    (sfile) => sfile.id === file.id,
                  );
                  if (searchedFile) {
                    searchedFile.checked = file.checked;
                  }
                });
              }
              this.searchedFiles = searchFiles;
            }
            this.isSearchLoading = false;
          });
        } else {
          this.isSearchLoading = false;
          this.searchedFiles = [];
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-project-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .modal-title {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-top: 30px;
  }
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: black;
  }
  .add-to-project-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 25px 0 10px;
    width: 100%;
    .ws-search-bar {
      padding-left: 10px;
    }
    .ws-select-title-wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-left: 10px;
      .ws-title {
        display: flex;
        align-items: center;
        img {
          height: 40px;
          width: 40px;
        }
        .panelHeader {
          color: #075689;
          font-family: Lato;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
        }
      }
    }
    .ws-selected {
      display: flex;
      align-items: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4d5358;
      padding: 20px 0px 0px 10px;

      .disabled {
        pointer-events: none;
        color: #4d5358;
      }
      .enabled {
        cursor: pointer;
        color: #21a7e0;
      }
    }
    .files-result {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      .list-wrap {
        margin-bottom: 10px;
        .list-title {
          font-family: Lato;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-bottom: 8px;
          padding-left: 10px;
        }
        .files-list {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          padding-left: 10px;
          margin-left: 5px;
          .files-list-item {
            ::v-deep .v-input--selection-controls {
              margin-top: 0;
              padding-top: 0;
              width: 100%;
              .v-input__control {
                .v-input__slot {
                  margin-bottom: 0;
                  .v-input--selection-controls__input {
                    margin-right: 10px;
                  }
                }
                .v-messages {
                  display: none;
                }
              }
            }
            .label-wrapper {
              display: flex;
              column-gap: 5px;
              align-items: center;
              .icon-wrapper {
                display: flex;
              }
            }

            display: flex;
          }
        }
        .favourite-scroll {
          overflow-y: auto;
          height: 103px;
          padding-top: 10px;
        }
      }
    }
  }
  .upload-to-ws-action {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    padding-right: 15px;
    position: absolute;
    bottom: 30px;
    right: 10px;
    z-index: 1;
    .ws-add-to-project {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: white;
      text-transform: none;
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      //   margin-bottom: 10px;
    }
  }
}

.file-name {
  width: calc(100% - 21px);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121619;
}
</style>
