var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSlidesLimitCrosses)?_c('div',{staticClass:"generate-slides"},[(_vm.rightPanelComponent === 'promptExamples')?_c('div',[_c('div',[_c('v-btn',{staticClass:"back-btn",attrs:{"text":"","color":"primary"},on:{"click":_vm.closePromptExamples}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s('mdi-chevron-left'))]),_vm._v(" Back ")],1)],1),_c('SlideGeneratorProTips',{attrs:{"origin":"prezentations"}})],1):_vm._e(),(_vm.rightPanelComponent === 'AddSlides')?_c('AddNewSlide',{attrs:{"slideCount":_vm.getTotalSlides,"promptDetails":_vm.changeReq,"isFromSmartTools":false,"origin":"prezentations"},on:{"change":_vm.handleGenerateSlides}}):_vm._e(),(_vm.rightPanelComponent === 'MagicFill')?_c('MagicFill',{attrs:{"selectedSlide":_vm.getSlideDetailFinal,"promptDetails":_vm.changeReq,"origin":"prezentations"},on:{"panelChange":_vm.openContext,"change":_vm.handleGenerateSlides}}):_vm._e(),(_vm.rightPanelComponent === 'smartCharts')?_c('SmartCharts',{attrs:{"slideType":_vm.selectedSlide.slide_type,"origin":"prezentations"},on:{"change":_vm.handleGenerateSlides}}):_vm._e(),(_vm.rightPanelComponent === 'replaceVisual')?_c('replace-visual',{ref:"replaceVisual",attrs:{"slide":_vm.selectedSlide,"extractedImages":_vm.selectedSlide.extractedImages,"origin":"prezentations"},on:{"error":_vm.replaceImageError,"change":_vm.handleGenerateSlides,"toggleReplaceImage":_vm.onImageReplaced,"replaceStart":_vm.startReplaceVisuals}}):_vm._e(),(_vm.rightPanelComponent === 'changeLayout')?_c('ChangeLayout',{attrs:{"relatedSlides":_vm.selectedSlide ? _vm.selectedSlide.relatedSlides : [],"origin":"prezentations"},on:{"change":_vm.handleGenerateSlides}}):_vm._e(),(
      _vm.rightPanelComponent === 'nodeCount' &&
      _vm.selectedSlide &&
      _vm.selectedSlide.slide_type === 'table'
    )?_c('TableNodeCount',{attrs:{"node":_vm.getNodeCount},on:{"change":_vm.handleGenerateSlides}}):_vm._e(),(
      _vm.rightPanelComponent === 'nodeCount' &&
      _vm.selectedSlide &&
      _vm.selectedSlide.slide_type !== 'table'
    )?_c('NodeCount',{attrs:{"nodeCount":_vm.selectedSlide.slide_node_count,"siblings":_vm.selectedSlide.siblings,"slideType":_vm.selectedSlide.slide_type,"origin":"prezentations"},on:{"change":_vm.handleGenerateSlides}}):_vm._e(),_c('ConfirmationDialog',{attrs:{"show":_vm.showConfirmation,"on-primary-click":_vm.onDialogCancel,"on-secondary-click":_vm.onDialogConfirm,"on-close":_vm.onDialogCancel,"primary-btn-text":_vm.replaceVisualCancel ? _vm.replaceVisualCancel : _vm.$t('common.cancel'),"secondary-btn-text":_vm.dialogPrimary,"title":_vm.dialogTitle,"text":_vm.dialogText}})],1):_c('div',{staticClass:"generate-slides"},[_vm._m(0),(!_vm.isSlidesLimitCrosses)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1):_c('div',{staticClass:"suggest-block__summary-text",style:({
      marginTop: '80px',
    })},[_c('img',{attrs:{"src":"/assets/img/compliance-check-failed.svg","alt":""}}),_c('br'),_c('br'),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading-block"},[_c('div',{staticClass:"heading-block__main"},[_vm._v("Generate slide")]),_c('div',{staticClass:"heading-block__sub"},[_vm._v("Powered by ASTRID™ AI")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"error-container"},[_c('div',{staticClass:"error-text"},[_vm._v(" Generate slides is optimized for decks with up to 25 slides. Please consider removing some slides from your presentation and try again. ")])])}]

export { render, staticRenderFns }