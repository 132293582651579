<template>
  <div class="prompt-example-container">
    <div class="heading">
      {{ $t('generate.promptExamplesText') }}
      <!-- <div class="sub">Powered by ZenSense™ AI</div> -->
    </div>
    <v-tabs color="#21a7e0" height="57" fixed-tabs v-model="currentTab">
      <v-tab class="vtab" v-if="allowAgProTips">
        <b>{{ $t('generate.proTips.title') }}</b>
      </v-tab>
      <v-tab class="vtab">
        <b>Examples</b>
      </v-tab>
      <v-tab class="vtab">
        <b>{{ 'Context' }}</b>
      </v-tab>
      <v-tabs-items v-model="currentTab" class="slides-example-container">
        <v-tab-item class="pro-tip-container" v-if="allowAgProTips">
          <v-expansion-panels flat accordion>
            <v-expansion-panel v-for="(pTips, i) in proTips" :key="i">
              <v-expansion-panel-header class="pe-list__panel_header">
                <div class="pe-list__header">
                  <div>
                    {{ pTips.label }}
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pe-list__content">
                  <p class="pe-list__description" v-if="pTips.desc">
                    {{ pTips.desc }}
                  </p>
                  <div class="chip-container p-tips" v-if="pTips.isImageTab">
                    <v-chip-group
                      v-model="selectedImgChip"
                      mandatory
                      cols="1"
                      active-class="active-chip"
                    >
                      <v-chip
                        class="img-chip"
                        :ripple="false"
                        v-for="chip in pTips.imgChips"
                        :key="chip"
                      >
                        {{ chip }}
                      </v-chip>
                    </v-chip-group>
                    <div class="pe-list__img-container">
                      <img
                        class="pe-list__image"
                        v-if="pTips.imgsPath[selectedImgChip]"
                        :src="pTips.imgsPath[selectedImgChip]"
                      />
                    </div>
                  </div>

                  <template v-else-if="pTips.tips">
                    <ProTips
                      v-for="(tips, j) in pTips.tips"
                      :key="j"
                      :tips="tips"
                    />
                  </template>
                  <a @click="onViewExamples(pTips.viewExamplesTab)">
                    {{ $t('generate.proTips.viewExamples') }}
                  </a>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item>
          <div class="slides-example-content">
            <div class="chip-container">
              <v-chip-group
                v-model="selectedIndustry"
                mandatory
                cols="1"
                active-class="active-chip"
              >
                <v-chip
                  class="img-chip"
                  :ripple="false"
                  v-for="industry in industries"
                  :key="industry"
                  :value="industry"
                >
                  {{ industry }}
                </v-chip>
              </v-chip-group>
            </div>
            <div
              v-for="(slide, index) in slides[selectedIndustry]"
              class="slide-content"
              :key="slide.tip"
            >
              <div class="tip-container">
                <img src="/assets/img/generate/Tips-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.tip') }}</div>
                  <div class="tip-info">{{ slide.tip }}</div>
                </div>
              </div>
              <div v-for="(prompt, idx) in slide.prompts" :key="idx">
                <div class="tip-container">
                  <img src="/assets/img/generate/Examples-icon.svg" />
                  <div class="content">
                    <div class="tip-header">{{ $t('generate.example') }}</div>
                    <div class="tip-info" v-html="prompt.example"></div>
                  </div>
                </div>
                <img
                  :src="prompt.image"
                  class="tip-img"
                  @click="openDetailedView('slides', index, idx)"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="slides-example-content">
            <div class="chip-container">
              <v-chip-group
                v-model="selectedIndustry"
                mandatory
                cols="1"
                active-class="active-chip"
              >
                <v-chip
                  class="img-chip"
                  :ripple="false"
                  v-for="industry in industries"
                  :key="industry"
                  :value="industry"
                >
                  {{ industry }}
                </v-chip>
              </v-chip-group>
            </div>
            <div
              v-for="context in context[selectedIndustry]"
              class="slide-content"
              :key="context.tip"
            >
              <div class="tip-container">
                <img src="/assets/img/generate/Tips-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.tip') }}</div>
                  <div class="tip-info">{{ context.tip }}</div>
                </div>
              </div>
              <div class="tip-container">
                <img src="/assets/img/generate/Examples-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.example') }}</div>
                  <div class="tip-info" v-html="context.prompt"></div>
                </div>
              </div>
              <div class="tip-container">
                <img src="/assets/img/generate/context.svg" />
                <div class="content">
                  <div class="tip-header">{{ 'Additional Context' }}</div>
                  <div class="context-container">
                    <div
                      class="context"
                      v-for="(file, index) in context.files"
                      :key="index"
                    >
                      <img :src="file.icon" />
                      <div>{{ file.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <img
                  :src="slide.image"
                  class="tip-img"
                  @click="openDetailedView('slides', index)"
                /> -->
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ProTips from '../../../../common/SharedComponents/ProTips.vue';
import PromptExamplesDetailedView from '../../../GenerateSlides/PromptExampleDetailedView.vue';

export default {
  name: 'PromptExamples',
  components: {
    ProTips,
  },
  props: {
    openContext: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTab: 0,
      currentProTip: 0,
      tabKey: 0,
      selectedImgChip: 0,
      industries: ['Bio Pharma', 'Technology', 'Others'],
      selectedIndustry: 'Bio Pharma',
      proTips: [
        // {
        //   label: this.$t('generate.proTips.tip1.label'),
        //   isImageTab: true,
        //   imgChips: ['Example 1', 'Example 2', 'Example 3', 'Example 4'],
        //   imgsPath: [
        //     '/assets/img/generate/pro-tip-1.svg',
        //     '/assets/img/generate/pro-tip-2.svg',
        //     '/assets/img/generate/pro-tip-3.svg',
        //     '/assets/img/generate/pro-tip-4.svg',
        //   ],
        //   viewExamplesTab: 2,
        // },
        {
          label: this.$t('generate.proTipsForPrez.tip1.label'),
          desc: this.$t('generate.proTipsForPrez.tip1.desc'),
          tips: this.$t('generate.proTipsForPrez.tip1.tips'),
          viewExamplesTab: 1,
        },
        {
          label: this.$t('generate.proTipsForPrez.tip2.label'),
          desc: this.$t('generate.proTipsForPrez.tip2.desc'),
          tips: this.$t('generate.proTipsForPrez.tip2.tips'),
          viewExamplesTab: 1,
        },
        {
          label: this.$t('generate.proTipsForPrez.tip3.label'),
          desc: this.$t('generate.proTipsForPrez.tip3.desc'),
          tips: this.$t('generate.proTipsForPrez.tip3.tips'),
          viewExamplesTab: 1,
        },
      ],
      slides: {
        'Bio Pharma': [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  "Create a slide highlighting Cmova's breakthrough in gene therapy and its significance in advancing genetic research and treatment development. Add an <strong>image of a DNA helix.</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_prez_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  "Design a slide with <strong>5 points</strong> on Viromina's strategy for expanding into emerging markets, covering regulatory challenges, local partnerships, and workforce training initiatives.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_prez_2.png',
              },
            ],
          },
          {
            tip: "Visuals and statistics together can create a powerful impact. Mention specific graphs or charts you'd like to include in your slide.",
            prompts: [
              {
                example:
                  "Create a <strong>clustered bar graph</strong> detailing the financial performance of Pharmavex's key drug portfolio, showcasing revenue growth, market penetration, and long-term profitability.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_prez_3.png',
              },
            ],
          },
        ],
        Technology: [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  "Design a slide showcasing TechNova's latest AI-driven software platform, emphasizing its role in automating workflows, enhancing data analytics, and improving cybersecurity. Add an <strong>image of a server room.</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Tech_prez_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  "Create a slide with <strong>3 key points</strong> on Aztec AI's use of machine learning for predictive analytics, covering its applications in retail, healthcare, and finance.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Tech_prez_2.png',
              },
            ],
          },
          {
            tip: "Visuals and statistics together can create a powerful impact. Mention specific graphs or charts you'd like to include in your slide",
            prompts: [
              {
                example:
                  "Create a <strong>pie chart</strong> highlighting TeleAir's market share in the telecom industry, emphasizing key services such as broadband, mobile connectivity, and enterprise solutions.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Tech_prez_4.png',
              },
            ],
          },
        ],
        Others: [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  "Create a slide focusing on WealthSafe Bank's customer retention initiatives, showcasing the impact of personalized financial services and mobile banking solutions on customer loyalty. Add an <strong>image on customer satisfaction.</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_prez_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  'Design a slide with <strong>5 points</strong> on AiroGo’s sustainable aviation initiatives, focusing on eco-friendly designs, fuel efficiency, and carbon footprint reduction.',
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_prez_2.png',
              },
            ],
          },
          {
            tip: "Visuals and statistics together can create a powerful impact. Mention specific graphs or charts you'd like to include in your slide.",
            prompts: [
              {
                example:
                  'Generate a <strong>line graph</strong> showing the growth of RetailAce’s online sales, highlighting year-over-year increases in customer purchases and revenue.',
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_prez_3.png',
              },
            ],
          },
        ],
      },
      context: {
        'Bio Pharma': [
          {
            tip: 'Need to provide more context? Just upload the relevant documents and specify the key sections. Auto Generator will create a slide focused on the topics you want to highlight.',
            prompt:
              "Create a slide detailing the financial performance of a NexCure's key drug portfolio, emphasizing revenue growth, market expansion, and the strategic importance of new product launches in driving future profitability.",
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'NexCure Annual Performance Report',
              },
            ],
          },
          {
            tip: 'Multiply the impact! Upload multiple files to provide richer context, and Auto Generator will create a slide that blends insights from all the provided documents.',
            prompt:
              'Create a slide outlining the latest biopharma regulatory changes, focusing on how companies are navigating evolving compliance requirements and preparing for the approval of new therapies.',
            files: [
              {
                icon: '/assets/img/generate/DOC.svg',
                name: 'Compliance Risk Report',
              },
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Company Annual Report ',
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the given document',
            files: [
              {
                icon: '/assets/img/generate/PPT.svg',
                name: 'US Bio Pharma landscape',
              },
              {
                icon: '/assets/img/generate/link.svg',
                name: 'Weblink covering FDA Regulation Updates',
              },
            ],
          },
        ],
        Technology: [
          {
            tip: 'Need to provide more context? Just upload the relevant documents and specify the key sections. Auto Generator will create a slide focused on the topics you want to highlight.',
            prompt:
              'Create a slide outlining the latest trends in artificial intelligence and machine learning, focusing on how NanoByte is integrating these technologies to drive business innovation, enhance customer experiences, and improve operational efficiencies.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'NanoByte Growth Plan',
              },
            ],
          },
          {
            tip: 'Multiply the impact! Upload multiple files to provide richer context, and Auto Generator will create a slide that blends insights from all the provided documents.',
            prompt:
              "Create a slide focusing on the performance of Ominore's cloud computing services over the past year, emphasizing scalability improvements, customer acquisition, and the impact of recent product innovations on revenue growth.",
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Annual Report on Ominore’s cloud service performance metrics',
              },
              {
                icon: '/assets/img/generate/PPT.svg',
                name: "Presentation on Ominore's roadmap for cloud service enhancements in 2025",
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the report in a slide',
            files: [
              {
                icon: '/assets/img/generate/PPT.svg',
                name: "10-slide deck on ACME's Tech Vision 2028",
              },
            ],
          },
        ],
        Others: [
          {
            tip: 'Need to provide more context? Just upload the relevant documents and specify the key sections. Auto Generator will create a slide focused on the topics you want to highlight.',
            prompt:
              'Create a slide showcasing the financial performance of RetailAce over the past year, focusing on growth in online sales, regional expansion, and customer engagement through personalized marketing campaigns.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Document on RetailAce Financial outlook report',
              },
            ],
          },
          {
            tip: 'Multiply the impact! Upload multiple files to provide richer context, and Auto Generator will create a slide that blends insights from all the provided documents.',
            prompt:
              'Create a slide outlining the latest trends in supply chain management, focusing on automation, sustainability efforts, and strategies for improving operational efficiency.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'White paper on the role of AI in automating supply chain processes',
              },
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Sustainability benchmarks and practices report',
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the attached document in a single slide',
            files: [
              {
                icon: '/assets/img/generate/DOC.svg',
                name: 'A 5-page report on PureWater Outreach Programs',
              },
            ],
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters('users', ['allowAgProTips']),
  },
  methods: {
    openDetailedView(type, index, exampleIndex) {
      console.log(type, index);
      this.$modal.show(
        PromptExamplesDetailedView,
        {
          type,
          data:
            type === 'slides'
              ? this.slides[this.selectedIndustry][index].prompts[exampleIndex]
              : this.prezentations[this.selectedIndustry][index].examples[
                  exampleIndex
                ],
        },
        {
          name: 'PromptExamplesDetailedView',
          width: type === 'slides' ? '830px' : '1074px',
          height: 'auto',
          styles: {
            'max-height': '90%',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    onViewExamples(tab) {
      this.currentTab = tab;
    },
  },
  mounted() {
    if (this.openContext) {
      if (this.allowAgProTips) {
        this.currentTab = 3;
      } else {
        this.currentTab = 2;
      }
    }
  },
};
</script>
<style scoped lang="scss">
.prompt-example-container {
  padding: 20px 50px 20px 20px;
  width: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .v-tab {
    text-transform: none !important;
    color: black;
    font-weight: 300;
    font-family: 'Lato';
    font-size: 1.1rem;
    letter-spacing: normal;
    padding: 16px 8px 16px 8px;
  }

  .slide-content {
    border-bottom: 0.5px solid #c1c7cd;
  }

  .tip-container {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    padding: 10px 10px 10px 0px;
    align-items: flex-start;

    .content {
      position: relative;
      top: 8px;

      .tip-header {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #075689;
        margin-bottom: 10px;
      }

      .tip-info {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
      }
    }
  }

  .tip-img {
    border-radius: 6px;
    margin: 16px 0;
    margin-left: 60px;
    margin-bottom: 40px;
    width: calc(100% - 60px);
    border: 0.5px solid #c1c7cd;
    cursor: pointer;
    ::v-deep .v-icon {
      color: white;
    }
  }

  .context-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
    margin-top: 20px;

    .context {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .slides-example-container {
    height: calc(100vh - 261px);
    overflow: auto;
    padding-right: 10px;
  }
}
@-moz-document url-prefix() {
  .slide-content {
    padding-right: 10px;
  }
}
.pe-list {
  &__panel_header {
    padding: 16px 0;
    min-height: 64px;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
    font-size: 16px;
  }
  &__description {
    text-align: left;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #212121;
  }
}
.pro-tip-container {
  margin-top: 10px;
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
    margin-bottom: 10px;
  }
}

.chip-container {
  margin-bottom: 12px;

  &.p-tips {
    ::v-deep .v-slide-group__content {
      justify-content: space-between;
      margin-top: 0;
    }
  }
  ::v-deep .v-slide-group__content {
    gap: 12px;
    margin-top: 28px;
    padding-right: 10px;
    padding-top: 0;
    .active-chip {
      background: #e1f5fb !important;
      border: 1px solid transparent !important;
      &::before {
        opacity: 0;
      }
    }
    v-chip,
    .v-chip--outlined,
    .v-chip.v-chip {
      background: none;
      border: 1px solid rgba(105, 112, 119, 1);
      margin-top: 0;
    }
  }
  .img-chip {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    border: 1px solid #697077;
    color: #343a3f;
    border-radius: 8px;
  }
  img {
    width: 100%;
  }
}
</style>
