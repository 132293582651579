<template>
  <div class="td-progress-container">
    <v-tooltip bottom fixed max-width="350" close-delay="1500">
      <template v-slot:activator="{ on, attrs }">
        <div v-if="theme.thumbnailURL">
          <img class="pt-card__thumbnail" :src="theme.thumbnailURL" />
        </div>
        <div v-if="theme.status === 'inprogress'" class="progress-container">
          <v-progress-circular
            :value="theme.percentage"
            :size="128"
            :width="12"
            :rotate="-90"
            color="#121619"
            v-bind="attrs"
            v-on="on"
          >
            <span class="percentage"> {{ theme.percentage }}% Complete </span>
          </v-progress-circular>
        </div>
        <div v-else-if="theme.status === 'failed'" class="error-container">
          <img
            class="td-error"
            :src="'assets/img/templateDesign/warning-line.png'"
            v-bind="attrs"
            v-on="on"
          />
        </div>
      </template>
      <div
        class="tooltip"
        style="white-space: pre-wrap"
        v-if="theme.status === 'inprogress'"
      >
        {{ $t('templateDesign.inProgressTooltip') }}
        <a @click="getInTouch()">{{ $t('templateDesign.getInTouch') }}</a>
      </div>
      <div
        class="tooltip"
        style="white-space: pre-wrap; text-align: center"
        v-else-if="theme.status === 'failed'"
      >
        {{ $t('templateDesign.tdFailedTooltip') }}
        <a @click="getInTouch()">{{ $t('templateDesign.getInTouch') }}</a>
        {{ $t('templateDesign.withDesignTeam') }}
      </div>
    </v-tooltip>
  </div>
</template>
<script>
import moment from 'moment';
import { sendEmail } from '../../../../utils/api-helper';
import ContactSoonModal from '../../../common/ContactSoonModal.vue';

export default {
  name: 'TemplateInProgress',
  props: {
    theme: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getInTouch() {
      console.log('getInTouch');
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.td-progress-container {
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  ::v-deep .v-progress-circular__info {
    background: #c1c7cd;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .percentage {
    width: 70%;
  }
  ::v-deep .v-progress-circular__underlay {
    stroke: #f2f4f8;
  }
}
.v-tooltip__content {
  pointer-events: initial;
}
.center-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 300px;
  opacity: 1;
}
.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
.error-container {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
</style>
